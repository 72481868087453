import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from '@reach/router'
import { snakeCase, sortBy } from 'lodash'
import classnames from 'classnames'
import { colors } from '../../utils/style-guide'
import { Breadcrumb } from './types'
import { Tooltip } from '../Tooltip'
import { IconWrapper } from '../IconWrapper'
import { ChevronRight } from '../Icons'

interface BreadcrumbsProps {
  list: Breadcrumb[]
  truncate?: boolean
}

const useStyles = makeStyles({
  button: {
    minWidth: '0px',
  },
  linkText: {
    color: colors.neutralMid300,
    '&:hover, &:visited': {
      textDecoration: 'underline',
    },
  },
  linkColor: {
    color: colors.brand100,
  },
  slash: {},
  '@media (max-width: 1000px)': {
    linkText: {
      '&:not(:last-child)': {
        display: 'none',
      },
    },
    slash: {
      display: 'none',
    },
  },
})

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  list = [],
  truncate = true,
}) => {
  // sort the breadcrumbs on the basis of level given
  const sortedList = sortBy(list, 'level')

  const isLastItem = (index: number) => index === sortedList.length - 1

  // We want to always show the first, second, second last and last breadcrumb
  const indicesToKeep = [0, 1, sortedList.length - 2, sortedList.length - 1]

  const shouldTruncateBreadcrumbs = (index: number) => {
    if (sortedList.length > 4 && !indicesToKeep.includes(index)) {
      return true
    }
    return false
  }
  const TRUNCATE_LENGTH = 12
  const shouldTruncateBreadcrumb = (text: string) => {
    if (truncate && text.length > TRUNCATE_LENGTH) {
      return (
        <Tooltip info={text} placement='bottom' arrow>
          <span>{text.substring(0, TRUNCATE_LENGTH)}...</span>
        </Tooltip>
      )
    }
    return text
  }

  const classes = useStyles()

  const BreadcrumbComponent = ({
    item,
    index,
  }: {
    item: Breadcrumb
    index: number
  }) => {
    return (
      <span>
        {shouldTruncateBreadcrumbs(index) ? (
          <span>...</span>
        ) : (
          <Link
            className={classnames(classes.linkText, {
              [classes.linkColor]: !isLastItem(index),
            })}
            key={item.label}
            to={item.url}
          >
            {shouldTruncateBreadcrumb(item.label)}
          </Link>
        )}
        {!isLastItem(index) && (
          <IconWrapper
            inline
            size='s'
            style={{
              verticalAlign: 'middle',
              marginBottom: '2px',
              color: colors.brand100,
            }}
          >
            <ChevronRight />
          </IconWrapper>
        )}
      </span>
    )
  }

  return (
    <div>
      {sortedList.map((item, index) => {
        return (
          <BreadcrumbComponent
            key={snakeCase(item.label)}
            item={item}
            index={index}
          />
        )
      })}
    </div>
  )
}
