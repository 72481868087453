import * as React from 'react'

function SvgEmr(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.508 5.708A6.84 6.84 0 0112 4c3.363 0 6.154 2.545 6.457 5.828 1.99.286 3.543 1.983 3.543 4.065C22 16.179 20.128 18 17.859 18H6.726C4.135 18 2 15.92 2 13.314 2 11.07 3.583 9.212 5.678 8.74c.178-1.098.872-2.193 1.83-3.033zm.816.963c-.946.832-1.441 1.833-1.441 2.617v.57l-.557.063c-1.746.195-3.076 1.654-3.076 3.393 0 1.867 1.537 3.413 3.476 3.413H17.86c1.616 0 2.891-1.288 2.891-2.834 0-1.548-1.275-2.836-2.891-2.836h-.625v-.636C17.235 7.595 14.91 5.273 12 5.273a5.604 5.604 0 00-3.676 1.4V6.67z'
        fill='#42526E'
      />
      <path
        d='M11.5 22a.5.5 0 001 0h-1zm.854-12.354a.5.5 0 00-.708 0l-3.182 3.182a.5.5 0 10.708.708L12 10.707l2.828 2.829a.5.5 0 10.708-.708l-3.182-3.182zM12.5 22V10h-1v12h1z'
        fill='#42526E'
      />
    </svg>
  )
}

export default SvgEmr
