import './polyfills'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { environment } from '@awell/libs-web/environment'
import App from './App'
import { initializeCrashReporter } from './crash-reporter'
import { isNil } from 'lodash'

if (module.hot != null) {
  module.hot.accept()
}
// @ts-expect-error Intercom is loaded through cdn script, so typescript doesn't know about it
const { Intercom } = window
if (!isNil(Intercom)) {
  Intercom('boot', {
    app_id: environment.intercom_app_id,
    // Website visitor so may not have any user related info
  })
}

initializeCrashReporter()

ReactDOM.render(<App />, document.getElementById('app'))
