import { isNil } from 'lodash'
import { useNotifications } from '../useNotifications'
import { useGetStakeholdersByPathwayDefinitionIdsQuery, type StakeholderOption } from './types'

interface UseStakeholdersByPathwayDefinitionIdsHook {
  loading: boolean
  stakeholders?: Array<StakeholderOption>
}

export const useStakeholdersByPathwayDefinitionIds = ({ 
    pathwayDefinitionIds,
}: {
    pathwayDefinitionIds: Array<string>
}): UseStakeholdersByPathwayDefinitionIdsHook => {
  const { notifyError } = useNotifications()
  const { data, loading, error } =
    useGetStakeholdersByPathwayDefinitionIdsQuery({
      variables: {
        pathway_definition_ids: pathwayDefinitionIds,
      },
    })

  if (!isNil(error)) {
    notifyError({
      message: 'Something went wrong while loading stakeholders',
      error,
    })
    return { loading: false, stakeholders: [] }
  }
  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    stakeholders: data?.stakeholdersByPathwayDefinitionIds.stakeholders,
  }
}
