import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import {
  WebhookCall,
  WebhookCallsForPathwayDefinitionQueryVariables,
  useWebhookCallsForPathwayDefinitionQuery,
} from './types'

interface UseWebhookCallsForPathwayDefinition {
  loading: boolean
  webhookCalls?: Array<WebhookCall>
}

export const useWebhookCallsForPathwayDefinition = (
  pathway_definition_id: string,
): UseWebhookCallsForPathwayDefinition => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const variables: WebhookCallsForPathwayDefinitionQueryVariables = {
    pathway_definition_id,
  }

  const { data, loading, error } = useWebhookCallsForPathwayDefinitionQuery({
    variables,
  })

  const webhookCalls =
    data?.webhookCallsForPathwayDefinition.webhook_calls ?? []

  if (error) {
    notifyError({
      message: t('error_load_webhook_plural'),
      error,
    })
    return { loading: false }
  }

  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    webhookCalls,
  }
}
