import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import { type RouteComponentProps, useParams } from '@reach/router'
import { ContentContainer } from '@awell/ui-kit/components/ContentContainer'
import { NavigationButton } from '@awell/ui-kit/components/NavigationButton'
import {
  HeadingMain,
  SubHeading,
  Text,
} from '@awell/ui-kit/components/Typography/TypographyNew'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { Table } from '@awell/ui-kit/components/Table'
import { format } from 'date-fns'
import { Button } from '@awell/ui-kit/components/Button'
import { useStyles } from './useStyles'
import { useApiCallDetailsListColumns } from './useApiCallDetailsListColumns'
import { ApiCallDetailsSkeleton } from './skeleton'
import { useGetPathwayApiCall } from '../../../hooks/useGetPathwayApiCall'
import { useRetryApiCall } from '../../../hooks/useRetryApiCall'
import { isNil } from 'lodash'

interface ApiCallDetailsProps extends RouteComponentProps {
  pathwayId: string
}

export const ApiCallDetails: FC<ApiCallDetailsProps> = ({ pathwayId }) => {
  const { id } = useParams()
  const { loading, apiCall } = useGetPathwayApiCall(id)
  const { t } = useTranslation()
  const { columns } = useApiCallDetailsListColumns()
  const { retryApiCall } = useRetryApiCall()

  const classes = useStyles()
  const backNavButtonUrl = `/pathway/${pathwayId}/logs/api-calls`
  if (loading || !apiCall) {
    return <ApiCallDetailsSkeleton backNavigationList={backNavButtonUrl} />
  }
  const triggeredAtLastResponse =
    apiCall.responses[apiCall.responses.length - 1].date
  return (
    <ScrollContainer className={classes.container}>
      <ContentContainer>
        <article>
          <div className={classes.navContainer}>
            <div style={{ display: 'flex' }}>
              <NavigationButton linkUrl={backNavButtonUrl} />
              <div className={classes.textContainer}>
                <HeadingMain
                  variant='subHeadline'
                  color={colors.neutralDark500}
                >
                  {apiCall?.title}
                </HeadingMain>
              </div>
            </div>
            <Button
              color='primary'
              onClick={() => {
                retryApiCall(id)
              }}
            >
              {t('retry')}
            </Button>
          </div>

          <div className={classes.basicInfoContainer}>
            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('URL')}
              </Text>
              <Text>{apiCall.request.endpoint}</Text>
            </div>

            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('triggered_at')}
              </Text>
              <Text>
                {format(
                  new Date(triggeredAtLastResponse),
                  'd-LLL-yyyy K:mm:ss a',
                )}
              </Text>
            </div>

            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('status')}
              </Text>
              <Text>{t(`api_call_${apiCall.status.toLowerCase()}`)}</Text>
            </div>
          </div>

          <section>
            <SubHeading variant='smallHeadline'>{t('logs')}</SubHeading>
            <Table columns={columns} rows={apiCall.responses} fullWidth />
          </section>

          <section className={classes.bodyContainer}>
            <div>
              <SubHeading variant='smallHeadline'>
                {t('request_body')}
              </SubHeading>
              <div className={classes.jsonContainer}>
                <ReactJson
                  src={
                    !isNil(apiCall?.request?.body) &&
                    JSON.parse(apiCall.request.body)
                  }
                />
              </div>
            </div>
          </section>
        </article>
      </ContentContainer>
    </ScrollContainer>
  )
}

ApiCallDetails.displayName = 'ApiCallDetails'
