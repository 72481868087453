import React, { type FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { findChildByType } from '../../utils/findChildByType'
import { spacing } from '../../utils/style-guide'

interface Props {
  children: JSX.Element | Array<JSX.Element> | null
}

interface HeaderVariant {
  variant?: 'default' | 'careApp'
}

// using a slots pattern here: https://medium.com/@srph/react-imitating-vue-slots-eab8393f96fd
interface Component {
  Header: FC<HeaderVariant>
  Body: FC
}

function Header() {
  return null
}

function Body() {
  return null
}

const useStyle = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '4rem 1fr',
  },

  '@media (max-width: 500px)': {
    header: {
      ...spacing.paddingX.xxs,
      height: spacing.l,
    },
  },
  main: {
    height: '100%',
    maxHeight: '100%',
    position: 'relative',
  },
})

export const AppLayout: Component & FC<Props> = ({ children }: Props) => {
  const classes = useStyle()
  const header = findChildByType(children, Header)
  const body = findChildByType(children, Body)

  return (
    <div className={classes.container}>
      {header && <div>{header.props.children}</div>}
      {body && <main className={classes.main}>{body.props.children}</main>}
    </div>
  )
}
AppLayout.displayName = 'AppLayout'
AppLayout.Header = Header
AppLayout.Body = Body
