import React, { createContext, type FC, useContext } from 'react'

import { type Stakeholder } from './types'

interface StakeholderContextInterface {
  stakeholders: Array<Stakeholder>
  loading?: boolean
}

const initialValue: StakeholderContextInterface = {
  stakeholders: [],
  loading: false,
}

const StakeholdersContext =
  createContext<StakeholderContextInterface>(initialValue)

export const StakeholderContextProvider: FC<StakeholderContextInterface> = ({
  stakeholders,
  loading = false,
  children,
}) => {
  return (
    <StakeholdersContext.Provider value={{ stakeholders, loading }}>
      {children}
    </StakeholdersContext.Provider>
  )
}

export const useStakeholdersContext = (): StakeholderContextInterface =>
  useContext(StakeholdersContext)
