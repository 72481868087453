import { LinkTab } from '@awell/ui-kit/components/Tabs'
import { RouteComponentProps, Router, useLocation } from '@reach/router'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'
import { WebhookLogsTab } from './WebhooksTab'

export const LogsPage: FC<RouteComponentProps> = () => {
  const { pathname } = useLocation()
  const classes = useStyles()
  const { t } = useTranslation()
  const isCurrentTabPath = (tabName: string) => pathname.includes(tabName)
  return (
    <div>
      <div className={classes.tabsContainer}>
        <LinkTab
          tabLabel={t('webhook_plural')}
          selected={isCurrentTabPath('/webhooks')}
          linkToLocation='webhooks'
        />
      </div>
      <div className={classes.routeContainer}>
        {/* Primary Routers will manage focus on location transitions so we set
           // it to false here, otherwise it scrolls to the inner content on load */}
        <Router primary={false}>
          <WebhookLogsTab path='/webhooks' />
        </Router>
      </div>
    </div>
  )
}
