import * as React from 'react'

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 17a6 6 0 100-12 6 6 0 000 12zm8-6a8 8 0 11-16 0 8 8 0 0116 0z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.943 15.943a1 1 0 011.414 0l4.35 4.35a1 1 0 11-1.414 1.414l-4.35-4.35a1 1 0 010-1.414z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgSearch
