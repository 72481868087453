import {
  useGetDetailsForPublishedPathwayDefinitionsQuery,
  type DetailsForPublishedPathwayDefinition,
} from './types'
import { useNotifications } from '../useNotifications'
import { isNil } from 'lodash'

interface UseDetailsForPublishedPathwayDefinitionsHook {
  loading: boolean
  detailsForPathwayDefinitions?: Array<DetailsForPublishedPathwayDefinition>
}

export const useDetailsForPublishedPathwayDefinitions =
  (): UseDetailsForPublishedPathwayDefinitionsHook => {
    const { notifyError } = useNotifications()
    const { data, loading, error } =
      useGetDetailsForPublishedPathwayDefinitionsQuery()

    if (!isNil(error)) {
      notifyError({
        message:
          'Something went wrong while loading published pathway definitions',
        error,
      })
      return { loading: false, detailsForPathwayDefinitions: [] }
    }
    if (loading) {
      return { loading: true }
    }

    return {
      loading: false,
      detailsForPathwayDefinitions:
        data?.getStatusForPublishedPathwayDefinitions
          .publishedPathwayDefinitions ?? [],
    }
  }
