import React, { createContext, FC, useContext, useState } from 'react'

export interface MultipleSelectOptionsEditorInterface {
  isCopyOptionsEditorOpen: boolean
  onToggleOptionsEditor: (isOpen: boolean) => void
}

const initialContext = {
  isCopyOptionsEditorOpen: false,
  onToggleOptionsEditor: () => null,
}

export const MultipleSelectOptionsEditor = createContext<
  MultipleSelectOptionsEditorInterface
>(initialContext)

export const MultipleSelectOptionsEditorContext: FC = ({ children }) => {
  const [isCopyOptionsEditorOpen, setCopyOptionsEditorOpen] = useState(false)

  return (
    <MultipleSelectOptionsEditor.Provider
      value={{
        isCopyOptionsEditorOpen,
        onToggleOptionsEditor: isOpen => setCopyOptionsEditorOpen(isOpen),
      }}
    >
      {children}
    </MultipleSelectOptionsEditor.Provider>
  )
}

export const useMultipleSelectOptionsEditor = (): MultipleSelectOptionsEditorInterface =>
  useContext(MultipleSelectOptionsEditor)
