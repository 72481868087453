import * as React from 'react'

function SvgChevronLeftDouble(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 14 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.707.293a1 1 0 00-1.414 0L1.707 2.879l-.135.144a3 3 0 00.135 4.098l2.586 2.586.094.083a1 1 0 001.32-1.497L3.121 5.707l-.083-.094a1 1 0 01.083-1.32l2.586-2.586.083-.094a1 1 0 00-.083-1.32zm8 0a1 1 0 00-1.414 0L9.707 2.879l-.135.144a3 3 0 00.135 4.098l2.586 2.586.094.083a1 1 0 001.32-1.497l-2.586-2.586-.083-.094a1 1 0 01.083-1.32l2.586-2.586.083-.094a1 1 0 00-.083-1.32z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgChevronLeftDouble
