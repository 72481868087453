import DOMPurify from 'dompurify'

/**
 * Sanitizes html, but leaves youtube and vimeo iframes
 * Keep this in sync with @awell/libs-service/utils/sanitizeHtml
 */
export function sanitizeHtml(dirtyHtml: string): string {
  // eslint-disable-next-line consistent-return
  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const src = node.getAttribute('src') ?? ''
      const isYoutube = src.startsWith('https://www.youtube.com/embed/')
      const isVimeo = src.startsWith('https://player.vimeo.com/video/')
      if (!isYoutube && !isVimeo) {
        return node.parentNode?.removeChild(node)
      }
    }
  })

  const sanitizedHtml = DOMPurify.sanitize(dirtyHtml, {
    ADD_TAGS: ['iframe'], // or ALLOWED_TAGS
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'], // or //or ALLOWED_ATR
  })

  return sanitizedHtml
}
