import { type PatientProfile, Sex, Language } from './types'

export const initialPatientProfile: PatientProfile = {
  email: '',
  first_name: '',
  last_name: '',
  birth_date: '',
  sex: Sex.NotKnown,
  preferred_language: Language.english,
  patient_code: '',
  national_registry_number: '',
  phone: '',
  mobile_phone: '',
  identifier: [],
  address: {
    street: '',
    city: '',
    zip: '',
    state: '',
    country: '',
  },
}
