/* eslint-disable react/display-name */
import { type Column } from '@awell/ui-kit/components/Table'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type Activity } from 'app-care/src/hooks/useActivityFeed'
import { formatRelative, parseISO } from 'date-fns'
import { usePatientPathways } from 'app-care/src/hooks/usePatientPathways'
import { IconLabelCell } from 'app-care/src/components/IconLabelCell'
import { ActivityTypeIcon } from 'app-care/src/components/ActivityTypeIcon'
import { isNil } from 'lodash'
import { enGB, enUS } from 'date-fns/locale'

export const usePatientActivitiesColumns = (
  patientId: string,
): {
  columns: Array<Column<Activity>>
} => {
  const { t } = useTranslation()

  const { patientPathways } = usePatientPathways(patientId)

  const getActivityPathwayTitle = React.useMemo(
    () =>
      (activity: Activity): string => {
        const pathway = patientPathways?.find(
          pathway => pathway.id === activity.context?.pathway_id,
        )
        return pathway?.title ?? '-'
      },
    [patientPathways],
  )

  const columns: Array<Column<Activity>> = [
    {
      title: t('workingList:column_pathway_name'),
      field: 'pathway',
      type: 'string',
      sorting: true,
      filtering: true,
      searchable: false,
      width: 150,
      render: getActivityPathwayTitle,
    },
    {
      title: t('workingList:column_step_title'),
      field: 'stepTitle',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (activity: Activity): string => activity.container_name ?? '-',
      width: 100,
    },
    {
      title: t('workingList:column_activity_title'),
      field: 'activityTitle',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (activity: Activity): ReactElement => (
        <IconLabelCell
          label={activity.object.name}
          icon={<ActivityTypeIcon type={activity.object.type} />}
          status={activity.status}
          to={
            !isNil(activity.stream_id)
              ? `/pathway/${activity.stream_id}`
              : undefined
          }
        />
      ),
      width: 150,
    },
    {
      title: t('workingList:column_activity_date'),
      field: 'date',
      type: 'date',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (activity: Activity): string =>
        formatRelative(parseISO(activity.date), new Date(), {
          // match locale to navigator window language
          locale: window.navigator.language?.includes('en-US') ? enUS : enGB,
        }),
      width: 90,
    },
  ]
  return { columns }
}
