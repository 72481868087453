import { makeStyles } from '@material-ui/core'
import { spacing, colors, rounded, shadows } from '../../utils/style-guide'

export const useStyles = makeStyles({
  condition: {
    marginTop: spacing.xxxs,
    '&:first-child': {
      marginTop: 0,
    },
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    '&:after': {
      content: '',
      position: 'absolute',
      top: '50%', // half way down (vertical center).
      marginTop: '-15px', // adjust position, arrow has a height of 30px.
      left: '-30px',
      border: 'solid 15px transparent',
      borderRightColor: '#FFF',
      zIndex: 1,
    },
    width: spacing.input.xl,
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: `${colors.neutralLight0}`,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      ...shadows.m,
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
  popper: {
    ...rounded.m,
    ...shadows.m,
    zIndex: 2000,
    backgroundColor: `${colors.neutralLight0}`,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '100% 0',
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '100% 100%',
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '0 0',
      },
    },
  },
  conditionsLabel: {
    color: colors.brand100,
  },
  conditionLine: {
    marginBottom: spacing.xxxs,
  },
})
