// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import {
  getAbove,
  isCollapsed,
  someNode,
  unwrapNodes,
  getPluginType,
  ELEMENT_LINK,
  ToolbarButton,
  ToolbarButtonProps,
  wrapLink,
  insertNodes,
  TElement,
  usePlateEditorState,
} from '@udecode/plate'
import { BaseRange, Editor, Transforms } from 'slate'

export interface ToolbarLinkProps extends ToolbarButtonProps {
  getLinkUrl?: (prevUrl: string | null) => Promise<string | null>
  position?: BaseRange
  id: string
}

export const ToolbarButtonLink: FC<ToolbarLinkProps> = ({
  getLinkUrl,
  position,
  id,
  ...props
}) => {
  const editor = usePlateEditorState(id)

  const type = getPluginType(editor, ELEMENT_LINK)
  const isLink = !!position && someNode(editor, { match: { type } })

  return (
    <ToolbarButton
      active={isLink}
      onMouseDown={async event => {
        if (!editor) return
        event.preventDefault()
        let prevUrl = ''

        const linkNode = getAbove(editor, {
          at: position,
          match: { type },
        })

        if (linkNode) {
          prevUrl = linkNode[0].url as string
        }

        let newUrl: string
        if (getLinkUrl) {
          newUrl = await getLinkUrl(prevUrl)
        } else {
          // We keep the original prompt as a fallback behaviour
          // eslint-disable-next-line no-alert
          newUrl = window.prompt(`Enter the URL of the link:`, prevUrl)
        }

        if (!newUrl) {
          return
        }

        const shouldWrap: boolean =
          linkNode !== undefined && isCollapsed(position)

        if (!shouldWrap && isCollapsed(position)) {
          insertNodes<TElement>(editor, {
            type,
            url: newUrl,
            children: [{ text: newUrl }],
          })
          return
        }

        // if our cursor is inside an existing link, but don't have the text selected, select it now
        if (shouldWrap && isCollapsed(position)) {
          const linkLeaf = Editor.leaf(editor, position)
          const [, inlinePath] = linkLeaf
          Transforms.select(editor, inlinePath)
        }

        unwrapNodes(editor, { at: position, match: { type } })

        wrapLink(editor, { at: position, url: newUrl })

        Transforms.collapse(editor, { edge: 'end' })
      }}
      {...props}
    />
  )
}

ToolbarButtonLink.displayName = 'ToolbarButtonLink'
