import { RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import { ContentContainer } from '@awell/ui-kit/components/ContentContainer'
import { PageTitle } from '../PageTitle'
import { useStyles } from './useStyles'

export const CareAppPageLayout: FC<RouteComponentProps> = ({
  children,
}): JSX.Element => {
  const classes = useStyles()
  return (
    <ContentContainer classNames={classes.container}>
      <>
        <PageTitle />
        {children}
      </>
    </ContentContainer>
  )
}

CareAppPageLayout.displayName = 'CareAppPageLayout'
