// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'
import { TableIcons } from './TableIcons'
import { ChevronRight } from '../Icons'
import { spacing } from '../../utils/style-guide'
import { IconButton } from '../Button'

const MTableGroupRow = props => {
  const rotateIconStyle = isOpen => ({
    transform: isOpen ? 'rotate(90deg)' : 'none',
  })

  function render() {
    let colSpan = props.columns.filter(columnDef => !columnDef.hidden).length
    props.options.selection && colSpan++
    props.detailPanel && colSpan++
    props.actions && props.actions.length > 0 && colSpan++
    const column = props.groups[props.level]

    let detail
    if (props.groupData.isExpanded) {
      if (props.groups.length > props.level + 1) {
        // Is there another group
        detail = props.groupData.groups.map((groupData, index) => (
          <props.components.GroupRow
            actions={props.actions}
            key={groupData.value || `${index}`}
            columns={props.columns}
            components={props.components}
            detailPanel={props.detailPanel}
            getFieldValue={props.getFieldValue}
            groupData={groupData}
            groups={props.groups}
            icons={TableIcons}
            level={props.level + 1}
            path={[...props.path, index]}
            onGroupExpandChanged={props.onGroupExpandChanged}
            onGroupSelected={props.onGroupSelected}
            onRowSelected={props.onRowSelected}
            onRowClick={props.onRowClick}
            onToggleDetailPanel={props.onToggleDetailPanel}
            onTreeExpandChanged={props.onTreeExpandChanged}
            onEditingCanceled={props.onEditingCanceled}
            onEditingApproved={props.onEditingApproved}
            options={props.options}
            hasAnyEditingRow={props.hasAnyEditingRow}
            isTreeData={props.isTreeData}
            cellEditable={props.cellEditable}
            onCellEditStarted={props.onCellEditStarted}
            onCellEditFinished={props.onCellEditFinished}
            scrollWidth={props.scrollWidth}
            treeDataMaxLevel={props.treeDataMaxLevel}
          />
        ))
      } else {
        detail = props.groupData.data.map((rowData, index) => {
          return (
            <props.components.Row
              actions={props.actions}
              key={index}
              columns={props.columns}
              components={props.components}
              data={rowData}
              detailPanel={props.detailPanel}
              level={(props.level || 0) + 1}
              getFieldValue={props.getFieldValue}
              icons={TableIcons}
              path={[...props.path, rowData.tableData.uuid]}
              onRowSelected={props.onRowSelected}
              onRowClick={props.onRowClick}
              onToggleDetailPanel={props.onToggleDetailPanel}
              options={props.options}
              isTreeData={props.isTreeData}
              onTreeExpandChanged={props.onTreeExpandChanged}
              onEditingCanceled={props.onEditingCanceled}
              onEditingApproved={props.onEditingApproved}
              hasAnyEditingRow={props.hasAnyEditingRow}
              cellEditable={props.cellEditable}
              onCellEditStarted={props.onCellEditStarted}
              onCellEditFinished={props.onCellEditFinished}
              scrollWidth={props.scrollWidth}
              treeDataMaxLevel={props.treeDataMaxLevel}
            />
          )
        })
      }
    }

    const freeCells = []
    for (let i = 0; i < props.level; i++) {
      freeCells.push(<TableCell padding='checkbox' key={i} />)
    }

    let { value } = props.groupData
    if (column.lookup) {
      value = column.lookup[value]
    }

    let { title } = column
    if (typeof props.options.groupTitle === 'function') {
      title = props.options.groupTitle(props.groupData)
    } else if (typeof title !== 'string') {
      title = React.cloneElement(title)
    }

    const separator = props.options.groupRowSeparator || ' '

    const showSelectGroupCheckbox =
      props.options.selection && props.options.showSelectGroupCheckbox

    const mapSelectedRows = groupData => {
      let totalRows = 0
      let selectedRows = 0

      if (showSelectGroupCheckbox) {
        if (groupData.data.length) {
          totalRows += groupData.data.length
          groupData.data.forEach(row => row.tableData.checked && selectedRows++)
        } else {
          groupData.groups.forEach(group => {
            const [groupTotalRows, groupSelectedRows] = mapSelectedRows(group)

            totalRows += groupTotalRows
            selectedRows += groupSelectedRows
          })
        }
      }

      return [totalRows, selectedRows]
    }

    const [totalRows, selectedRows] = mapSelectedRows(props.groupData)

    return (
      <>
        <TableRow ref={props.forwardedRef}>
          {freeCells}
          <props.components.Cell
            colSpan={colSpan}
            padding='none'
            columnDef={column}
            value={value}
            icons={TableIcons}
          >
            <>
              <span
                onClick={() => {
                  props.onGroupExpandChanged(props.path)
                }}
                tabIndex={0}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    props.onGroupExpandChanged(props.path)
                  }
                }}
                role='button'
              >
                <IconButton
                  variant='floatingAccent'
                  style={{
                    transition: 'transform ease 200ms',
                    ...rotateIconStyle(props.groupData.isExpanded),
                    marginRight: spacing.xs,
                  }}
                >
                  <ChevronRight />
                </IconButton>
              </span>
              {showSelectGroupCheckbox && (
                <Checkbox
                  indeterminate={selectedRows > 0 && totalRows !== selectedRows}
                  checked={totalRows === selectedRows}
                  onChange={(event, checked) =>
                    props.onGroupSelected &&
                    props.onGroupSelected(checked, props.groupData.path)
                  }
                  style={{ marginRight: 8 }}
                />
              )}
              <span>
                {title}
                {separator}
              </span>
            </>
          </props.components.Cell>
        </TableRow>
        {detail}
      </>
    )
  }

  return render()
}

MTableGroupRow.defaultProps = {
  columns: [],
  groups: [],
  level: 0,
  options: {},
}

export default React.forwardRef(function MTableGroupRowRef(props, ref) {
  return <MTableGroupRow {...props} forwardedRef={ref} />
})
