import React, { FC } from 'react'
import { useStyles } from './useStyles'

export const CenteredLayout: FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.centeredContainer}>{children}</div>
}

CenteredLayout.displayName = 'CenteredLayout'
