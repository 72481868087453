import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Flex } from '../Flex'
import { Search } from '../Icons'
import { SearchForm } from '../SearchForm'
import { useStyles } from './useStyles'
import { TableComponent } from './TableComponent'
import { type TableProps } from './types'

export const Table = ({
  tableRef,
  columns,
  rows,
  actionText = '',
  emptyDataSourceMessage,
  onSearch,
  paging,
  pageSize,
  rowStyles,
  fullWidth = false,
  compactCells,
  toolbarContent,
  grouping,
  groupTitleComponent,
  nonTablePageContentHeight,
  searchTerm,
  noPaddingX = false,
}: TableProps): JSX.Element => {
  const classes = useStyles()
  const [query, setQuery] = useState(searchTerm ?? '')
  const { t } = useTranslation()

  const handleSearch = (data: string): void => {
    if (onSearch) {
      onSearch(data)
    }
    setQuery(data)
  }

  return (
    <div
      className={classNames({
        [classes.container]: fullWidth && !noPaddingX,
      })}
    >
      {onSearch && (
        <Flex
          justify='space-between'
          className={fullWidth ? classes.fullWidthToolbar : classes.toolbar}
        >
          <SearchForm
            value={query}
            onSearch={handleSearch}
            iconEnd={<Search />}
            placeholder={t('search')}
            compact
          />
          <div style={{ justifySelf: 'flex-end' }}>{toolbarContent}</div>
        </Flex>
      )}
      <TableComponent
        tableRef={tableRef}
        columns={columns}
        rows={rows}
        actionText={actionText}
        emptyDataSourceMessage={emptyDataSourceMessage}
        paging={paging}
        pageSize={pageSize}
        rowStyles={rowStyles}
        fullWidth={fullWidth}
        compactCells={compactCells}
        grouping={grouping}
        groupTitleComponent={groupTitleComponent}
        nonTablePageContentHeight={nonTablePageContentHeight}
      />
    </div>
  )
}
