import { useApolloClient } from '@apollo/client'
import { updateQuery } from '@awell/libs-web/graphql'
import { isNil, sortBy } from 'lodash'
import { useEffect } from 'react'
import { useNotifications } from '../useNotifications'
import { usePathwayContext } from '../usePathwayContext'
import {
  type Element,
  useOnElementCreatedSubscription,
  useOnElementCompletedSubscription,
  useOnElementUpdatedSubscription,
  type PathwayElementsQueryVariables,
  usePathwayElementsQuery,
  type PathwayElementsQuery,
  PathwayElementsDocument,
} from './types'

interface UsePathwayElementsHook {
  loading: boolean
  elements?: Array<Element>
}

export const usePathwayElements = (): UsePathwayElementsHook => {
  const { notifyError } = useNotifications()
  const { pathwayId } = usePathwayContext()
  const variables: PathwayElementsQueryVariables = {
    pathway_id: pathwayId!,
  }
  const client = useApolloClient()
  const { data, loading, error } = usePathwayElementsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  })

  const onElementCreated = useOnElementCreatedSubscription({ variables })
  useOnElementUpdatedSubscription({ variables })
  useOnElementCompletedSubscription({ variables })

  const elements = data?.pathwayElements.elements ?? []

  const updateQueryResults = (updatedElements: Array<Element>): void => {
    if (data) {
      const updatedQuery = updateQuery<PathwayElementsQuery, Array<Element>>(
        data,
        ['pathwayElements', 'elements'],
        sortBy(updatedElements, element => element.start_date),
      )
      client.writeQuery({
        query: PathwayElementsDocument,
        variables,
        data: updatedQuery,
      })
    }
  }

  useEffect(() => {
    if (!isNil(onElementCreated.data)) {
      const {
        data: { elementCreated },
      } = onElementCreated
      const updatedElements = [
        elementCreated,
        ...elements.filter(element => element.id !== elementCreated.id),
      ]
      updateQueryResults(updatedElements)
    }
  }, [onElementCreated.data])

  if (error) {
    notifyError({
      message: 'Something went wrong while loading the pathway elements',
      error,
    })
  }

  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    elements,
  }
}
