import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  headerContainer: {
    borderBottom: `1px solid ${colors.neutralLight50}`,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
  tabButtons: {
    display: 'flex',
    alignSelf: 'flex-end',
    paddingLeft: spacing.xxs,
    paddingRight: spacing.xxs,
  },
  titlesAndAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTop: {
    marginBottom: spacing.xs,
    marginTop: spacing.xs,
  },
  title: {
    marginBottom: spacing.xxxs,
  },
  subtitle: {},
  titleLeft: {
    flexGrow: 1,
    maxWidth: '70%',
  },
  headerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTools: {
    display: 'flex',
  },
  pathwayTitle: {
    marginLeft: spacing.xxs,
  },
  breadcrumbs: {
    paddingBottom: spacing.xxs,
  },
  action: {
    alignSelf: 'center',
    display: 'flex',
    '& > *': {
      marginLeft: spacing.xxs,
    },
  },
})
