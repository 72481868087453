import * as React from 'react'
import MuiMenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'
import { Type, TypeVariants } from '../Typography'
import { Tooltip } from '../Tooltip'

interface MenuItemProps {
  onClick?:
    | ((event: React.MouseEvent) => void)
    | ((event: React.MouseEvent) => Promise<void>)
  children: unknown
  disabled?: boolean
  dataCy?: string
  dense?: boolean
  disableGutters?: boolean
  style?: React.CSSProperties
  selected?: boolean
  'data-heap'?: string
  tooltipInfo?: string
}

const useStyles = makeStyles({
  root: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
})

type Ref = HTMLDivElement

export const MenuItem = React.forwardRef<Ref, MenuItemProps>(function MenuItem(
  {
    children,
    onClick,
    disabled = false,
    dense = false,
    disableGutters = false,
    style = {},
    dataCy = null,
    selected,
    tooltipInfo = '',
    ...props
  },
  ref,
) {
  const classes = useStyles()
  const { 'data-heap': dataHeap } = props
  return (
    <Tooltip arrow info={tooltipInfo} enterDelay={0}>
      <li data-cy={dataCy} data-heap={dataHeap}>
        <MuiMenuItem
          ref={ref}
          component='div'
          classes={classes}
          onClick={onClick}
          disabled={disabled}
          dense={dense}
          disableGutters={disableGutters}
          style={style}
          selected={selected}
        >
          <Type variant={TypeVariants.regular}>{children}</Type>
        </MuiMenuItem>
      </li>
    </Tooltip>
  )
})
