import type { FetchResult } from '@apollo/client'
import { type RetryPushToEmrMutation, useRetryPushToEmrMutation } from './types'

interface UseRetryPushToEmrHook {
  retryPushToEmr: () => Promise<FetchResult<RetryPushToEmrMutation>>
}

export const useRetryPushToEmr = (
  activityId: string,
): UseRetryPushToEmrHook => {
  const [retryPushToEmr] = useRetryPushToEmrMutation({
    variables: {
      input: {
        activity_id: activityId,
      },
    },
  })
  return { retryPushToEmr }
}
