import * as React from 'react'

function SvgProfile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.964 15.464A5 5 0 018.5 14h8a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3h-8a3 3 0 00-3 3v2a1 1 0 11-2 0v-2a5 5 0 011.464-3.536zM12.5 4a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgProfile
