import React, { useEffect, useRef, useState, useCallback } from 'react'

type useStateSafeParams<T> = T | (() => T)
type dispatch<T> = React.Dispatch<React.SetStateAction<T>>
/**
 * Wrapper around reacts `useState` hook.
 * Use this hook to prevent memory leak as this won't call set state on unmounted component.
 *
 * @param initialValue initial state value
 */
export const useStateSafe = <T>(
  initialValue: useStateSafeParams<T>,
): [T, dispatch<T>] => {
  const [val, setVal] = useState<T>(initialValue)
  const mountedRef = useRef<boolean>()
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])
  const setValue: dispatch<T> = useCallback(
    (s: React.SetStateAction<T>) => {
      if (mountedRef.current) {
        setVal(s)
      }
    },
    [setVal],
  )
  return [val, setValue]
}
