import { type TElement } from '@udecode/plate'
import type { DynamicVariable as DynamicVariableType } from '../../../generated/types-design'

export {
  Status as ConfigurationStatusState,
  type Constant,
} from '../../../generated/types-design'
interface DynamicVariableNodeData {
  variableId: string
}

export type DynamicVariableNode = TElement<DynamicVariableNodeData> &
  DynamicVariableNodeData

export const ELEMENT_DYNAMIC_VARIABLE = 'dynamic-variable'
export type { PlateEditor } from '@udecode/plate'
export const NON_EXISTENT_ELEMENT_DYNAMIC_VARIABLE = 'dynamic-variable-plugin'
export type NonExistentVariableId = Omit<DynamicVariableNode, 'variableId'>
export type DynamicVariable = Pick<
  DynamicVariableType,
  'id' | 'status' | 'label' | 'transformations'
>
