import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../utils/style-guide'

export const useIconStyles = makeStyles({
  base: {
    width: spacing.icon,
    height: spacing.icon,
    color: colors.neutralLight0,
    transition:
      'box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out',
    'input:not(:checked) + & > *, &.empty > *': {
      backgroundColor: colors.neutralLight0,
    },
    'input:not(:checked):not(:disabled):hover + & > *, &.empty.hover > *': {
      color: colors.neutralLight40,
      backgroundColor: colors.neutralLight40,
    },
    'input:not(:checked):not(:disabled):focus + & > *, &.empty.focus > *': {
      boxShadow: `0 0 0 .0625rem ${colors.brand100}`,
    },
    'input:not(:checked):disabled + & > *, &.empty.disabled > *': {
      opacity: 0.3,
    },
    '&.empty.error > *': {
      boxShadow: `0 0 0 .0625rem ${colors.signalError100}`,
    },

    'input:checked + & > *, &.filled > *': {
      backgroundColor: colors.brand100,
      boxShadow: `0 0 0 .0625rem ${colors.brand100}`,
    },
    'input:checked:hover:not(:disabled) + & > *, &.filled.hover > *': {
      color: colors.neutralLight40,
      backgroundColor: colors.brand40,
      boxShadow: `0 0 0 .0625rem ${colors.brand40}`,
    },
    'input:checked:focus:not(:disabled) + & > *, &.filled.focus > *': {
      backgroundColor: colors.brand40,
      boxShadow: `0 0 0 .0625rem ${colors.brand100}`,
    },
    'input:checked:disabled + & > *, &.filled.disabled > *': {
      backgroundColor: colors.brand40,
      boxShadow: `0 0 0 .0625rem ${colors.brand40}`,
      opacity: 0.3,
    },
  },
  error: {
    '& > *': {
      boxShadow: `0 0 0 .0625rem ${colors.signalError100} !important`,
    },
    'input:not(:checked) + & > *, &.empty > *': {
      backgroundColor: colors.neutralLight0,
      boxShadow: `0 0 0 .0625rem ${colors.signalError100}`,
    },
    'input:not(:checked):hover + & > *, &.empty.hover > *': {
      color: colors.neutralLight40,
      backgroundColor: colors.neutralLight40,
    },
    'input:not(:checked):focus + & > *, &.empty.focus > *': {
      boxShadow: `0 0 0 .0625rem ${colors.signalError100}`,
    },
    'input:not(:checked):disabled + & > *, &.empty.disabled > *': {
      opacity: 0.3,
    },

    'input:checked + & > *, &.filled > *': {
      backgroundColor: colors.signalError100,
      boxShadow: `0 0 0 .0625rem ${colors.signalError100}`,
    },
    'input:checked:hover + & > *, &.filled.hover > *': {
      color: colors.neutralLight40,
      backgroundColor: colors.signalError60,
      boxShadow: `0 0 0 .0625rem ${colors.signalError60}`,
    },
    'input:checked:focus + & > *, &.filled.focus > *': {
      backgroundColor: `${colors.signalError60} !important`,
      boxShadow: `0 0 0 .0625rem ${colors.signalError100}`,
    },
    'input:checked:disabled + & > *, &.filled.disabled > *': {
      backgroundColor: colors.signalError60,
      boxShadow: `0 0 0 .0625rem ${colors.signalError60}`,
      opacity: 0.3,
    },
  },
})
