import { environment } from '@awell/libs-web/environment'
import React, { useEffect, type FC } from 'react'
import { useWhoami } from '../hooks/useWhoami'
import { environmentMapper } from '@awell/libs-web/experiments'

import {
  StatsigClient,
  type StatsigOptions,
  type StatsigUser,
} from '@statsig/js-client'

import { StatsigProvider } from '@statsig/react-bindings'
import { isNil } from 'lodash'

const statsigOptions: StatsigOptions = {
  environment: {
    tier: environmentMapper(environment.environment),
  },
}

const myStatsigClient = new StatsigClient(
  environment.statsig.client_key,
  { userID: undefined },
  statsigOptions,
)

myStatsigClient.initializeSync()

export const FeatureGateProvider: FC = ({ children }) => {
  const { user, loading } = useWhoami()

  useEffect(() => {
    if (!loading && !isNil(user?.id)) {
      const statsigUser: StatsigUser = {
        userID: user?.id ?? '',
        email: user?.profile?.email ?? '',
        custom: {
          tenant_name: user?.tenant?.name ?? '',
        },
        // custom IDs allow us to A/B test features at the
        // tenant level instead of at the user level.
        // https://docs.statsig.com/guides/experiment-on-custom-id-types
        customIDs: {
          tenant_id: user?.tenant_id ?? '',
        },
      }

      myStatsigClient.updateUserSync(statsigUser)
    }
  }, [loading, user])

  return <StatsigProvider client={myStatsigClient}>{children}</StatsigProvider>
}
