import React, { type FC } from 'react'
import { Avatar } from '../Avatar'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'
import { EmrFailure, EmrSuccess } from '../Icons'
import { colors } from '../../utils/style-guide'
import { TimeAgoIndicator } from '../ActivityList'
import { type EventContent } from '../../compositions/ActivityFeed'

// TODO: Add in props for handling user imgs when implemented
export const ActivityEvent: FC<EventContent> = ({
  avatar,
  timestamp: eventTimestamp,
  summaryText,
}) => {
  const classes = useStyles()
  const getInitials = (name: string) => {
    const initialsArray = name.match(/\b(\w)/g)
    if (initialsArray) {
      return initialsArray.join('').slice(0, 2)
    }
    return ''
  }

  const isStakeholder =
    avatar !== 'ava' && avatar !== 'emr_failure' && avatar !== 'emr_success'

  return (
    <div className={classes.eventDetails}>
      <div>
        {isStakeholder && <Avatar>{getInitials(avatar)}</Avatar>}
        {avatar === 'emr_failure' && (
          <Avatar>
            <EmrFailure />
          </Avatar>
        )}
        {avatar === 'emr_success' && (
          <Avatar>
            <EmrSuccess />
          </Avatar>
        )}
        {avatar === 'ava' && <Avatar ava />}
      </div>
      <div>
        <Type
          className={classes.activityEventText}
          variant={TypeVariants.small}
          color={colors.neutralDark500}
        >
          {summaryText}
        </Type>
        <TimeAgoIndicator timestampDate={new Date(eventTimestamp)} />
      </div>
    </div>
  )
}

ActivityEvent.displayName = 'ActivityEvent'
