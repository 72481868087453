import { Spinner } from '@awell/ui-kit/components/Spinner'
import { Heading6 } from '@awell/ui-kit/components/Typography'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { isNil } from 'lodash'
import React, { type FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import { useApiCall } from '../../hooks/useApiCall'
import { type ApiCallAsJson, type Activity } from './types'
import { useStyles } from './useStyles'

interface Props {
  activity: Activity
}

export const ApiCallActivityDetails: FC<Props> = ({ activity }) => {
  const { t } = useTranslation()
  const { loading, apiCall } = useApiCall(activity.object.id)
  const classes = useStyles()

  if (loading) {
    return <Spinner />
  }

  const formatJsonObject = (): ApiCallAsJson | Record<string, never> => {
    if (isNil(apiCall)) {
      return {}
    }

    const data: ApiCallAsJson = {
      request: {
        endpoint: apiCall.request.endpoint,
        method: apiCall.request.method,
        headers: {},
        body: '',
      },
      responses: [],
      id: apiCall.id,
      status: apiCall.status,
      title: apiCall.title,
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const parseBodyToJson = (responseBody: string) => {
      try {
        return JSON.parse(responseBody)
      } catch (error) {
        console.warn(
          'Error parsing API call body as JSON. Using raw string instead.',
        )
        return responseBody
      }
    }

    if (apiCall?.request.method !== 'GET' && !isNil(apiCall.request.body)) {
      data.request.body = parseBodyToJson(apiCall.request.body)
    }

    apiCall.request.headers.forEach(header => {
      data.request.headers[header.key] = header.value
    })

    apiCall.responses.forEach(response => {
      data.responses.push({
        date: response.date,
        status: response.status,
        body: parseBodyToJson(response.body),
      })
    })

    return data
  }

  return (
    <>
      <Heading6 spacing={`${spacing.xs} 0 0 0`}>
        {t('api_call_request_label')}
      </Heading6>
      <div className={classes.jsonContainer}>
        <ReactJson src={formatJsonObject()} />
      </div>
    </>
  )
}

ApiCallActivityDetails.displayName = 'ApiCallActivityDetails'
