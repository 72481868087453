import { createPluginFactory } from '@udecode/plate'
import { mentionOnKeyDownHandler } from './handlers/mentionOnKeyDownHandler'
import { isSelectionInMentionInput } from './queries'
import {
  type ConstantData,
  type DynamicVariableData,
  type MentionPlugin,
} from './types'
import { withMention } from './withMention'
import { Node } from 'slate'
import React from 'react'
import { MentionInputElement } from './MentionInputElement'
import { mentionOnClickHandler } from './handlers/mentionOnClickHandler'
import { findMention } from './queries/findMention'
import { type PluginOptions } from '../pluginsWithMentions'
import { snakeCase } from 'lodash'

export const ELEMENT_DYNAMIC_VARIABLE = 'dynamic-variable'
export const ELEMENT_CONSTANT = 'constant'
export const ELEMENT_MENTION_INPUT = 'mention_input'
export const ELEMENT_MENTION = 'mention'

export const createMentionPlugin = (
  options: PluginOptions,
): ReturnType<ReturnType<typeof createPluginFactory<MentionPlugin>>> =>
  createPluginFactory<MentionPlugin>({
    key: ELEMENT_MENTION,
    isElement: true,
    isInline: true,
    isVoid: true,
    handlers: {
      onKeyDown: mentionOnKeyDownHandler({ query: isSelectionInMentionInput }),
      onClick: mentionOnClickHandler({
        openVariableModal: options.onEditVariable,
        query: findMention,
      }),
    },
    withOverrides: withMention,
    options: {
      trigger: '/',
      createMentionNode: (
        item,
        data: ConstantData | DynamicVariableData | undefined,
      ) => {
        // prevent creating mention mode with invalid keys
        if (item.key === 'dynamic-variable' || item.key === 'constant') {
          return undefined
        }
        const splittedKey = item.key.split(':')
        // set variableId WITHOUT prefix
        const variableId =
          splittedKey.length > 1 ? splittedKey[1] : splittedKey[0]
        // set text WITH prefix
        const text =
          splittedKey.length > 1
            ? item.key
            : `${snakeCase(data?.type) ?? ''}:${splittedKey[0]}`
        return {
          type: data?.type ?? '',
          variableId,
          value: item.text,
          children: [{ text: `{{{${text}}}}` }],
          ...{ tooltip: (data as ConstantData).value },
          ...{ status: (data as DynamicVariableData).status },
        }
      },
      insertSpaceAfterMention: false,
    },
    plugins: [
      {
        key: ELEMENT_MENTION_INPUT,
        isElement: true,
        isInline: true,
        component: MentionInputElement,
      },
    ],
    then: (editor, { key }) => ({
      options: {
        id: key,
      },
    }),
    serializeHtml: ({ element }) => {
      return <span>{Node.string(element)}</span>
    },
  })()

export const createConstantMentionPlugin = createPluginFactory<MentionPlugin>({
  key: ELEMENT_CONSTANT,
  isElement: true,
  isInline: true,
  isVoid: true,
  serializeHtml: ({ element }) => <span>{Node.string(element)}</span>,
})

export const createDynamicVariableMentionPlugin =
  createPluginFactory<MentionPlugin>({
    key: ELEMENT_DYNAMIC_VARIABLE,
    isElement: true,
    isInline: true,
    isVoid: true,
    serializeHtml: ({ element }) => <span>{Node.string(element)}</span>,
  })
