import { format, isValid, parseISO } from 'date-fns'
import { isEmpty, isNil } from 'lodash'
/**
 * Formats incoming date in iso format to passed in format(defaults to dd/MM/yy at HH:mm)
 * e.g. 07/04/21 at 11:12 or 07/04/21 at 22:03
 */
export const formatDate =
  (dateFormat = "dd/MM/yy 'at' HH:mm") =>
  (isoDateStr: string): string | undefined => {
    const parsedDate = parseISO(isoDateStr)
    if (!isValid(parsedDate)) {
      return undefined
    }
    return format(parsedDate, getFormat(dateFormat))
  }

const getFormat = (dateFormat?: string): string => {
  if (!isNil(dateFormat) && !isEmpty(dateFormat)) {
    return dateFormat
  }
  const defaultLocale = new Intl.DateTimeFormat().resolvedOptions().locale
  return defaultLocale === 'en-US'
    ? "MM/dd/yy 'at' HH:mm"
    : "dd/MM/yy 'at' HH:mm"
}
