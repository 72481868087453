import * as React from 'react'

function SvgExternalLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 6a1 1 0 011-1h4.6v1.4H6.4v11.2h11.2v-4.2H19v-2.8h-1.4V7.39l-5.105 5.105-.99-.99 5.105-5.106-3.21.001V5H19v13a1 1 0 01-1 1H6a1 1 0 01-1-1V6z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgExternalLink
