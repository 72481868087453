import { useGetPatientDemographicsQueryConfigurationQuery } from '../../generated/types-orchestration'

interface UsePatientDemographicsConfigurationHook {
  loading: boolean
  is_enabled: boolean
  input_text_label: string
}

export const usePatientDemographicsConfiguration =
  (): UsePatientDemographicsConfigurationHook => {
    const { data, loading } = useGetPatientDemographicsQueryConfigurationQuery()

    return {
      loading,
      is_enabled:
        data?.patientDemographicsQueryConfiguration?.is_enabled ?? false,
      input_text_label:
        data?.patientDemographicsQueryConfiguration?.input_box_text ?? '',
    }
  }
