import * as React from 'react'
import MuiTab, { TabProps } from '@material-ui/core/Tab'

interface Props extends TabProps {
  label: string | JSX.Element
  index: number
  disabled?: boolean
  className?: string
  component?: string // Material UI accepts this, not sure why it's not in the props
  href?: string // Material UI accepts this, not sure why it's not in the props
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export const Tab: React.FC<Props> = ({ label, index, className, ...rest }) => {
  return (
    <MuiTab
      className={className}
      label={<span>{label}</span>}
      {...a11yProps(index)}
      {...rest}
      style={{ whiteSpace: 'nowrap', textTransform: 'none' }}
    />
  )
}

Tab.displayName = 'Tab'
