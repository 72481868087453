import { colors, spacing } from '../../utils/style-guide'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize, isEmpty, isNil } from 'lodash'
import ReactJson from 'react-json-view'
import { Heading5, Text } from '../Typography'
import { ExtensionActionFieldType, type ExtensionActivityField } from './types'
import { useStyles } from './useStyles'
import { formatDate, str_to_bool } from '../../utils'

export const ExtensionActivityRecordField = ({
  type,
  label,
  value,
}: Omit<ExtensionActivityField, 'id'>): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const safeJsonParse = (jsonString: string): Record<string, unknown> => {
    try {
      return JSON.parse(jsonString)
    } catch (e: any) {
      return {
        error: t('error_parsing_json'),
        message: !isNil(e.message) ? e.message : 'Unknown error',
      }
    }
  }

  const getFieldFromType = (): JSX.Element => {
    switch (type) {
      case ExtensionActionFieldType.String:
      case ExtensionActionFieldType.Text:
      case ExtensionActionFieldType.Numeric:
      case ExtensionActionFieldType.NumericArray:
      case ExtensionActionFieldType.StringArray:
        return (
          <div>
            <Heading5 variant='textMedium'>{label}:</Heading5>
            <Text spacing={`0 0 ${spacing.xxxs}`}>{value}</Text>
          </div>
        )
      case ExtensionActionFieldType.Boolean: {
        const valueText = str_to_bool(value)
          ? capitalize(t('yes'))
          : capitalize(t('no'))
        return (
          <div>
            <Heading5 variant='textMedium'>{label}:</Heading5>
            <Text spacing={`0 0 ${spacing.xxxs}`}>{valueText}</Text>
          </div>
        )
      }
      case ExtensionActionFieldType.Html:
        return (
          <div>
            <Heading5 variant='textMedium'>{label}</Heading5>
            <div className={classes.htmlViewerContainer}>
              {isEmpty(value) && t('message_content_missing')}
              <div dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          </div>
        )
      case ExtensionActionFieldType.Json:
        return (
          <>
            <Heading5 variant='textMedium'>{label}</Heading5>
            <div className={classes.jsonWrapper}>
              <ReactJson
                src={safeJsonParse(value)}
                enableClipboard={false}
                displayDataTypes={false}
              />
            </div>
          </>
        )
      case ExtensionActionFieldType.Date: {
        const date =
          formatDate({ isoDateStr: value, dateFormat: 'd-LLL-yyyy' }) ?? value
        return (
          <div>
            <Heading5 variant='textMedium'>{label}:</Heading5>
            <Text spacing={`0 0 ${spacing.xxxs}`}>{date}</Text>
          </div>
        )
      }
      default:
        return (
          <Text variant='textMedium' color={colors.red500}>
            {t('error_extension_field_type_not_supported')}
          </Text>
        )
    }
  }

  return <div className={classes.field}>{getFieldFromType()} </div>
}

ExtensionActivityRecordField.displayName = 'ExtensionActivityRecordField'
