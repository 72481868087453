import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { colors, condensedTabHeader } from '../../utils/style-guide'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
    flexDirection: 'column',
  },
})
const useBodyStyles = makeStyles({
  body: {
    backgroundColor: colors.neutralLight20,
    flex: 1,
    zIndex: 1,
    height: '100%',
  },
  // Preview (timeline & activity feed) has a fixed height, so we need to set maxHeight to prevent overflow
  // 8rem is calculated from 4rem (app header height) and 4rem (tab header height)
  fixedHeight: {
    maxHeight: `calc(100vh - 8rem)`,
  },
})
const useHeaderStyles = makeStyles({
  header: {
    zIndex: 10, // Make sure that header is shown above body, otherwise there can be weird UI bugs when scrolling
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: condensedTabHeader,
  },
})

const Header: FC = ({ children }) => {
  const classes = useHeaderStyles()
  return <div className={classes.header}>{children}</div>
}

const Body: FC<{ fixedHeight?: boolean }> = ({
  fixedHeight = true,
  children,
}) => {
  const classes = useBodyStyles()

  return (
    <div className={`${classes.body} ${fixedHeight && classes.fixedHeight}`}>
      {children}
    </div>
  )
}

interface Component {
  Header: FC
  Body: FC<{ fixedHeight?: boolean }>
}

const HeaderBodyLayout: Component & FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.main}>{children}</div>
}

HeaderBodyLayout.displayName = 'HeaderBodyLayout'
HeaderBodyLayout.Header = Header
HeaderBodyLayout.Body = Body

export { HeaderBodyLayout }
