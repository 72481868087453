import React, { createContext, type FC, useContext } from 'react'

interface Labels {
  restart_label: string
  no_questions_tip: string
}

export interface PreviewContextProps {
  labels: Labels
}

const initialContext = {
  labels: {
    restart_label: 'Restart preview',
    no_questions_tip: 'No questions',
  },
}

export const PreviewContext = createContext<PreviewContextProps>(initialContext)

interface PreviewContextProviderProps {
  labels?: Labels
}

export const PreviewContextProvider: FC<PreviewContextProviderProps> = ({
  labels = initialContext.labels,
  children,
}) => {
  return (
    <PreviewContext.Provider value={{ labels }}>
      {children}
    </PreviewContext.Provider>
  )
}

export const usePreviewContext = (): PreviewContextProps =>
  useContext(PreviewContext)
