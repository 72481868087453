import { Button } from '@awell/ui-kit/components/Button'
import { useForm, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormBlock } from '../../../../components/FormBlock'
import { useUpdatePatient } from '../../../../hooks/useUpdatePatient'
import {
  type IdentifierField,
  type Patient,
  type PatientProfile,
} from '../../types'
import React, { type FC } from 'react'
import { Identifiers, PatientProfileInput } from '../../FormInputs'
import { useStyles } from '../../useStyles'
import { useIdentifierSystems } from '../../../../hooks/useIdentifierSystems'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import classnames from 'classnames'
import { Divider } from '@material-ui/core'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { isNil } from 'lodash'

interface PatientIdentifierProps {
  patient: Patient
}
export const PatientIdentifiers: FC<PatientIdentifierProps> = ({ patient }) => {
  const { t } = useTranslation()
  const { identifierSystemsOptions } = useIdentifierSystems()
  const { inputFieldRow, oneInputHalfRow } = useFormStyles()
  const defaultValues = {
    ...patient?.profile,
    identifier: patient?.profile?.identifier ?? [],
  }
  const { control, errors, handleSubmit } = useForm<PatientProfile>({
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray({
    name: 'identifier',
    control,
  })
  const { saveBtnContainer } = useStyles()
  const { updatePatient } = useUpdatePatient(patient.id)

  const onSubmit = (): void => {
    void handleSubmit(async data => {
      // this can be removed once we fully deprecate patient_code
      if (isNil(data.identifier)) {
        data.identifier = []
      } else {
        // filter out empty values
        data.identifier = data.identifier.filter(i => i.value !== '')
      }
      await updatePatient(data)
    })()
  }

  return (
    <FormBlock
      title={t('identifiers')}
      subtitleLink='https://developers.awellhealth.com/awell-orchestration/docs/misc/patient-identifiers'
      subtitleLinkText={t('patient_code_learn_more')}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Identifiers
          fields={fields as Array<IdentifierField>}
          onAppend={append}
          onRemove={remove}
          addFieldTitle={t('add_identifier')}
          control={control}
          errors={errors}
          identifierSystems={identifierSystemsOptions}
        />
        <Divider style={{ marginTop: spacing.xs, marginBottom: spacing.xs }} />
        <div className={classnames(inputFieldRow, oneInputHalfRow)}>
          <PatientProfileInput
            name='patient_code'
            control={control}
            errors={errors}
            label={t('patient_code_deprecated')}
            description={t('patient_code_description')}
            placeholder='011010000110110000001010'
          />
        </div>
        <div className={saveBtnContainer}>
          <Button onClick={onSubmit}>{t('save')}</Button>
        </div>
      </form>
    </FormBlock>
  )
}
