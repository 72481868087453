import { Spinner } from '@awell/ui-kit/components/Spinner'
import React from 'react'
import { Table } from '@awell/ui-kit/components/Table'
import { useGeneratedClinicalNote } from '../../../hooks/useClinicalNote'
import { Activity } from '../types'
import { useClinicalNoteContextColumns } from './useContextColumns'

interface ClinicalNotesContextTabProps {
  activity: Activity
}

export const ClinicalNotesContextTab = ({
  activity,
}: ClinicalNotesContextTabProps): JSX.Element => {
  const { loading, clinicalNote } = useGeneratedClinicalNote(activity.object.id)
  const { columns } = useClinicalNoteContextColumns()

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Table
        columns={columns}
        rows={clinicalNote!.context}
        fullWidth
        nonTablePageContentHeight={0}
      />
    </>
  )
}

ClinicalNotesContextTab.displayName = 'ClinicalNotesContextTab'
