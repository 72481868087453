import { useWhoamiQuery, type User } from './types'
import { useNotifications } from '../useNotifications'

interface UseWhoamiHook {
  loading: boolean
  user?: User
}

export const useWhoami = (): UseWhoamiHook => {
  const { notifyError } = useNotifications()
  const { data, loading, error } = useWhoamiQuery()

  if (error) {
    notifyError({
      message: 'something went  wrong while loading the current user',
      error,
    })
    return { loading: false }
  }
  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    user: data?.whoami.user,
  }
}
