import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useFormStyles = makeStyles(theme => ({
  inputFieldRow: {
    width: '100%',
    display: 'grid',
    columnGap: spacing.s,
    marginBottom: spacing.xs,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  threeInputs: {
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  twoInputs: {
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  oneInputHalfRow: {
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  oneInputFullRow: {
    gridTemplateColumns: '1fr',
  },
}))
