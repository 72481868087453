import React, { type FC } from 'react'
import { Link } from '@reach/router'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'
import { Type, TypeVariants } from '../Typography'
import { noop } from 'lodash'

interface LinkCellProps {
  title: string
  id?: string
  onClick?: (() => void) | (() => Promise<void>)
}

const useStyles = makeStyles({
  link: {
    '&:hover': {
      color: colors.blue400,
      textDecoration: 'underline',
      '&> div > svg': {
        color: colors.blue400,
      },
    },
  },
})
export const LinkCell: FC<LinkCellProps> = ({ id, title, onClick = noop }) => {
  const classes = useStyles()

  return (
    <Link to={id ?? ''} className={classes.link} onClick={onClick}>
      <Type variant={TypeVariants.medium} color={colors.brand100}>
        {title}
      </Type>
    </Link>
  )
}
