/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  CSSProperties,
  FC,
  MouseEventHandler,
  TouchEventHandler,
} from 'react'
import classnames from 'classnames'
import { useStyles } from './useStyles'

interface ResizableDividerProps {
  className?: string
  style?: CSSProperties
  dragging: boolean
  transparent?: boolean
  onMouseDown: MouseEventHandler
  onMouseUp: MouseEventHandler
  onTouchStart: TouchEventHandler
  onTouchEnd: TouchEventHandler
}

export const ResizableDivider: FC<ResizableDividerProps> = ({
  className = '',
  onMouseDown,
  onTouchStart,
  onTouchEnd,
  style = {},
  dragging,
  transparent = false,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.dividerHitbox, className, {
        [classes.dragging]: dragging,
        [classes.transparent]: transparent,
      })}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      style={style}
    >
      <div
        className={transparent ? classes.transparentDivider : classes.divider}
      />
    </div>
  )
}

ResizableDivider.displayName = 'ResizableDivider'
