import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing.s,
  },
  factCellcontainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between', // Ensures space between text and chevron
    transition: 'all 0.3s ease',
  },
  textContainer: {
    flex: 1, // Takes up as much space as possible
    paddingRight: '20px', // Provides space for the chevron icon
  },
  chevron: {
    transition: 'transform 0.3s ease',
    color: colors.neutralLight40,
    cursor: 'pointer',
  },
  idStyle: {
    fontWeight: 500,
  },
  quoteStyle: {
    color: colors.neutralMid400,
    fontWeight: 500,
  },
  rotate180: {
    transform: 'rotate(180deg)',
  },
})
