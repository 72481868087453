import { isEmpty, isNil } from 'lodash'
import React, { type FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FeedSystemAction, FeedReminder, Play, StepStop } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { AwellActivityLayout } from './AwellActivityLayout'
import { BaselineDataPoints } from './BaselineDataPoints'
import {
  type Activity,
  ActivityAction,
  ActivityObjectType,
  type BaselineDataPoint,
  ActivitySubjectType,
} from './types'

interface AwellActivityProps {
  activity: Activity
  baselineDataPoints?: Array<BaselineDataPoint>
  pathwayStatusExplanation?: string
}

export const AwellActivity: FC<AwellActivityProps> = ({
  activity,
  baselineDataPoints = [],
  pathwayStatusExplanation,
}) => {
  const { t } = useTranslation('activityfeed')
  const { subject, action, object, indirect_object, date } = activity

  switch (object.type) {
    case ActivityObjectType.Pathway:
      if (action === ActivityAction.Activate) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <Play />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('pathway_activated', { subject, object })}
            </AwellActivityLayout.Summary>
            {baselineDataPoints.length > 0 ? (
              <AwellActivityLayout.Extra>
                <BaselineDataPoints baselineDataPoints={baselineDataPoints} />
              </AwellActivityLayout.Extra>
            ) : (
              <></>
            )}
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Complete) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('pathway_completed', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Stopped) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <StepStop />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {!isNil(pathwayStatusExplanation) &&
              !isEmpty(pathwayStatusExplanation.trim())
                ? t('pathway_stopped_with_reason', { pathwayStatusExplanation })
                : t('pathway_stopped_without_reason')}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.IsWaitingOn) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('pathway_waiting', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      return null
    case ActivityObjectType.Track:
      if (action === ActivityAction.Activate) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('track_started', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Added) {
        return (
          <AwellActivityLayout useCard>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {subject.type === ActivitySubjectType.ApiCall
                ? t('track_added_by_api_call', { object })
                : t('track_added_by_user', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Complete) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('track_completed', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Stopped) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <StepStop />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('track_stopped', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      return null
    case ActivityObjectType.Step:
      if (action === ActivityAction.Activate) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('step_activated', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Complete) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('step_completed', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Stopped) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <StepStop />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('step_stopped', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Postponed) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('step_waiting', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      if (action === ActivityAction.Discarded) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('step_discarded', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      return null
    case ActivityObjectType.Reminder:
      return (
        <AwellActivityLayout useCard timestamp={date}>
          <AwellActivityLayout.Icon>
            <IconWrapper background rounded size='l'>
              <FeedReminder />
            </IconWrapper>
          </AwellActivityLayout.Icon>
          <AwellActivityLayout.Summary>
            <Trans
              i18nKey='reminder_activity_summary'
              ns='activityfeed'
              values={{
                firstName: indirect_object?.name,
                email: isNil(indirect_object?.email)
                  ? ''
                  : indirect_object?.email,
              }}
            />
          </AwellActivityLayout.Summary>
        </AwellActivityLayout>
      )
    case ActivityObjectType.Action:
      if (action === ActivityAction.Discarded) {
        return (
          <AwellActivityLayout useCard timestamp={date}>
            <AwellActivityLayout.Icon>
              <IconWrapper background rounded size='l'>
                <FeedSystemAction />
              </IconWrapper>
            </AwellActivityLayout.Icon>
            <AwellActivityLayout.Summary>
              {t('action_discarded', { subject, object })}
            </AwellActivityLayout.Summary>
          </AwellActivityLayout>
        )
      }
      return null
    default:
      return null
  }
}

AwellActivity.displayName = 'AwellActivity'
