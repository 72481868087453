import * as React from 'react'

function SvgInfoQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 19a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm0-12A2.5 2.5 0 0010 9.5a.5.5 0 001 0 1.5 1.5 0 112.632.984l-.106.11-.118.1-.247.185a3.104 3.104 0 00-.356.323c-.512.546-.805 1.286-.805 2.298a.5.5 0 001 0c0-.758.196-1.254.535-1.614l.075-.076.08-.073.088-.072.22-.163.153-.125A2.5 2.5 0 0012.501 7zm.5 8.5a.5.5 0 11-1 0 .5.5 0 011 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgInfoQuestion
