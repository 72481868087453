import {
  SearchFilterBar,
  type FilterVariants,
} from '@awell/ui-kit/components/SearchFilterBar'
import {
  type Column,
  Table,
  type TableQuery,
  type TableQueryResult,
} from '@awell/ui-kit/components/Table'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { type RouteComponentProps } from '@reach/router'
import React, { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetOrchestrationFacts,
  type OrchestrationFact,
} from '../../../hooks/useGetOrchestrationFacts'
import { usePathwayContext } from '../../../hooks/usePathwayContext'
import { useAuditLogsColumns } from './useAuditLogsColumns'
import { useStyles } from './useStyles'
import { useDebouncedCallback } from 'use-debounce'
import { FullPageListSkeleton } from '@awell/ui-kit/components/Skeleton/FullPageListSkeleton'
import { isEmpty, isNil } from 'lodash'

interface OrchestrationFactFilters {
  startDate: string | undefined
  endDate: string | undefined
  query: string
}

export const AuditLogs: FC<RouteComponentProps> = ({ location }) => {
  const { t } = useTranslation()
  const { pathwayId } = usePathwayContext()
  const classes = useStyles()
  const tableRef = React.createRef<any>()

  const [sortDirection, setSortDirection] = useState('asc')
  const { columns } = useAuditLogsColumns({ sortDirection })

  const queryParams = new URLSearchParams(location?.search)
  const toDateQueryParam = queryParams.get('to')

  const { loading, refetchOrchestrationFacts } = useGetOrchestrationFacts(
    pathwayId!,
  )

  const [appliedFilters, setFilters] = useState<OrchestrationFactFilters>({
    startDate: undefined,
    endDate: undefined,
    query: '',
  })

  // hacky approach to reset pagination when filters change (common issue with material-table)
  const [resetPagination, setResetPagination] = useState(false)

  useEffect(() => {
    void debouncedTableRefresh()
    setResetPagination(true)
  }, [appliedFilters])

  useEffect(() => {
    if (!isNil(toDateQueryParam) && toDateQueryParam !== '') {
      setFilters({
        ...appliedFilters,
        endDate: toDateQueryParam,
      })
      setSortDirection('desc')
    }
  }, [toDateQueryParam])

  const debouncedTableRefresh = useDebouncedCallback(
    () => tableRef?.current?.onQueryChange(),
    1000,
  )

  if (loading) {
    return (
      <FullPageListSkeleton<Column<OrchestrationFact>>
        hasHeaderButton={false}
        hasHeaderSubtitle={false}
        cellCount={columns.length}
        columns={columns}
      />
    )
  }

  const handleSearchAndFilters = ({
    startDate,
    endDate,
    query,
  }: OrchestrationFactFilters): void => {
    setFilters({
      ...appliedFilters,
      startDate,
      endDate,
      query,
    })
  }

  const filters: Array<FilterVariants> = [
    {
      label: t('from'),
      name: 'startDate',
      type: 'date_time',
      format: 'yyyy-MM-dd hh:mm:ss a',
    },
    {
      label: t('to'),
      name: 'endDate',
      type: 'date_time',
      defaultValue: toDateQueryParam,
      format: 'yyyy-MM-dd hh:mm:ss a',
    },
  ]

  const handleQueryChange = async (
    query: TableQuery<OrchestrationFact>,
  ): Promise<TableQueryResult<OrchestrationFact>> => {
    const { page: currentPage, pageSize, orderDirection } = query

    const page = resetPagination ? 0 : currentPage

    const offset = page * pageSize
    const count = pageSize
    const {
      data: {
        pathwayFacts: { facts, pagination },
      },
    } = await refetchOrchestrationFacts({
      pagination: {
        count,
        offset,
      },
      filters: {
        date: {
          lte: appliedFilters.endDate,
          gte: appliedFilters.startDate,
        },
        pathway_id: pathwayId!,
        keyword: appliedFilters.query,
      },
      sorting: {
        field: 'date',
        direction: isEmpty(orderDirection) ? 'desc' : orderDirection,
      },
    })

    resetPagination && setResetPagination(false)

    return {
      data: facts,
      totalCount: pagination?.total_count ?? 0,
      page,
    }
  }

  return (
    <div>
      <div className={classes.filtersContainer}>
        <SearchFilterBar<OrchestrationFactFilters>
          onSearchFilter={handleSearchAndFilters}
          filters={filters}
          appliedFilters={appliedFilters}
          placeholder={t('audit_logs_search_placeholder')}
          customSpacing={`${spacing.xs} 0`}
          hideBorder
        />
      </div>
      <div>
        <Table
          columns={columns}
          rows={handleQueryChange}
          fullWidth
          paging
          nonTablePageContentHeight={500}
          tableRef={tableRef}
          rowStyles={{
            verticalAlign: 'top',
          }}
        />
      </div>
    </div>
  )
}

AuditLogs.displayName = 'AuditLogs'
