import { useTheme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Fade from '@material-ui/core/Fade'
import { type TransitionProps } from '@material-ui/core/transitions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classnames from 'classnames'
import { isNil, kebabCase } from 'lodash'
import React, { type FC, type ReactElement, type ReactNode } from 'react'
import { Button } from '../Button'
import { Error, Warning } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

export interface AlertModalLabels {
  heading: string
  primaryAction: string
  secondaryAction: string
}

interface AlertModalProps {
  children?: ReactNode
  labels: AlertModalLabels
  appearance?: 'danger' | 'warning'
  onAction: () => void
  onClose: () => void
  open: boolean
  additionalActionButton?: ReactElement

  hidePrimaryAction?: boolean
  style?: Record<string, any>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<never, never> },
  ref: React.Ref<unknown>,
) {
  return <Fade in ref={ref} {...props} />
})

export const AlertModal: FC<AlertModalProps> = ({
  children,
  appearance,
  labels,
  onClose,
  onAction,
  open,
  additionalActionButton,

  hidePrimaryAction = false,
  style,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const icon = (() => {
    switch (appearance) {
      case 'warning':
        return <Warning />
      case 'danger':
        return <Error />
      default:
        return null
    }
  })()

  const isPrimary = appearance !== 'danger' && appearance !== 'warning'
  const isError = appearance === 'danger'
  const isWarning = appearance === 'warning'

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      // @ts-expect-error FIXME: - Transition is not typed correctly
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      classes={{
        container: classes.backdrop,
        paper: classes.modal,
      }}
      style={{ ...style }}
    >
      <div id='alert-dialog-slide-title' className={classes.header}>
        {icon && (
          <IconWrapper
            className={classnames(classes.icon, {
              [classes.error]: isError,
              [classes.warning]: isWarning,
              [classes.informative]: isPrimary,
            })}
          >
            {icon}
          </IconWrapper>
        )}

        <div className={classes.title}>
          <Type variant={TypeVariants.h4}>{labels.heading}</Type>
        </div>
      </div>
      {!isNil(children) && <DialogContent>{children}</DialogContent>}
      <DialogActions classes={{ root: classes.rootFooter }}>
        <div
          className={classnames(classes.actionsWrapper, {
            [classes.hasAdditionalAction]: additionalActionButton,
          })}
        >
          {additionalActionButton && additionalActionButton}

          <div>
            <Button variant='text' color='secondary' onClick={onClose}>
              {labels.secondaryAction}
            </Button>
            {!hidePrimaryAction && (
              <Button
                onClick={onAction}
                appearance={appearance}
                className={classes.mainActionButton}
                data-heap={`modal-primary-action-${kebabCase(
                  labels.primaryAction,
                )}`}
              >
                {labels.primaryAction}
              </Button>
            )}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

AlertModal.displayName = 'AlertModal'
