import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  statusDot: {
    height: spacing.xxs,
    width: spacing.xxs,
    borderRadius: '50%',
    marginRight: spacing.xxs,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing.xxs,
  },
})
