import * as React from 'react'

function SvgCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M16.191 6.412a1 1 0 011.685 1.07l-.067.106-6.816 10.122-4.807-7.129a1 1 0 011.548-1.26l.08.098 3.193 4.87 5.184-7.877z'
      />
    </svg>
  )
}

export default SvgCheckmark
