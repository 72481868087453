import { spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  titleContainer: {
    paddingTop: spacing.m,
    paddingBottom: spacing.m,
  },
  breadCrumbs: {
    marginBottom: spacing.s,
    lineHeight: '1.857rem', // 26px
  },
})
