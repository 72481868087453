import { makeStyles } from '@material-ui/core'
import { rounded, colors, spacing, labelColors } from '../../utils/style-guide'
import { typographyStyles } from '../Typography/Typography'

export const useStyles = makeStyles({
  status: {
    ...rounded.sm,
    textAlign: 'center',
    width: '100px',
  },
  stepCardStatus: {
    ...rounded.sm,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: 'fit-content',
    cursor: 'pointer',
    color: colors.neutralDark800,
  },
  labelContainer: {
    height: spacing.xs,
    width: '100%',
    display: 'flex',
    // spacing between the label and the summary text when open
    marginBottom: 4,
    alignItems: 'center',
  },
  compact: {
    width: 'max-content',
    paddingRight: spacing.xxxs,
    paddingLeft: spacing.xxxs,
  },
  text: {
    ...typographyStyles.xsmall,
  },
  stepLabelText: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      userSelect: 'none',
      lineHeight: 1,
      fontSize: 10,
      textTransform: 'uppercase',
    },
  },
  green: {
    backgroundColor: colors.green100,
    '& $text': {
      color: colors.neutralLight0,
    },
  },
  yellow: {
    backgroundColor: colors.yellow75,
    '& $text': {
      color: colors.neutralDark800,
    },
  },
  blue: {
    backgroundColor: colors.blue50,
    '& $text': {
      color: colors.blue500,
    },
  },
  grey: {
    backgroundColor: colors.neutralLight40,
    '& $text': {
      color: colors.neutralDark500,
    },
  },
  red: {
    backgroundColor: colors.signalError100,
    '& $text': {
      color: colors.neutralLight0,
    },
  },
  blueLabel: {
    backgroundColor: labelColors.blue,
  },
  tealLabel: {
    backgroundColor: labelColors.slateBlue,
  },
  slateBlueLabel: {
    backgroundColor: labelColors.slateBlue,
  },
  greenLabel: {
    backgroundColor: labelColors.green,
  },
  orangeLabel: {
    backgroundColor: labelColors.yellow,
  },
  yellowLabel: {
    backgroundColor: labelColors.yellow,
  },
  purpleLabel: {
    backgroundColor: labelColors.purple,
  },
  redLabel: {
    backgroundColor: labelColors.pink,
  },
  pinkLabel: {
    backgroundColor: labelColors.pink,
  },
})
