/* eslint-disable max-params */
import { useEffect } from 'react'

export enum KeyboardKey {
  'ENTER' = 'Enter',
  'TAB' = 'Tab',
  'SHIFT' = 'Shift',
  'ESCAPE' = 'Escape',
}

type UseKeyListenerHook = (
  eventName: 'keydown' | 'keyup',
  handler: (event: KeyboardEvent) => void,
  ...deps: Array<any>
) => void

/**
 * Custom React Hook that provides a declarative useKeyListener
 */
export const useKeyListener: UseKeyListenerHook = (
  eventName,
  handler,
  deps = [],
) => {
  useEffect(() => {
    const isSupported = document && document.addEventListener
    if (!isSupported) {
      return
    }

    const eventListener = (event: KeyboardEvent) => handler(event)
    document.addEventListener(eventName, eventListener)
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener(eventName, eventListener)
    }
  }, [eventName, ...deps])
}
