import { makeStyles } from '@material-ui/core'
import { colors } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  questionIcon: {
    display: 'block',
    alignSelf: 'flex-start',
    color: colors.neutralDark500,
    borderRadius: 4,
  },
  active: {
    color: colors.neutralLight0,
    backgroundColor: colors.brand100,
  },
})
