import { compose, find, fromPairs, isNil, map } from 'lodash/fp'
import {
  type Answer,
  type AnswerInput,
  type AnswerValue,
  type Question,
  type QuestionRuleResult,
  type QuestionWithVisibility,
} from '../types'

const ensureString = (val: AnswerValue) =>
  typeof val === 'string'
    ? val
    : typeof val === 'undefined'
    ? ''
    : JSON.stringify(val)

type FormHookResponse = Record<string, AnswerValue>
type AwellFormResponseInput = Array<AnswerInput>

export const convertToAwellInput = (
  formResponse: FormHookResponse,
): AwellFormResponseInput => {
  const answers = Object.keys(formResponse).map(question_id => ({
    question_id,
    value: ensureString(formResponse[question_id]),
  }))
  return answers
}

export const convertToFormHook = (answers: Array<Answer>): FormHookResponse => {
  return compose(
    fromPairs,
    map<Answer, [string, AnswerValue]>(answer => [
      answer.question_id,
      answer.value,
    ]),
  )(answers)
}

/**
 * Updates question visibility after rules evaluations
 */
export const updateVisibility = (
  questions: Array<Question>,
  evaluation_results: Array<QuestionRuleResult>,
): Array<QuestionWithVisibility> =>
  questions.map(question => {
    const result = find<QuestionRuleResult>(['question_id', question.id])(
      evaluation_results,
    )
    const visible = isNil(result) ? true : result.satisfied
    return { ...question, visible }
  })
