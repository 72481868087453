import * as React from 'react'

function SvgStepStop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='#fff'
      {...props}
    >
      <rect
        width={16}
        height={16}
        rx={1}
        fillRule='evenodd'
        fill='currentColor'
        transform='translate(4 4)'
      />
    </svg>
  )
}

export default SvgStepStop
