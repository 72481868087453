import * as React from 'react'

function SvgQrCode(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path d='M1.805.055C.953.215.21.98.05 1.848.02 2.023 0 2.996 0 4.5c0 2.367 0 2.371.11 2.578.109.227.41.422.64.422.23 0 .531-.195.64-.422.11-.207.11-.21.11-2.629V2.031l.11-.16c.054-.086.175-.207.261-.262l.16-.109H4.45c2.418 0 2.422 0 2.63-.11.226-.109.421-.41.421-.64 0-.23-.195-.531-.422-.648L6.871 0 4.453.004C3.125.008 1.937.03 1.805.054zm0 0M16.922.102C16.695.219 16.5.52 16.5.75c0 .23.195.531.422.64.207.11.21.11 2.629.11h2.418l.16.11c.086.054.207.175.262.261l.109.16V4.45c0 2.418 0 2.422.11 2.63.109.226.41.421.64.421.23 0 .531-.195.648-.422C24 6.871 24 6.868 24 4.5c0-1.504-.02-2.477-.05-2.652A2.288 2.288 0 0022.151.05C21.977.02 21.004 0 19.5 0c-2.367 0-2.371 0-2.578.102zm0 0' />
      <path d='M4.906 3.8a1.548 1.548 0 00-1.011.84l-.122.258v2.579c0 2.433.004 2.593.09 2.812.121.305.48.688.793.824l.242.114h5.204l.242-.114c.312-.136.672-.52.793-.824.086-.219.09-.379.09-2.789s-.004-2.57-.09-2.79c-.121-.304-.48-.687-.793-.823l-.242-.114-2.508-.007c-1.38-.008-2.586.011-2.688.035zM9.75 7.5v2.25h-4.5v-4.5h4.5zm0 0' />
      <path d='M6.375 7.5v1.125h2.25v-2.25h-2.25zm0 0M13.906 3.8a1.548 1.548 0 00-1.011.84l-.122.258v2.579c0 2.433.004 2.593.09 2.812.121.305.48.688.793.824l.242.114h5.204l.242-.114c.312-.136.672-.52.793-.824.086-.219.09-.379.09-2.789s-.004-2.57-.09-2.79c-.121-.304-.48-.687-.793-.823l-.242-.114-2.508-.007c-1.38-.008-2.586.011-2.688.035zm4.844 3.7v2.25h-4.5v-4.5h4.5zm0 0' />
      <path d='M15.375 7.5v1.125h2.25v-2.25h-2.25zm0 0M4.906 12.8a1.548 1.548 0 00-1.011.84l-.122.258v2.579c0 2.433.004 2.593.09 2.812.121.305.48.688.793.824l.242.114h5.204l.242-.114c.312-.136.672-.52.793-.824.086-.219.09-.379.09-2.789s-.004-2.57-.09-2.79c-.121-.304-.48-.687-.793-.823l-.242-.114-2.508-.007c-1.38-.008-2.586.011-2.688.035zm4.844 3.7v2.25h-4.5v-4.5h4.5zm0 0' />
      <path d='M6.375 16.5v1.125h2.25v-2.25h-2.25zm0 0M12.75 13.875V15H15v2.25h-2.25v2.25H15v-2.25h2.25v2.25h2.25v-2.25h-2.25V15h2.25v-2.25h-2.25V15H15v-2.25h-2.25zm0 0M.422 16.602a.625.625 0 00-.313.32C0 17.129 0 17.132 0 19.5c0 1.504.02 2.477.05 2.652.165.891.907 1.633 1.798 1.797.175.031 1.148.051 2.652.051 2.367 0 2.371 0 2.578-.11.227-.109.422-.41.422-.64 0-.23-.195-.531-.422-.64-.207-.11-.21-.11-2.629-.11H2.031l-.16-.11a1.042 1.042 0 01-.262-.261l-.109-.16V19.55c0-2.418 0-2.422-.102-2.63C1.281 16.696.98 16.5.75 16.5c-.066 0-.215.047-.328.102zm0 0M22.922 16.602a.625.625 0 00-.313.32c-.109.207-.109.21-.109 2.629v2.418l-.11.16a1.042 1.042 0 01-.261.262l-.16.109H19.55c-2.418 0-2.422 0-2.63.11-.226.109-.421.41-.421.64 0 .23.195.531.422.64.207.11.21.11 2.578.11 1.504 0 2.477-.02 2.652-.05a2.288 2.288 0 001.797-1.798c.031-.175.051-1.148.051-2.652 0-2.367 0-2.371-.102-2.578-.117-.227-.418-.422-.648-.422-.066 0-.215.047-.328.102zm0 0' />
    </svg>
  )
}

export default SvgQrCode
