import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  navContainer: {
    display: 'flex',
    marginBottom: spacing.xs,
    justifyContent: 'space-between',
  },
  bodyContainer: {
    marginTop: spacing.m,
  },
  basicInfoContainer: {
    display: 'flex',
    marginBottom: spacing.m,
  },
  basicInfoGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: spacing.xxxl,
  },
  container: {
    background: colors.neutralLight0,
    height: '100%',
  },

  jsonContainer: {
    background: colors.neutralLight20,
    borderRadius: '8px',
    marginTop: spacing.xxs,
    padding: spacing.s,
  },

  responseInfo: {
    marginTop: spacing.xxs,
    display: 'flex',
  },
})
