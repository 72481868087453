// @ts-nocheck TODO: remove this comment and fix errors
import { ActionType, ElementType, GanttData, GanttFilters } from './types'

interface UseFiltersHook {
  applyFilters: (_id: string, task: GanttData) => boolean
}

export const useFilters = ({
  query,
  type,
  status,
  activity_type,
  stakeholder,
}: GanttFilters): UseFiltersHook => {
  const matchesSearchString = (task: GanttData): boolean => {
    const normalisedSearchTerm = query.toLowerCase().trim()
    if (!normalisedSearchTerm) {
      return true
    }

    return task.text.toLowerCase().includes(normalisedSearchTerm)
  }
  const matchesTypeFilter = (task: GanttData): boolean => {
    if (!type?.length) return true
    return type.includes(task.element_type)
  }
  const matchesStatusFilter = (task: GanttData): boolean => {
    if (!status?.length) return true
    return status.includes(task.status)
  }
  const matchesStakeholderFilter = (task: GanttData): boolean => {
    if (!stakeholder?.length) return true
    return task.stakeholders
      .map(({ id }) => id)
      .some(id => stakeholder.includes(id))
  }
  const matchesActivityTypeFilter = (task: GanttData): boolean => {
    if (!activity_type?.length) return true
    return activity_type.includes(task.activity_type)
  }

  const applyFilters = (_id: string, task: GanttData): boolean => {
    const matchesType = matchesTypeFilter(task)
    const matchesStatus = matchesStatusFilter(task)
    const matchesActivityType = matchesActivityTypeFilter(task)
    const matchesStakeholder = matchesStakeholderFilter(task)
    const matchesSearch = matchesSearchString(task)

    return (
      matchesActivityType &&
      matchesStatus &&
      matchesType &&
      matchesStakeholder &&
      matchesSearch
    )
  }

  return { applyFilters }
}
