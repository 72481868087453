import React, { type ReactElement, type FC } from 'react'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { useStyles } from './useStyles'
import { isNil } from 'lodash'
import { colors } from '@awell/ui-kit/utils/style-guide'

interface FormBlockProps {
  title: string
  subtitle?: string
  subtitleLink?: string
  subtitleLinkText?: string
  children: React.ReactNode | Array<React.ReactNode>
}

export const FormBlock: FC<FormBlockProps> = ({
  title,
  subtitle,
  subtitleLink,
  subtitleLinkText = '',
  children,
}): ReactElement => {
  const classes = useStyles()
  return (
    <div className={classes.formBlockContainer}>
      <Type variant={TypeVariants.h4}>{title}</Type>
      {!isNil(subtitle) && (
        <Type variant={TypeVariants.regular} color={colors.neutralMid200}>
          {subtitle}
        </Type>
      )}
      {!isNil(subtitleLink) && (
        <div>
          <a
            href={subtitleLink}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.link}
          >
            {subtitleLinkText}
          </a>
        </div>
      )}
      <div className={classes.formContent}>{children}</div>
    </div>
  )
}

FormBlock.displayName = 'FormBlock'
