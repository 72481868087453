// @ts-nocheck TODO: remove this comment and fix errors
import { ToolbarButton } from '@udecode/plate'
import { isEmpty } from 'lodash'
import React, { FC, useState, ReactNode } from 'react'
import { Dialog } from '../../Dialog'
import { spacing } from '../../../utils/style-guide'
import { Tooltip } from '../../Tooltip'

interface ToolbarModalProps {
  icon: ReactNode
  children: (onClose: () => void) => ReactNode
  tooltip?: string
  dialogStyle?: Record<string, string | number>
}

/**
 * Use this component to add a button to the toolbar that needs to open a modal
 * dialog.
 *
 * The modal dialog will automatically close when the user clicks on the backdrop or
 * presses the Escape key. To be able to close the modal programmatically the children
 * is a render prop that accepts a single `onClose` parameter.
 *
 * Example:
 * ```
 * <MyToolbarItem>
 *  <ToolbarModal icon={<Add />}>
 *    {onClose => (
 *      <DialogActions>
 *        <Button onClick={onClose}>Click me to close the dialog</Button>
 *      </DialogActions>
 *    )}
 *  </ToolbarModal>
 * </MyToolbarItem>
 * ```
 */
export const ToolbarModal: FC<ToolbarModalProps> = ({
  icon,
  children,
  tooltip,
  dialogStyle = {},
}) => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip info={tooltip} arrow>
        <span>
          <ToolbarButton icon={icon} onMouseDown={() => setOpen(true)} />
        </span>
      </Tooltip>
      <Dialog
        open={open}
        hideBackdrop
        disablePortal
        style={
          isEmpty(dialogStyle)
            ? {
                position: 'absolute',
                top: 0,
                left: spacing.s,
                width: 'fit-content',
                height: 'fit-content',
              }
            : dialogStyle
        }
        onClose={onClose}
      >
        {children(onClose)}
      </Dialog>
    </>
  )
}

ToolbarModal.displayName = 'ToolbarModal'
