/* eslint-disable react/jsx-wrap-multilines */
import React, { FC } from 'react'
import { colors } from '../../../utils/style-guide'
import { Button } from '../../Button'
import { Refresh } from '../../Icons'
import { IconWrapper } from '../../IconWrapper'
import { useFormContextUpdater } from '../contexts'
import { usePreviewContext } from '../usePreviewContext'

export const RestartPreviewButton: FC = () => {
  const { resetForm } = useFormContextUpdater()
  const { labels } = usePreviewContext()

  return (
    <Button
      color='primary'
      onClick={resetForm}
      startIcon={
        <IconWrapper color={colors.brand100} size='xxs'>
          <Refresh />
        </IconWrapper>
      }
    >
      {labels.restart_label}
    </Button>
  )
}

RestartPreviewButton.displayName = 'RestartPreviewButton'
