// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import {
  HeadingTertiary,
  Type,
  TypeVariants,
} from '@awell/ui-kit/components/Typography'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { useStyles } from './useStyles'

interface PatientProfileAttributeRowProps {
  heading: string
  noData?: boolean
}

export const PatientProfileAttributeRow: FC<PatientProfileAttributeRowProps> = ({
  heading,
  noData = false,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.profileAttributeRow}>
      <HeadingTertiary color={colors.neutralLight100} variant='tinyHeadline'>
        {heading}
      </HeadingTertiary>
      <Type
        variant={noData ? TypeVariants.subtle : TypeVariants.medium}
        style={noData && { fontStyle: 'italic' }}
      >
        {children}
      </Type>
    </div>
  )
}
