import {
  createPlugins,
  HeadingToolbar,
  Plate,
  type PlatePlugin,
} from '@udecode/plate'
import classnames from 'classnames'
import { defaultTo } from 'lodash'
import React, { type FC, useMemo } from 'react'
import { type EditableProps } from 'slate-react/dist/components/editable'
import { switchCaseMatchGuard } from '../../utils'
import { rounded, type } from '../../utils/style-guide'
import { InputLabel } from '../InputField'
import { components } from './components'
import { DataVariableContextProvider } from './DataVariablesContextProvider'
import { type DynamicVariable } from './DynamicVariablePlugin'
import { StakeholderContextProvider } from './HostedPagesLinkPlugin'
import { type Stakeholder } from './HostedPagesLinkPlugin/types'
import {
  defaultPlugins,
  textHtmlExtensionActionFieldPlugins,
  jsonEditorPlugins,
  plaintextPlugins,
  clinicalNotePlugins,
} from './plugins'
import { HoveringToolbar } from './Toolbar'
import { ToolbarVariant } from './types'
import { useDebounceChanges } from './useEditorContent'
import { toolbarStyle, useStyles } from './useStyles'
import { parseStringSlateContent } from './utils'

interface Props {
  mode?:
    | 'rich-text'
    | 'plaintext'
    | 'json'
    | 'text-html-extension-action-field'
    | 'clinical-note'
  content?: string
  onChange: (content: string, html: string, plaintext?: string) => void
  debounced?: boolean
  placeholder?: string
  readOnly?: boolean
  variablesLoading?: boolean
  id: string
  label?: string
  description?: string
  required?: boolean
  toolbarVariant?: ToolbarVariant
  onDynamicVariableClick?: (variable_id: string) => void
  variables?: Array<DynamicVariable>
  stakeholders?: Array<Stakeholder>
  selectedVariableId?: string
  hideToolbar?: boolean
  customStyle?: Record<string, string>
}

export const Editor: FC<Props> = ({
  mode = 'rich-text',
  placeholder = '',
  content = '',
  onChange,
  debounced = true,
  readOnly = false,
  children,
  id,
  label,
  description,
  required = false,
  toolbarVariant = ToolbarVariant.left,
  onDynamicVariableClick = () => null,
  variables = [],
  stakeholders = [],
  selectedVariableId = '',
  hideToolbar = false,
  customStyle = {},
  variablesLoading = false,
}) => {
  const classes = useStyles({ mode: toolbarVariant })

  const plugins = useMemo(() => {
    const getPlugins = (): Array<PlatePlugin> => {
      switch (mode) {
        case 'rich-text':
          return defaultPlugins
        case 'plaintext':
          return plaintextPlugins
        case 'json':
          return jsonEditorPlugins
        case 'text-html-extension-action-field':
          return textHtmlExtensionActionFieldPlugins
        case 'clinical-note':
          return clinicalNotePlugins
        default:
          return switchCaseMatchGuard(mode)
      }
    }
    return createPlugins(getPlugins(), {
      components,
    })
  }, [mode])

  const { onChangeNodes } = useDebounceChanges({
    editorId: id,
    onChange,
    debounced,
    plugins,
    variables,
  })

  const editableProps: EditableProps = {
    placeholder,
    className: classnames(classes.common, classes.editor, {
      [classes.jsonEditor]: mode === 'json',
    }),
    readOnly,
    style: {
      ...rounded.m,
      ...type.s,
      // set fixed height so the editor doesn't grow
      // dimensions from Figma design
      height: 280,
      overflow: 'auto',
      // these styles have inline defaults set by Slate so rather than using !important in our useStyles, we can overwrite them here
      borderWidth: '2px',
      minHeight: `calc(10 * ${type.s.lineHeight})`,
      ...customStyle,
    },
  }

  const showHoveringToolbar = mode === 'rich-text'
  return (
    <>
      <InputLabel
        htmlFor={id}
        label={defaultTo(label, '')}
        description={description}
        required={required}
      />
      <StakeholderContextProvider stakeholders={stakeholders}>
        <DataVariableContextProvider
          loading={variablesLoading}
          variables={variables}
          onVariableClick={onDynamicVariableClick}
          selectedVariableId={selectedVariableId}
        >
          <Plate
            id={id}
            plugins={plugins}
            value={parseStringSlateContent(content)}
            editableProps={editableProps}
            onChange={onChangeNodes}
          >
            <HeadingToolbar
              styles={{
                root: toolbarStyle[toolbarVariant],
              }}
              className={classnames({
                [classes.hiddenToolbar]: hideToolbar,
              })}
            >
              {children}
            </HeadingToolbar>
            {showHoveringToolbar && <HoveringToolbar editorId={id} />}
            {/* SLOT FOR RENDERING RICH TEXT MODALS - DO NOT REMOVE */}
            <div id={`modal-slot-${id}`} style={{ position: 'relative' }} />
          </Plate>
        </DataVariableContextProvider>
      </StakeholderContextProvider>
    </>
  )
}
Editor.displayName = 'Editor'
