import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'
import { type Option } from '../../components/MultipleChoiceEditor/types'
import { getPreviewableIcd10Codes } from '../../components/FormBuilder/QuestionInput/helpers/getPreviewableIcd10Codes'

// TODO: move this to env variables
const ICD_10_CLASSIFICATION_ENDPOINT = `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search`

interface UseICDClassificationListHook {
  options: Array<Option>
  loading: boolean
  error: string | null
  onIcdClassificationSearchChange: (val: string) => void
}

/**
 * Custom hook to fetch and manage a list of ICD-10 classification codes. When in preview mode, the hook will use a predefined list of ICD-10 codes.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {boolean} [params.isPreview=false] - Indicates if the hook is running in preview mode.
 *
 * @returns {UseICDClassificationListHook} An object containing the options, loading state, error message, and a function to update the search term.
 *
 * @example
 * const {
 *   options,
 *   loading,
 *   error,
 *   onIcdClassificationSearchChange,
 * } = useICDClassificationList({ isPreview: false })
 */
export const useICDClassificationList = ({
  isPreview = false,
}: {
  isPreview?: boolean
}): UseICDClassificationListHook => {
  const [options, setOptions] = useState<Array<Option>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState('')

  const fetchICDCodes = async (terms: string): Promise<void> => {
    setLoading(true)
    setError(null)

    try {
      /**
       * The API documentation can be found here: https://clinicaltables.nlm.nih.gov/apidoc/icd10cm/v3/doc.html
       *
       * sf: A comma-separated list of fields to be searched.
       * df: A comma-separated list of display fields which are intended for the user to see when looking at the results.
       */
      const response = await axios.get(ICD_10_CLASSIFICATION_ENDPOINT, {
        params: {
          terms,
          sf: 'code,name',
          df: 'code,name',
          maxList: 10,
        },
      })

      /**
       * The API documentation can be found here: https://clinicaltables.nlm.nih.gov/apidoc/icd10cm/v3/doc.html
       *
       * The response data is an array with the following structure:
       * 1. The total number of results on the server
       * 2. An array of codes for the returned items.
       * 3. A hash of the "extra" data requested via the "ef" query parameter above
       * 4. An array, with one element for each returned code, where each element is an array of the display strings specified with the "df" query parameter.
       *
       * We're only interestd in the fourth element of the response data.
       */
      const [, , , displayStrings] = response.data

      const icdOptions: Array<Option> = displayStrings.map(
        ([code, name]: [string, string]) => ({
          value: `${code}|${name}`,
          label: `${code} - ${name}`,
        }),
      )

      setOptions(icdOptions)
    } catch (err) {
      setError('Failed to fetch ICD-10 codes')
    } finally {
      setLoading(false)
    }
  }

  const debouncedFetchICDCodesRef = useRef(
    debounce((terms: string) => {
      void fetchICDCodes(terms)
    }, 600),
  )

  useEffect(() => {
    if (searchValue.length > 1) {
      if (isPreview) {
        // Use preview data
        const previewCodes = getPreviewableIcd10Codes()
        const filteredOptions = previewCodes
          .filter(([code, name]) =>
            `${code} ${name}`.toLowerCase().includes(searchValue.toLowerCase()),
          )
          .map(([code, name]) => ({
            value: `${code}|${name}`,
            label: `${code} - ${name}`,
          }))
        setOptions(filteredOptions)
      } else {
        // Fetch from API
        debouncedFetchICDCodesRef.current(searchValue)
      }
    } else {
      setOptions([])
    }

    return () => {
      if (!isPreview) {
        debouncedFetchICDCodesRef.current.cancel()
      }
    }
  }, [searchValue, isPreview])

  const onIcdClassificationSearchChange = (val: string): void => {
    setSearchValue(val)
  }

  return { options, loading, error, onIcdClassificationSearchChange }
}
