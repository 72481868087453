/* eslint-disable react/jsx-wrap-multilines */
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from '@material-ui/core'
import { isNil, toString } from 'lodash'
import React, { type ChangeEvent, type FC } from 'react'
import classnames from 'classnames'
import { colors } from '../../../utils/style-guide'
import { Type, TypeVariants } from '../../Typography'
import { useStyles } from './useStyles'

export interface RadioGroupOption {
  id: string
  label: string
  value: unknown
}

interface RadioGroupProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  options: Array<RadioGroupOption>
  value: string
  name?: string
  error?: string
  disabled?: boolean
  label?: string
  alignHorizontally?: boolean
  required?: boolean
}

export const RadioGroup: FC<RadioGroupProps> = ({
  value,
  options,
  name,
  error,
  disabled,
  onChange,
  onBlur,
  label,
  alignHorizontally = false,
  required = false,
}) => {
  const classes = useStyles()

  return (
    <FormControl component='fieldset' classes={{ root: classes.formControl }}>
      {!isNil(label) && (
        <Type className={classes.label} variant={TypeVariants.medium}>
          {label}
          {required && <span className={classes.requiredFieldMarker}>*</span>}
        </Type>
      )}
      <MaterialRadioGroup
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        row={alignHorizontally}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            disabled={disabled}
            value={toString(option.value)}
            classes={{
              root: classnames(classes.formControlLabel, {
                [classes.alignHorizontally]: alignHorizontally,
              }),
              label: classnames({
                [classes.horizontalLabel]: alignHorizontally,
              }),
            }}
            control={
              <Radio
                color='primary'
                name={name}
                classes={{
                  root: classes.radioCheckboxInputs,
                }}
                disableRipple
              />
            }
            label={option.label}
          />
        ))}
      </MaterialRadioGroup>
      {!isNil(error) && (
        <FormHelperText className={classes.error}>
          <Type variant={TypeVariants.small} color={colors.signalError100} span>
            {error}
          </Type>
        </FormHelperText>
      )}
    </FormControl>
  )
}
