import * as React from 'react'

function SvgFeedReminder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 4a4 4 0 014-4h24a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4z'
        fill='#FFF'
      />
      <path
        d='M17.353 22.202a1.333 1.333 0 01-2.307 0m7.82-2.667H9.533a2 2 0 002-2V14.2a4.668 4.668 0 017.966-3.3 4.668 4.668 0 011.367 3.3v3.334a2.001 2.001 0 002 2z'
        stroke='currentColor'
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default SvgFeedReminder
