import { isEmpty, toNumber } from 'lodash'
import { DataPointValueType } from '../../AwellActivity'
import { type Option } from '../types'

interface getParsedOptionsResponse {
  options: Array<Omit<Option, 'id'>>
}

export const getParsedOptions = (
  optionString: string,
  dataPointValueType: DataPointValueType = DataPointValueType.Number,
): getParsedOptionsResponse => {
  const optionsArray = optionString
    .trim()
    .split('\n')
    .filter(option => !isEmpty(option))

  const allOptionsHaveValidValues = optionsArray.every(option => {
    /**
     * Safety check
     */
    if (option.length === 0) {
      return false
    }

    const optionValue = option.slice(0, option.indexOf('=')).trim()

    /**
     * A value cannot be empty
     */
    if (optionValue.length === 0) {
      return false
    }

    /**
     * If the data point value type is string,
     * the value needs to be a string with an equal sign
     */
    if (
      dataPointValueType === DataPointValueType.String ||
      dataPointValueType === DataPointValueType.StringsArray
    ) {
      if (option.includes('=')) {
        return true
      }

      return false
    }

    const normalizedValue = toNumber(optionValue)

    /**
     * A value needs to be a number
     */
    if (Number.isNaN(normalizedValue)) {
      return false
    }

    return true
  })

  /**
   * If all options have valid values,
   * extract those values for our options
   */
  if (allOptionsHaveValidValues) {
    const options = optionsArray.map(element => {
      const value = element.slice(0, element.indexOf('=')).trim()
      const label = element.slice(element.indexOf('=') + 1).trim()

      return { value, label }
    })

    return { options }
  }

  /**
   * If not all options have values,
   * create auto-incremented values
   */
  const options = optionsArray.map((option, index) => {
    const label = option.slice(option.indexOf('=') + 1).trim()

    return {
      value:
        dataPointValueType === DataPointValueType.String ||
        dataPointValueType === DataPointValueType.StringsArray
          ? `option_${index + 1}`
          : `${index}`,
      label,
    }
  })

  return {
    options,
  }
}
