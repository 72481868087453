import React, { type FC } from 'react'
import { Text } from '../../../Typography'
import { Switch } from '../../../Switch'
import { makeStyles } from '@material-ui/core'
import { spacing } from '../../../../utils/style-guide'

const useStyles = makeStyles({
  configurationTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing.xs,
    marginTop: spacing.xs,
  },
})

interface ConfigurationTitleProps {
  title: string
  onToggle?: () => void
  enabled?: boolean
}

export const ConfigurationTitle: FC<ConfigurationTitleProps> = ({
  title,
  onToggle,
  enabled = false,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.configurationTitle}>
      <div>
        <Text variant='smallHeadline'>{title}</Text>
      </div>
      {onToggle && (
        <div>
          <Switch
            labelPlacement='start'
            checked={enabled}
            onChange={() => {
              onToggle()
            }}
            contentBoxOnly
          />
        </div>
      )}
    </div>
  )
}
