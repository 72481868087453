import React, { FC, createRef, useEffect } from 'react'

export interface ResizablePanelProps {
  panelWidth: number
  setPanelWidth: (value: number) => void
  className?: string
}

export const ResizablePanel: FC<ResizablePanelProps> = ({
  children,
  panelWidth,
  setPanelWidth,
  className,
}) => {
  const panelRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (panelRef.current) {
      if (!panelWidth) {
        setPanelWidth(panelRef.current.clientWidth)
        return
      }

      panelRef.current.style.width = `${panelWidth}px`
    }
  }, [panelRef])

  return (
    <div ref={panelRef} className={className}>
      {children}
    </div>
  )
}

ResizablePanel.displayName = 'ResizablePanel'
