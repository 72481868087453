import { makeStyles } from '@material-ui/core'
import { spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  descriptionTitleContainer: {
    flex: 1,
    minWidth: `calc(100% - ${spacing.xxl})`, // 100% - icon with number size to always take all available space
    marginTop: `-${spacing.xxs}`, // to align top of RichText with icon
    paddingLeft: spacing.xxs,
  },
})
