import React, { type ReactNode, useState } from 'react'
import { truncate } from 'lodash'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'
import { ChevronDown } from '../Icons'
import { IconWrapper } from '../IconWrapper'

interface CollapsibleTableCellProps {
  text: string
  maxLength?: number
  stylizedContent?: ReactNode // The styled component or JSX to display when expanded
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between', // Ensures space between text and chevron
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  textContainer: {
    flex: 1, // Takes up as much space as possible
    paddingRight: '20px', // Provides space for the chevron icon
  },
  chevron: {
    transition: 'transform 0.3s ease',
    color: colors.neutralLight40,
  },
})

export const CollapsibleTableCell: React.FC<CollapsibleTableCellProps> = ({
  text,
  stylizedContent,
  maxLength = 80, // setting a default value
}) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  const displayContent = isExpanded
    ? stylizedContent
    : truncate(text, { length: maxLength })

  return (
    <div
      className={classes.container}
      onClick={() => {
        setIsExpanded(!isExpanded)
      }}
    >
      <div className={classes.textContainer}>{displayContent}</div>
      {text.length > maxLength && (
        <span
          className={classes.chevron}
          style={{
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <IconWrapper>
            <ChevronDown />
          </IconWrapper>
        </span>
      )}
    </div>
  )
}

CollapsibleTableCell.displayName = 'CollapsibleTableCell'
