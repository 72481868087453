import React, { type FC } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../utils/style-guide'

const useStyles = makeStyles({
  pill: {
    display: 'inline-block',
    padding: '0px 10px',
    borderRadius: '16px',
    textTransform: 'capitalize',
  },
  info: {
    backgroundColor: colors.brand200,
    color: 'white',
  },
  error: {
    backgroundColor: colors.red,
    color: 'white',
  },
  warning: {
    backgroundColor: colors.yellow75,
    color: 'black',
  },
})

export type StatusType = 'info' | 'error' | 'warning'

interface StatusPillProps {
  status: StatusType
  label: string
}

export const StatusPill: FC<StatusPillProps> = ({ status, label }) => {
  const classes = useStyles()
  return (
    <Typography
      variant='body2'
      className={`${classes.pill} ${classes[status]}`}
    >
      {label}
    </Typography>
  )
}

StatusPill.displayName = 'StatusPill'
