import React from 'react'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { ActivityFeedSkeleton } from '@awell/ui-kit/compositions/ActivityFeed'

export const useStyles = makeStyles({
  container: {
    backgroundColor: colors.neutralLight20,
    height: '100%',
    display: 'grid',
    gridTemplateRows: '89px 1fr 64px',
    gridTemplateAreas: `"actionBar"
                       "previewContent"`,
  },
  actionBar: {
    gridArea: 'actionBar',
  },
  previewContent: {
    gridArea: 'previewContent',
  },
  actionSkeletonContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${spacing.xs}`,
    borderBottom: `1px solid #dfe1e6`,
    backgroundColor: colors.neutralLight0,
  },
  actionSkeleton: {
    marginRight: spacing.xs,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const PathwayActivityFeedSkeleton = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div
        className={classnames(
          classes.actionBar,
          classes.actionSkeletonContainer,
        )}
      >
        <Skeleton
          variant='rect'
          width={320}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={126}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={116}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={157}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={151}
          height={40}
          className={classes.actionSkeleton}
        />
        <div className={classes.checkboxContainer}>
          <Skeleton
            variant='rect'
            width={20}
            height={20}
            className={classes.actionSkeleton}
          />
          <Skeleton variant='text' width={100} />
        </div>
      </div>

      <ActivityFeedSkeleton classname={classes.previewContent} />
    </div>
  )
}

PathwayActivityFeedSkeleton.displayName = 'PathwayActivityFeedSkeleton'
