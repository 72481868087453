import React, { FC } from 'react'
import { Type, TypeVariants } from '../../Typography'
import { RestartPreviewButton } from '../RestartPreviewButton'
import { useStyles } from './useStyles'

interface PreviewHeaderProps {
  previewTitle: string
}

export const PreviewHeader: FC<PreviewHeaderProps> = ({ previewTitle }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.toolbar}>
        <RestartPreviewButton />
      </div>
      <div className={classes.title}>
        <Type variant={TypeVariants.h3}>{previewTitle}</Type>
      </div>
    </>
  )
}

PreviewHeader.displayName = 'PreviewHeader'
