import { makeStyles } from '@material-ui/core/styles'
import { colors, rounded, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles(theme => ({
  headerContainer: {
    margin: `${spacing.s} 0 ${spacing.xxs}`,
  },
  inputFieldsContainer: {
    display: 'grid',
    gridTemplateColumns: '85px minmax(250px, 1fr) 80px',
    gridTemplateAreas: `"optionValue optionLabel sideButtonSection"`,
    gridGap: spacing.xxxs,
    marginTop: spacing.xxs,
    marginBottom: spacing.xxs,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '45px 1fr 70px',
    },
  },
  optionValue: {
    gridArea: 'optionValue',
  },
  optionLabel: {
    gridArea: 'optionLabel',
  },
  sideButtonSection: {
    gridArea: 'sideButtonSection',
  },
  bottomSectionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: `${spacing.xxxs} 0 0`,
  },
  multipleChoiceContainer: {
    width: '100%',
    marginRight: spacing.xs,
  },
  wrapper: {
    width: '100%',
    position: 'relative',
    paddingLeft: spacing.l,
    [theme.breakpoints.down('md')]: {
      paddingLeft: spacing.xxs,
    },
  },
}))

export const useStylesCopy = makeStyles({
  importButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapper: {
    width: '500px',
  },
  optionsArea: {
    ...rounded.m,
    border: `2px solid ${colors.neutralLight40}`,
    width: '100%',
    fontSize: '1rem',
    fontFamily: 'inherit',
    padding: spacing.xxs,
    color: colors.neutralDark800,
    lineHeight: spacing.xs,
    letterSpacing: '1.2px',
  },
  importIconWrapper: {
    fontSize: '1.4rem',
  },
  errorList: {
    margin: spacing.xxs,
    padding: `0 0 0 ${spacing.xs}`,
    '& li': {
      color: colors.red500,
    },
  },
})
