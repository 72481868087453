import * as React from 'react'

function SvgDot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
      <circle fill='currentColor' cx={8} cy={8} r={3} />
    </svg>
  )
}

export default SvgDot
