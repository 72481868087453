// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import { findChildByType } from '../../utils/findChildByType'
import { SidePaneLayout } from '../SidePaneLayout'

interface Slots {
  Feed: FC
  Detail: FC
}

const Feed: FC = ({ children }) => {
  return <>{children}</>
}

const Detail: FC = ({ children }) => {
  return <>{children}</>
}

export const ActivityFeed: FC & Slots = ({ children }) => {
  const feed = findChildByType(children, Feed)
  const detail = findChildByType(children, Detail)

  return (
    <SidePaneLayout>
      <SidePaneLayout.Main>{feed}</SidePaneLayout.Main>
      {detail && <SidePaneLayout.Side>{detail}</SidePaneLayout.Side>}
    </SidePaneLayout>
  )
}

ActivityFeed.displayName = 'ActivityFeed'
ActivityFeed.Feed = Feed
ActivityFeed.Detail = Detail
