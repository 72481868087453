// @ts-nocheck TODO: remove this comment and fix errors
import * as React from 'react'
import classnames from 'classnames'
import { useStyles } from './useStyles'
import {
  Action,
  Pathway,
  Step,
  StickyNote,
  Track,
  TrackEnd,
  Transition,
} from '../Icons'

export enum CategoryTypes {
  'action' = 'action',
  'pathway' = 'pathway',
  'track' = 'track',
  'step' = 'step',
  'transition' = 'transition',
  'show' = 'show',
  'hide' = 'hide',
  'stickyNote' = 'sticky-note',
  'track-end' = 'track-end',
}

interface CategoryIconProps {
  type: CategoryTypes
  small?: boolean
  className?: string
  neutral?: boolean
  style?: React.CSSProperties
  labelColor?: string
  inline?: boolean
}

export const CategoryIcon: React.FC<CategoryIconProps> = ({
  type,
  small,
  className,
  neutral,
  style = {},
  labelColor,
  inline = false,
}) => {
  const classes = useStyles()

  return (
    <span
      className={classnames(className, classes.categoryIcon, classes[type], {
        // FIXME: labelColor names used here don't match labelColors in style guide
        // (e.g. teal -> slateBlue) due label color changes. Kept it like this to
        // be backwards compatible but this needs to be fixed across the app
        [classes[labelColor]]: type === CategoryTypes.step,
        [classes.small]: small,
        [classes.neutral]: neutral,
        [classes.inline]: inline,
      })}
      style={{ minWidth: 'inherit', ...style }}
    >
      {(() => {
        switch (type) {
          case CategoryTypes.step:
            return <Step />
          case CategoryTypes.stickyNote:
            return <StickyNote />
          case CategoryTypes.action:
            return <Action />
          case CategoryTypes.transition:
            return <Transition />
          case CategoryTypes.pathway:
            return <Pathway />
          case CategoryTypes.track:
            return <Track />
          case CategoryTypes['track-end']:
            return <TrackEnd />
          default:
            return <Step />
        }
      })()}
    </span>
  )
}

CategoryIcon.displayName = 'CategoryIcon'
