import { Button } from '@awell/ui-kit/components/Button'
import classnames from 'classnames'
import React, { type FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import { FormBlock } from '../../../../components/FormBlock'
import { useUpdatePatient } from '../../../../hooks/useUpdatePatient'
import {
  PatientProfileInput,
  PatientProfileRadioGroup,
  PatientProfileSelect,
} from '../../FormInputs'
import {
  languageOptions,
  type PatientProfile,
  type Patient,
  sexOptions,
} from '../../types'
import { useStyles } from '../../useStyles'

type PersonalInformation = Pick<
  PatientProfile,
  | 'address'
  | 'first_name'
  | 'birth_date'
  | 'last_name'
  | 'preferred_language'
  | 'national_registry_number'
  | 'sex'
>

interface PatientPersonalInformationProps {
  patient: Patient
}

export const PatientPersonalInformation: FC<
  PatientPersonalInformationProps
> = ({ patient }) => {
  const { t } = useTranslation()
  const { saveBtnContainer } = useStyles()
  const { inputFieldRow, oneInputHalfRow, oneInputFullRow, twoInputs } =
    useFormStyles()
  const { control, errors, handleSubmit } = useForm<PersonalInformation>({
    defaultValues: patient?.profile ?? undefined,
  })
  const { updatePatient } = useUpdatePatient(patient.id)

  const onSubmit = (): void => {
    void handleSubmit(async data => {
      await updatePatient(data)
    })()
  }

  return (
    <FormBlock title={t('personal_information')}>
      <form onSubmit={() => handleSubmit(onSubmit)}>
        <div className={classnames(inputFieldRow, twoInputs)}>
          <PatientProfileInput
            name='first_name'
            control={control}
            errors={errors}
            label={t('first_name')}
            placeholder='Henrietta'
            errorText={t('form_mandatory_error_text')}
          />
          <PatientProfileInput
            name='last_name'
            control={control}
            errors={errors}
            label={t('family_name')}
            placeholder='Lacks'
            errorText={t('form_mandatory_error_text')}
          />
        </div>
        <div className={classnames(inputFieldRow, oneInputHalfRow)}>
          <PatientProfileInput
            name='birth_date'
            control={control}
            errors={errors}
            label={t('birth_date')}
            inputType='date'
            errorText={t('form_mandatory_error_text')}
          />
        </div>
        <div className={inputFieldRow}>
          <PatientProfileRadioGroup
            name='sex'
            control={control}
            errors={errors}
            options={sexOptions}
            label={t('sex')}
          />
        </div>
        <div className={classnames(inputFieldRow, oneInputFullRow)}>
          <PatientProfileInput
            name='address.street'
            control={control}
            errors={errors}
            label={t('street_address')}
            placeholder='1 Royal Fort House'
          />
        </div>
        <div className={classnames(inputFieldRow, twoInputs)}>
          <PatientProfileInput
            name='address.city'
            control={control}
            errors={errors}
            label={t('city')}
            placeholder='Bristol'
          />
          <PatientProfileInput
            name='address.state'
            control={control}
            errors={errors}
            label={t('province')}
            placeholder='South West'
          />
        </div>
        <div className={classnames(inputFieldRow, twoInputs)}>
          <PatientProfileInput
            name='address.zip'
            control={control}
            errors={errors}
            label={t('postal_code')}
            placeholder='BS8 1TH'
          />
          <PatientProfileInput
            name='address.country'
            control={control}
            errors={errors}
            label={t('country')}
            placeholder='United Kingdom'
          />
        </div>
        <div className={classnames(inputFieldRow, oneInputFullRow)}>
          <PatientProfileInput
            name='national_registry_number'
            control={control}
            errors={errors}
            label={t('national_registry_number')}
            placeholder='HeLa1920'
          />
        </div>
        <div className={classnames(inputFieldRow, oneInputHalfRow)}>
          <PatientProfileSelect
            name='preferred_language'
            control={control}
            errors={errors}
            options={languageOptions}
            label={t('language')}
          />
        </div>
        <div className={saveBtnContainer}>
          <Button onClick={onSubmit}>{t('save')}</Button>
        </div>
      </form>
    </FormBlock>
  )
}
