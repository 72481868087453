export enum CustifyEvents {
  GO_CARE_TO_CAREOPS = 'go_care_to_careops',
  GO_CARE_TO_STUDIO = 'go_care_to_studio',
  GO_STUDIO_TO_CAREOPS = 'go_studio_to_careops',
  GO_STUDIO_TO_CARE = 'go_studio_to_care',
  GO_CAREOPS_TO_STUDIO = 'go_careops_to_studio',
  GO_CAREOPS_TO_CARE = 'go_careops_to_care',
  CAREFLOW_STARTED_CARE = 'careflow_started_care',
  CAREFLOW_SET_LIVE = 'careflow_set_live',
  CAREFLOW_PUBLISHED = 'careflow_published',
  CAREFLOW_CREATED = 'careflow_created',
  DUPLICATE_CAREFLOW = 'duplicate_careflow',
  AHP_LINK_CREATED = 'ahp_link_created',
  API_KEY_GENERATED = 'api_key_generated',
  SOURCE_CONTROL_ADDED = 'source_control_added',
  EXTENSION_CONFIGURED = 'extension_configured',
  CALCULATION_ADDED = 'calculation_added',
  API_CALL_REST_ADDED = 'api_call_rest_added',
  API_CALL_GRAPHQL_ADDED = 'api_call_graphql_added',
  MESSAGE_ADDED = 'message_added',
  PUSH_TO_EMR_ADDED = 'push_to_emr_added',
  CLINICAL_NOTE_ADDED = 'clinical_note_added',
  FORM_ADDED = 'form_added',
  CHECKLIST_ADDED = 'checklist_added',
  PLUGIN_ADDED = 'plugin_added',
  ACCESS_WORKLIST = 'access_worklist',
  ACCESS_MONITORING_PAGE = 'access_monitoring_page',
  ACCESS_SHELLY = 'access_shelly',
  INCOMING_WEBHOOK_CONFIGURED = 'incoming_webhook_configured',
  OUTGOING_WEBHOOK_CONFIGURED = 'outgoing_webhook_configured',
  IDENTIFIER_SYSTEM_CONFIGURED = 'identifier_system_configured',
  ADD_STEP_TO_LIBRARY = 'add_step_to_library',
  GLOBAL_CONSTANT_DELETED = 'global_constant_deleted',
  GLOBAL_CONSTANT_ADDED = 'global_constant_added',
  GLOBAL_CONSTANT_UPDATED = 'global_constant_updated',
}
