import { Button } from '@awell/ui-kit/components/Button'
import {
  HeadingSecondary,
  HeadingTertiary,
} from '@awell/ui-kit/components/Typography'
import React, { type FC, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { usePatientPathways } from '../../hooks/usePatientPathways'
import { useStyles } from './useStyles'
import { PathwayStatus } from './types'
import { ActivePathwaysCardSkeleton } from './skeletons/ActivePathwaysCardSkeleton'

interface ActivePathwaysCardProps {
  patientId: string
  onViewAll: () => void
  onViewPathway: (id: string) => void
}

const ACTIVE_PATHWAYS_COUNT = 3

export const ActivePathwaysCard: FC<ActivePathwaysCardProps> = ({
  patientId,
  onViewAll,
  onViewPathway,
}): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { patientPathways, loading } = usePatientPathways(patientId)

  if (loading) {
    return <ActivePathwaysCardSkeleton />
  }
  /* @TODO Sort by last activity date */
  const activePatientPathways = (patientPathways ?? [])
    .filter(
      pathway =>
        pathway.status === PathwayStatus.Active ||
        pathway.status === PathwayStatus.Starting,
    )
    .splice(0, ACTIVE_PATHWAYS_COUNT)

  return (
    <section className={classes.activePathwaysContainer}>
      <HeadingSecondary variant='subHeadline'>
        {t('active_pathways')}
      </HeadingSecondary>
      <div className={classes.activePathwaysList}>
        {activePatientPathways.map(pathway => {
          return (
            <section key={pathway.id} className={classes.pathwayRow}>
              <div className={classes.pathwayNameAndActivitiesNumberWrapper}>
                <HeadingTertiary variant='tinyHeadline'>
                  {pathway.title}
                </HeadingTertiary>
              </div>
              <Button
                onClick={() => {
                  onViewPathway(pathway.id)
                }}
                size='small'
                color='primary'
              >
                {t('view')}
              </Button>
            </section>
          )
        })}
      </div>

      <Button onClick={onViewAll} className={classes.cta} color='primary'>
        {t('view_all')}
      </Button>
    </section>
  )
}

ActivePathwaysCard.displayName = 'ActivePathwaysCard'
