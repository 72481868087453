import {
  useGetPublishedPathwayDefinitionsQuery,
  type PublishedPathwayDefinition,
} from './types'
import { useNotifications } from '../useNotifications'
import { isNil } from 'lodash'

interface UsePublishedPathwayDefinitionsHook {
  loading: boolean
  publishedPathwayDefinitions?: Array<PublishedPathwayDefinition>
}

export const usePublishedPathwayDefinitions =
  (): UsePublishedPathwayDefinitionsHook => {
    const { notifyError } = useNotifications()
    const { data, loading, error } = useGetPublishedPathwayDefinitionsQuery()

    if (!isNil(error)) {
      notifyError({
        message:
          'Something went wrong while loading published pathway definitions',
        error,
      })
      return { loading: false, publishedPathwayDefinitions: [] }
    }
    if (loading) {
      return { loading: true }
    }

    return {
      loading: false,
      publishedPathwayDefinitions:
        data?.publishedPathwayDefinitions.publishedPathwayDefinitions,
    }
  }
