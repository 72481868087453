import * as React from 'react'

function SvgCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M17.657 6.343c.362.363.388.935.078 1.327l-.078.087L13.414 12l4.243 4.243a1 1 0 01-1.327 1.492l-.087-.078L12 13.414l-4.243 4.243a1 1 0 01-1.492-1.327l.078-.087L10.586 12 6.343 7.757A1 1 0 017.67 6.265l.087.078L12 10.586l4.243-4.243a1 1 0 011.414 0z'
      />
    </svg>
  )
}

export default SvgCross
