import { environment } from '@awell/libs-web/environment'
import { navigate, type RouteComponentProps } from '@reach/router'
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import React, { useEffect, type FC, type ReactNode } from 'react'
import { useCookies } from 'react-cookie'

interface LogoutRouteProps {
  loadingComponent: ReactNode
}

export const LogoutRoute: FC<RouteComponentProps & LogoutRouteProps> = ({
  loadingComponent,
}) => {
  const stytch = useStytchB2BClient()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookies, removeCookies] = useCookies([
    'stytch_session',
    'stytch_intermediate_session',
    'stytch_session_jwt',
  ])
  const { session } = useStytchMemberSession()
  /**
   * When the app navigates to /logout, the app can either provide a url where the user should be redirected to
   * or they will be redirected to the / page.
   *
   * In case the app provides the redirect uri via location state property, we handle this by
   * - forcing fusion auth to redirect to /logout page after logging out
   * - setting a return_to localstorage variable that stores the redirect uri the app needs to redirect back to.
   *
   */
  useEffect(() => {
    if (!isNil(session)) {
      stytch.session
        .revoke()
        .then(resp => {
          removeCookies('stytch_session')
          removeCookies('stytch_intermediate_session')
          removeCookies('stytch_session_jwt')
          void navigate(environment.urls.careops + '/login')
        })
        .catch(() => {
          console.warn('could not log out')
        })
    }
  }, [])
  return <>{loadingComponent}</>
}

LogoutRoute.displayName = 'LogoutRoute'
