// @ts-nocheck TODO: remove this comment and fix errors
import { RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import { PathwayPage } from '../pages/Pathway'
import { currentPageTitleVar } from '../local-state'

interface PathwayParams {
  pathwayId: string
}

export const PathwayRoute: FC<RouteComponentProps<PathwayParams>> = ({
  pathwayId,
}) => {
  currentPageTitleVar('Awell Care')
  return <PathwayPage pathwayId={pathwayId} />
}

PathwayRoute.displayName = 'PathwayRoute'
