import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  tabsContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${colors.neutralLight30}`,
  },
  routeContainer: {
    marginTop: spacing.s,
  },
})
