/* eslint-disable no-param-reassign */
import { TFunction } from 'react-i18next'
import { Gantt, GanttStatic, GanttTemplates } from './dhtmlxGantt/dhtmlxgantt'
import {
  config,
  getGridFile,
  getGridFolder,
  getGridRowClass,
  getTaskClass,
  getTaskRowClass,
  getTimelineCellClass,
  getTooltipText,
  zoomConfig,
} from './GanttConfig'

export const getGanttInstance = (t: TFunction<string>): GanttStatic => {
  const ganttInstance = Gantt.getGanttInstance()

  ganttInstance.plugins({
    tooltip: true,
    marker: true,
  })

  const templates: Partial<GanttTemplates> = {
    tooltip_text: (start: Date, end: Date, task: any) =>
      getTooltipText(start, end, task, t),
    timeline_cell_class: getTimelineCellClass(ganttInstance),
    grid_folder: getGridFolder,
    grid_file: getGridFile,
    grid_row_class: getGridRowClass,
    task_row_class: getTaskRowClass,
    task_class: getTaskClass,
  }

  ganttInstance.templates = { ...ganttInstance.templates, ...templates }
  ganttInstance.config = { ...ganttInstance.config, ...config(t) }
  ganttInstance.ext.zoom.init(zoomConfig)
  ganttInstance.ext.zoom.setLevel('hour')

  return ganttInstance
}
