import React from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { spacing, responsiveContentContainer } from '../../utils/style-guide'

const useStyles = makeStyles(theme => ({
  container: {
    padding: spacing.s,
    [theme.breakpoints.up('md')]: {
      padding: spacing.m,
    },
    [theme.breakpoints.up('lg')]: {
      ...responsiveContentContainer,
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
}))

interface ContentContainerProps {
  classNames?: string
}

export const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  classNames,
}) => {
  const classes = useStyles()

  return (
    <section className={classnames(classes.container, classNames)}>
      {children}
    </section>
  )
}

ContentContainer.displayName = 'ContentContainer'
