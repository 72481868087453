import { isNil } from 'lodash'
import React, { type FC, useEffect } from 'react'
import { colors } from '../../utils/style-guide'

export interface UploadWidgetConfig {
  cloudName: string
  uploadPreset: string
}
interface UploadWidgetProps {
  onSubmit: (value: string) => void
  config: UploadWidgetConfig
  allowedFormats?: Array<string>
  sources?: Array<'local' | 'url' | 'camera' | 'google_drive'>
  defaultSource?: string
  onAbort?: () => void
}

/**
 * opens a modal allowing image upload.
 * Image can be on your machine or URL (creates copy of image hosted on URL)
 * @precondition EnableImageUpload component is rendered
 * @params onSubmit callback to be called when image is uploaded
 * @returns promise resolves in a URL where image is hosted
 */
export const UploadWidget: FC<UploadWidgetProps> = ({
  onSubmit,
  config,
  allowedFormats,
  sources = ['local', 'url'],
  defaultSource = sources[0],
  onAbort,
}) => {
  const onSuccess = (res: any, widget: any) => {
    if (res.event === 'success') {
      const { info } = res
      const { secure_url } = info
      // cloudinary adds overflow: 'hidden' to the html body but does not revert this change when an image is uploaded. For now lets overwrite this overflow value
      document.body.style.overflow = 'auto'
      onSubmit(secure_url)
      widget.destroy({ removeThumbnails: true })
    }
  }

  const onAbortUploadWidget = (res: any) => {
    if (res.event === 'abort' && onAbort) {
      onAbort()
    }
  }

  useEffect(() => {
    if (!isNil(config)) {
      const { cloudName, uploadPreset } = config
      // @ts-expect-error TODO: type window object
      const uploadWidget = window.cloudinary?.openUploadWidget(
        {
          multiple: false, // don't allow uploading multiple images
          singleUploadAutoClose: true,
          showAdvancedOptions: false, // There is an advanced toggle in image upload. don't now what this does. Check cloudinary docs
          showPoweredBy: false, // don't show powered by cloudinary
          cloudName,
          uploadPreset,
          sources, // local = user's machine, url = an image available at a given URL
          cropping: false,
          resourceType: allowedFormats ? 'auto' : 'image',
          clientAllowedFormats: allowedFormats,
          defaultSource,
          styles: {
            palette: {
              window: colors.neutralLight0,
              sourceBg: colors.neutralLight0,
              windowBorder: colors.neutralLight30,
              tabIcon: colors.neutralDark800,
              inactiveTabIcon: colors.neutralLight50,
              menuIcons: colors.neutralDark800,
              link: colors.brand100,
              action: colors.brand10,
              inProgress: colors.brand100,
              complete: colors.signalSuccess100,
              error: colors.signalError100,
              textDark: colors.neutralDark800,
              textLight: colors.neutralLight10,
            },
            fonts: {
              default: null,
              "'Roboto', sans-serif": {
                url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
                active: true,
              },
            },
          },
        },
        (error: any, result: any) => {
          onAbortUploadWidget(result)
          if (!isNil(error)) {
            return
          }
          onSuccess(result, uploadWidget)
        },
      )
    }
  }, [])

  return <div id='upload-widget' />
}

UploadWidget.displayName = 'UploadWidget'
