import { type ApolloQueryResult } from '@apollo/client'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import {
  useGetOrchestrationFactsQuery,
  type GetOrchestrationFactsQuery,
  type GetOrchestrationFactsQueryVariables,
  type OrchestrationFact,
} from './types'

interface UseGetOrchestrationFactsHook {
  loading: boolean
  facts?: Array<OrchestrationFact>
  pagination?: GetOrchestrationFactsQuery['pathwayFacts']['pagination']
  sorting?: GetOrchestrationFactsQuery['pathwayFacts']['sorting']
  refetchOrchestrationFacts: (
    query?: Partial<GetOrchestrationFactsQueryVariables>,
  ) => Promise<ApolloQueryResult<GetOrchestrationFactsQuery>>
}

export const useGetOrchestrationFacts = (
  pathway_id: string,
): UseGetOrchestrationFactsHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const baseInputs: GetOrchestrationFactsQueryVariables = {
    pagination: {
      count: 10,
      offset: 0,
    },
    filters: {
      pathway_id,
    },
    sorting: {
      field: 'timestamp',
      direction: 'asc',
    },
  }

  const {
    data,
    loading,
    error,
    refetch: refetchOrchestrationFacts,
  } = useGetOrchestrationFactsQuery({
    variables: { ...baseInputs },
  })

  if (!isNil(error)) {
    notifyError({
      message: t('error_loading_facts'),
      error,
    })
    return { loading: false, refetchOrchestrationFacts }
  }

  if (loading) {
    return { loading: true, refetchOrchestrationFacts }
  }

  if (!isNil(data)) {
    return {
      loading: false,
      pagination: data.pathwayFacts.pagination,
      sorting: data.pathwayFacts.sorting,
      facts: data.pathwayFacts.facts,
      refetchOrchestrationFacts,
    }
  }

  return { loading: true, facts: [], refetchOrchestrationFacts }
}
