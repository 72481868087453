export type {
  MessageAttachment,
  AddAttachmentInput,
} from '../../generated/types-design'

export { MessageAttachmentType } from '../../generated/types-design'

export enum ToolbarVariant {
  left = 'left',
  right = 'right',
  condensed = 'condensed',
}

export interface DialogLabels {
  insertVideo?: string
  insertImage?: string
  insertLink?: string
  insert: string
  remove: string
  cancel: string
}

export enum DialogType {
  link = 'link',
  image = 'image',
  video = 'video',
}
