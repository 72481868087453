import * as React from 'react'

function SvgActivityLocationPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 1a6 6 0 016 6c0 2.867-1.935 5.506-5.732 7.922a.5.5 0 01-.536 0C3.935 12.506 2 9.867 2 7a6 6 0 016-6zm0 1a5 5 0 00-5 5c0 2.325 1.54 4.558 4.68 6.692l.32.213.32-.213C11.46 11.558 13 9.325 13 7a5 5 0 00-5-5zm0 3.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z'
        fill='#42526E'
      />
    </svg>
  )
}

export default SvgActivityLocationPin
