import { makeStyles } from '@material-ui/core'
import React, { type FC } from 'react'
import { NavigationButton } from '../NavigationButton'
import { InlineText } from '../Typography/TypographyNew'
import { IconWrapper } from '../IconWrapper'
import SvgChevronRight from '../Icons/ChevronRight'
import { colors, spacing } from '../../utils/style-guide'
import { TopNavigationWithBreadcrumbsSkeleton } from './skeletons/TopNavigationWithBreadcrumbsSkeleton'
import { useTranslation } from 'react-i18next'
import { Badge } from '../Badge'

export interface TopNavigationWithBreadCrumbs {
  navigationLink: string
  parentPageName: string
  activeItemTitle: string
  loading?: boolean
  isPathwayStopped?: boolean
}
const useStyles = makeStyles({
  breadCrumbContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing.xs,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})
export const TopNavigationWithBreadcrumbs: FC<TopNavigationWithBreadCrumbs> = ({
  navigationLink,
  parentPageName,
  activeItemTitle,
  loading = false,
  isPathwayStopped = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (loading) {
    return <TopNavigationWithBreadcrumbsSkeleton />
  }

  return (
    <>
      {navigationLink && <NavigationButton linkUrl={navigationLink} />}
      {isPathwayStopped && <Badge kind='error'>{t('stopped')}</Badge>}
      <div className={classes.breadCrumbContainer}>
        <div className={classes.textContainer}>
          <InlineText variant='smallHeadline' color={colors.neutralDark500}>
            {parentPageName}
          </InlineText>
          <IconWrapper color={colors.neutralDark500}>
            <SvgChevronRight />
          </IconWrapper>
          <InlineText variant='smallHeadline' color={colors.neutralLight100}>
            {activeItemTitle}
          </InlineText>
        </div>
      </div>
    </>
  )
}

TopNavigationWithBreadcrumbs.displayName = 'TopNavigationWithBreadcrumbs'
