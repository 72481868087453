/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { type FC, useEffect } from 'react'
import { useStyles } from './useStyles'
import { isNil, noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { colors } from '../../utils/style-guide'

interface SliderInputProps {
  id?: string
  value: number | undefined
  onChange: (value: number | undefined) => void
  disabled?: boolean
  min: number
  max: number
  step?: number
  marks?: boolean
  minLabel?: string
  maxLabel?: string
  showMinMaxValues?: boolean
  valueTooltipMode?: boolean
  touchTooltipLabel?: string
  /**
   * Function to reflect the touched state
   */
  onTouched?: (touched: boolean) => void
}

const MIDPOINT_PERCENTAGE = 0.5 // 50%
const THUMB_WIDTH = 16 // px
const TOP_POSITION_ADJUSTMENT = -28 // px

export const SliderInput: FC<SliderInputProps> = ({
  id,
  value,
  onChange,
  disabled = false,
  max = 10,
  min = 0,
  marks,
  step = 1,
  minLabel = '',
  maxLabel = '',
  showMinMaxValues = false,
  valueTooltipMode = false,
  touchTooltipLabel,
  onTouched = noop,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const hasInitialValue = !isNil(value)
  const [touched, setTouched] = React.useState<boolean>(hasInitialValue)
  const [internalValue, setInternalValue] = React.useState<number | undefined>(
    hasInitialValue ? Number(value) : value,
  )
  const tooltipRef = React.useRef<HTMLDivElement>(null)

  const [tooltipPosition, setTooltipPosition] = React.useState({
    left: 0,
    top: 0,
  })

  useEffect(() => {
    if (touched && valueTooltipMode && tooltipRef.current) {
      const input = tooltipRef.current.closest(
        `.${classes.awellRangeInputWrapper}`,
      ) as HTMLElement
      if (internalValue !== undefined) {
        const inputWidth = input.clientWidth
        const range = max - min
        const percentage = (internalValue - min) / range
        const thumbPosition =
          (isNaN(percentage) ? MIDPOINT_PERCENTAGE : percentage) *
          (inputWidth - THUMB_WIDTH)
        const tooltipLeft = thumbPosition + THUMB_WIDTH / 2
        const tooltipTop = TOP_POSITION_ADJUSTMENT
        setTooltipPosition({
          left: tooltipLeft,
          top: tooltipTop,
        })
      }
    }
  }, [internalValue, touched])

  const renderValueTooltip = (
    value: number,
    left: number,
    top: number,
    touched = false,
  ): JSX.Element | null => {
    return (
      <div
        ref={tooltipRef}
        id='awell__slider_tooltip'
        className={classes.tooltip}
        style={{ left, top, display: touched ? 'block' : 'none' }}
      >
        {value}
      </div>
    )
  }

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newValue = Number(event.target.value)
    setInternalValue(newValue)
    onChange(newValue)
    onTouched(true)
    setTouched(true)
  }
  // This is required to ensure we can handle form reset
  useEffect(() => {
    if (value === undefined) {
      setInternalValue(value)
      onTouched(false)
      setTouched(false)
    }
  }, [value])

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const style = {
    '--awellStep': step,
    '--awellMin': min,
    '--awellMax': max,
    '--awellTicksColor': marks === true ? colors.neutralLight50 : 'transparent',
  } as React.CSSProperties

  const shouldShowMinLabel = minLabel.length > 0
  const shouldShowMaxLabel = maxLabel.length > 0

  const showThumb =
    touched || typeof internalValue === 'number' || (disabled && touched)

  const tooltipLabel =
    typeof internalValue !== 'number' && disabled && !touched
      ? t('value_not_selected')
      : t('touch_to_select')

  return (
    <div key={id}>
      <div className={classes.sliderContainer}>
        {!showThumb && (
          <div
            id='awell__slider_touch_tooltip'
            className={classes.tooltipTouched}
          >
            {touchTooltipLabel ?? tooltipLabel}
          </div>
        )}
        <div
          className={`${classes.awellRangeInputWrapper} ${
            marks === true ? classes.withMarks : ''
          } ${classes.range}`}
          style={style}
        >
          <input
            disabled={disabled}
            required={true}
            data-testid={id}
            type='range'
            id={id}
            min={min}
            max={max}
            step={step}
            className={`${classes.awellRangeInput} ${
              showThumb ? classes.showThumb : classes.hideThumb
            }`}
            onChange={handleValueChange}
            onFocus={(e) => {
              if (!touched) {
                handleValueChange(e)
              } else {
                setTouched(true)
                onTouched(true)
              }
            }}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={internalValue}
            defaultValue={internalValue}
            aria-labelledby='discrete-slider'
          />
          {valueTooltipMode &&
            typeof internalValue === 'number' &&
            renderValueTooltip(
              internalValue,
              tooltipPosition.left,
              tooltipPosition.top,
              touched,
            )}
          <div
            className={classes.awellRangeInputDatalist}
            data-testid={`${id}-datalist`}
          >
            <div className={classes.minLabel} aria-label='Minimum value'>
              {showMinMaxValues && <p>{min}</p>}
              {shouldShowMinLabel && <p>{minLabel}</p>}
            </div>
            <div className={classes.maxLabel} aria-label='Maximum value'>
              {showMinMaxValues && <p>{max}</p>}
              {shouldShowMaxLabel && <p>{maxLabel}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
