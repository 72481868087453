import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'
import { Skeleton } from '../Skeleton'
import { Logo, LogoSizes } from '../Logo'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  container: {
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: spacing.m,
    marginRight: spacing.m,
  },
  buttonSectionItem: {
    marginRight: spacing.xs,
  },
})
export const AppHeaderSkeleton: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.logo}>
          <Logo size={LogoSizes.medium} />
        </div>
        <div>
          <Skeleton variant='text' width='100px' />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <Skeleton
          variant='rect'
          width='23px'
          height='23px'
          className={classes.buttonSectionItem}
        />
        <Skeleton
          variant='rect'
          width='23px'
          height='23px'
          className={classes.buttonSectionItem}
        />
        <Skeleton
          variant='rect'
          width='130px'
          height='40px'
          className={classes.buttonSectionItem}
        />
      </div>
    </div>
  )
}
AppHeaderSkeleton.displayName = 'AppHeaderSkeleton'
