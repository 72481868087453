/* eslint-disable react/display-name */
import React from 'react'
import {
  type SnackbarOrigin,
  useSnackbar,
  SnackbarContent,
  type SnackbarKey,
} from 'notistack'
import { Button, IconButton } from '@awell/ui-kit/components/Button'
import {
  NotificationMessage,
  NotificationMessageContent,
  NotificationMessageContentActions,
  NotificationTypes,
} from '@awell/ui-kit/components/NotificationMessage'
import {
  Cross,
  ExclamationMark,
  Informative,
} from '@awell/ui-kit/components/Icons'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@awell/ui-kit/hooks/useWindowSize'
import { CrashReporter } from '../../crash-reporter'

type NotifyErrorFn = (args: { message: string; error?: unknown }) => void

type NotifyFn = (args: {
  message: string
  notifyType?: NotificationTypes
  icon?: JSX.Element
  placement?: {
    vertical: 'top' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
  persist?: boolean
  content?: React.ReactElement
  actions?: React.ReactElement
}) => void

interface UseNotificationHook {
  notify: NotifyFn
  notifyError: NotifyErrorFn
}

export const useNotifications = (): UseNotificationHook => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const action = (key: React.ReactText) => (
    <IconButton
      onClick={() => {
        closeSnackbar(key)
      }}
    >
      <Cross />
    </IconButton>
  )
  const DEFAULT_SNACKBAR_PLACEMENT: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  }
  const MOBILE_SNACKBAR_PLACEMENT: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }

  const DEFAULT_TIME_IN_MS_BEFORE_HIDING = 3000
  const SNACKBAR_MAX_WIDTH_PX = 400 // Defined in Design System

  const handleReport = (key: SnackbarKey, error: Error) => {
    CrashReporter.report(error)
    closeSnackbar(key)
    enqueueSnackbar(t('error_reported'), {
      anchorOrigin: isMobile
        ? MOBILE_SNACKBAR_PLACEMENT
        : DEFAULT_SNACKBAR_PLACEMENT,
      autoHideDuration: DEFAULT_TIME_IN_MS_BEFORE_HIDING,
      preventDuplicate: true,
      action,
      content: () => (
        <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
          <NotificationMessage
            icon={<Informative />}
            message={t('error_reported')}
            notifyType={NotificationTypes.informative}
            persist={false}
          />
        </SnackbarContent>
      ),
    })
  }
  /**
   * Use this to notify the user about an error using a snackbar notification.
   * The error parameter is optional to make it easier to use this to report
   * errors originating from graphql queries: query hooks always return an error
   * property, so this saves from writing an `if(error !== undefined)` clause
   * for each query.
   */
  const notifyError: NotifyErrorFn = ({ message, error }) => {
    if (error !== undefined && error instanceof Error) {
      enqueueSnackbar(message, {
        persist: true,
        action,
        anchorOrigin: isMobile
          ? MOBILE_SNACKBAR_PLACEMENT
          : DEFAULT_SNACKBAR_PLACEMENT,
        content: key => (
          <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
            <NotificationMessage
              icon={<ExclamationMark />}
              message={message}
              onClose={() => {
                closeSnackbar(key)
              }}
              notifyType={NotificationTypes.error}
              persist
            >
              <NotificationMessageContent>
                {t('error_description', { message: error.message })}
              </NotificationMessageContent>
              <NotificationMessageContentActions>
                <Button
                  variant='text'
                  onClick={() => {
                    handleReport(key, error)
                  }}
                  data-heap='report-error'
                >
                  {t('report_error')}
                </Button>
              </NotificationMessageContentActions>
            </NotificationMessage>
          </SnackbarContent>
        ),
      })
    }
  }

  /**
   * Use notify for informative, warning and success notifications
   * You can also use it for errors if no user action (e.g. error reporting)
   * or additional messaging (e.g. full error readout) is needed
   * Defaults to informative notification type and icon
   * ! Do not override the default placement unless by design
   */
  const notify: NotifyFn = ({
    message,
    icon = <Informative />,
    placement = isMobile
      ? MOBILE_SNACKBAR_PLACEMENT
      : DEFAULT_SNACKBAR_PLACEMENT,
    notifyType = NotificationTypes.informative,
    persist = false,
    content = null,
    actions = null,
  }) => {
    enqueueSnackbar(message, {
      anchorOrigin: placement,
      autoHideDuration: DEFAULT_TIME_IN_MS_BEFORE_HIDING,
      preventDuplicate: true,
      persist,
      action,
      content: key => (
        <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
          <NotificationMessage
            icon={icon}
            message={message}
            onClose={() => {
              closeSnackbar(key)
            }}
            notifyType={notifyType}
            persist={persist}
          >
            {(content || actions) && (
              <>
                <NotificationMessageContent>
                  {content}
                </NotificationMessageContent>
                <NotificationMessageContentActions>
                  {actions}
                </NotificationMessageContentActions>
              </>
            )}
          </NotificationMessage>
        </SnackbarContent>
      ),
    })
  }

  return { notifyError, notify }
}
