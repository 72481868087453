import MaterialTable, { MTableBodyRow as Row } from '@material-table/core'
import Paper from '@material-ui/core/Paper'
import React, { type FC } from 'react'
import classNames from 'classnames'
import { colors, spacing, type } from '../../utils/style-guide'
import { TableIcons } from './TableIcons'
import { useStyles } from './useStyles'
import GroupRow from './GroupRow'
import { type TableProps } from './types'

// 310px is the space between top of the page and the table header
// that also sets the bottom of the table above the intercom logo
// We use this offset to calculate a max table height regardless of
// the user's window size
// TODO: move this into pathway studio
export const HEIGHT_IN_PX_OF_NON_TABLE_PAGE_CONTENT = 310

// @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
const Container = (props: unknown) => <Paper {...props} elevation={0} />
Container.displayName = 'TableContainer'

export const TableComponent: FC<TableProps> = ({
  tableRef = React.useRef(null),
  columns,
  rows,
  actionText = '',
  emptyDataSourceMessage,
  paging = false,
  pageSize = 10,
  rowStyles = {},
  fullWidth = false,
  compactCells = true,
  grouping,
  groupTitleComponent = groupData => groupData.value,
}) => {
  const classes = useStyles()
  // We use the window innerheight to calculate a max height of the table which enables
  // scrolling *inside* the table with a sticky header (ScrollContainer does not)
  // const { windowHeight } = useWindowSize()

  const tableColumns = columns.map(column => {
    if (compactCells) {
      return {
        ...column,
        cellStyle: {
          padding: `${spacing.xxs} ${spacing.xs}`,
        },
      }
    }
    return {
      ...column,
      cellStyle: {
        padding: spacing.xs,
      },
    }
  })

  return (
    <div className={classNames({ [classes.container]: !fullWidth })}>
      <MaterialTable
        components={{
          Toolbar: () => null,
          Container,
          Row,
          GroupRow,
          Groupbar: () => null,
        }}
        tableRef={tableRef}
        // @ts-expect-error FIXME: icon prop types
        icons={TableIcons}
        columns={tableColumns}
        data={rows}
        localization={{
          body: {
            emptyDataSourceMessage,
          },
          header: { actions: actionText },
        }}
        options={{
          searchFieldAlignment: 'left',
          emptyRowsWhenPaging: false,
          filtering: false,
          showTitle: false,
          paging,
          sorting: true,
          pageSizeOptions: [10, 25, 50],
          pageSize,
          draggable: false,
          tableLayout: 'fixed',
          grouping,
          defaultExpanded: true,
          groupTitle: groupTitleComponent,
          rowStyle: {
            fontSize: type.s.fontSize,
            color: colors.neutralDark600,
            ...rowStyles,
          },
        }}
      />
    </div>
  )
}

TableComponent.displayName = 'TableComponent'
