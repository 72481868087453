import { spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  headerContainer: {
    margin: `${spacing.xxs} 0`,
  },
  inputFieldsContainer: {
    display: 'grid',
    gridTemplateColumns: '5fr 5fr 1fr',
    gridTemplateAreas: `"inputKey inputValue inputDeleteBtn"`,
    gridGap: spacing.xs,
    marginBottom: spacing.xxs,
  },
  inputKey: {
    gridArea: 'inputKey',
  },
  inputValue: {
    gridArea: 'inputValue',
  },
  deleteBtnContainer: {
    gridArea: 'inputDeleteBtn',
    display: 'flex',
    alignItems: 'flex-end',
  },
  bottomSectionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: `${spacing.xs} 0 ${spacing.xxs}`,
  },
})
