import React, { type FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { List, ListSubheader, ListDivider, ListAction } from '../List'
import { useDropdownStyles } from './DropDownStyles'
import { type InternalOption } from './generate-options-from-children'
import { truncateInside } from '../../utils'
import { Icon, IconNames } from '../Icon'
import { CategoryIcon, CategoryTypes } from '../CategoryIcon'
import { CheckBoxIcon } from '../SelectionControls'
import { Flex } from '../Flex'
import { spacing } from '../../utils/style-guide'
import { useStyles } from './useStyles'
import { isNil } from 'lodash'

const MAX_OPTIONS_TO_DISPLAY = 4

type getOptionProps = ({
  index,
  option,
}: {
  index: number
  option: unknown
}) => Partial<{ key: number | string }>

interface DropDownProps {
  compact?: boolean
  getOptionProps: getOptionProps
  items: Array<OptionInterface> | Array<Group>
  listBoxProps: () => Record<string, unknown>
  multiple?: boolean
  nested?: boolean
  inputProps?: { value: string }
  open?: boolean
  position?: 'top' | 'bottom'
}

interface GroupsProps {
  compact?: boolean
  getOptionProps: getOptionProps
  items: Array<Group>
  listBoxProps: Record<string, unknown>
  multiple?: boolean
}

interface OptionsProps {
  compact?: boolean
  getOptionProps: getOptionProps
  items: Array<OptionInterface>
  listBoxProps?: Record<string, unknown>
  multiple?: boolean
  inputProps?: { value: string }
}

interface Group {
  group: string
  index: number
  key: string | number
  options: Array<OptionInterface>
}

interface OptionInterface {
  content: string
  index: number
  key: string | number
  multiple?: boolean
}

const Options: FC<OptionsProps> = ({
  compact,
  getOptionProps,
  items,
  listBoxProps = {},
  multiple = false,
  inputProps,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <List {...listBoxProps}>
      {multiple && (
        <li className={classes.searchFieldWrapper}>
          <input
            className={classes.searchField}
            placeholder={`${t('search')}...`}
            {...inputProps}
          />
        </li>
      )}
      {items.map((option, index) => {
        const optionProps = getOptionProps({
          option,
          index,
        })
        return (
          <Option
            key={optionProps.key}
            optionProps={optionProps}
            option={option}
            multiple={multiple ?? false}
            compact={compact}
          >
            <span style={{ fontSize: '14px' }}>
              {truncateInside({
                max_length: 40,
              })(option.content)}
            </span>
          </Option>
        )
      })}
    </List>
  )
}
Options.displayName = 'Options'

const Groups: FC<GroupsProps> = ({
  compact,
  getOptionProps,
  items,
  listBoxProps,
  multiple,
}) => {
  const dropdownStyleProps: {
    optionsToDisplay: number
    hasSubheader: boolean
    smallGroup: boolean
  } = {
    optionsToDisplay: MAX_OPTIONS_TO_DISPLAY,
    hasSubheader: true,
    smallGroup: false,
  }

  const { subheader } = useDropdownStyles(dropdownStyleProps)
  return (
    <List {...listBoxProps}>
      {items.map((group, index) => (
        <Fragment key={group.key}>
          <li>
            <ListSubheader primary className={subheader}>
              {group.group}
            </ListSubheader>
            <Options
              items={group.options}
              getOptionProps={getOptionProps}
              multiple={multiple}
              compact={compact}
            />
          </li>
          {index < items.length - 1 && <ListDivider />}
        </Fragment>
      ))}
    </List>
  )
}
Groups.displayName = 'Groups'

export const DropDown: FC<DropDownProps> = ({
  compact,
  getOptionProps,
  items,
  listBoxProps,
  multiple,
  nested = false,
  inputProps,
  open = false,
  position = 'bottom',
}) => {
  const dropdownStyleProps: {
    optionsToDisplay: number
    hasSubheader: boolean
    smallGroup: boolean
  } = {
    optionsToDisplay: MAX_OPTIONS_TO_DISPLAY,
    hasSubheader: nested,
    smallGroup: false,
  }

  if (!open) return null

  if (nested) {
    const optionsInGroup = (items[0] as Group).options.length
    if (optionsInGroup > MAX_OPTIONS_TO_DISPLAY) {
      dropdownStyleProps.optionsToDisplay = MAX_OPTIONS_TO_DISPLAY
    } else if (optionsInGroup < MAX_OPTIONS_TO_DISPLAY) {
      dropdownStyleProps.optionsToDisplay = optionsInGroup
      dropdownStyleProps.smallGroup = true
    }
    const classes = useDropdownStyles(dropdownStyleProps)
    return (
      <div className={classes.dropdown}>
        <Groups
          items={items as Array<Group>}
          listBoxProps={listBoxProps()}
          getOptionProps={getOptionProps}
          multiple={multiple}
          compact={compact}
        />
      </div>
    )
  }

  const classes = useDropdownStyles(dropdownStyleProps)
  return (
    <div
      className={`${classes.dropdown} ${
        position === 'top' ? classes.top : classes.bottom
      }`}
    >
      <Options
        compact={compact}
        getOptionProps={getOptionProps}
        items={items as Array<OptionInterface>}
        listBoxProps={listBoxProps()}
        multiple={multiple}
        inputProps={inputProps}
      />
    </div>
  )
}

DropDown.displayName = 'DropDown'

const Option: FC<{
  compact?: boolean
  multiple: boolean
  option: InternalOption
  optionProps: Record<string, unknown>
}> = ({ children, compact, multiple, option, optionProps = {} }) => {
  let iconComponent
  switch (true) {
    // @ts-expect-error TODO: fix this
    case Boolean(CategoryTypes[option.icon]):
      iconComponent = <CategoryIcon type={option.icon as CategoryTypes} />
      break
    // @ts-expect-error TODO: fix this
    case Boolean(IconNames[option.icon]):
      iconComponent = <Icon name={option.icon as IconNames} />
      break
    default:
      iconComponent = undefined
  }

  const icon =
    multiple || !isNil(option.icon) ? (
      <Flex gap={multiple && !isNil(option.icon) ? spacing.xxs : '0rem'}>
        {multiple && <CheckBoxIcon checked={option.selected} />}
        {!isNil(option.icon) && iconComponent}
      </Flex>
    ) : undefined

  return (
    <ListAction
      compact={compact}
      icon={icon}
      onMouseDown={event => {
        event.preventDefault()
      }}
      disabled={option.disabled}
      {...optionProps}
    >
      {children}
    </ListAction>
  )
}
Option.displayName = 'Option'
