import { ChecklistPreview } from '@awell/ui-kit/components/ChecklistPreview'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import React, { FC } from 'react'
import type { Activity } from '../../hooks/useChecklistActivity'
import {
  ActivityStatus,
  useChecklistActivity,
} from '../../hooks/useChecklistActivity'

interface ChecklistActivityDetailsProps {
  activity: Activity
  onClose: () => void
}

export const ChecklistActivityDetails: FC<ChecklistActivityDetailsProps> = ({
  activity,
  onClose,
}) => {
  const { loading, onSubmit, title, items, disabled } = useChecklistActivity({
    activity,
  })

  const handleSubmitChecklist = async () => {
    await onSubmit()
    onClose()
  }

  const onItemUpdated = () => {
    // Nothing to see here, move along
    // This is the place to add code when we'll save individual interactions
    // with checklist items in the backend.
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <ChecklistPreview
      title={title}
      items={items}
      onItemUpdated={onItemUpdated}
      onSubmit={handleSubmitChecklist}
      readOnly={activity.status !== ActivityStatus.Active}
      disabled={disabled}
    />
  )
}

ChecklistActivityDetails.displayName = 'ChecklistActivityDetails'
