import React, { type FC, type KeyboardEvent } from 'react'
import { IconWrapper } from '../IconWrapper'
import { colors } from '../../utils/style-guide'
import { ActivityPerson } from '../Icons'
import { Text } from '../Typography'
import { Lozenge } from '../Lozenge'
import { CardListItem } from '../PanelActivityCard'
import { type Activity, SidePanelCardVariants } from './types'
import { useActivityContent } from '../../compositions/ActivityFeed/useActivityContent'
import { useItemStyles } from './useStyles'
import { TimeAgoIndicator } from './TimeAgoIndicator'
import { isNil } from 'lodash'

interface ActivityListItemProps {
  activity: Activity
  onSelectElement: (activity: Activity) => void
  variant: SidePanelCardVariants
}

export const ActivityListItem: FC<ActivityListItemProps> = ({
  activity,
  onSelectElement,
  variant,
}) => {
  const { content } = useActivityContent(activity)
  const classes = useItemStyles()
  const {
    summaryText,
    activityStatus,
    activityStatusColor,
    ActivityIcon,
    stakeholder,
    timestamp,
  } = content
  const timestampDate = new Date(timestamp)
  const handleActivitySelect = (): void => {
    onSelectElement(activity)
  }

  const handleSelectActivityOnEnter = (
    e: KeyboardEvent<HTMLDivElement>,
  ): void => {
    e.key === 'Enter' && onSelectElement(activity)
  }
  return (
    <CardListItem
      onListItemClick={handleActivitySelect}
      onKeyboardEvent={handleSelectActivityOnEnter}
      icon={ActivityIcon}
    >
      <>
        <Text variant='smallHeadline'>{summaryText}</Text>
        <div className={classes.activityBottomDetails}>
          {!isNil(stakeholder) && (
            <div className={classes.iconWithText}>
              <IconWrapper size='xs' color={colors.neutralMid300}>
                <ActivityPerson />
              </IconWrapper>
              <Text variant='textSmall' color={colors.neutralMid300}>
                {stakeholder}
              </Text>
            </div>
          )}
          {variant === SidePanelCardVariants.Step && (
            <Lozenge
              content={activityStatus}
              color={activityStatusColor}
              compact
            />
          )}
          {variant === SidePanelCardVariants.Pending && (
            <TimeAgoIndicator timestampDate={timestampDate} />
          )}
        </div>
      </>
    </CardListItem>
  )
}
