import { isNil } from 'lodash'
import React from 'react'
import { Text } from '../Typography'
import { ExtensionActivityRecordField } from './ExtensionActivityRecordField'
import { ExtensionActivityStakeholders } from './ExtensionActivityStakeholders'
import {
  type ActivityObject,
  type Maybe,
  type ExtensionActionFieldType,
  type PluginActionSettingsProperty,
  type ExtensionDataPoint,
} from './types'
import { useStyles } from './useStyles'

interface ExtensionActionActivityPreviewProps<T, U> {
  activity: T
  extensionActivityRecord: U
}

interface ExtendableExtensionRecordFieldType {
  fields: Array<{
    id: string
    type: ExtensionActionFieldType
    label: string
    value: string
  }>
  settings?: Maybe<Array<PluginActionSettingsProperty>>
  data_points?: Maybe<Array<ExtensionDataPoint>>
}
interface ExtendableActivityType {
  indirect_object?: Maybe<ActivityObject>
  object: ActivityObject
  stakeholders?: Array<ActivityObject> | null
}

export const ExtensionActionActivityPreview = <
  T extends ExtendableActivityType,
  U extends ExtendableExtensionRecordFieldType,
>({
  extensionActivityRecord,
  activity,
}: ExtensionActionActivityPreviewProps<T, U>): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <ExtensionActivityStakeholders
        stakeholders={
          isNil(activity?.stakeholders) ? [] : activity.stakeholders
        }
      />
      {extensionActivityRecord.settings?.map(({ label, value }) => (
        <div className={classes.field} key={label}>
          <Text variant='textMedium'>{label}:</Text>
          <Text>{value}</Text>
        </div>
      ))}
      {extensionActivityRecord.fields.map(({ id, ...props }) => (
        <ExtensionActivityRecordField key={id} {...props} />
      ))}
    </>
  )
}

ExtensionActionActivityPreview.displayName = 'ExtensionActionActivityPreview'
