import React, { type FC } from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { Flex } from '../Flex'
import { appHeaderHeight, spacing } from '../../utils/style-guide'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: appHeaderHeight,
    ...spacing.paddingX.s,
  },
})

interface AppHeaderProps {
  children: JSX.Element | Array<JSX.Element>
  style?: React.CSSProperties
  className?: string
}

export const AppHeader: FC<AppHeaderProps> = ({
  children,
  style,
  className,
}) => {
  const classes = useStyles()
  return (
    <Flex
      align='center'
      className={classnames(classes.container, className)}
      style={style}
    >
      {children}
    </Flex>
  )
}
