import { makeStyles } from '@material-ui/core'
import { borders, colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  container: {
    ...borders.s,
    borderColor: colors.neutralLight40,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 20,
  },
  header: {
    padding: spacing.s,
    paddingBottom: spacing.xs,
    backgroundColor: colors.neutralLight0,
    flexDirection: 'column',
    display: 'flex',
    borderBottom: `1px solid ${colors.neutralLight40}`,
  },
  headerWithTabs: {
    padding: spacing.s,
    paddingBottom: 0,
    backgroundColor: colors.neutralLight0,
    flexDirection: 'column',
    display: 'flex',
    borderBottom: 'none',
  },
  headerActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing.xxs,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  content: {
    padding: spacing.s,
    flexGrow: 1,
    maxHeight: '100%',
    backgroundColor: colors.neutralLight0,
    overflow: 'auto',
  },
  contentWithTabs: {
    padding: 0,
    flexGrow: 1,
    maxHeight: '100%',
    backgroundColor: colors.neutralLight0,
    overflow: 'auto',
    '& > *:not(:first-child)': {
      padding: spacing.s,
    },
  },
})
