// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import classnames from 'classnames'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useStyles } from './useStyles'
import { Text } from '../Typography'
import { Colors } from '../../enums'

export interface StepLabelLozengeProps {
  content: string
  color?: Colors
  className?: string
  compact?: boolean
  open?: boolean
  onClick?: () => void
}

export const StepLabelLozenge: FC<StepLabelLozengeProps> = ({
  content,
  color = Colors.stepDefault,
  className = '',
  compact = false,
  onClick,
  open = true,
}) => {
  const classes = useStyles({ open })

  const variants: Variants = {
    open: {
      // total width is text width plus 8px padding on each side
      paddingLeft: 8,
      paddingRight: 8,
      // fixed height of 16px by design
      height: 16,
      lineHeight: 1,
    },
    collapsed: {
      // total fixed width of 40px using padding
      paddingLeft: 20,
      paddingRight: 20,
      width: 0,
      // fixed height of 6px by design
      height: 6,
      lineHeight: 1,
    },
  }

  return (
    <motion.div className={classes.labelContainer}>
      <motion.div
        transition={{
          type: 'tween',
          ease: 'easeInOut',
        }}
        whileHover={{ scale: 1.03 }}
        variants={variants}
        initial={false}
        animate={open ? 'open' : 'collapsed'}
        className={classnames(
          classes.stepCardStatus,
          Colors[color] && classes[`${color}Label`],
          className,
          {
            [classes.compact]: compact,
          },
        )}
        onClick={onClick}
        role='button'
        tabIndex={0}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            onClick()
          }
        }}
        data-heap={open ? 'collapse-label-lozenge' : 'expand-label-lozenge'}
      >
        {/* TODO: Extract this into a reusable animation component */}
        <AnimatePresence initial={false}>
          <motion.div
            initial={false}
            animate={{
              opacity: open ? 1 : 0,
              visibility: open ? 'visible' : 'hidden',
              display: open ? 'block' : 'none',
            }}
          >
            <div className={classes.stepLabelText}>
              <Text variant='textSmallBold'>{content}</Text>
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </motion.div>
  )
}

StepLabelLozenge.displayName = 'StepLabelLozenge'
