import { ButtonGroup, makeStyles } from '@material-ui/core'
import React, { type FC } from 'react'
import { Popover } from '../Popover'
import { IconButton } from './IconButton'
import { ChevronDown } from '../Icons'
import { colors, spacing } from '../../utils/style-guide'
import { Button } from './Button'
import classNames from 'classnames'
import { isNil } from 'lodash'

const useStyles = makeStyles({
  listContainer: {
    padding: spacing.xxxs,
    minWidth: spacing.input.l,
    maxHeight: spacing.list.m,
    overflowY: 'auto',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
  },
  listItem: {
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
    borderRadius: spacing.xxxs,
    '&:hover': {
      backgroundColor: colors.brand10,
    },
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  withIcon: {
    paddingLeft: spacing.xxs,
  },
  disabled: {
    boxShadow: 'none',
  },
})

interface ButtonWithDropdownProps {
  title: string
  id?: string
  options: Array<{
    label: string
    onClick: () => void
    icon?: React.ReactElement
  }>
  disabled?: boolean
}

export const ButtonWithDropdown: FC<ButtonWithDropdownProps> = ({
  title,
  id = 'button-with-dropdown',
  options,
  disabled = false,
}) => {
  const classes = useStyles()

  return (
    <Popover id={id} isInline placement='bottom-end'>
      {/* @ts-expect-error - slots type is not figured out yet */}
      <Popover.Target>
        <>
          {' '}
          <ButtonGroup
            variant='contained'
            aria-label='split button'
            disabled={disabled}
            className={classNames({
              [classes.disabled]: disabled,
            })}
          >
            <Button onClick={() => {}}>{title}</Button>
            <IconButton
              variant='groupedRight'
              style={{
                borderRadius: 'none',
              }}
            >
              <ChevronDown />
            </IconButton>
          </ButtonGroup>
        </>
      </Popover.Target>
      {/* @ts-expect-error - slots type is not figured out yet */}
      <Popover.Content>
        {!disabled && (
          <div className={classes.listContainer}>
            {options.map((option, index) => (
              <div
                role='option'
                key={index}
                aria-selected
                tabIndex={0}
                className={classNames(classes.listItem, {
                  [classes.withIcon]: !isNil(option.icon),
                })}
                onClick={option.onClick}
              >
                {option.icon ?? option.icon} {option.label}
              </div>
            ))}
          </div>
        )}
      </Popover.Content>
    </Popover>
  )
}
