import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { sortBy } from 'lodash'
import { Text } from '../Typography'
import { type Activity, type SidePanelCardVariants } from './types'
import { useStyles } from './useStyles'
import { ActivityListItem } from './ActivityListItem'

interface Props {
  activities: Array<Activity>
  onSelectActivity: (activity: Activity) => void
  variant: SidePanelCardVariants
}

export const ActivityList: FC<Props> = ({
  activities,
  onSelectActivity,
  variant,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const sortedActivities = sortBy(activities, activity => activity.date)
  return (
    <div>
      {sortedActivities.length > 0 ? (
        <>
          <div className={classes.activityList}>
            {sortedActivities.map(activity => (
              <ActivityListItem
                key={activity.id}
                activity={activity}
                onSelectElement={onSelectActivity}
                variant={variant}
              />
            ))}
          </div>
        </>
      ) : (
        <Text variant='textMedium'>{t('no_pending_activities')}</Text>
      )}
    </div>
  )
}

ActivityList.displayName = 'ActivityList'
