import { makeStyles } from '@material-ui/core'
import {
  borders,
  colors,
  rounded,
  spacing,
  type,
} from '../../../utils/style-guide'

export const useStyles = makeStyles({
  container: {
    ...type.xs,
    ...spacing.paddingX.xxs,
    ...rounded.m,
    ...borders.s,
    // to match design
    display: 'inline-flex',
    width: 'fit-content',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginTop: -3,
  },
  rest: {
    borderColor: colors.neutralLight50,
    backgroundColor: colors.neutralLight30,
    color: colors.signalInformative100,
    '&:hover': {
      backgroundColor: colors.neutralLight10,
    },
  },
  active: {
    borderColor: colors.brand100,
    backgroundColor: colors.brand10,
    color: colors.brand100,
    '&:hover': {
      backgroundColor: colors.blue50,
    },
  },
  highlight: {
    boxShadow: 'rgb(180 213 255) 0px 0px 0px 2px',
  },
  error: {
    borderColor: colors.signalError100,
    backgroundColor: colors.signalError20,
    color: colors.signalError100,

    // ensure vertical alignment with valid var and slate text
    verticalAlign: 'middle',
    '&:hover': {
      backgroundColor: colors.red50,
    },
  },
  wrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  notExistentVar: {
    borderColor: colors.signalError100,
    backgroundColor: colors.signalError20,
    color: colors.signalError100,

    // ensure vertical alignment with valid var and slate text
    verticalAlign: 'middle',
  },
})
