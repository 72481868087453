// @ts-nocheck TODO: remove this comment and fix errors
import classnames from 'classnames'
import * as React from 'react'
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core'
import { log } from '../../utils/log'
import { colors, spacing } from '../../utils/style-guide'
import { StrictUnion } from '../../utils/types'
import { DragIndicator } from '../Icons'
import { IconWrapper } from '../IconWrapper'

type Ref = HTMLUListElement

interface ListProps {
  /**
   * Should each list item be divided with an thin line
   */
  divided?: boolean
}

interface ReorderableList extends ListProps {
  /**
   * Should the items in the list be reorderable via drag 'n drop
   */
  reorderable: true

  /**
   * Prop name on the list item that has a unique value in the list. Required
   * when `reorderable` is set to `true`
   *
   * Default: `key`
   */
  listItemIdentifier?: string

  onDragEnd(result: DropResult, provided: ResponderProvided): void
  onDragStart?(initial: DragStart, provided: ResponderProvided): void
}

interface NonReorderableList extends ListProps {
  /**
   * Should the items in the list be reorderable via drag 'n drop
   */
  reorderable?: false
}

interface WrapperProps {
  onDragEnd(result: DropResult, provided: ResponderProvided): void
  onDragStart?(initial: DragStart, provided: ResponderProvided): void
  children(
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot,
  ): JSX.Element
}

const useStyles = makeStyles({
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& > [class*="listSubheader"]:first-child': {
      paddingTop: 0,
    },
    '& > [class*="listDivider"] + [class*="listSubheader"]': {
      paddingTop: 0,
    },

    // When elements in list are dragged, visually hide the placeholder
    '& > button': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  listDivided: {
    borderTop: `1px solid ${colors.neutralLight40}`,
    '& > *': {
      borderBottom: `1px solid ${colors.neutralLight40}`,
    },
  },
  reorderableListItemContentWrapper: {
    display: 'inline-flex',
    width: '100%',
    position: 'relative',
  },
  reorderableListItemContent: {
    flex: 1,
    '& > *': {
      paddingLeft: `${spacing.xxl} !important`,
    },
  },
  draggableIcon: {
    position: 'absolute',
    width: spacing.s,
    marginRight: spacing.xxs,
    color: colors.neutralLight50,
    top: '50%',
    transform: `translate(${spacing.xs}, -50%)`,
  },
  tempDisableReordering: {
    '& > *': {
      paddingLeft: `${spacing.l} !important`,
    },
  },
})

/**
 * Component to render when <List /> is reorderable={true}
 */
const Reorderable: React.FC<WrapperProps> = ({
  children,
  onDragEnd,
  onDragStart,
}) => (
  <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore No idea why this fails when building mycare. TODO: figure out why it fails */}
    <Droppable droppableId='list'>{(...args) => children(...args)}</Droppable>
  </DragDropContext>
)
Reorderable.displayName = 'Reorderable'

/**
 * Component to render when <List /> is reorderable={false}
 */
const NonReorderable: React.FC<WrapperProps> = ({ children }) =>
  children(
    { droppableProps: undefined, placeholder: null, innerRef: null },
    { isDraggingOver: undefined, isUsingPlaceholder: undefined },
  )

NonReorderable.displayName = 'NonReorderable'

export const List = React.forwardRef<
  Ref,
  StrictUnion<NonReorderableList | ReorderableList> & React.HTMLProps<Ref>
>(function List(
  {
    children,
    divided = false,
    reorderable,
    listItemIdentifier = 'key',
    onDragEnd,
    onDragStart,
    ...rest
  },
  ref,
) {
  const classes = useStyles()

  const Wrapper = reorderable ? Reorderable : NonReorderable

  let kids = children

  if (reorderable) {
    React.Children.forEach(kids, (kid: React.ReactElement) => {
      if (!kid[listItemIdentifier] && !kid.props[listItemIdentifier]) {
        log.error(
          `No "${listItemIdentifier}" prop provided for child in Reorderable list`,
          'child:',
          kid,
        )
      }
    })

    kids = React.Children.map(
      kids,
      (child: React.ReactElement, _index: number) => {
        const Kid = child
        const identifier = Kid[listItemIdentifier] || Kid.key || _index

        const key = Kid.key || identifier
        const draggableId = Kid.props.draggableId || `${identifier}`
        const index = Kid.props.index || _index
        const grandKids = Kid.props.children
        return (
          <Draggable
            draggableId={draggableId}
            index={index}
            key={key}
            disableInteractiveElementBlocking
          >
            {provided => (
              // eslint-disable-next-line react/jsx-pascal-case
              <Kid.type
                {...provided.draggableProps}
                {...Kid.props}
                ref={provided.innerRef}
              >
                <span className={classes.reorderableListItemContentWrapper}>
                  <span
                    className={classes.draggableIcon}
                    {...provided.dragHandleProps}
                  >
                    <IconWrapper>
                      <DragIndicator />
                    </IconWrapper>
                  </span>
                  <span className={classes.reorderableListItemContent}>
                    {typeof grandKids === 'string' ? (
                      <span>{grandKids}</span>
                    ) : (
                      grandKids
                    )}
                  </span>
                </span>
              </Kid.type>
            )}
          </Draggable>
        )
      },
    )
  }

  return (
    <Wrapper onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {provided => (
        <ul
          className={classnames({
            [classes.list]: true,
            [classes.listDivided]: divided,
            [classes.tempDisableReordering]: reorderable === false,
          })}
          ref={provided.innerRef || ref}
          {...provided.droppableProps}
          {...rest}
        >
          {kids}
          {provided.placeholder}
        </ul>
      )}
    </Wrapper>
  )
})

List.displayName = 'List'
