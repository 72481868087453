import { sharedTypePolicies } from '@awell/libs-web/graphql'
import { pathwayLayoutVar, currentPageTitleVar } from './index'

export const typePolicies = {
  Query: {
    fields: {
      pathwayLayout: {
        read() {
          return pathwayLayoutVar()
        },
      },
      pageTitle: {
        read() {
          return currentPageTitleVar()
        },
      },
    },
  },
  ...sharedTypePolicies,
  // IMPORTANT
  // Given that some activity objects share the same id (example: 'Checklist' object shares id with underlying 'Action; object),
  // we have to tell GraphQL cache that it has to take id & type into account when identifying this object.
  ActivityObject: {
    keyFields: ['id', 'type'],
  },
  ActivitySubject: {
    keyFields: ['id', 'type'],
  },
}
