import {
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createHeadingPlugin,
  createHistoryPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
  ELEMENT_PARAGRAPH,
  ELEMENT_TODO_LI,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  type PlateEditor,
  type PlatePlugin,
  type ResetNodePlugin,
  type SoftBreakPlugin,
} from '@udecode/plate'
import {
  createDynamicVariablePlugin,
  createNonExistentDynamicVariablePlugin,
} from './DynamicVariablePlugin'

import { createHostedPagesLinkPlugin } from './HostedPagesLinkPlugin'

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
  defaultType: ELEMENT_PARAGRAPH,
}

const optionsResetBlockTypePlugin: Partial<
  PlatePlugin<Record<string, unknown>, ResetNodePlugin>
> = {
  options: {
    rules: [
      {
        ...resetBlockTypesCommonRule,
        hotkey: 'Enter',
        predicate: isBlockAboveEmpty,
      },
      {
        ...resetBlockTypesCommonRule,
        hotkey: 'Backspace',
        predicate: isSelectionAtBlockStart,
      },
    ],
  },
}

export const optionsSoftBreakPlugin: Partial<
  PlatePlugin<Record<string, unknown>, SoftBreakPlugin>
> = {
  options: {
    rules: [
      { hotkey: 'shift+enter' },
      {
        hotkey: 'enter',
        query: {
          allow: [ELEMENT_BLOCKQUOTE],
        },
      },
    ],
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TEditor = PlateEditor<any>
export const viewerPlugins: Array<PlatePlugin<TEditor>> = [
  // editor
  createReactPlugin(), // withReact
  createHistoryPlugin(), // withHistory
  // elements
  createParagraphPlugin(), // paragraph element
  createBlockquotePlugin(), // blockquote element
  createCodeBlockPlugin(), // code block element
  createHeadingPlugin(), // heading elements
  // marks
  createBoldPlugin(), // bold mark
  createItalicPlugin(), // italic mark
  createUnderlinePlugin(), // underline mark
  createStrikethroughPlugin(), // strikethrough mark
  createCodePlugin(), // code mark
  createImagePlugin(),
  createMediaEmbedPlugin(),
  createLinkPlugin(),
  createListPlugin(),
  createResetNodePlugin(optionsResetBlockTypePlugin),

  createSelectOnBackspacePlugin({
    options: {
      query: {
        allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
      },
    },
  }),
  createSoftBreakPlugin(optionsSoftBreakPlugin),
  // layout
  createTrailingBlockPlugin({ type: ELEMENT_PARAGRAPH }),
]
export const defaultPlugins: Array<PlatePlugin<TEditor>> = [
  ...viewerPlugins,
  createDynamicVariablePlugin(),
  createNonExistentDynamicVariablePlugin(),
]

export const plaintextPlugins: Array<PlatePlugin<TEditor>> = [
  createReactPlugin(), // withReact
  createHistoryPlugin(), // withHistory
  createParagraphPlugin(), // paragraph element
  createResetNodePlugin(optionsResetBlockTypePlugin),
  createSoftBreakPlugin(optionsSoftBreakPlugin),
  createTrailingBlockPlugin({ type: ELEMENT_PARAGRAPH }),
  createDynamicVariablePlugin(),
  createNonExistentDynamicVariablePlugin(),
]

export const baseJsonEditorPlugins: Array<PlatePlugin<TEditor>> = [
  createReactPlugin(), // withReact
  createCodeBlockPlugin(),
]

export const jsonEditorPlugins: Array<PlatePlugin<TEditor>> = [
  ...baseJsonEditorPlugins,
  createDynamicVariablePlugin(),
  createHostedPagesLinkPlugin(),
]

export const textHtmlExtensionActionFieldPlugins: Array<PlatePlugin<TEditor>> =
  [...defaultPlugins, createHostedPagesLinkPlugin()]

export const clinicalNotePlugins: Array<PlatePlugin<TEditor>> = [
  ...defaultPlugins,
  createHostedPagesLinkPlugin(),
]
