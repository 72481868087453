import React from 'react'
import { Tooltip as MaterialUiTooltip, makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'
import { isNil } from 'lodash'

const DELAY_BEFORE_SHOWING_IN_MS = 1000

const useStyles = makeStyles({
  popper: {
    backgroundColor: colors.signalInformative100,
    color: colors.neutralLight0,
    fontSize: '14px',
    fontWeight: 400,
  },
  arrow: {
    color: colors.signalInformative100,
  },
  bottom: ({
    bottomAdjustment,
  }: {
    bottomAdjustment: string | number | undefined
  }) => ({
    ...(!isNil(bottomAdjustment) ? { top: bottomAdjustment } : {}),
  }),
})

export const Tooltip: React.FC<{
  children: JSX.Element
  info: string | React.ReactFragment
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
  enterDelay?: number
  exitDelay?: number
  arrow?: boolean
  highlighted?: boolean
  style?: React.CSSProperties
  interactive?: boolean
}> = ({
  children,
  info,
  placement = 'top',
  exitDelay,
  arrow,
  enterDelay = DELAY_BEFORE_SHOWING_IN_MS,
  highlighted = false,
  style = {},
  ...props
}) => {
  if (info === '') {
    return <> {children} </>
  }
  const bottomPlacement = placement.includes('bottom')
  const classes = useStyles({ bottomAdjustment: style.top })

  return (
    <MaterialUiTooltip
      title={info}
      placement={placement}
      arrow={arrow}
      enterDelay={enterDelay}
      leaveDelay={exitDelay}
      classes={{
        arrow: classes.arrow,
        tooltipPlacementBottom: bottomPlacement ? classes.bottom : '',
        tooltip: classes.popper,
      }}
      style={{
        color: highlighted ? colors.brand100 : 'inherit',
        ...style,
      }}
      {...props}
    >
      {children}
    </MaterialUiTooltip>
  )
}

Tooltip.displayName = 'Tooltip'
