import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  paper: {
    minWidth: '300px',
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-start',
    padding: `${spacing.xs} ${spacing.xs} 0`,
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 0,
  },
  rootContent: {
    padding: spacing.xs,
  },
  rootFooter: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${spacing.xxs} ${spacing.xs}`,
  },
  inputField: {
    '& svg': {
      color: colors.neutralMid100,
    },
  },
})
