import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  formBlockContainer: {
    padding: spacing.s,
    border: `1px solid ${colors.neutralLight30}`,
    borderRadius: spacing.xxxs,
    marginBottom: spacing.xs,
  },
  formContent: {
    marginTop: spacing.s,
  },
  link: {
    color: colors.brand100,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})
