// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import {
  ToolbarButton,
  ToolbarButtonProps,
  insertNodes,
  usePlateEditorState,
} from '@udecode/plate'
import { BaseRange } from 'slate'
import { DialogType } from '../types'

export interface ToolbarButtonMediaProps extends ToolbarButtonProps {
  getUrl?: (type: DialogType) => Promise<string>
  type: DialogType
  position?: BaseRange
  id: string
}

export const ToolbarButtonMedia: FC<ToolbarButtonMediaProps> = ({
  getUrl,
  type,
  position,
  id,
  ...props
}) => {
  const editor = usePlateEditorState(id)

  return (
    <ToolbarButton
      onMouseDown={async event => {
        try {
          if (!editor) return
          event.preventDefault()
          let url: string
          if (getUrl && type === DialogType.video) {
            url = await getUrl(DialogType.video)
          }
          if (type === DialogType.image) {
            url = await getUrl(DialogType.image)
          }
          // Fallback behaviour if getUrl not provided
          if (!getUrl && !url) {
            // eslint-disable-next-line no-alert
            url = window.prompt(
              'Enter the URL to your image or video:',
              'https://',
            )
          }
          if (!url) return
          // since the modal changes focus away from the Slate editor,
          // we lose our selection position. As insertEmbed and insertImageEmbed
          // do not expose the target location, we have to insert the node directly
          insertNodes(
            editor,
            {
              type: type === DialogType.image ? 'img' : 'media_embed',
              url,
              children: [{ text: '' }],
            },
            {
              at: position,
            },
          )
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn(err)
        }
      }}
      {...props}
    />
  )
}

ToolbarButtonMedia.displayName = 'ToolbarMedia'
