import React, { ElementType } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

export const FadeOutIn: ElementType = ({
  children,
  classes,
  isOpen,
  exit = {},
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          transition={{
            type: 'spring',
            restSpeed: 0.1,
            bounce: 0.1,
            delay: 0.2,
          }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
            transition: { delay: 0 },
            ...exit,
          }}
          className={classes}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
