import { type RouteComponentProps } from '@reach/router'
import React, { type FC } from 'react'
import { Timeline } from '../../../components/Timeline'

interface Props {
  pathwayId: string
}

export const PathwayTimelineRoute: FC<RouteComponentProps & Props> = ({
  pathwayId,
}) => {
  return <Timeline pathwayId={pathwayId} />
}

PathwayTimelineRoute.displayName = 'PathwayTimelineRoute'
