import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLabelInteractionContextProvider } from '../../compositions/ActivityFeed'
import { Colors } from '../../enums'
import { colors } from '../../utils/style-guide'
import { StepLabelLozenge } from '../Lozenge'
import { Heading5 } from '../Typography'
import { Element } from './types'
import { useStyles } from './useStyles'

interface StepPanelHeaderProps {
  element: Element
}

export const StepPanelHeader = ({
  element,
}: StepPanelHeaderProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { labelOpen, setLabelOpen } = useLabelInteractionContextProvider()
  const { label, name } = element
  return (
    <div className={classes.cardMain}>
      <div className={classes.summaryDetails}>
        <div className={classes.activityContent}>
          <div>
            {/* Lift element up slightly to reduce UI shift down when expanding label */}
            <div style={{ marginBottom: 8 }}>
              <StepLabelLozenge
                color={(label?.color as Colors) || Colors.stepDefault}
                content={label?.text || ''}
                open={labelOpen}
                onClick={() => setLabelOpen(!labelOpen)}
              />
            </div>
            <div className={classes.activityContentDetails}>
              <div>
                <Heading5 color={colors.neutralDark700}>{name}</Heading5>
                <div className={classes.cardDescription}>
                  {/* TODO: Add track name */}
                  {/* <Text variant='textSmall' color={colors.neutralMid300}>
                  {trackName && `${trackName}`}
                </Text> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

StepPanelHeader.displayName = 'StepPanelHeader'
