import { makeStyles } from '@material-ui/core'
import { colors } from '@awell/ui-kit/utils/style-guide'

export const usePreviewGrid = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '89px 1fr 64px',
    gridTemplateAreas: `"actionBar"
                       "previewContent"`,

    backgroundColor: colors.neutralLight0,
  },
  actionBar: {
    gridArea: 'actionBar',
  },
  previewContent: {
    gridArea: 'previewContent',
  },
})
