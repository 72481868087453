// @ts-nocheck TODO: remove this comment and fix errors
import { ReactElement } from 'react'
import { createPortal } from 'react-dom'

/**
 * When an element is positioned as 'fixed' by CSS, It is positioned relative to the initial containing block established by the viewport,
 * except when one of its ancestors has a transform, perspective, or filter property set to something other than none. This conflicts with
 * animation libraries, which put transform properties on elements. By using a portal and getting elements to the top of the component tree,
 * it will be positioned related to the viewport
 */
export function createPortalIfDragging(
  isDragging: boolean,
  element: React.ReactNode,
): ReactElement {
  if (isDragging) {
    return createPortal(element, document.querySelector('body'))
  }

  return element as ReactElement
}
