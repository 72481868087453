import { createTheme } from '@material-ui/core'
import { colors, fonts, paddingButton } from './style-guide'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.brand100,
      dark: colors.brand40,
    },
  },
  typography: {
    ...fonts.roboto,
    button: {
      textTransform: 'initial',
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    allVariants: {
      color: colors.neutralDark800,
    },
    htmlFontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
        boxShadow: 'none',
        lineHeight: 1,
      },

      outlined: {
        padding: paddingButton.medium,
        border: `2px solid ${colors.brand100}`,
        color: colors.brand100,
        backgroundColor: colors.neutralLight0,

        '&:hover, &.hover': {
          backgroundColor: colors.brand10,
        },
        '&:focus, &.focus': {
          backgroundColor: colors.brand10,
        },
        '&:disabled': {
          color: colors.neutralLight40,
          border: `2px solid ${colors.neutralLight30}`,
        },
      },
      outlinedPrimary: {
        padding: paddingButton.medium,
        border: `2px solid ${colors.brand100}`,
        color: colors.brand100,
        backgroundColor: colors.neutralLight0,
        '&:hover, &.hover': {
          border: `2px solid ${colors.brand100}`,
          backgroundColor: colors.brand10,
        },
        '&:focus, &.focus': {
          border: `2px solid ${colors.brand100}`,
          backgroundColor: colors.brand10,
        },
        '&:disabled': {
          color: colors.neutralLight40,
          border: `2px solid ${colors.neutralLight30}`,
        },
      },
      outlinedSecondary: {
        padding: paddingButton.medium,
        border: `2px solid ${colors.brand100}`,
        color: colors.brand100,
        backgroundColor: colors.neutralLight0,

        '&:hover, &.hover': {
          border: `2px solid ${colors.brand100}`,
          backgroundColor: colors.brand10,
        },
        '&:focus, &.focus': {
          border: `2px solid ${colors.brand100}`,
          backgroundColor: colors.brand10,
        },
        '&:disabled': {
          color: colors.neutralLight40,
          border: `2px solid ${colors.neutralLight30}`,
        },
      },
      outlinedSizeSmall: {
        padding: paddingButton.small,
        lineHeight: 1,
      },
      contained: {
        boxShadow: 'none',
        padding: paddingButton.mediumPlus,
        color: colors.neutralLight0,
        backgroundColor: colors.brand100,
        '&:hover, &.hover, &:focus, &:active': {
          backgroundColor: colors.brand300,
        },
        '&:disabled': {
          backgroundColor: colors.neutralLight20,
          color: colors.neutralLight50,
        },
      },
      containedPrimary: {
        padding: paddingButton.mediumPlus,
        color: colors.brand100,
        backgroundColor: colors.brand10,
        '&:hover, &.hover, &:focus, &:active': {
          backgroundColor: colors.brand75,
        },
        '&:active': {
          borderColor: colors.brand100,
        },
        '&:disabled': {
          backgroundColor: colors.neutralLight20,
          color: colors.neutralLight50,
        },
      },
      containedSecondary: {
        padding: paddingButton.mediumPlus,
        color: colors.neutralDark500,
        backgroundColor: colors.neutralLight30,
        '&:hover, &.hover, &:focus, &:active': {
          backgroundColor: colors.neutralLight50,
        },
        '&:active': {
          borderColor: colors.neutralDark500,
        },
        '&:disabled': {
          backgroundColor: colors.neutralLight20,
          color: colors.neutralLight50,
        },
      },
      containedSizeSmall: {
        padding: paddingButton.smallPlus,
        lineHeight: 1,
      },
      text: {
        padding: paddingButton.mediumPlus,
        backgroundColor: 'transparent',
        color: colors.brand100,
        '&:hover, &.hover, &:focus, &:active': {
          backgroundColor: colors.brand10,
        },

        '&:disabled': {
          backgroundColor: colors.neutralLight0,
          color: colors.neutralLight40,
        },
      },
      textPrimary: {
        padding: paddingButton.mediumPlus,
        backgroundColor: 'transparent',
        color: colors.brand100,
        '&:hover, &.hover, &:focus, &:active': {
          backgroundColor: 'transparent',
          color: colors.brand300,
        },
        '&:disabled': {
          color: colors.neutralLight40,
        },
      },
      textSecondary: {
        padding: paddingButton.mediumPlus,
        backgroundColor: 'transparent',
        color: colors.neutralDark500,
        '&:hover, &.hover, &:focus, &:active': {
          backgroundColor: colors.neutralLight30,
        },
        '&:disabled': {
          backgroundColor: colors.neutralLight0,
          color: colors.neutralLight50,
        },
      },
      textSizeSmall: {
        padding: paddingButton.smallPlus,
        lineHeight: 1,
      },
    },
  },
})

export default theme