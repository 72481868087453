enum StatsigEnvironment {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

export const environmentMapper = (env: string): StatsigEnvironment => {
  const envLower = env.toLowerCase()
  switch (envLower) {
    case 'development':
      return StatsigEnvironment.development
    case 'staging':
      return StatsigEnvironment.development
    case 'sandbox':
      return StatsigEnvironment.staging
    case 'production':
      return StatsigEnvironment.production
    case 'production-us':
      return StatsigEnvironment.production
    case 'production-uk':
      return StatsigEnvironment.production
    default:
      // for all local environments
      return StatsigEnvironment.development
  }
}
