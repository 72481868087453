import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { colors } from '@awell/ui-kit/utils/style-guide'
import React, { useState, type FC, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../useStyles'

import { type Maybe } from '@awell/ui-kit/utils'
import { type PatientProfile } from '../../../types'
import { Button } from '@awell/ui-kit/components/Button'
import { PreviewDetailsOnLitsModal } from './PreviewDetailsOnListModal'

interface ChooseFromMultiplePatientOptionsProps {
  patientProfiles: Array<Maybe<PatientProfile>>
  onProfileSelect: (index: number) => void
  patientIdentifier: string
  onClickGoToFirstStep: () => void
}

export const ChooseFromMultiplePatientOptions: FC<
  ChooseFromMultiplePatientOptionsProps
> = ({
  patientProfiles,
  onProfileSelect,
  patientIdentifier,
  onClickGoToFirstStep,
}): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [selectedProfile, setSelectedProfile] =
    useState<Maybe<PatientProfile>>()
  const [isPreviewDetailsModanOpen, setIsPreviewDetailsModanOpen] =
    useState<boolean>(false)

  const setProfileAndIndex = (index: number): void => {
    setSelectedIndex(index)
    setSelectedProfile(patientProfiles[index])
  }

  return (
    <div className={classes.createPatientStepContainer}>
      <Type variant={TypeVariants.h4} className={classes.importPatientTitle}>
        {t('import_patient_successfully_imported', { patientIdentifier })}
      </Type>
      <Type
        className={classes.importPatientTitleDescription}
        variant={TypeVariants.regular}
      >
        {t('import_patient_imported_multiple_description')}
      </Type>

      {patientProfiles.map((profile, index) => {
        if (profile) {
          return (
            <div
              className={
                index === selectedIndex
                  ? `${classes.singleProfileOption} ${classes.selectedProfile}`
                  : classes.singleProfileOption
              }
              key={index}
              onClick={() => {
                setProfileAndIndex(index)
              }}
            >
              <div className={classes.profileDataBottom}>
                <Type variant={TypeVariants.h6}>{`${profile.first_name ?? ''} ${
                  profile.last_name ?? ''
                }`}</Type>
                <Type
                  variant={TypeVariants.regular}
                  color={colors.neutralMid200}
                >
                  {t('sex')}: {t(`${profile?.sex?.toLowerCase() ?? ''}`)}
                </Type>
                <Type
                  variant={TypeVariants.regular}
                  color={colors.neutralMid200}
                >
                  {t('birthdate')}: {profile.birth_date}
                </Type>

                <div
                  className={classes.createPatientStepListPreviewContainer}
                  onClick={() => {
                    setIsPreviewDetailsModanOpen(true)
                  }}
                >
                  <Type variant={TypeVariants.link}>Preview details</Type>
                </div>
              </div>
            </div>
          )
        }
        return <></>
      })}
      <div className={classes.stepButtons}>
        <div>
          <Button
            color='primary'
            className={classes.cancelButton}
            onClick={onClickGoToFirstStep}
          >
            <>{t('go_back')}</>
          </Button>
          <Button
            disabled={selectedIndex < 0}
            onClick={() => {
              onProfileSelect(selectedIndex)
            }}
          >
            <>{t('proceed')}</>
          </Button>
        </div>
      </div>
      {selectedProfile && (
        <PreviewDetailsOnLitsModal
          open={isPreviewDetailsModanOpen}
          onClose={() => {
            setSelectedIndex(-1)
            setIsPreviewDetailsModanOpen(false)
          }}
          onSelect={() => {
            setIsPreviewDetailsModanOpen(false)
          }}
          onNext={() => {
            let nextIndex = selectedIndex + 1
            if (nextIndex >= patientProfiles.length) {
              nextIndex = 0
            }
            setProfileAndIndex(nextIndex)
          }}
          onPrevious={() => {
            let previous = selectedIndex - 1
            if (previous < 0) {
              previous = patientProfiles.length - 1
            }
            setProfileAndIndex(previous)
          }}
          profile={selectedProfile}
        />
      )}
    </div>
  )
}

ChooseFromMultiplePatientOptions.displayName =
  'ChooseFromMultiplePatientOptions'
