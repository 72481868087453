// @ts-nocheck TODO: remove this comment and fix errors
import { isNil } from 'lodash/fp'
import React, { createContext, FC, useContext, useState } from 'react'
import { Track } from './types'

interface PathwayTracksContextInterface {
  hasMultipleTracks: boolean
  tracks: Array<Track>
  activeTrack: Track
  setActiveTrack: (track: Track) => void
}
const initialContext: PathwayTracksContextInterface = {
  hasMultipleTracks: false,
  activeTrack: null,
  tracks: [],
  setActiveTrack: () => null,
}

const PathwayTracksContext = createContext<PathwayTracksContextInterface>(
  initialContext,
)

interface PathwayTracksContextProps {
  tracks: Array<Track>
}
export const PathwayTracksContextProvider: FC<PathwayTracksContextProps> = ({
  tracks,
  children,
}) => {
  const [activeTrack, setActiveTrack] = useState<Track>(null)
  const hasMultipleTracks = tracks?.length > 1

  if (isNil(activeTrack) && tracks?.length) {
    setActiveTrack(tracks[0])
  }
  return (
    <PathwayTracksContext.Provider
      value={{ hasMultipleTracks, tracks, activeTrack, setActiveTrack }}
    >
      {children}
    </PathwayTracksContext.Provider>
  )
}

export const usePathwayTracksContext = (): PathwayTracksContextInterface =>
  useContext(PathwayTracksContext)
