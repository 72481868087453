import { OptionProps } from '@awell/ui-kit/components/ComboBox'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import classnames from 'classnames'
import { capitalize, chunk, isEmpty, isNil, map } from 'lodash'
import React, { FC } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormBlock } from '../FormBlock'
import {
  StartPathwayInputField,
  StartPathwayRadioGroup,
  StartPathwaySelect,
} from './FormInputs'
import {
  DataPointDefinition,
  DataPointValueType,
  StartPathwayFormData,
} from './types'

interface StartingDataPointsProps {
  dataPointDefinitions: Array<DataPointDefinition>
  formMethods: UseFormMethods<StartPathwayFormData>
}

export const StartingDataPoints: FC<StartingDataPointsProps> = ({
  dataPointDefinitions,
  formMethods,
}) => {
  const { inputFieldRow, twoInputs } = useFormStyles()
  const { t } = useTranslation()

  const yesOrNoOptions = [
    {
      id: '1',
      label: capitalize(t('yes')),
      value: 1,
    },
    {
      id: '2',
      label: capitalize(t('no')),
      value: 0,
    },
  ]

  const pairs = chunk(dataPointDefinitions, 2).reverse() // array of DataPointDefinition pair tuples, reversed to display in expected order (oldest first)

  return (
    <FormBlock title={t('pathway_data_points')}>
      {pairs.map(pair => {
        const reactKey = map(pair, 'id').join('-')
        return (
          <div className={classnames(inputFieldRow, twoInputs)} key={reactKey}>
            {pair.map(({ id, title, possibleValues, valueType, optional }) => {
              const isRequired = optional !== true
              if (!isNil(possibleValues) && !isEmpty(possibleValues)) {
                return (
                  <StartPathwaySelect
                    name={id}
                    key={id}
                    required={isRequired}
                    formMethods={formMethods}
                    options={possibleValues as OptionProps[]}
                    label={capitalize(title)}
                    errorText={t('form_mandatory_question_label')}
                  />
                )
              }
              if (valueType === DataPointValueType.Boolean) {
                return (
                  <StartPathwayRadioGroup
                    name={id}
                    key={id}
                    required={isRequired}
                    formMethods={formMethods}
                    options={yesOrNoOptions}
                    label={capitalize(title)}
                    errorText={t('form_mandatory_question_label')}
                  />
                )
              }
              return (
                <StartPathwayInputField
                  key={id}
                  name={id}
                  required={isRequired}
                  formMethods={formMethods}
                  dataPointValueType={valueType}
                  label={capitalize(title)}
                  errorText={t('form_mandatory_question_label')}
                />
              )
            })}
          </div>
        )
      })}
    </FormBlock>
  )
}
