import type {
  Activity as ActivityType,
  ActivityLabel,
  ActivityObject,
  ActivitySubject,
  SubActivity,
} from '../../generated/types-design'

export type Activity = Pick<
  ActivityType,
  | 'id'
  | 'action'
  | 'date'
  | 'status'
  | 'reference_id'
  | 'track'
  | 'container_name'
  | 'isUserActivity'
> & {
  subject: Pick<ActivitySubject, 'id' | 'type' | 'name'>
  object: Pick<ActivityObject, 'id' | 'type' | 'name'>
  indirect_object?: Pick<ActivityObject, 'id' | 'type' | 'name'>
  label?: Pick<ActivityLabel, 'id' | 'text' | 'color'>
  sub_activities: Array<
    Pick<SubActivity, 'id' | 'date' | 'action' | 'error'> & {
      subject: Pick<ActivitySubject, 'id' | 'type' | 'name'>
    }
  >
}

export enum SidePanelCardVariants {
  Step = 'Step',
  Trigger = 'Trigger',
  Pending = 'Pending',
}

export const TIME_FORMAT_FOR_ACTIVITY = 'PPpp'
