// @ts-nocheck TODO: remove this comment and fix errors
import { CSSProperties } from 'react'
/* 
lineClamp enables you to work with multi-line text while still enjoying
truncating the text that overflows with a stylish ellipsis. To use it,
import it to where your JSS styles are constructed and spread it to a 
declaration like so, passing in the number of the line at which you want
clamping to occur:
  content: {
    flex: '1',
    marginLeft: spacing.xs,
    ...lineClamp(7),
  },
*/

interface LineClampProperties {
  overflow: CSSProperties['overflow']
  display: CSSProperties['display']
  lineClamp: CSSProperties['lineClamp']
  boxOrient: CSSProperties['boxOrient']
  wordBreak: CSSProperties['wordBreak']
}

/**
 * Enables line-clamping (ellipsis truncation after given number of lines)
 * if you want words to break anywhere as well then manually add in the
 * wordBreak CSS property (CSSProperties['wordBreak']) to your class/style
 * in combination with the lineClamp helper
 */
export const lineClamp: (lines: number) => LineClampProperties = (
  lines: number,
) => {
  return {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: lines,
    boxOrient: 'vertical',
    wordBreak: 'break-word',
  }
}

/**
 * Checks if there are excess characters than the provided value
 * in the HTML string for Slate Editor
 * @param text
 * @param max_chars
 * @returns boolean
 */
export const hasExcessCharacters = (
  text: string,
  max_chars: number,
): boolean => {
  if (text) {
    const textBlocks = JSON.parse(text)
    return (
      textBlocks.map(block => block.children[0].text).join('').length >
      max_chars
    )
  }
  return false
}

/**
 * Only use when counting number of lines from Slate Editor
 * This is due Slate using stringified HTML to store content
 * We count the number of children as a proxy for lines
 * @param text
 * @param max_lines
 * @returns boolean
 */
export const hasExcessLines = (text: string, max_lines: number): boolean => {
  if (text) {
    const textBlocks = JSON.parse(text)
    return textBlocks.map(a => a.children).flat().length > max_lines
  }
  return false
}
