import React, { FC } from 'react'
import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab'
import { useStyles } from './useStyles'

interface AwellTimelineProps {
  className?: string
  withoutTopConnector?: boolean
  longConnector?: boolean
}

export const AwellTimeline: FC<AwellTimelineProps> = ({
  children,
  className,
  withoutTopConnector,
  longConnector,
}) => {
  const classes = useStyles()

  return (
    <TimelineItem
      classes={{
        root: classes.itemRoot,
        missingOppositeContent: classes.missingOppositeContent,
      }}
      style={{ padding: 0, margin: 0 }}
      className={className}
    >
      {!withoutTopConnector && (
        <TimelineSeparator
          classes={{
            root: longConnector
              ? classes.longSeparatorRoot
              : classes.shortSeparatorRoot,
          }}
        >
          <TimelineConnector
            classes={{
              root: longConnector
                ? classes.longConnectorRoot
                : classes.shortConnectorRoot,
            }}
          />
        </TimelineSeparator>
      )}
      <TimelineContent
        classes={{
          root: longConnector
            ? classes.longContentRoot
            : classes.shortContentRoot,
        }}
      >
        {children}
      </TimelineContent>
    </TimelineItem>
  )
}

AwellTimeline.displayName = 'AwellTimeline'
