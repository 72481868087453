import * as React from 'react'

function SvgDuplicate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.375 8.75V7H4a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1v-3.375h-1.75l-.25-.25V19H5V9h2.625l-.25-.25z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 4a1 1 0 00-1-1H8a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V4zM9 5h10v10H9V5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgDuplicate
