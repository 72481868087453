// todo: rename file to default_config.ts
import {
  type SliderConfig,
  type NumberConfig,
  type MultipleSelectConfig,
  type DateConfig,
  AllowedDatesOptions,
} from '../types'

export const default_slider_config: SliderConfig = {
  min: 0,
  max: 10,
  step_value: 1,
  min_label: '',
  max_label: '',
  is_value_tooltip_on: false,
  display_marks: false,
  show_min_max_values: false,
}

export const default_number_config: NumberConfig = {
  range: {
    enabled: false,
    min: 0,
    max: 10,
  },
}

export const default_multiple_choice_config: MultipleSelectConfig = {
  range: {
    min: 0,
    max: 10,
    enabled: false,
  },
  exclusive_option: {
    enabled: false,
  },
}

export const default_date_config: DateConfig = {
  allowed_dates: AllowedDatesOptions.All,
  include_date_of_response: false,
}
