/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { type FC, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'
import { useForm } from 'react-hook-form'
import { FormBlock } from '../../../../components/FormBlock'
import { PatientProfileInput } from '../../FormInputs'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import classnames from 'classnames'
import { Button } from '@awell/ui-kit/components/Button'
import { useNavigate } from '@reach/router'
import { isEmpty } from 'lodash'

interface PatientDemographicsProps {
  patient_identifier: string
}

interface IdentificationStepProps {
  onSubmitIdentification: (patient_identifier: string) => void
  inputTextLabel: string
}

export const IdentificationStep: FC<IdentificationStepProps> = ({
  onSubmitIdentification,
  inputTextLabel,
}): ReactElement => {
  const classes = useStyles()

  const { t } = useTranslation()
  const { inputFieldRow, oneInputFullRow } = useFormStyles()
  const navigate = useNavigate()

  const { control, errors, handleSubmit, watch } =
    useForm<PatientDemographicsProps>({
      mode: 'onBlur',
      defaultValues: {},
    })

  const watchPatientIdentifier = watch('patient_identifier')

  const onSubmit = async (): Promise<void> => {
    await handleSubmit(async data => {
      onSubmitIdentification(data.patient_identifier)
    })()
  }

  return (
    <div className={classes.createPatientStepContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBlock
          title={t('identification')}
          subtitle={t('import_identification_subtitle')}
        >
          <div className={classnames(inputFieldRow, oneInputFullRow)}>
            <PatientProfileInput
              name='patient_identifier'
              control={control}
              errors={errors}
              label={inputTextLabel}
              placeholder='429826517'
            />
          </div>
        </FormBlock>
        <div className={classes.stepButtons}>
          <div>
            <Button
              color='primary'
              className={classes.cancelButton}
              onClick={() => {
                void navigate('/patients/create')
              }}
            >
              <>{t('cancel')}</>
            </Button>
            <Button
              onClick={onSubmit}
              disabled={isEmpty(watchPatientIdentifier)}
            >
              <>{t('import')}</>
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

IdentificationStep.displayName = 'IdentificationStep'
