import React from 'react'
import classnames from 'classnames'
import { Skeleton } from '../../Skeleton'
import { useSkeletonStyles } from './useSkeletonStyles'
import { useStyles } from '../useStyles'

export const SystemActivityCardSkeleton = () => {
  const classes = useStyles()
  const skeletonClasses = useSkeletonStyles()

  return (
    <div
      className={classnames(
        classes.cardContainer,
        skeletonClasses.feedItemContainer,
        skeletonClasses.systemCardContainer,
      )}
    >
      <div className={skeletonClasses.contentContainer}>
        <div className={skeletonClasses.cardContent}>
          <Skeleton
            width={32}
            height={32}
            variant='rect'
            style={{ marginRight: '16px' }}
          />
          <div>
            <Skeleton variant='text' width={200} />
          </div>
        </div>
      </div>
    </div>
  )
}
