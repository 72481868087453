import React from 'react'
import classnames from 'classnames'
import { useStyles } from './useStyles'
import { type IconProps } from './types'

export const IconWrapper: React.FC<IconProps> = ({
  size = 's',
  className = '',
  background = false,
  backgroundColor,
  style = {},
  bare = false,
  inline = false,
  light = false,
  rounded = false,
  color,
  children,
  innerRef = null,
}) => {
  const classes = useStyles({ color, backgroundColor })
  return (
    <div
      className={classnames(
        className,
        classes[size],
        {
          [classes.light]: light,
          [classes.container]: !bare,
          [classes.background]: background,
          [classes.inline]: inline,
          [classes.rounded]: rounded,
        },
        [classes.defaultColor],
      )}
      style={style}
      ref={innerRef}
    >
      {children}
    </div>
  )
}

IconWrapper.displayName = 'IconWrapper'
