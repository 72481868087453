import * as React from 'react'

function SvgDashboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 5h10a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V7a2 2 0 012-2zM3 7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7zm9 0a1 1 0 011 1v6a1 1 0 11-2 0V8a1 1 0 011-1zm-3 3a1 1 0 00-2 0v6a1 1 0 102 0v-6zm8 0a1 1 0 10-2 0v5a1 1 0 102 0v-5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgDashboard
