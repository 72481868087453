import { IconButton } from '@awell/ui-kit/components/Button'
import { Refresh } from '@awell/ui-kit/components/Icons'
import React from 'react'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { useTranslation } from 'react-i18next'
import { useRetryActivity } from '../../hooks/useRetryActivity'

interface RetryActivityButtonProps {
  id: string
  disabled?: boolean
}
export const RetryActivityButton = ({
  id,
  disabled = false,
}: RetryActivityButtonProps): JSX.Element => {
  const { retryActivity } = useRetryActivity(id)
  const { t } = useTranslation()

  return (
    <IconButton
      onClick={async () => {
        await retryActivity()
      }}
      style={{ padding: spacing.xxxs }}
      iconColor={colors.neutralLight100}
      tooltip={t('retry')}
      disabled={disabled}
    >
      <Refresh />
    </IconButton>
  )
}
