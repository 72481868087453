import * as React from 'react'
import {
  IconButton as MuiIconButton,
  type IconButtonProps as MuiIconButtonProps,
  makeStyles,
} from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'
import { IconWrapper, type Size } from '../IconWrapper'
import { Tooltip } from '../Tooltip'

interface IconButtonProps extends MuiIconButtonProps {
  tooltip?: string
  label?: string
  variant?:
    | 'default'
    | 'subtle'
    | 'floating'
    | 'accent'
    | 'floatingAccent'
    | 'groupedRight'
    | 'secondary'
  iconSize?: Size
  href?: string
  target?: string
  rel?: string
  compact?: boolean
  iconColor?: string
}

export const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none !important',
    boxShadow: 'none !important',
    padding: spacing.xxs,
    ...rounded.m,
    '& span': {
      color: colors.neutralDark500,
    },
    '&:hover, &:active, &:focus': {
      backgroundColor: colors.brand10,
      border: 'none !important',
      boxShadow: 'none !important',
    },
    '&:disabled': {
      '& svg': {
        color: colors.neutralLight50,
      },
    },
  },

  subtle: {
    ...rounded.m,
    backgroundColor: colors.neutralLight20,
    '&:hover, &:active, &:focus': {
      backgroundColor: colors.neutralLight30,
    },
    '&:active': {
      backgroundColor: colors.neutralLight40,
    },
  },
  default: {
    ...rounded.m,
    '&:hover span': {
      color: colors.neutralDark800,
    },
    '&:hover svg': {
      color: colors.neutralDark800,
    },
  },
  floating: {
    '&:hover, &:active, &:focus, &:focus:not(:hover)': {
      backgroundColor: 'transparent',
    },
    '&:hover span': {
      color: colors.signalInformative100,
    },
    '&:hover svg': {
      color: colors.signalInformative100,
    },
  },
  secondary: {
    backgroundColor: colors.brand10,
    color: colors.brand100,
    '&:hover, &.hover': {
      backgroundColor: colors.brand75,
    },
    '&:focus, &.focus': {
      backgroundColor: colors.brand75,
      outline: `1px solid ${colors.brand100}`,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLight40,
      color: colors.neutralLight0,
    },
  },
  groupedRight: {
    backgroundColor: colors.brand100,
    color: colors.neutralLight0,
    '& span': {
      color: colors.neutralLight0,
    },
    '& svg': {
      color: colors.neutralLight0,
    },
    '&:hover, &.hover, &:focus, &:active': {
      backgroundColor: colors.brand300,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLight20,
      color: colors.neutralLight50,
    },
    borderRadius: `0 ${rounded.m.borderRadius} ${rounded.m.borderRadius} 0`,
  },
  floatingAccent: {
    backgroundColor: colors.neutralLight0,
    '& span': {
      color: colors.brand100,
    },
    '& svg': {
      color: colors.brand100,
    },
    '&:hover, &:active, &:focus': {
      backgroundColor: colors.neutralLight0,
      '& svg': {
        color: colors.brand300,
      },
    },
  },

  accent: {
    ...rounded.m,
    backgroundColor: colors.brand100,
    color: colors.neutralLight0,
    '& span': {
      color: colors.neutralLight0,
    },
    '& svg': {
      color: colors.neutralLight0,
    },
    '&:hover, &.hover, &:focus, &:active': {
      backgroundColor: colors.brand300,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLight20,
      color: colors.neutralLight50,
    },
  },
})

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      tooltip = '',
      onClick,
      iconSize = 's',
      variant = 'default',
      iconColor,
      children,
      disabled,
      ...props
    },
    ref,
  ) {
    const classes = useStyles()

    return (
      <Tooltip info={tooltip} arrow>
        <span>
          <MuiIconButton
            ref={ref}
            onClick={onClick}
            disabled={disabled}
            {...props}
            classes={{ root: `${classes.root} ${classes[variant]}` }}
            disableRipple
          >
            <IconWrapper
              size={iconSize}
              color={iconColor ?? colors.neutralDark500}
            >
              {children}
            </IconWrapper>
          </MuiIconButton>
        </span>
      </Tooltip>
    )
  },
)
