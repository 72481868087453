export enum DynamicConfigName {
  AUDIT_LOGS_AVA = 'orchestration_facts_ava',
  SHELLY_COLD_PROMPTS = 'shelly_cold_prompts',
  STEP_TEMPLATES = 'step_templates',
  NEW_STEP_DESIGN_CONFIG = 'new_step_design_config',
}

export interface DynamicConfigTypes {
  [DynamicConfigName.AUDIT_LOGS_AVA]: {
    more_information: {
      message: string
      link: string
    }
  }
}
