import * as React from 'react'

function SvgDragIndicator(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M9 16a2 2 0 11.001 3.999A2 2 0 019 16zm6 0a2 2 0 11.001 3.999A2 2 0 0115 16zm-6-6a2 2 0 11.001 3.999A2 2 0 019 10zm6 0a2 2 0 11.001 3.999A2 2 0 0115 10zM9 4a2 2 0 11.001 3.999A2 2 0 019 4zm6 0a2 2 0 11.001 3.999A2 2 0 0115 4z'
      />
    </svg>
  )
}

export default SvgDragIndicator
