import React, { FC } from 'react'

import classnames from 'classnames'
import { useStyles } from './useStyles'
import { colors } from '../../utils/style-guide'

interface RequestStatusProps {
  status: number
}

export const RequestStatus: FC<RequestStatusProps> = ({ status }) => {
  const classes = useStyles()

  const getStatusColor = (): string => {
    if (status >= 100 && status <= 199) {
      return colors.brand80
    }
    if (status >= 200 && status <= 299) {
      return colors.green
    }
    if (status >= 300 && status <= 399) {
      return colors.purple
    }
    if (status >= 400 && status <= 499) {
      return colors.signalError100
    }
    return colors.accent100
  }

  return (
    <div className={classes.statusContainer}>
      <div
        className={classnames(classes.statusDot)}
        style={{ background: getStatusColor() }}
      />
      {status}
    </div>
  )
}

RequestStatus.displayName = 'RequestStatus'
