import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  label: {
    display: 'block',
    marginBottom: spacing.xxxs,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  inputRoot: {
    ...rounded.m,
    marginTop: 0,
    padding: `${spacing.xsm} ${spacing.xs}`,
    width: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight0,
    '&.error, &.error:hover': {
      borderColor: colors.signalError100,
      backgroundColor: colors.neutralLight0,
    },
    '& input': {
      textTransform: 'none',
    },
  },
  inputRootCompact: {
    ...rounded.m,
    marginTop: 0,
    // 3px to match compact paddingY of other Material UI inputs
    padding: `3px ${spacing.xsm}`,
    // padding: `0.452rem ${spacing.xsm}`,
    width: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight0,
    '&.error, &.error:hover': {
      borderColor: colors.signalError100,
      backgroundColor: colors.neutralLight0,
    },
    '& input': {
      paddingTop: spacing.xxxs,
      paddingBottom: spacing.xxxs,
      textTransform: 'none',
    },
  },

  simpleInputCompact: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  hover: {
    '&:hover:not($focused)': {
      borderColor: colors.neutralMid60,
      backgroundColor: colors.neutralLight0
    },
  },
  disabled: {
    borderColor: colors.neutralLight20,
    backgroundColor: colors.neutralLight20,
    pointerEvents: 'none',
  },
  focused: {
    borderColor: colors.brand100,
    backgroundColor: colors.neutralLight0,
  },
  error: {
    paddingTop: 2,
    display: 'content',
    height: spacing.xs,
  },
  warning: {
    borderColor: colors.signalWarning100,
  },
  requiredFieldMarker: {
    color: colors.red100,
    marginLeft: spacing.xxxs,
  },
  clearButton: {
    padding: '0 !important',
    margin: 0,
    backgroundColor: 'transparent !important',
    minWidth: '0 !important',
  },
  plainDisabled: {
    opacity: 0.6,
  },
})
