import { InputField } from '@awell/ui-kit/components/InputField'
import React, { type FC } from 'react'
import { Controller, type UseFormMethods } from 'react-hook-form'
import { DataPointValueType, type StartPathwayFormData } from '../types'
import {
  getInputErrorMessage,
  isValidInputValue,
} from '@awell/ui-kit/utils/forms'

enum InputType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Telephone = 'tel',
}

const mapDataPointValueTypeToInputType = (
  dataPointValueType: DataPointValueType,
): InputType => {
  switch (dataPointValueType) {
    case DataPointValueType.Number:
      return InputType.Number
    case DataPointValueType.Date:
      return InputType.Date
    case DataPointValueType.String:
      return InputType.Text
    case DataPointValueType.Telephone:
      return InputType.Telephone
    case DataPointValueType.Json:
      return InputType.Text
    default:
      throw new Error(
        `DataPointValueType type ${dataPointValueType} is not handled by StartPathwayInput`,
      )
  }
}
interface StartPathwayInputFieldProps {
  name: string
  required?: boolean
  formMethods: UseFormMethods<StartPathwayFormData>
  label: string
  errorText: string
  className?: string
  dataPointValueType: DataPointValueType
}

export const StartPathwayInputField: FC<StartPathwayInputFieldProps> = ({
  name,
  formMethods,
  label,
  errorText,
  className = '',
  required = true,
  dataPointValueType,
}) => {
  const inputType = mapDataPointValueTypeToInputType(dataPointValueType)

  return (
    <Controller
      name={name}
      control={formMethods.control}
      defaultValue=''
      rules={{
        required,
        validate: value =>
          isValidInputValue({
            inputRequired: required,
            inputType,
            inputValue: value,
          }),
      }}
      render={({ onChange, onBlur, value }) => (
        <InputField
          type={inputType}
          onChange={onChange}
          onBlur={onBlur}
          className={className}
          value={value}
          compact
          label={label}
          error={getInputErrorMessage(formMethods.errors, name)}
          required={required}
          withErrorSpace
        />
      )}
    />
  )
}
