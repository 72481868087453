import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    '& > div:last-child': {
      borderBottom: `1px solid ${colors.neutralLight10}`,
      // height of preview controls ribbon (64px) plus 16px "padding"
      paddingBottom: 80,
    },
    alignItems: 'center',
    paddingTop: spacing.xxs,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginLeft: spacing.m,
    },
  },
  feedItemContainer: {
    width: 750, // From figma design
    [theme.breakpoints.down('lg')]: {
      width: 600,
    },
    [theme.breakpoints.down('sm')]: {
      width: 400,
    },
  },
  accordionBar: {
    ...spacing.paddingX.xs,
    ...spacing.paddingY.xxs,
    marginTop: spacing.s,
    display: 'flex',
    backgroundColor: colors.neutralLight20,
    color: colors.neutralMid300,
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  accordionTitle: {
    alignSelf: 'center',
  },
  accordianHidden: {
    display: 'none',
  },
  summary: {
    ...spacing.paddingX.xs,
  },
}))
