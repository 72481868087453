// @ts-nocheck TODO: remove this comment and fix errors
import { debounce, Input, InputAdornment } from '@material-ui/core'
import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { colors } from '../../utils/style-guide'
import { IconButton } from '../Button'
import { Cross } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Text } from '../Typography'
import { useStyles } from './useStyles'

interface Props
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'autoComplete' | 'autoFocus'
  > {
  value: string
  onChange?: (value: string) => void
  onKeyPress?: () => void
  label?: string
  placeholder?: string
  error?: string
  warning?: string
  disabled?: boolean
  iconEnd?: JSX.Element
  hideLabel?: boolean
  compact?: boolean
  className?: string
  style?: React.CSSProperties
  debounced?: boolean
  onSearch: (query: string) => void
  fullWidth?: boolean
  ref?: React.Ref<unknown>
}

export const SearchForm: React.FC<Props> = ({
  value,
  onChange,
  autoFocus = false,
  iconEnd,
  label,
  hideLabel,
  placeholder,
  onKeyPress,
  warning,
  disabled,
  compact,
  className = '',
  style,
  debounced = false,
  onSearch,
  fullWidth = false,
  ref,
}) => {
  const classes = useStyles()
  const [internalValue, setInternalValue] = useState(value)
  // Boolean to track if user is typing or not in the input field
  const [isTyping, setTyping] = useState(false)
  // Timer to unset isTyping value after a timeout
  const [typingTimer, setTypingTimer] = useState(null)

  // The value prop is updated when the changes have been recorded by the backend.
  // If the user is still typing when that happens, the internal value should not
  // be refreshed as it is older than the latest changes typed by the user.
  const refreshInternalValue = () => {
    if (!debounced || !isTyping) {
      setInternalValue(value)
    }
  }
  useEffect(refreshInternalValue, [value])

  // To reset the timer when this component is destroyed
  useEffect(() => {
    return function cleanup() {
      clearTimeout(typingTimer)
    }
  }, [])

  const DEBOUNCE_DELAY = 1000
  const debouncedOnChange = useRef(debounce(onSearch, DEBOUNCE_DELAY)).current

  // Record changes in internal state plus sets isTyping for 2 seconds
  // to deliver a smooth UX to users
  // => Prevents the input value to jump back while user is still typing
  const internalOnChange = newValue => {
    clearTimeout(typingTimer)
    setTyping(true)
    setTypingTimer(setTimeout(() => setTyping(false), 2000))
    setInternalValue(newValue)
    if (debounced) {
      debouncedOnChange(newValue)
    } else {
      onSearch(newValue)
    }
  }

  const handleClearSearch: () => void = () => {
    setInternalValue('')
    onSearch('')
  }

  const endAdornment = iconEnd && (
    <InputAdornment position='end'>
      {internalValue === '' ? (
        <IconWrapper bare style={{ color: colors.neutralDark500 }}>
          {iconEnd}
        </IconWrapper>
      ) : (
        <IconButton
          variant='floating'
          onClick={handleClearSearch}
          className={classes.clearButton}
        >
          <Cross />
        </IconButton>
      )}{' '}
    </InputAdornment>
  )

  return (
    <div
      className={classnames(className, classes.searchContainer, {
        [classes.fullWidth]: fullWidth,
      })}
      style={style}
    >
      <label>
        <span
          className={classnames(classes.label, {
            disabled,
            'sr-only': hideLabel,
          })}
        >
          {label}
        </span>
        <Input
          fullWidth
          disableUnderline
          classes={{
            input: classes.inputElement,
            root: classes[compact ? 'inputRootCompact' : 'inputRoot'],
            disabled: classes.disabled,
            focused: classes.focused,
          }}
          value={internalValue}
          onChange={event => internalOnChange(event.target.value)}
          onKeyPress={onKeyPress}
          placeholder={hideLabel && label ? label : placeholder}
          type='text'
          disabled={disabled}
          endAdornment={endAdornment}
          autoFocus={autoFocus}
          ref={ref}
        />
      </label>
      {warning && (
        <div className={classes.warningMessage}>
          <Text variant='textSmall'>{warning}</Text>
        </div>
      )}
    </div>
  )
}

SearchForm.displayName = 'SearchForm'
