// @ts-nocheck TODO: remove this comment and fix errors
import {
  ELEMENT_LINK,
  getPluginType,
  unwrapNodes,
  usePlateEditorState,
} from '@udecode/plate'
import { isEmpty } from 'lodash'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BaseRange } from 'slate'
import { get_video_embed_url } from '../../../utils/get_video_embed_url'
import { UploadWidget, UploadWidgetConfig } from '../../UploadWidget'
import { DialogType } from '../types'
import { MediaToolbarModal } from './MediaToolbarModal'

export interface DialogLabels {
  insertVideo?: string
  insertImage?: string
  insertLink?: string
  updateLink?: string
  insert: string
  remove: string
  cancel: string
  placeholderUrl: string
  placeholderDisplayName?: string
}

interface SlateDialogHook {
  position: BaseRange
  getUrlWithDialog: (type: DialogType) => Promise<string>
  getLinkUrlWithDialog: (prevUrl: string) => Promise<string>
}

// Coordinates for very first character position in Slate
const startPosition: BaseRange = {
  anchor: { path: [0], offset: 0 },
  focus: { path: [0], offset: 0 },
}

interface UseSlateDialog {
  dialogLabels: DialogLabels
  id: string
  config?: UploadWidgetConfig
}

export const useSlateDialog = ({
  dialogLabels,
  id,
  config = {
    cloudName: '',
    uploadPreset: '',
  },
}: UseSlateDialog): SlateDialogHook => {
  const editor = usePlateEditorState(id)
  const position = editor.selection ?? startPosition

  const cleanUpModal = () => {
    if (document.getElementById(`modal-slot-${id}`)?.hasChildNodes()) {
      ReactDOM.unmountComponentAtNode(
        document.getElementById(`modal-slot-${id}`),
      )
    }
  }

  const getUrlWithDialog: (type: DialogType) => Promise<string> = type => {
    return new Promise((resolve, reject) => {
      const Dialog: FC = () => {
        const { insertVideo, insert, cancel, placeholderUrl } = dialogLabels
        if (type !== DialogType.image) {
          return (
            <MediaToolbarModal
              type={type}
              labels={{
                heading: insertVideo,
                primaryAction: insert,
                cancelAction: cancel,
                placeholderUrl,
              }}
              open
              onClose={() => {
                reject()
                cleanUpModal()
              }}
              onSubmit={value => {
                resolve(get_video_embed_url(value))
                cleanUpModal()
              }}
            />
          )
        }
        if (type === DialogType.image) {
          return (
            <UploadWidget
              onSubmit={value => {
                resolve(value)
                cleanUpModal()
              }}
              config={config}
            />
          )
        }
        return null
      }
      Dialog.displayName = 'Toolbar Dialog'
      ReactDOM.render(<Dialog />, document.getElementById(`modal-slot-${id}`))
    })
  }

  const getLinkUrlWithDialog: (
    prevUrl: string,
  ) => Promise<string> = prevUrl => {
    return new Promise((resolve, reject) => {
      const Dialog = () => {
        const {
          insertLink,
          updateLink,
          insert,
          remove,
          cancel,
          placeholderUrl,
        } = dialogLabels
        const linkType = getPluginType(editor, ELEMENT_LINK)

        return (
          <MediaToolbarModal
            labels={{
              heading: isEmpty(prevUrl) ? insertLink : updateLink,
              primaryAction: insert,
              deleteAction: remove,
              cancelAction: cancel,
              placeholderUrl,
            }}
            open
            onClose={() => {
              reject()
              cleanUpModal()
            }}
            onRemove={() => {
              // Remove the link elements at cursor position or within selection
              unwrapNodes(editor, {
                at: position,
                match: { type: linkType },
              })
            }}
            onSubmit={value => resolve(value)}
            initialValue={prevUrl}
          />
        )
      }
      Dialog.displayName = 'Toolbar Dialog'
      ReactDOM.render(<Dialog />, document.getElementById(`modal-slot-${id}`))
    })
  }

  return {
    position,
    getUrlWithDialog,
    getLinkUrlWithDialog,
  }
}
