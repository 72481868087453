// @ts-nocheck TODO: remove this comment and fix errors
import isArray from 'lodash/isArray'
import flatten from 'lodash/flatten'
import set from 'lodash/fp/set'
import { Children } from 'react'
import { log } from '../../utils/log'
import { type IconNames } from '../Icon'
import { type CategoryTypes } from '../CategoryIcon'
import { findChild } from '../../utils/find-child'

export interface InternalOption {
  content?: unknown
  group?: string
  icon?: IconNames | CategoryTypes
  index: number
  label?: string
  selected?: boolean
  value?: string
  disabled?: boolean
}

const wrapInArray = value => (isArray(value) ? value : [value])
const addIndexAsProperty = (item, index) => ({ ...item, index })

function convertOptionToObject(optionComponent) {
  let value = optionComponent.props?.value
  const selected = optionComponent.props?.selected
  const label = optionComponent.props?.label
  const content = optionComponent.props?.children
  const icon = optionComponent.props?.icon
  const disabled = optionComponent.props?.disabled

  if (value == null && typeof content === 'string') {
    value = content
  }

  return { content, icon, label, selected, value, disabled }
}

function flattenGroups(groups, optionsType) {
  const convertedGroups = groups.map(group => {
    const { title } = group.props
    return wrapInArray(group.props.children)
      .filter(findChild(optionsType))
      .map(convertOptionToObject)
      .map(set('group', title))
  })

  return flatten(convertedGroups)
}

export function generateOptionsFromChildren({
  children,
  optionGroupType,
  optionType,
}): { options: Array<InternalOption>; isNested: boolean } {
  const kids = Children.toArray(children)
  const rootOptionGroups = (kids || []).filter(findChild(optionGroupType))
  const rootOptions = (kids || []).filter(findChild(optionType))
  let options = []

  if (rootOptions.length > 0 && rootOptionGroups.length > 0) {
    log.error('Mix of <Options/> and <OptionGroup/> provided')
  }

  if (rootOptionGroups.length > 0) {
    options = flattenGroups(rootOptionGroups, optionType)
  }

  if (rootOptions.length > 0) {
    options = rootOptions.map(convertOptionToObject)
  }

  return {
    options: options.map(addIndexAsProperty),
    isNested: rootOptionGroups.length > 0,
  }
}
