import React, { FC } from 'react'
import { ErrorBoundary as BaseErrorBoundary } from '@awell/ui-kit/compositions/ErrorPage'
import { environment } from '@awell/libs-web/environment'

interface ErrorBoundaryProps {
  panel?: boolean
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ panel, children }) => {
  const showStack =
    environment.environment === 'local' ||
    environment.environment === 'development' ||
    environment.environment === 'staging'

  return (
    <BaseErrorBoundary showStack={showStack} panel={panel}>
      {children}
    </BaseErrorBoundary>
  )
}

ErrorBoundary.displayName = 'ErrorBoundary'
