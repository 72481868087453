import React from 'react'
import { isEmpty, isNil } from 'lodash'
import { type Maybe } from '../../../utils'
import { colors } from '../../../utils/style-guide'
import { Text } from '../../Typography'
import { useStyles } from './useStyles'

interface TrademarkProps {
  trademark: Maybe<string>
}

export const Trademark = ({ trademark }: TrademarkProps): JSX.Element | null => {
  const classes = useStyles()
  if (isNil(trademark) && isEmpty(trademark)) {
    return null
  }

  return (
    <div className={classes.trademark}>
      <Text variant='textSmall' color={colors.neutralMid200}>
        {trademark}
      </Text>
    </div>
  )
}
