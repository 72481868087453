import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing, shadows } from '../../utils/style-guide'

export const useStyles = makeStyles({
  activityItemContainer: {
    ...rounded.m,
    ...shadows.xs,
    border: `1px solid ${colors.neutralLight40}`,
    padding: spacing.xs,
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      width: 'fit-content',
      marginRight: spacing.xs,
    },
    pointerEvents: 'all',
    cursor: 'pointer',
    textTransform: 'inherit',
    '&:active': {
      backgroundColor: colors.neutralLight10,
    },
    '&:hover': {
      ...shadows.xsm,
    },
    '&:focus': {
      outline: `1px solid ${colors.neutralLight30}`,
    },
  },
  activityDetails: {
    flexGrow: 1,
  },
})
