import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { RadioIcon, CheckBoxIcon } from './Icon'
import { Flex } from '../Flex'
import { spacing, colors } from '../../utils/style-guide'

interface SelectionControlsProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  value: string
  error?: boolean
  style?: React.CSSProperties
}

interface RadioProps extends SelectionControlsProps {
  name: string
}

interface FullSelectionControlsProps extends SelectionControlsProps {
  kind: 'radio' | 'checkbox'
}

type Ref = HTMLInputElement

const useStyles = makeStyles({
  label: {
    position: 'relative',
    zIndex: 0,
    ...spacing.paddingY.xxs,
    '&:hover:not(.disabled)': {
      cursor: 'pointer',
    },
  },
  input: {
    position: 'absolute',
    top: '1.25rem',
    left: '0.75rem',
    transform: 'translate(-50%, -50%)',
    margin: 0,
    zIndex: -1,
    opacity: 0,
  },
  icon: {
    zIndex: 1,
  },
  labelText: {
    marginLeft: spacing.xxs,
    '$label:hover $input:not(:disabled) &': {
      color: colors.brand100,
    },
    '$label:not(:hover) $input:focus ~ &': {
      color: colors.brand100,
    },
    '$label.error:hover &, $label.error $input:focus ~ &': {
      color: `${colors.signalError100} !important`,
    },
    '$input:disabled ~ &': {
      opacity: 0.3,
    },
  },
})

const SelectionControls = React.forwardRef<Ref, FullSelectionControlsProps>(
  function SelectionControls(
    { label, kind, error, disabled, style, ...props },
    ref,
  ) {
    const classes = useStyles()

    return (
      <Flex
        tag='label'
        className={classnames(classes.label, { error, disabled })}
        style={style}
        align='center'
      >
        <input
          className={classes.input}
          type={kind}
          ref={ref}
          disabled={disabled}
          {...props}
        />
        {kind === 'radio' && (
          <RadioIcon error={error} className={classes.icon} />
        )}
        {kind === 'checkbox' && (
          <CheckBoxIcon {...props} error={error} className={classes.icon} />
        )}
        <span className={classes.labelText}>{label}</span>
      </Flex>
    )
  },
)

export const Radio: React.FC<RadioProps> = props => (
  <SelectionControls kind='radio' {...props} />
)
Radio.displayName = 'Radio'

export const Checkbox: React.FC<SelectionControlsProps> = props => (
  <SelectionControls kind='checkbox' {...props} />
)
Checkbox.displayName = 'Checkbox'
