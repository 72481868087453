import * as React from 'react'
import {
  createGenerateClassName,
  StylesProvider,
  type Theme,
  ThemeProvider,
} from '@material-ui/core/styles'
import theme from '../../utils/theme'
import setVhCustomProperty from '../../utils/set-vh-custom-property'
import '../index.css'

interface AppThemeProps {
  seed?: string
  muiTheme?: Theme
}

export const AppTheme: React.FC<AppThemeProps> = ({
  children,
  seed = 'ui-kit',
  muiTheme = theme,
}) => {
  React.useEffect(() => {
    setVhCustomProperty()
  }, [])

  const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    seed,
  })

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </StylesProvider>
  )
}
AppTheme.displayName = 'AppTheme'
