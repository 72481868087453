import React, { FC } from 'react'
import { GanttSkeleton } from '@awell/ui-kit/components/Gantt'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { usePreviewGrid } from '../useStyles'

export const useStyles = makeStyles({
  actionSkeletonContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${spacing.xs}`,
  },
  actionSkeleton: {
    marginRight: spacing.xs,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const TimelineSkeleton: FC = () => {
  const grid = usePreviewGrid()
  const classes = useStyles()

  return (
    <div className={grid.container}>
      <div
        className={classnames(grid.actionBar, classes.actionSkeletonContainer)}
      >
        <Skeleton
          variant='rect'
          width={320}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={126}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={116}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={157}
          height={40}
          className={classes.actionSkeleton}
        />
        <Skeleton
          variant='rect'
          width={151}
          height={40}
          className={classes.actionSkeleton}
        />
        <div className={classes.checkboxContainer}>
          <Skeleton
            variant='rect'
            width={20}
            height={20}
            className={classes.actionSkeleton}
          />
          <Skeleton variant='text' width={100} />
        </div>
      </div>
      <div className={grid.previewContent}>
        <GanttSkeleton />
      </div>
    </div>
  )
}

TimelineSkeleton.displayName = 'TimelineSkeleton'
