// @ts-nocheck TODO: remove this comment and fix errors
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { Colors } from '../../enums/Colors'
import { spacing, labelColors } from '../../utils/style-guide'

interface LabelTabProps {
  labelName: string
  labelColor: Colors
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: spacing.m,
    padding: `${spacing.xxs} ${spacing.xs}`,
    borderRadius: '4px 4px 0 0',
  },
})

export const LabelTab = ({
  labelName,
  labelColor,
}: LabelTabProps): JSX.Element => {
  const classes = useStyles()
  if (isEmpty(labelName)) {
    return null
  }
  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: labelColors[labelColor] || labelColors.green,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
    >
      {labelName}
    </div>
  )
}

LabelTab.displayName = 'LabelTab'
