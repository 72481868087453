import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormActivityDetails } from './FormActivityDetails'
import { ChecklistActivityDetails } from './ChecklistActivityDetails'
import { MessageActivityDetails } from './MessageActivityDetails'
import { CalculationActivityDetails } from './CalculationActivityDetails'
import { EmrActivityDetails } from './EmrActivityDetails'
import { ApiCallActivityDetails } from './ApiCallActivityDetails'
import { type Activity, ActivityObjectType, type EmrReportMetadataField } from './types'
import { ClinicalNoteActivityDetails } from './ClinicalNote'
import { ExtensionActivityDetails } from './Extension'
import { DecisionActivityDetails } from './DecisionActivityDetails'

interface ActivityContentProps {
  activity: Activity
  onClose: () => void
  onLoadEMRReport: (metadata?: Array<EmrReportMetadataField>) => void
}

export const ActivityContent = memo(
  ({ activity, onClose, onLoadEMRReport }: ActivityContentProps) => {
    const { t } = useTranslation()
    switch (activity?.object.type) {
      case ActivityObjectType.Form:
        return <FormActivityDetails activity={activity} onClose={onClose} />
      case ActivityObjectType.Message:
        return <MessageActivityDetails activity={activity} />
      case ActivityObjectType.Checklist:
        return (
          <ChecklistActivityDetails activity={activity} onClose={onClose} />
        )
      case ActivityObjectType.Calculation:
        return <CalculationActivityDetails activity={activity} />
      case ActivityObjectType.ClinicalNote:
        return <ClinicalNoteActivityDetails activity={activity} />
      case ActivityObjectType.EmrReport:
        return (
          <EmrActivityDetails
            activity={activity}
            onLoadEMRReport={onLoadEMRReport}
          />
        )
      case ActivityObjectType.ApiCall:
        return <ApiCallActivityDetails activity={activity} />
      case ActivityObjectType.PluginAction:
        return <ExtensionActivityDetails activity={activity} />
      case ActivityObjectType.Decision:
        return <DecisionActivityDetails activity={activity} />
      default:
        return <div style={{ textAlign: 'center' }}>{t('no_details')}</div>
    }
  },
  (prev, next) =>
    prev.activity.id === next.activity.id &&
    prev.activity.status === next.activity.status,
)

ActivityContent.displayName = 'ActivityContent'
