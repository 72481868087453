import { makeStyles } from '@material-ui/core'
import { rounded, spacing, colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  inputWrapper: {
    ...rounded.m,
    backgroundColor: colors.neutralLight10,
    borderColor: colors.neutralLight40,
    borderStyle: 'solid',
    borderWidth: '2px',
    width: '100%',
    '& input': {
      backgroundColor: colors.neutralLight10,
    },
    '&:hover, &:hover $inputElement': {
      cursor: 'pointer',
    },
    '&:hover, &:hover input': {
      cursor: 'pointer',
    },
  },
  disabledInputWrapper: {
    opacity: 0.4,
    cursor: 'default',
  },
  inputWrapperWarning: {
    borderColor: colors.signalWarning100,
    backgroundColor: colors.neutralLight0,
  },
  inputWrapperError: {
    borderColor: colors.signalError100,
    backgroundColor: colors.neutralLight0,
  },
  inputWrapperFocused: {
    borderColor: colors.brand100,
    backgroundColor: colors.neutralLight0,
    '& input': {
      backgroundColor: colors.neutralLight0,
    },
  },
  // Note that this element is the clickable area of the select component, so it
  // absolutely needs to take all the available space. If the wrapper container has a
  // padding or if this element has a margin, it creates a space inside the border
  // that can be clicked & focuses the input but does not open the dropdown.
  inputElement: {
    ...rounded.m,
    border: 'none',
    flex: 1,
    padding: spacing.xxs,
    width: '100%',
    '&:hover, &:focus, &:active': {
      outline: 'none',
    },
  },
  chevronIcon: {
    marginRight: spacing.xxs,
    transition: 'transform 0.15s ease-in-out',
    userSelect: 'all',
    pointerEvents: 'all',
  },
  chevronIconRotated: {
    transform: 'rotate(180deg)',
  },
  placeholder: {
    color: colors.neutralDark600,
    position: 'absolute',
    top: spacing.xxs,
    left: spacing.xxs,
    opacity: 0.8,
    transition: 'transform 0.25s ease-in-out',
    whiteSpace: 'pre',
    pointerEvents: 'none',
  },
  startIcon: {
    marginLeft: spacing.xxs,
  },
  label: {
    display: 'block',
    marginBottom: spacing.xxs,
  },
  description: {
    display: 'block',
    marginBottom: spacing.xxs,
    color: colors.neutralMid100,
  },
  tag: {
    textTransform: 'capitalize',
    '&:first-of-type': {
      paddingLeft: spacing.xxs,
    },
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  multiTags: {
    padding: spacing.xxs,
    width: '100%',
  },
  inputWithMultiSelect: {
    padding: spacing.xxs,
  },
  searchFieldWrapper: {
    pointerEvents: 'none',
    cursor: 'pointer',
    width: '100%',
    paddingBottom: spacing.xxs,
  },
  searchField: {
    width: '100%',
    padding: spacing.xxs,
  },
  checkboxIcon: {
    color: colors.brand100,
    padding: 0,
  },
})
