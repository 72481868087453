import React, { type FC } from 'react'
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  BlockToolbarButton,
  ListToolbarButton,
  MarkToolbarButton,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  getPluginType,
  ELEMENT_UL,
  ELEMENT_OL,
} from '@udecode/plate'
import FormatBold from '@material-ui/icons/FormatBold'
import FormatItalic from '@material-ui/icons/FormatItalic'
import FormatStrikethrough from '@material-ui/icons/FormatStrikethrough'
import FormatUnderlined from '@material-ui/icons/FormatUnderlined'
import FormatLink from '@material-ui/icons/Link'
import Looks3 from '@material-ui/icons/Looks3'
import LooksOne from '@material-ui/icons/LooksOne'
import LooksTwo from '@material-ui/icons/LooksTwo'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import FormatListNumbered from '@material-ui/icons/FormatListNumbered'
import { Tooltip } from '../../Tooltip'
import { type DialogLabels, useSlateDialog } from './useSlateDialog'
import { ToolbarButtonLink } from './ToolbarButtonLink'
import { useEditor } from '../useEditor'
import { useTranslation } from 'react-i18next'

interface StandardToolsProps {
  id: string
}

export const StandardTools: FC<StandardToolsProps> = ({ id }) => {
  const { t } = useTranslation()
  const dialogLabels: DialogLabels = {
    insertVideo: t('insert_video'),
    insertImage: t('insert_image'),
    insertLink: t('insert_link'),
    updateLink: t('update_link'),
    placeholderUrl: t('placeholder_paste_url'),
    insert: t('insert'),
    remove: t('remove'),
    cancel: t('cancel'),
  }

  const { editorState: editor } = useEditor(id)

  const { getLinkUrlWithDialog, position } = useSlateDialog({
    dialogLabels,
    id,
  })
  return (
    <>
      <Tooltip info='H1' arrow>
        <span>
          <BlockToolbarButton
            type={getPluginType(editor, ELEMENT_H1)}
            icon={<LooksOne />}
          />
        </span>
      </Tooltip>
      <Tooltip info='H2' arrow>
        <span>
          <BlockToolbarButton
            type={getPluginType(editor, ELEMENT_H2)}
            icon={<LooksTwo />}
          />
        </span>
      </Tooltip>
      <Tooltip info='H3' arrow>
        <span>
          <BlockToolbarButton
            type={getPluginType(editor, ELEMENT_H3)}
            icon={<Looks3 />}
          />
        </span>
      </Tooltip>
      <Tooltip info='Bold' arrow>
        <span>
          <MarkToolbarButton type={MARK_BOLD} icon={<FormatBold />} />
        </span>
      </Tooltip>
      <Tooltip info='Italic' arrow>
        <span>
          <MarkToolbarButton type={MARK_ITALIC} icon={<FormatItalic />} />
        </span>
      </Tooltip>
      <Tooltip info='Underline' arrow>
        <span>
          <MarkToolbarButton
            type={MARK_UNDERLINE}
            icon={<FormatUnderlined />}
          />
        </span>
      </Tooltip>
      <Tooltip info='Strikethrough' arrow>
        <span>
          <MarkToolbarButton
            type={MARK_STRIKETHROUGH}
            icon={<FormatStrikethrough />}
          />
        </span>
      </Tooltip>
      <Tooltip info='Link' arrow>
        <span>
          <ToolbarButtonLink
            id={id}
            icon={<FormatLink />}
            // @ts-expect-error fix type error in useSlateDialog
            getLinkUrl={getLinkUrlWithDialog}
            position={position}
          />
        </span>
      </Tooltip>
      <Tooltip info='Bullet List' arrow>
        <span>
          <ListToolbarButton
            type={getPluginType(editor, ELEMENT_UL)}
            icon={<FormatListBulleted />}
          />
        </span>
      </Tooltip>
      <Tooltip info='Numbered List' arrow>
        <span>
          <ListToolbarButton
            type={getPluginType(editor, ELEMENT_OL)}
            icon={<FormatListNumbered />}
          />
        </span>
      </Tooltip>
    </>
  )
}

StandardTools.displayName = 'StandardTools'
