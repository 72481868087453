import React from 'react'
import { Redirect } from '@reach/router'

import { useStytchIsAuthorized } from '@stytch/react/b2b'

import { FullHeightRouter, LogoutRoute } from '@awell/libs-web/routing'
import { ErrorPage as NotFound } from '@awell/ui-kit/compositions/ErrorPage'
import { Spinner } from '@awell/ui-kit/components/Spinner'

import { ErrorBoundary } from '../components/ErrorBoundary'

import PatientsRoute from './PatientsRoute'
import ActivitiesRoute from './ActivitiesRoute'
import WorklistRoute from './WorklistRoute'
import { PathwayRoute } from './PathwayRoute'
import { LogsRoute } from './LogsRoute'
import FallbackRoute from './FallbackRoute'

export const Routes: React.FC = () => {
  const { isAuthorized: canAccessPatients } = useStytchIsAuthorized(
    'patients',
    'read',
  )
  const { isAuthorized: canAccessWorklist } = useStytchIsAuthorized(
    'worklist',
    'worklist_read',
  )

  const getRedirectPath = (): string => {
    // always prioritize patients over worklist
    if (canAccessPatients) {
      return '/patients'
    } else if (canAccessWorklist) {
      return '/worklists'
    } else {
      return '/fallback'
    }
  }

  return (
    <ErrorBoundary>
      <FullHeightRouter>
        <PathwayRoute path='/pathway/:pathwayId/*' />
        <ActivitiesRoute path='/activities/*' />
        <PatientsRoute path='/patients/*' />
        <WorklistRoute path='/worklists/*' />
        <FallbackRoute path='/fallback' />
        <LogsRoute path='/logs/*' />
        <LogoutRoute loadingComponent={<Spinner />} path='/logout' />
        <NotFound sendHome default />
        <Redirect from='/' to={getRedirectPath()} />
      </FullHeightRouter>
    </ErrorBoundary>
  )
}
