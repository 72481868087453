import {
  ComboBox,
  findOption,
  OptionProps,
} from '@awell/ui-kit/components/ComboBox'
import React, { FC } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { StartPathwayFormData } from '../types'

interface StartPathwaySelectProps {
  name: string
  formMethods: UseFormMethods<StartPathwayFormData>
  label: string
  errorText: string
  options: OptionProps[]
  required?: boolean
  onChange?: (value: string) => void
}

export const StartPathwaySelect: FC<StartPathwaySelectProps> = ({
  name,
  formMethods,
  label,
  errorText,
  options,
  required = true,
  onChange = () => undefined,
}) => {
  const handleChange = (onSelected: (...event: unknown[]) => void) => (
    value: string,
  ) => {
    onSelected(value)
    onChange(value)
  }
  const hasError = Object.keys(formMethods.errors).includes(name)
  return (
    <Controller
      name={name}
      control={formMethods.control}
      defaultValue=''
      rules={{ required }}
      render={({ onChange: internalOnChange, value }) => {
        const selectedOption = findOption(options, value)
        return (
          <ComboBox
            options={options}
            onChange={(option: OptionProps | OptionProps[] | null) => {
              if (option && !Array.isArray(option)) {
                handleChange(internalOnChange)(option?.value)
              }
            }}
            selectedOption={selectedOption}
            id={name}
            label={label}
            compact
            fullWidth
            error={hasError}
            errorMessage={errorText}
            required={required}
          />
        )
      }}
    />
  )
}
