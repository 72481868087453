import React, { FC } from 'react'
import { useStyles } from './useStyles'

export interface CalculationProps {
  calculationOutcome: JSX.Element
  resultItems: JSX.Element[] | JSX.Element
}

export const CalculationPreview: FC<CalculationProps> = ({
  calculationOutcome,
  resultItems,
}) => {
  const classes = useStyles()

  return (
    <>
      <div>{calculationOutcome}</div>
      <div className={classes.calculationDataTabs}>
        <div>{resultItems}</div>
      </div>
    </>
  )
}

CalculationPreview.displayName = 'CalculationPreview'
