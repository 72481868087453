import { compose, defaultTo, find } from 'lodash/fp'
import { type OptionProps } from './ComboBox'

export function findOption(
  options: Array<OptionProps>,
  value: string | undefined | null,
): OptionProps {
  return compose(
    defaultTo(null), // defaulting to null to avoid uncontrolled to controlled component error
    find(['value', value]),
  )(options)
}
