// @ts-nocheck TODO: remove this comment and fix errors
import { RouteComponentProps, useNavigate } from '@reach/router'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@awell/ui-kit/components/Button'
import {
  AlertModal,
  AlertModalLabels,
} from '@awell/ui-kit/components/AlertModal'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { FormBlock } from '../../../components/FormBlock'
import { useDeletePatient } from '../../../hooks/useDeletePatient'

interface SettingsTabProps {
  patientId: string
  patientName: string
}

export const SettingsTab: FC<RouteComponentProps<SettingsTabProps>> = ({
  patientId,
  patientName,
}) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const deletePatientModalLabels: AlertModalLabels = {
    heading: t('delete_patient_heading', {
      patientName,
    }),
    primaryAction: t('delete'),
    secondaryAction: t('cancel'),
  }
  const navigate = useNavigate()
  const { deletePatient } = useDeletePatient()

  const handleDelete = async () => {
    const success = await deletePatient(patientId)
    if (success) navigate(`/patients`)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <FormBlock title={t('delete_patient')}>
        <Button onClick={() => setModalOpen(true)} appearance='danger'>
          {t('delete_patient')}
        </Button>
      </FormBlock>

      <AlertModal
        labels={deletePatientModalLabels}
        open={modalOpen}
        onAction={handleDelete}
        onClose={handleModalClose}
        appearance='danger'
      >
        <Type variant={TypeVariants.regular}>
          {t('delete_patient_confirmation', {
            patientName,
          })}
        </Type>
      </AlertModal>
    </>
  )
}

SettingsTab.displayName = 'SettingsTab'
