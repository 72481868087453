import { ActivityObjectType } from '@awell/ui-kit/generated/types-design'
import { capitalize, lowerCase } from 'lodash'

interface UseActivityTypesHook {
  activityTypes: Array<{
    value: ActivityObjectType
    label: string
  }>
}

interface useActivityTypesProps {
  includeSystemActivities?: boolean
}

export const useActivityTypes = ({
  includeSystemActivities = true,
}: useActivityTypesProps): UseActivityTypesHook => {
  const allActivityTypes = Object.values(ActivityObjectType).map(
    activityType => {
      return {
        value: activityType,
        label:
          ActivityObjectType.PluginAction === activityType
            ? 'Extension Action'
            : capitalize(lowerCase(activityType)),
      }
    },
  )

  if (!includeSystemActivities) {
    const NON_SYSTEM_ACTIVITIES = [
      ActivityObjectType.ApiCall,
      ActivityObjectType.Calculation,
      ActivityObjectType.Checklist,
      ActivityObjectType.ClinicalNote,
      ActivityObjectType.EmrReport,
      ActivityObjectType.Form,
      ActivityObjectType.Message,
      ActivityObjectType.PluginAction,
    ]

    return {
      activityTypes: allActivityTypes
        .filter(activityType =>
          NON_SYSTEM_ACTIVITIES.includes(activityType.value),
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    }
  }

  return {
    activityTypes: allActivityTypes,
  }
}
