export const sharedTypePolicies = {
  // IMPORTANT
  // Given that some activity objects share the same id (example: 'Checklist' object shares id with underlying 'Action; object),
  // we have to tell GraphQL cache that it has to take id & type into account when identifying this object.
  ActivityObject: {
    keyFields: ['id', 'type'],
  },
  ActivitySubject: {
    keyFields: ['id', 'type'],
  },
}
