import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  labelContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  label: {
    marginBottom: spacing.xxxs,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  fullWidth: {
    width: '100%',
  },
  autoCompleteInputRoot: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: spacing.xxxs,
    borderColor: ({ error }: { error: boolean }) =>
      error ? colors.signalError100 : colors.neutralLight40,
    backgroundColor: colors.neutralLight10,
    paddingLeft: 0,
    '&:focus, &:active': {
      borderColor: ({ error }: { error: boolean }) =>
        error ? colors.signalError100 : colors.brand100,
      backgroundColor: colors.neutralLight0,
    },
    '& > fieldset': {
      display: 'none',
    },
    textTransform: 'none',
  },
  focused: {
    '& > div > div': {
      borderColor: ({ error }: { error: boolean }) =>
        error ? colors.signalError100 : colors.brand100,
      backgroundColor: colors.neutralLight0,
    },
  },
  requiredFieldMarker: {
    color: colors.red100,
    marginLeft: spacing.xxxs,
  },
})
