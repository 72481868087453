import * as React from 'react'

function SvgError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.414 3.414a2 2 0 00-2.828 0l-7.071 7.071a2 2 0 000 2.829l7.07 7.07a2 2 0 002.83 0l7.07-7.07a2 2 0 000-2.829l-7.07-7.07zM11 6h2v7h-2V6zm0 9h2v2h-2v-2z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgError
