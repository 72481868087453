import * as React from 'react'

function SvgVideo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 21a9 9 0 100-18 9 9 0 000 18z'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 8l6 4-6 4V8z' fill='currentColor' />
    </svg>
  )
}

export default SvgVideo
