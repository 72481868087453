import { add, find, findIndex, isEmpty, compose } from 'lodash/fp'
import React, {
  createContext,
  type FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  type OnBeforeCaptureResponder,
  type OnDragEndResponder,
} from 'react-beautiful-dnd'
import { isNil, noop } from 'lodash'
import { MultipleSelectOptionsEditorContext } from './builderContexts/useMultipleSelectQuestionOptions'
import { type UserQuestionType, type Question, type FormattedJson } from './types'
import { type Maybe } from '../../utils/Maybe'
import { SimpleJsonEditor } from '../SimpleJsonEditor'

interface Labels {
  library_header: string
  form_title_placeholder: string
  question_title_placeholder: string
  show_close_configuration_label: string
  show_open_configuration_label: string
  step_value_label: string
  min_label: string
  max_label: string
  min_label_placeholder: string
  max_label_placeholder: string
  value_tooltip_on_label: string
  display_mark_label: string
  show_min_max_values_label: string
  show_question_key_label: string
  recode_values_label: string
  mandatory_question_label: string
  use_select_question_label: string
  option_label_placeholder: string
  option_value_error_message: string
  dropzone_label: string
  yes: string
  no: string
  min: string
  max: string
  add_range: string
  allowed_dates: string
  allowed_dates_options: {
    all: string
    past: string
    future: string
  }
  include_date_of_response: string
  add_selection_limits: string
  min_selectable: string
  max_selectable: string
}

export interface FormBuilderContextInterface {
  hasQuestions: boolean
  onDragEnd: OnDragEndResponder
  onBeforeCapture: OnBeforeCaptureResponder
  labels: Labels
  draggingQuestionId: string | null
  shouldShowQuestionNumber: boolean
  onDeleteQuestion: (questionId: string) => void
  activeQuestion: Question | null
  setActiveQuestion: (question: Question) => void
  getQuestionNumber: (questionId: string) => number
  onDuplicateQuestion: (questionId: string) => Promise<void>
  MetadataEditor: FC<{
    id: string
    value: Maybe<FormattedJson>
    onChange: (metadata: FormattedJson) => Promise<void>
  }>
}

const initialContext = {
  hasQuestions: false,
  onDragEnd: noop,
  onBeforeCapture: noop,
  labels: {
    step_value_label: 'Step value',
    min_label: 'Min value and label',
    max_label: 'Max value and label',
    min_label_placeholder: 'Min label',
    max_label_placeholder: 'Max label',
    value_tooltip_on_label: 'Slider value tooltip visible',
    display_mark_label: 'Display tick marks',
    show_min_max_values_label: 'Show min and max values',
    library_header: 'Pick a question',
    form_title_placeholder: 'Enter your form title here',
    question_title_placeholder: 'What would you like to ask ?',
    show_close_configuration_label: 'Question options',
    show_open_configuration_label: 'Hide options',
    show_question_key_label: 'Change question key',
    recode_values_label: 'Recode answer value(s)',
    mandatory_question_label: 'Answer is required',
    use_select_question_label: 'Use select input for answers',
    option_label_placeholder: 'Enter an answer here',
    option_value_error_message: 'Only numbers are allowed',
    restart_preview_label: 'Restart form preview',
    dropzone_label:
      'Drag and drop elements from the sidebar to build your form',
    yes: 'Yes',
    no: 'No',

    min: 'Min',
    max: 'Max',
    add_range: 'Add range',
    allowed_dates: 'Allowed dates',
    allowed_dates_options: {
      all: 'All dates',
      past: 'Past dates',
      future: 'Future dates',
    },
    include_date_of_response: 'Include date of response',
    add_selection_limits: 'Add selection limits',
    min_selectable: 'Minimum selectable',
    max_selectable: 'Maximum selectable',
  },
  setActiveQuestionId: noop,
  onDeleteQuestion: noop,
  onDuplicateQuestion: async () => {
    await Promise.resolve()
  },
  setActiveQuestion: noop,
  getQuestionNumber: () => 1,
  shouldShowQuestionNumber: false,
  activeQuestion: null,
  draggingQuestionId: null,
  MetadataEditor: () => null,
}

export const FormBuilderContext =
  createContext<FormBuilderContextInterface>(initialContext)

interface FormBuilderContextProviderProps {
  questions: Array<Question>
  onAddQuestion: (newQuestion: {
    userQuestionType: UserQuestionType
    destinationIndex: number
  }) => Promise<Question | undefined>
  onReorderQuestion: (input: {
    questionId: string
    destinationIndex: number
  }) => void
  onDeleteQuestion: (questionId: string) => void
  onDuplicateQuestion: (questionId: string) => Promise<Question | undefined>
  labels?: Labels
  MetadataEditor?: FC<{
    id: string
    value: Maybe<FormattedJson>
    onChange: (metadata: FormattedJson) => Promise<void>
  }>
}

export const FormBuilderContextProvider: FC<
  FormBuilderContextProviderProps
> = ({
  questions,
  onAddQuestion,
  onReorderQuestion,
  onDeleteQuestion,
  onDuplicateQuestion,
  labels = initialContext.labels,
  children,
  MetadataEditor = SimpleJsonEditor,
}) => {
    const [draggingQuestionId, setDraggingQuestionId] = useState<string | null>(
      null,
    )
    const [activeQuestion, setActiveQuestion] = useState<Question | null>(null)

    useEffect(() => {
      if (activeQuestion != null) {
        const question =
          find<Question>(['id', activeQuestion.id])(questions) ?? null
        setActiveQuestion(question)
      }
    }, [questions])

    const onDragEnd: OnDragEndResponder = ({
      draggableId,
      source,
      destination,
    }) => {
      if (destination === null) {
        return
      }
      if (
        destination?.droppableId === 'build' &&
        source.droppableId === 'build'
      ) {
        onReorderQuestion({
          questionId: draggableId,
          destinationIndex: destination.index,
        })
      }

      if (
        source.droppableId === 'library' &&
        destination?.droppableId === 'build'
      ) {
        const userQuestionType = draggableId as UserQuestionType

        onAddQuestion({
          userQuestionType,
          destinationIndex: destination.index,
        })
          .then(newQuestion => {
            if (isNil(newQuestion)) return
            setActiveQuestion(newQuestion)
          })
          .catch(() => {
            // do nothing
          })
      }
      setDraggingQuestionId(null)
    }
    const onBeforeCapture: OnBeforeCaptureResponder = beforeCapture => {
      setDraggingQuestionId(beforeCapture?.draggableId)
    }

    const getQuestionNumber = (questionId: string): number =>
      compose(add(1), findIndex<Question>(['id', questionId]))(questions)

    const hasQuestions = !isEmpty(questions)

    const handleDuplicateQuestion = async (): Promise<void> => {
      if (activeQuestion == null) return
      const newQuestion = await onDuplicateQuestion(activeQuestion.id)
      setActiveQuestion(newQuestion ?? null)
    }

    return (
      <FormBuilderContext.Provider
        value={{
          hasQuestions,
          onBeforeCapture,
          onDragEnd,
          labels,
          draggingQuestionId,
          onDeleteQuestion,
          activeQuestion,
          setActiveQuestion,
          getQuestionNumber,
          shouldShowQuestionNumber: true,
          onDuplicateQuestion: handleDuplicateQuestion,
          MetadataEditor,
        }}
      >
        <MultipleSelectOptionsEditorContext>
          {children}
        </MultipleSelectOptionsEditorContext>
      </FormBuilderContext.Provider>
    )
  }

export const useFormBuilderContext = (): FormBuilderContextInterface =>
  useContext(FormBuilderContext)
