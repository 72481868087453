import {
  type Answer,
  FormContextProvider,
  FormSettingsContextProvider,
} from '@awell/ui-kit/components/FormBuilder'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { FormMode } from '@awell/ui-kit/enums'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormQuery } from '../../hooks/useFormQuery'
import { type AnswerInput, type QuestionRuleResult } from './hooks/types'
import { useEvaluateFormRules } from './hooks/useEvaluateFormRules'

interface FormContainerProps {
  formId: string
  careflowId: string
  mode?: FormMode
  onSubmit?: (formResponse: Array<AnswerInput>) => Promise<void>
  answers?: Array<Answer>
}

export const FormContainer: FC<FormContainerProps> = ({
  children,
  formId,
  careflowId,
  mode = FormMode.FormPreview,
  onSubmit,
  answers,
}) => {
  const { t } = useTranslation()
  const { loading, form } = useFormQuery({ formId, careflowId })
  const [evaluateFormRules] = useEvaluateFormRules(formId)

  const handleEvaluateFormRules = async (
    response: Array<AnswerInput>,
  ): Promise<Array<QuestionRuleResult>> => {
    return await evaluateFormRules(response)
  }

  if (loading) {
    return <Spinner />
  }

  const modifiedQuestions = form?.questions.map(question => {
    return {
      ...question,
      options: question?.options?.map(option => {
        return {
          ...option,
          value: option?.value_string,
        }
      }),
    }
  })

  return (
    <FormSettingsContextProvider
      mode={mode}
      labels={{
        mandatory_error_text: t('form_mandatory_error_text'),
        invalid_error_text: t('form_invalid_error_text'),
        icd_10_description: t('icd_10_classfication_api_description'),
        pagination_currently_shown_items_info: ({
          firstShownQuestion,
          lastShownQuestion,
          totalCount,
        }) =>
          t('pagination_currently_shown_items_info', {
            firstShownQuestion,
            lastShownQuestion,
            totalCount,
          }),
      }}
    >
      <FormContextProvider
        questions={modifiedQuestions ?? []}
        evaluateDisplayConditions={handleEvaluateFormRules}
        onSubmit={onSubmit}
        answers={answers}
        trademark={form?.trademark}
      >
        {children}
      </FormContextProvider>
    </FormSettingsContextProvider>
  )
}

FormContainer.displayName = 'FormContainer'
