import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  titleRoot: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  closeIconButton: {
    position: 'absolute',
    top: spacing.xxxs,
    right: spacing.xxxs,
  },
  paper: {
    width: '100%',
    padding: spacing.m,
    margin: `${spacing.s}`,
    overflowX: 'hidden',
  },
  contentRoot: {
    padding: `${spacing.xs} 0`,
    flex: 'none',
    overflowY: 'unset',
    '&:first-child': {
      paddingTop: '0 !important',
    },
  },
  bottomActionsRoot: {
    marginTop: spacing.xs,
    display: 'flex',
    padding: 0,
  },
  formWrapper: {
    height: '100%',
  },
})
