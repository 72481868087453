import { colors, rounded, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

const HEIGHT_IN_PX_OF_PAGE_TOP_TO_TAB_CONTENT_TOP = 264

export const useStyles = makeStyles({
  panel: {
    backgroundColor: colors.neutralLight20,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabButtons: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  dashboardItems: {
    width: '100% !important',
    overflowY: 'auto',
    // to ensure any dashboards iframes are scrollable, we want to set a height on its bounding box
    // 300px is the height of the page and tab headers (264px) plus 36px for additional padding
    height: `calc(100vh - ${HEIGHT_IN_PX_OF_PAGE_TOP_TO_TAB_CONTENT_TOP}px - 36px)`,
  },
  noDashboardText: {
    textAlign: 'center',
    paddingTop: spacing.l,
  },
  pathwaySubtitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing.xxs,
  },
  separator: {
    marginLeft: spacing.xxs,
  },
  jsonContainer: {
    ...rounded.m,
    background: colors.neutralLight20,
    marginTop: spacing.xxs,
    padding: spacing.s,
  },
  addTrackInfoCard: {
    paddingTop: spacing.xs,
  },
  modal_content: {
    minWidth: '400px',
    minHeight: '200px',
  },
})
