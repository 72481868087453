/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

const useStyles = makeStyles({
  form: {
    display: 'grid',
    gridGap: spacing.xs,
  },
})

interface FormProps {
  onSubmit?: () => void
}

export const Form: FC<FormProps> = ({ children, onSubmit = () => {} }) => {
  const classes = useStyles()

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {children}
    </form>
  )
}

Form.displayName = 'Form'
