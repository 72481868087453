import React, { useState, useEffect, type FC } from 'react'
import type { Organization } from '@stytch/vanilla-js'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { useStytchB2BClient as useStytchB2BClientNext } from '@stytch/nextjs/b2b'
import { isNil } from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook'
import { SelectOrganizationModal } from './SelectOrganizationModal'

interface Props {
  onSwitch: () => void | Promise<void>
  environment: string
}

/**
 * Switch deftly between organizaations like a boss. ctrl-alt-shift-t.
 */

export const OrganizationSwitcher: FC<Props> = ({
  environment,
  children,
  onSwitch,
}) => {
  const [discoveredOrganizations, setDiscoveredOrganizations] = useState<
    Array<Organization>
  >([])
  const [showTenantSwitchModal, setShowTenantSwitchModal] =
    useState<boolean>(false)
  let stytch
  try {
    stytch = useStytchB2BClient()
  } catch (err) {
    stytch = useStytchB2BClientNext()
  }

  const discoverStytchOrganizations = async (): Promise<void> => {
    const response = await stytch.discovery.organizations.list()
    const availableOrganizations = response.discovered_organizations
      .map(({ organization }) => organization)
      .filter(organization => {
        if (
          isNil(organization.trusted_metadata.tenant_ids) ||
          typeof organization.trusted_metadata.tenant_ids !== 'object'
        ) {
          return false
        }
        const tenantIds = organization.trusted_metadata.tenant_ids as Record<
          string,
          string
        >
        const isLocalEnvironment = /local.*/.test(environment)
        const hasTenantInEnvironment = Object.keys(tenantIds).includes(environment)
        return isLocalEnvironment || hasTenantInEnvironment
      })
    setDiscoveredOrganizations(availableOrganizations)
  }

  const session = stytch.session.getInfo().session

  useEffect(() => {
    if (session !== null) {
      void discoverStytchOrganizations()
    }
  }, [session])

  useHotkeys(
    'Ctrl+Shift+Alt+t',
    () => {
      setShowTenantSwitchModal(true)
    },
    { preventDefault: true },
  )

  const switchOrganization = async (organizationId: string): Promise<void> => {
    await stytch.session.exchange({
      organization_id: organizationId,
      session_duration_minutes: 60 * 12,
    })
    void onSwitch()
  }

  return (
    <>
      {showTenantSwitchModal && (
        <SelectOrganizationModal
          organizations={discoveredOrganizations}
          switchOrganization={switchOrganization}
          onClose={() => {
            setShowTenantSwitchModal(false)
          }}
        />
      )}
      {children}
    </>
  )
}
