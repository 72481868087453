import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { isNil } from 'lodash'
import React, { type FC, Fragment } from 'react'
import { type Maybe } from '../../utils'
import { spacing } from '../../utils/style-guide'
import { Type, TypeVariants } from '../Typography'
import { type BaselineDataPoint, DataPointValueType } from './types'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: spacing.xs,
    marginLeft: spacing.xl,
    flexDirection: 'column',
    rowGap: spacing.xxxs,
  },
})

interface Props {
  baselineDataPoints?: Array<BaselineDataPoint>
}

export const BaselineDataPoints: FC<Props> = ({ baselineDataPoints = [] }) => {
  const classes = useStyles()

  const formatDate = (date: string) =>
    format(new Date(date), 'EEE, MMM d, y, h:mma')

  const getDataPointValue = ({
    value,
    valueType,
  }: {
    value: Maybe<string>
    valueType: DataPointValueType
  }): string => {
    if (isNil(value)) {
      return '-'
    }
    if (valueType === DataPointValueType.Date) {
      return formatDate(value)
    }
    return value
  }

  return (
    <div className={classes.container}>
      {baselineDataPoints.map(
        ({ definition: { id, title, valueType }, value }) => (
          <Fragment key={id}>
            <Type variant={TypeVariants.small}>{title}</Type>
            <Type variant={TypeVariants.h4}>
              {getDataPointValue({ value, valueType })}
            </Type>
          </Fragment>
        ),
      )}
    </div>
  )
}
