import * as React from 'react'

function SvgExclamationMark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 6h-2v8h2V6zm0 10h-2v2h2v-2z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgExclamationMark
