import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

export const getInputErrorMessage = (
  errors: Record<string, any>,
  inputName: string,
): string | undefined => {
  const { t } = useTranslation('translation')
  if (
    errors[inputName]?.type === 'validate' &&
    !isEmpty(errors[inputName]?.message)
  ) {
    return t('input_error_invalid_named', {
      input: errors[inputName].message,
      ns: 'translation',
    })
  }
  if (!isEmpty(errors[inputName]?.message)) {
    return errors[inputName].message
  }
  if (errors[inputName]?.type === 'required') {
    return t('input_error_mandatory', { ns: 'translation' })
  }
  if (errors[inputName]?.type === 'validate') {
    return t('input_error_invalid', { ns: 'translation' })
  }
}
