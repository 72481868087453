import { useNotifications } from '../useNotifications'
import {
  type PublishedPathwayDefinition,
  usePublishedPathwayDefinitions,
} from '../usePublishedPathwayDefinitions'
import { isEmpty, isNil } from 'lodash'

interface UsePublishedPathwayDefinitionHook {
  loading: boolean
  publishedPathwayDefinition?: PublishedPathwayDefinition
}

export const usePublishedPathwayDefinition = (
  pathwayDefinitionId: string,
): UsePublishedPathwayDefinitionHook => {
  const { notifyError } = useNotifications()
  const { publishedPathwayDefinitions, loading } =
    usePublishedPathwayDefinitions()

  if (loading) {
    return { loading: true }
  }

  if (
    isNil(publishedPathwayDefinitions) ||
    isEmpty(publishedPathwayDefinitions)
  ) {
    notifyError({
      message: 'Something went wrong while loading the pathway definition',
    })

    return { loading: false }
  }

  const publishedPathwayDefinition = publishedPathwayDefinitions.find(
    definition => definition.id === pathwayDefinitionId,
  )

  return {
    loading: false,
    publishedPathwayDefinition,
  }
}
