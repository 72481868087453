// @ts-nocheck TODO: remove this comment and fix errors
import { Button } from '@awell/ui-kit/components/Button'
import { findOption, OptionProps } from '@awell/ui-kit/components/ComboBox'
import { useFormStyles } from '@awell/ui-kit/components/Form'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { Type, TypeVariants } from '@awell/ui-kit/components/Typography'
import { FadeProps, useTheme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Fade from '@material-ui/core/Fade'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import { isEmpty, map, omit } from 'lodash'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePublishedPathwayDefinitions } from '../../hooks/usePublishedPathwayDefinitions'
import { FormBlock } from '../FormBlock'
import { StartPathwaySelect } from './FormInputs'
import { StartingDataPoints } from './StartingDataPoints'
import { DataPointInput, StartPathwayFormData } from './types'
import { useStyles } from './useStyles'

export interface StartPathwayLabels {
  heading: string
  primaryAction: string
  secondaryAction: string
}

interface StartPathwayProps {
  labels?: StartPathwayLabels
  onAction: (data: StartPathwayFormData) => void
  onClose: () => void
  open: boolean
}

const Transition = React.forwardRef<unknown, FadeProps>(function Transition( // using named function to avoid display name eslint error
  props,
  ref,
) {
  return <Fade in ref={ref} {...props} />
})

export const StartPathwayModal: FC<StartPathwayProps> = ({
  labels,
  onClose,
  onAction,
  open,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const { inputFieldRow, oneInputFullRow } = useFormStyles()
  const { t } = useTranslation()
  const formMethods = useForm<StartPathwayFormData>({
    defaultValues: { pathway_definition_id: '', data_points: [] },
  })
  const {
    loading,
    publishedPathwayDefinitions,
  } = usePublishedPathwayDefinitions()

  const [pathwayDefinitionId, setPathwayDefinitionId] = useState<string>()

  if (loading) {
    return <Spinner />
  }

  const onSubmit = () => {
    formMethods.handleSubmit((data: Record<string, string>) => {
      // TODO: instead of this mapping below, we should be able to do nested fields array form but need to figure that out
      const toDataPointInput = (
        value: string,
        key: string,
      ): DataPointInput => ({
        data_point_definition_id: key,
        value,
      })

      const withoutPathway = omit(data, ['pathway_definition_id'])
      const baselineInfo: DataPointInput[] = map(
        withoutPathway,
        toDataPointInput,
      )
      const data_points = baselineInfo.filter(({ value }) => !isEmpty(value))
      onAction({
        pathway_definition_id: data.pathway_definition_id,
        data_points,
      })
    })()
  }

  const pathwayOptions: Array<OptionProps> = publishedPathwayDefinitions.map(
    ({ id, title }) => ({
      label: title,
      value: id,
    }),
  )

  const selectedPathwayOption = findOption(pathwayOptions, pathwayDefinitionId)
  const selectedPathwayDefinition = publishedPathwayDefinitions.find(
    ({ id }) => id === selectedPathwayOption?.value,
  )
  const hasStartingDataPoints =
    selectedPathwayDefinition?.dataPointDefinitions.length > 0

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby='start-pathway-slide-title'
      aria-describedby='start-pathway-slide-description'
      classes={{
        container: classes.backdrop,
        paper: classes.modal,
      }}
    >
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div id='alert-dialog-slide-title' className={classes.header}>
          <div className={classes.title}>
            <Type variant={TypeVariants.h4}>{labels.heading}</Type>
          </div>
        </div>
        <DialogContent>
          <FormBlock title={t('select_pathway')}>
            <div className={classNames(inputFieldRow, oneInputFullRow)}>
              <StartPathwaySelect
                name='pathway_definition_id'
                formMethods={formMethods}
                options={pathwayOptions}
                label={t('pathway')}
                onChange={setPathwayDefinitionId}
                errorText={t('form_builder_mandatory_error_text')}
              />
            </div>
          </FormBlock>
          {hasStartingDataPoints && (
            <StartingDataPoints
              formMethods={formMethods}
              dataPointDefinitions={
                selectedPathwayDefinition?.dataPointDefinitions
              }
            />
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.rootFooter }}>
          <Button color='primary' onClick={onClose}>
            {labels.secondaryAction}
          </Button>
          <Button onClick={onSubmit}>{labels.primaryAction}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
