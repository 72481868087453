import * as React from 'react'

function SvgAction(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path fill='#fff' d='M0 0h24v24H0z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm0 4h4v16H4V4zm16 3H10v4h10V7zm0 6H10v4h10v-4z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgAction
