import * as React from 'react'

function SvgChevronDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M10.586 14.414c.74.74 1.915.78 2.701.117l.127-.117 4.293-4.293a1 1 0 00-1.32-1.497l-.094.083L12 13 7.707 8.707a1 1 0 00-1.497 1.32l.083.094 4.293 4.293z'
      />
    </svg>
  )
}

export default SvgChevronDown
