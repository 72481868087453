import * as React from 'react'

function SvgMore(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M6 10a2 2 0 11.001 3.999A2 2 0 016 10zm6 0a2 2 0 11.001 3.999A2 2 0 0112 10zm6 0a2 2 0 11.001 3.999A2 2 0 0118 10z'
      />
    </svg>
  )
}

export default SvgMore
