import { makeStyles } from '@material-ui/core'
import transitions from '@material-ui/core/styles/transitions'
import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  card: {
    width: '400px',
    padding: 0,
    pointerEvents: 'all',
  },
  warning: {
    '& svg': {
      backgroundColor: colors.signalWarning100,
      color: colors.neutralLight0,
    },
  },
  error: {
    '& svg': {
      backgroundColor: colors.signalError100,
      color: colors.neutralLight0,
    },
  },
  success: {
    '& svg': {
      backgroundColor: colors.signalSuccess100,
      color: colors.neutralLight0,
    },
  },
  informative: {
    '& svg': {
      backgroundColor: colors.signalInformative100,
      color: colors.neutralLight0,
    },
  },
  actions: {
    padding: `${spacing.xs} !important`,
    '& button': {
      padding: 0,
    },
  },
  notificationIcon: {
    minWidth: spacing.s,
    '& svg': {
      borderRadius: spacing.xxxs,
    },
  },
  message: {
    flexGrow: 1,
    ...spacing.paddingX.xxs,
    marginLeft: spacing.xxs,
  },
  expandIcon: {
    ...spacing.padding.xxs,
    transform: 'rotate(0deg)',
    transition: transitions.create('transform'),
  },
  expandIconOpen: {
    transform: 'rotate(180deg)',
  },
  expanded: {
    paddingBottom: spacing.xs,
    paddingLeft: spacing.xxl, // To keep content aligned with title
    paddingRight: spacing.xs, // To keep content aligned with title
  },
  content: {},
  contentActions: {
    marginTop: spacing.xxs,
  },
})
