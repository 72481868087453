import { isNil } from 'lodash'
import { lastIndexOf, truncate } from 'lodash/fp'

interface TruncateInsideOptions {
  max_length?: number
  max_end_length?: number
  omission?: string
}

export const DEFAULT_TRUNCATE_INSIDE_MAX_LENGTH = 25
export const DEFAULT_TRUNCATE_INSIDE_END_LENGTH = 12
export const DEFAULT_TRUNCATE_INSIDE_OMISSION = '…'

/**
 * Curried function that truncates string by keeping last word and punctuation sign
 * @optional options: { max_length: 45, max_end_length: 10, omission: '... '}
 * Example:
 * Long phrases are easily recollected when there is last word shown?
 * Long phrases are easily recolle... shown?
 *
 * Long phrases are easily recollected when there is longlastword?
 * Long phrases are easily rec... glastword?
 */
export const truncateInside =
  (options?: TruncateInsideOptions) =>
  (text?: string): string => {
    if (isNil(text)) {
      return ''
    }
    const opts = {
      max_length: DEFAULT_TRUNCATE_INSIDE_MAX_LENGTH,
      max_end_length: DEFAULT_TRUNCATE_INSIDE_END_LENGTH,
      omission: DEFAULT_TRUNCATE_INSIDE_OMISSION, // single character (ellipsis)
      ...options, // override defaults
    }
    const errors: Array<string> = []
    const { max_length, max_end_length, omission } = opts
    if (text.length <= max_length) {
      return text
    }
    if (max_length < max_end_length) {
      errors.push(
        'In the options object for truncateInside, max_length must be greater than max_end_length',
      )
    }
    if (omission.length >= max_length) {
      errors.push(
        'In the options object for truncateInside, omission string length must be less than max_length',
      )
    }
    if (errors.length > 0) {
      throw new Error(errors.join(', '))
    }
    const lastPart = text.slice(-max_end_length)
    const last_word_index = lastIndexOf(' ', lastPart) + 1
    const ending = lastPart.slice(last_word_index)
    const firstPart = truncate({
      length: max_length - ending.length - omission.length + 1,
      omission,
    })(text)
    return firstPart + ending
  }
