import { FullHeightRouter } from '@awell/libs-web/routing'
import { Redirect, RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import { CareAppPageLayout } from '../components/CareAppPageLayout'
import { currentPageTitleVar } from '../local-state'
import { LogsPage } from '../pages/Logs'

// TODO when home route gets re-enabled we need to redirect to clinical app here like in PathwaysRoute
export const LogsRoute: FC<RouteComponentProps> = () => {
  currentPageTitleVar('Awell Care Logs')
  return (
    <FullHeightRouter primary={false}>
      <CareAppPageLayout path='/'>
        <LogsPage path='/*' />
        <Redirect from='/' to='webhooks' />
      </CareAppPageLayout>
    </FullHeightRouter>
  )
}

LogsRoute.displayName = 'LogsPage'
