import { makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'

export const defaultHeadingColor = colors.neutralDark800

export const useStyles = makeStyles(() => ({
  hugeHeadline: {
    fontSize: '2.286rem', // 32px
    lineHeight: '2.857rem', // 40px
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
  },
  bigHeadline: {
    fontSize: '2rem', // 28px
    lineHeight: '2.286rem', // 32px
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
  },
  headline: {
    fontSize: '1.714rem', // 24rem
    lineHeight: '2rem', // 28px
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
  },
  subHeadline: {
    fontSize: '1.286rem', // 18px
    fontWeight: 500,
    lineHeight: '1.714rem', // 24px
    color: colors.neutralDark800,
    margin: 0,
  },
  smallHeadline: {
    fontSize: '1.143rem', // 16px
    lineHeight: '1.429rem', // 20px
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
  },
  tinyHeadline: {
    fontSize: '1rem', // 14px
    lineHeight: '1.143rem', // 16px
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
  },
  tinyHeadlineCaps: {
    fontSize: '0.857rem', // 12px
    lineHeight: '1.143rem', // 16px
    fontWeight: 700,
    textTransform: 'uppercase',
    color: colors.neutralDark800,
    margin: 0,
  },
  discreetHeadline: {
    fontSize: '0.857rem', // 12px
    lineHeight: '1.143rem', // 16px
    fontWeight: 700,
    color: colors.neutralMid400,
    margin: 0,
  },
  undersizeHeadline: {
    fontSize: '0.857rem', // 12px
    lineHeight: '1.143rem', // 16px
    fontWeight: 700,
    color: colors.neutralMid400,
    margin: 0,
  },
  // Normal text styles start

  textRegular: {
    fontSize: '14px',
    lineHeight: '1.429rem',
    fontWeight: 400,
    color: colors.neutralDark800,
    margin: 0,
    wordBreak: 'break-word',
  },
  textMedium: {
    fontSize: '14px',
    lineHeight: '1.429rem',
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
    wordBreak: 'break-word',
  },
  textSmall: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: colors.neutralDark800,
    margin: 0,
  },
  textSmallMedium: {
    fontSize: '12px',
    lineHeight: '1.143rem',
    fontWeight: 500,
    color: colors.neutralDark800,
    margin: 0,
  },
  textSmallBold: {
    fontSize: '12px',
    lineHeight: '1.143rem',
    fontWeight: 700,
    color: colors.neutralDark800,
    margin: 0,
  },
  textTinyHint: {
    fontSize: '9px',
    lineHeight: '9px',
    fontWeight: 400,
    color: colors.neutralDark800,
    margin: 0,
  },
  inputPlaceholder: {
    fontWeight: 400,
    fontSize: '1rem', // 14px
    lineHeight: '1.429rem', // 20px
    color: colors.neutralMid200,
    margin: 0,
  },
  link: () => ({
    fontSize: '1rem', // 14px
    lineHeight: '1.429rem',
    fontWeight: 500,
    color: colors.brand100,
    '&:hover': {
      color: colors.blue300,
      textDecoration: 'underline',
      '&> div > svg': {
        color: colors.blue300,
      },
    },
  }),
  linkBold: () => ({
    fontSize: '0.857rem', // 12px
    lineHeight: '1.143rem',
    fontWeight: 700,
  }),
}))
