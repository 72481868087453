import React from 'react'
import { CodeBlock as CodeBlockComponent, github } from 'react-code-blocks'

interface CollapsibleProps {
  text: string
  language?: string
  showLineNumbers?: boolean
}
export const CodeBlock: React.FC<CollapsibleProps> = ({
  text,
  language = 'json',
  showLineNumbers = true,
}) => {
  return (
    <CodeBlockComponent
      text={text}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={github}
      as={undefined}
      forwardedAs={undefined}
    />
  )
}
