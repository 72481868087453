import React, { type FC } from 'react'
import { useStyles } from './useStyles'
import { Button } from '../Button'
import { ActivityCardSummary } from './ActivityCardSummary'
import { type ActivityContent } from '../../compositions/ActivityFeed'

export interface ActivityCardMainProps {
  content: ActivityContent
  hideStatus?: boolean
  completed: boolean
  onSelect: () => void
}

/**
 * Renders the activity summary and the list of events for that activity.
 */
export const ActivityCardMain: FC<ActivityCardMainProps> = ({
  content,
  onSelect,
  completed,
}) => {
  const classes = useStyles()
  const {
    summaryText,
    stepName,
    ActivityIcon,
    timestampLabel,
    timestamp,
    trackName,
    buttonTextComplete,
    buttonTextPending,
  } = content

  return (
    <div className={classes.cardMain}>
      <ActivityCardSummary
        timestampLabel={timestampLabel}
        ActivityIcon={ActivityIcon}
        timestamp={timestamp}
        stepName={stepName}
        trackName={trackName}
      >
        {summaryText}
      </ActivityCardSummary>
      <Button
        onClick={onSelect}
        color={completed ? 'primary' : 'default'}
        style={{ padding: 6, height: 32, minWidth: 140 }}
      >
        {completed ? buttonTextComplete : buttonTextPending}
      </Button>
    </div>
  )
}

ActivityCardMain.displayName = 'ActivityCardMain'
