import { format, isValid, parseISO } from 'date-fns'

/**
 * Formats incoming date in iso format to passed in format(defaults to dd/MM/yy at HH:mm)
 * e.g. 07/04/21 at 11:12 or 07/04/21 at 22:03
 */
export const formatDate = ({
  isoDateStr,
  dateFormat = "dd/MM/yy 'at' HH:mm",
}: {
  isoDateStr: string
  dateFormat?: string
}): string | undefined => {
  const parsedDate = parseISO(isoDateStr)
  if (!isValid(parsedDate)) {
    return undefined
  }
  return format(parsedDate, dateFormat)
}
