import { makeStyles } from '@material-ui/core'
import { spacing, colors, rounded } from '../../utils/style-guide'

export const useStyles = makeStyles({
  title: {
    display: 'flex',
  },
  checklist: {
    paddingLeft: '4px',
    marginTop: spacing.xs,
    marginBottom: spacing.xs,
  },
  checkboxListFormControl: {
    display: 'flex',
  },
  icon: {
    ...rounded.m,
    width: spacing.s,
    height: spacing.s,
    background: colors.neutralLight0,
    border: `2px solid ${colors.neutralDark500}`,
  },
})
