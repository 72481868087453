import React, { FC } from 'react'
import { Fullscreen, Minus, Plus } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Tooltip } from '../Tooltip'
import './dhtmlxGantt/dhtmlxgantt.css'
import './Gantt.css'
import { GanttData, ElementType, GanttLink, GanttFilters } from './types'
import { useDhtmlGantt } from './useDhtmlGantt'
import { useStyles } from './useStyles'

interface GanttProps {
  data: Array<GanttData>
  links: Array<GanttLink>
  onElementClick: (element: GanttData) => void
  filters: GanttFilters
}

export const GanttComponent: FC<GanttProps> = ({
  data,
  links,
  onElementClick,
  filters,
}) => {
  const classes = useStyles()
  const handleElementClick = (element: GanttData) => {
    const clickableElementTypes = [
      ElementType.Action,
      ElementType.Step,
      ElementType.Track,
    ]
    const isClickableElement = clickableElementTypes.includes(
      element.element_type,
    )
    if (isClickableElement) {
      onElementClick(element)
    }
  }
  const { container, zoomIn, zoomOut, zoomToFit, zoomState } = useDhtmlGantt({
    data,
    links,
    filters,
    onTaskSelected: handleElementClick,
    onTaskRowClick: handleElementClick,
  })
  return (
    <div className={classes.ganttContainer}>
      <div ref={container} style={{ width: '100%', height: '100%' }} />
      <div className={classes.controls}>
        <Tooltip
          info={zoomState.zoomInDisabled ? '' : 'Zoom in'}
          placement='left'
        >
          <button
            type='button'
            className={classes.zoomButton}
            onClick={zoomIn}
            disabled={zoomState.zoomInDisabled}
          >
            <IconWrapper size='xs'>
              <Plus />
            </IconWrapper>
          </button>
        </Tooltip>
        <Tooltip
          info={zoomState.zoomOutDisabled ? '' : 'Zoom out'}
          placement='left'
        >
          <button
            type='button'
            className={classes.zoomButton}
            onClick={zoomOut}
            disabled={zoomState.zoomOutDisabled}
          >
            <IconWrapper size='xs'>
              <Minus />
            </IconWrapper>
          </button>
        </Tooltip>
        <Tooltip info='Zoom to fit' placement='left'>
          <button
            type='button'
            className={classes.zoomButton}
            onClick={zoomToFit}
          >
            <IconWrapper size='xs'>
              <Fullscreen />
            </IconWrapper>
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

GanttComponent.displayName = 'Gantt'
