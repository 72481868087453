import { isEmpty } from 'lodash'
import {
  validatePhoneNumberLength,
  parsePhoneNumberWithError,
  type ParseError,
} from 'libphonenumber-js'

interface IsValidInputValueArgs {
  inputValue: string
  inputRequired?: boolean
  inputType?: string
  validateIsOnChange?: boolean
}

enum PhoneNumberErrorCodes {
  TOO_SHORT = 'TOO_SHORT',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
}

/**
 * Checks if the input value is valid, supports partial phone numbers
 * @param {string} inputValue - The value of the input
 * @param {boolean} inputRequired - Whether the input is required
 * @param {string} inputType - The type of the HTML input (e.g. text, tel, email)
 * @param {boolean} validateIsOnChange - validation is happening on input change
 * @returns {boolean} - Whether the input value is valid
 */
export const isValidInputValue = ({
  inputValue,
  inputRequired,
  inputType,
  validateIsOnChange = false,
}: IsValidInputValueArgs): boolean => {
  // skip validation if input is not required and empty
  if (inputRequired === true && isEmpty(inputValue)) {
    return true
  }
  // validate phone number, skip if number is partially entered
  if (!isEmpty(inputValue) && inputType === 'tel') {
    try {
      const parsedNumber = parsePhoneNumberWithError(inputValue)
      // if number can be correctly parsed, we don't want to trigger validation error if it's too short
      return (
        parsedNumber.isValid() ||
        (validateIsOnChange &&
          validatePhoneNumberLength(inputValue) ===
            PhoneNumberErrorCodes.TOO_SHORT)
      )
    } catch (error) {
      // trigger validation if error is INVALID_COUNTRY
      // (i.e. number starts with an invalid country code or without a +)
      if (
        (error as ParseError).message === PhoneNumberErrorCodes.INVALID_COUNTRY
      ) {
        return false
      }
      // skip validation if error is TOO_SHORT
      if (
        validateIsOnChange &&
        (error as ParseError).message === PhoneNumberErrorCodes.TOO_SHORT
      ) {
        return true
      }
      return false
    }
  }
  // don't trigger validation error otherwise
  return true
}
