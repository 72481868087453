import { type RouteComponentProps } from '@reach/router'
import React, { type FC, useEffect } from 'react'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { isNil } from 'lodash'
import { WorkingListTable } from '../../../components/WorkingListTable'
import { usePatient } from '../../../hooks/usePatient'

interface WorkingListForPatientParams {
  patientId: string
}

export const WorkingListForPatient: FC<
  RouteComponentProps<WorkingListForPatientParams>
> = ({ patientId = '' }) => {
  const { addBreadcrumbs, updateBreadcrumb } = useBreadcrumbs()
  const { patient, loading: loadingPatient } = usePatient(patientId)

  const breadcrumb = {
    label:
      !isNil(patient) && !isNil(patient.profile) && !isNil(patient.profile.name)
        ? patient.profile.name
        : patientId,
    url: `/activities/patient/${patientId}`,
    level: 1,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  useEffect(() => {
    updateBreadcrumb(breadcrumb)
  }, [patient])

  if (loadingPatient) {
    return <div>Loading patient</div>
  }

  if (isNil(patient)) {
    return <div>Could not load patient with id {patientId}</div>
  }


  return <WorkingListTable patientId={patientId} />
}
