import { useTranslation } from 'react-i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryAllFailedWebhookCallsForPathwayDefinitionMutation } from './types'

interface UseRetryAllFailedWebhookCallsForPathwayDefinitionHook {
  retryFailedWebhookCallsForPathwayDefinition: () => void
}

export const useRetryAllFailedWebhookCallsForPathwayDefinition = (
  pathwayDefinitionId: string,
): UseRetryAllFailedWebhookCallsForPathwayDefinitionHook => {
  const { notifyError, notify } = useNotifications()
  const { t } = useTranslation()
  const [
    retryWebhooksMutation,
  ] = useRetryAllFailedWebhookCallsForPathwayDefinitionMutation()

  const retryFailedWebhookCallsForPathwayDefinition = async () => {
    try {
      await retryWebhooksMutation({
        variables: {
          input: { pathway_definition_id: pathwayDefinitionId },
        },
      })
      notify({
        message: t('webhook_retry_all_failed_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('error_retry_all_failed_webhook_calls'),
        error,
      })
    }
  }

  return { retryFailedWebhookCallsForPathwayDefinition }
}
