import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  headingRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  activityList: {
    '& > div': {
      marginTop: spacing.xsm,
    },
    '& > div:first-child': {
      marginTop: 0,
    },
  },
})

export const useItemStyles = makeStyles({
  iconWithText: {
    display: 'flex',
    width: 'fit-content',
    '& > div:first-child': {
      marginRight: spacing.xxxs,
    },
  },
  activityBottomDetails: {
    display: 'flex',
    marginTop: spacing.xxxs,
    '& > *': {
      marginRight: spacing.xsm,
    },
  },
})
