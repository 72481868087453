import React, { createContext, type FC, useContext } from 'react'

import {
  type DynamicVariable,
  type Constant,
} from './DynamicVariablePlugin/types'

interface DataVariableContextInterface {
  variables: Array<DynamicVariable>
  constants?: Array<Constant>
  loading?: boolean
  selectedVariableId?: string
  onVariableClick?: (variableId: string) => void
}

const initialValue: DataVariableContextInterface = {
  variables: [],
  constants: [],
  loading: false,
  selectedVariableId: undefined,
  onVariableClick: () => undefined,
}

const DataVariablesContext =
  createContext<DataVariableContextInterface>(initialValue)

export const DataVariableContextProvider: FC<DataVariableContextInterface> = ({
  variables,
  constants = [],
  loading = false,
  selectedVariableId,
  onVariableClick = () => undefined,
  children,
}) => {
  return (
    <DataVariablesContext.Provider
      value={{
        variables,
        constants,
        loading,
        selectedVariableId,
        onVariableClick,
      }}
    >
      {children}
    </DataVariablesContext.Provider>
  )
}

export const useDataVariablesContext = (): DataVariableContextInterface =>
  useContext(DataVariablesContext)
