import { useTranslation } from 'react-i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryApiCallMutation } from './types'

interface useRetryApiCallHook {
  retryApiCall: (id: string) => void
}

export const useRetryApiCall = (): useRetryApiCallHook => {
  const { notifyError, notify } = useNotifications()
  const { t } = useTranslation()
  const [retryApiCallMutation] = useRetryApiCallMutation()

  const retryApiCall = async (apiId: string) => {
    try {
      await retryApiCallMutation({
        variables: {
          input: {
            api_call_id: apiId,
          },
        },
      })
      notify({
        message: t('api_call_retry_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('api_call_retry_error'),
        error,
      })
    }
  }

  return { retryApiCall }
}
