import * as React from 'react'

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.702 17.518a.26.26 0 00-.322-.035 6.538 6.538 0 01-9.815-4.063.263.263 0 00-.255-.206H3.261a.262.262 0 00-.257.31c.753 4.248 4.463 7.475 8.927 7.475a9.027 9.027 0 005.188-1.63.262.262 0 00.036-.4l-1.453-1.451zM13.214 5.311c0 .124.086.227.206.255a6.538 6.538 0 014.063 9.815.26.26 0 00.035.322l1.452 1.452a.262.262 0 00.4-.036A9.025 9.025 0 0021 11.93c0-4.464-3.226-8.174-7.475-8.927a.261.261 0 00-.31.257v2.05zM5.566 10.44a6.547 6.547 0 014.876-4.875.263.263 0 00.206-.255V3.261a.262.262 0 00-.308-.257 9.073 9.073 0 00-7.336 7.334.262.262 0 00.258.309h2.05a.263.263 0 00.254-.206z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgLogo
