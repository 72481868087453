import {
  RadioGroup,
  RadioGroupOption,
} from '@awell/ui-kit/components/FormBuilder/QuestionInput/RadioGroup'
import React, { ChangeEvent, FC } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { StartPathwayFormData } from '../types'

interface StartPathwayRadioGroupProps {
  name: string
  formMethods: UseFormMethods<StartPathwayFormData>
  label?: string
  required?: boolean
  errorText?: string
  options: RadioGroupOption[]
}

export const StartPathwayRadioGroup: FC<StartPathwayRadioGroupProps> = ({
  name,
  formMethods,
  label = '',
  errorText = '',
  required = true,
  options,
}) => {
  const hasError = Object.keys(formMethods.errors).includes(name)

  return (
    <Controller
      name={name}
      control={formMethods.control}
      defaultValue=''
      rules={{ required }}
      render={({ onChange, onBlur, value }) => {
        return (
          <RadioGroup
            options={options}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value)
            }
            onBlur={onBlur}
            value={value}
            name={name}
            label={label}
            error={hasError ? errorText : undefined}
            alignHorizontally
            required={required}
          />
        )
      }}
    />
  )
}
