// @ts-nocheck TODO: remove this comment and fix errors
import { Column } from '@awell/ui-kit/components/Table'
import React, { FC } from 'react'
import { capitalize, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { Button } from '@awell/ui-kit/components/Button'
import { RequestStatus } from '@awell/ui-kit/components/RequestStatus'
import { Text } from '@awell/ui-kit/components/Typography'
import { format } from 'date-fns'
import { getWebhookCallDate } from '@awell/ui-kit/utils/getWebhookCallDate'
import { useRetryWebhookCall } from '../../../hooks/useRetryWebhookCall'
import { WebhookCall } from './types'

interface UseWebhookLogsColumnsParams {
  includePathwayDefinition?: boolean
}

export const useWebhookLogsColumns = ({
  includePathwayDefinition = false,
}: UseWebhookLogsColumnsParams): {
  columns: Array<Column<WebhookCall>>
} => {
  const { t } = useTranslation()
  const { retryWebhook } = useRetryWebhookCall()
  const renderActionRow = ({ id, webhook_name }: WebhookCall) => {
    return (
      <Button
        variant='text'
        color='primary'
        style={{ paddingLeft: '0' }}
        onClick={() => retryWebhook(id, webhook_name)}
      >
        {t('retry')}
      </Button>
    )
  }
  const columns: Array<Column<WebhookCall>> = [
    {
      title: t('webhook'),
      field: 'name',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: ({ webhook_name }) => <Text>{webhook_name}</Text>,
    },
    {
      title: t('patient_id'),
      field: 'patient_id',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: ({ pathway: { patient_id } }) => (
        <LinkCell id={`/patients/${patient_id}`} title={patient_id} />
      ),
    },
    {
      title: t('pathway_id'),
      field: 'pathway.id',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: ({ pathway: { id } }) => (
        <LinkCell id={`/pathway/${id}`} title={id} />
      ),
    },
    {
      title: t('called_at'),
      field: 'called_at',
      sorting: true,
      filtering: false,
      grouping: true,
      searchable: false,
      defaultSort: 'asc',
      width: 150,
      customSort: (webhookCall, nextWebhookCall) => {
        const prevDate = getWebhookCallDate(webhookCall)
        const nextDate = getWebhookCallDate(nextWebhookCall)

        if (new Date(prevDate) > new Date(nextDate)) return -1
        if (new Date(prevDate) < new Date(nextDate)) return 1
        return 0
      },
      // eslint-disable-next-line react/display-name
      render: webhookCall => (
        <Text>
          {format(getWebhookCallDate(webhookCall), 'd-LLL-yyyy K:mm:ss a')}
        </Text>
      ),
    },
    {
      title: t('response'),
      field: 'responses',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      // eslint-disable-next-line react/display-name
      render: ({ responses, status }) =>
        status === 'PENDING' || isEmpty(responses) ? (
          '--'
        ) : (
          <RequestStatus status={responses[responses.length - 1].status} />
        ),
      width: 90,
    },
    {
      title: capitalize(t('actions')),
      field: 'actions',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      render: renderActionRow,
      width: 90,
    },
  ]

  const pathwayDefinitionColumn: Column<WebhookCall> = {
    title: t('pathway_definition_id'),
    field: 'pathway_definition_id',
    sorting: false,
    filtering: false,
    searchable: false,
    grouping: false,
    width: 120,
    // eslint-disable-next-line react/display-name
    render: ({ pathway: { pathway_definition_id } }) => (
      <Text>{pathway_definition_id}</Text>
    ),
  }

  if (includePathwayDefinition) {
    columns.splice(1, 0, pathwayDefinitionColumn)
  }

  return { columns }
}
