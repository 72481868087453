import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  itemRoot: {
    minHeight: spacing.xs,
  },
  missingOppositeContent: {
    '&::before': {
      flex: 0,
      padding: 0,
    },
  },
  longSeparatorRoot: {
    transform: `translateX(${spacing.xl})`,
    position: 'absolute',
    height: 128,
  },
  shortSeparatorRoot: {
    transform: `translateX(${spacing.xl})`,
    position: 'absolute',
    height: 64,
  },
  longConnectorRoot: {
    zIndex: 0,
    backgroundColor: colors.neutralLight40,
    minHeight: spacing.xl,
  },
  shortConnectorRoot: {
    zIndex: 0,
    backgroundColor: colors.neutralLight40,
    minHeight: spacing.xs,
  },
  longContentRoot: {
    zIndex: 2,
    paddingTop: spacing.xxxl,
    paddingBottom: 0,
  },
  shortContentRoot: {
    zIndex: 2,
    paddingTop: spacing.m,
    paddingBottom: 0,
  },
})
