import { type Column } from '../../components/Table'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LinkCell } from '../../components/Table/LinkCell'
import { type ScheduledElement } from './types'
import { capitalize } from 'lodash'

export const useScheduledElementsColumns = (): {
  columns: Array<Column<ScheduledElement>>
} => {
  const { t } = useTranslation()
  const columns: Array<Column<ScheduledElement>> = [
    {
      title: t('element'),
      field: 'name',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: ({ name, id }) => <LinkCell id={`#${id}`} title={name} />,
    },
    {
      title: capitalize(t('type')),
      field: 'type',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 60,
      render: ({ type }) => (type === 'track' ? t('track') : t('step')),
    },
    {
      title: t('scheduled_for'),
      field: 'start_date',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 120,
      // custom sort always by start_date from soonest to latest
      customSort: (a, b) => {
        const aDate = parseISO(a.start_date)
        const bDate = parseISO(b.start_date)
        return aDate > bDate ? 1 : -1
      },
      // eslint-disable-next-line react/display-name
      render: ({ start_date }) => format(parseISO(start_date), `d MMM yyyy, p`),
    },
  ]
  return { columns }
}
