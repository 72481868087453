import { createContext } from 'react'

export const defaultLabels = {
  delete: 'Delete',
  edit: 'Edit',
  done: 'Done',
  add: 'Add Item',
}

export const InteractiveListContext = createContext({
  editing: false,
  onDelete: (itemId: string) => {
    // Default value doesn't do anything
  },
  labels: defaultLabels,
  reorderable: false,
  reorderEnabled: false,
  actionable: false,
})
