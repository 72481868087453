import {
  usePlateEditorRef,
  createPlateEditor,
  PlateEditor,
  usePlateEditorState,
} from '@udecode/plate'

interface UseEditorHook {
  editorRef: PlateEditor
  editor: PlateEditor
  editorState: PlateEditor
}

// For later when we create editor using plugins and compnents in the createPlateEditor function
export const useEditor = (id?: string): UseEditorHook => {
  const editorRef = usePlateEditorRef(id)
  const editorState = usePlateEditorState(id)
  const editor = createPlateEditor()
  return {
    editorRef,
    editor,
    editorState,
  }
}
