import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  type InMemoryCacheConfig,
  type NormalizedCacheObject,
  split,
  type Operation,
} from '@apollo/client'
import { type ErrorLink, onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient as createWebsocketClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import Cookies from 'js-cookie'

export const createClient = ({
  httpUri,
  wsUri,
  onNetworkError = () => undefined,
  extraLinks = [],
  cacheConfig,
}: {
  httpUri: string
  wsUri: string
  onNetworkError?: ErrorLink.ErrorHandler
  extraLinks?: Array<ApolloLink>
  cacheConfig: InMemoryCacheConfig
}): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({ uri: httpUri, credentials: 'include' })

  const errorHandlingLink = onError(response => {
    if (response.networkError != null) {
      onNetworkError(response)
    }
  })

  const authenticationLink = new ApolloLink((operation, forward) => {
    // No need to manually authenticate requests now that we are using Stytch.
    // Authentication is done via cookie instead of a JWT.
    return forward(operation)
  })

  const wsLink: ApolloLink = new GraphQLWsLink(
    createWebsocketClient({
      url: wsUri,
      connectionParams: {
        sessionToken: Cookies.get('stytch_session'),
      },
    }),
  )

  /**
   * if incoming request is subscription the connection needs to go through websocket (i.e. wsLink)
   *
   * Split enables this behavior:
   * if isSubscription(...) = true then wsLink is used
   * if isSubscription(...) = false then defaultLink is used
   */
  const isSubscription = (operation: Operation): boolean => {
    const definition = getMainDefinition(operation.query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  }

  const defaultLink = ApolloLink.from([
    authenticationLink,
    ...extraLinks,
    errorHandlingLink,
    httpLink,
  ])

  const link = split(isSubscription, wsLink, defaultLink)

  const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    cache: new InMemoryCache(cacheConfig),
    link,
    credentials: 'include',
  })

  return client
}
