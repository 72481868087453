import { isEmpty, isNil } from 'lodash'
import React from 'react'
import { colors } from '../../utils/style-guide'
import { ActivityLocationPin, ActivityPerson } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Lozenge, type LozengeColors } from '../Lozenge'
import { InnerText, Text } from '../Typography'
import { Timestamp } from './Timestamp'
import { useStyles } from './useStyles'

interface ActivityCardFooterProps {
  stakeholder?: string
  location?: string
  activityStatus: string
  activityStatusColor: LozengeColors
  timestamp: string
  lastUpdatedTimestamp: string | undefined
}

export const ActivityCardFooter = ({
  stakeholder,
  location,
  activityStatus,
  activityStatusColor,
  timestamp,
  lastUpdatedTimestamp,
}: ActivityCardFooterProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <>
        <div className={classes.footerIcons}>
          {!isEmpty(activityStatus) && (
            <div className={classes.footerItem}>
              <Lozenge
                content={activityStatus}
                color={activityStatusColor}
                compact
              />
            </div>
          )}
          {!isNil(stakeholder) && (
            <div className={classes.footerItem}>
              <IconWrapper inline size='xs' className={classes.footerIcon}>
                <ActivityPerson />
              </IconWrapper>
              <InnerText variant='textRegular' color={colors.neutralMid300}>
                {stakeholder}
              </InnerText>
            </div>
          )}
          {!isNil(location) && (
            <div className={classes.footerItem}>
              <IconWrapper inline size='s' className={classes.footerIcon}>
                <ActivityLocationPin />
              </IconWrapper>
              <Text variant='textRegular' color={colors.neutralMid300}>
                {location}
              </Text>
            </div>
          )}
          <Timestamp
            timestamp={timestamp}
            timestampLabel='activityfeed:activated'
          />
        </div>
      </>
    </div>
  )
}
