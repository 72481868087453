import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  stepButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    justifyItems: 'space-between',
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
  },
  singleProfileOption: {
    ...spacing.paddingX.s,
    ...spacing.paddingY.xxs,
    border: `2px solid ${colors.neutralLight30}`,
    borderRadius: spacing.xxxs,
    marginBottom: spacing.xxs,
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      border: `2px solid ${colors.brand80}`,
    },
  },
  selectedProfile: {
    border: `2px solid ${colors.brand80}`,
  },
  profileDataBottom: {
    display: 'flex',
    justifyItems: 'space-between',
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,
  },
  createPatientStepContainer: {
    width: '100%',
  },
  createPatientFormContainer: {
    maxWidth: '780px',
    margin: 'auto',
    marginTop: spacing.xs,
  },
  createPatientStepListPreviewContainer: {
    width: '30%',
  },
  cancelButton: {
    marginRight: spacing.xs,
  },
  previewModal: {
    minHeight: '530px',
    maxHeight: '530px',
  },
  previewModalPadding: {
    padding: '24px 24px 24px 24px',
  },
  previewDetailsColumn: {
    width: '33%',
  },
  previewHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: -20,
  },
  previewHeaderButtonsContainer: {
    textAlign: 'right',
    display: 'flex',
  },
  previewHeaderIconButtons: {
    padding: 2,
  },
  previewHeaderRoundedButtons: {
    width: 32,
    borderRadius: 16,
    height: 'auto',
  },
  previewChevronContainer: {
    marginRight: 12,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing.m,
  },
  importPatientTitle: {
    marginBottom: spacing.xs,
  },
  importPatientIcon: {
    margin: 'auto',
    marginBottom: spacing.xxs,
  },
  importPatientSuccessIcon: {
    margin: 'auto',
    background: colors.green50,
    borderRadius: 24,
  },
  importPatientTitleDescription: {
    marginBottom: spacing.xs,
    color: colors.neutralMid200,
  },
  importStatusContainer: {
    textAlign: 'center',
    maxWidth: '420px',
  },
  createdPatientAndCtaContainer: {
    textAlign: 'center',
  },
})
