import {
  ListItemText,
  OutlinedInput,
  Select,
  MenuItem,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'
import { Button } from '../Button'
import { Tooltip } from '../Tooltip'
import { ChevronDown } from '../Icons'
import { CheckBoxIcon } from '../SelectionControls'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

export interface OptionProps {
  value: string | number
  label: string
}

export interface SingleSelectWithRadioButtonProps {
  label?: string
  options?: OptionProps[]
  error?: boolean
  compact?: boolean
  id?: string
  onChange: (items: OptionProps | null) => void
  hideClearButton?: boolean
  disabled?: boolean
  disabledTooltip?: string
  defaultSelectedOption?: string
}

export const SingleSelectWithRadioButton: FC<SingleSelectWithRadioButtonProps> = ({
  label = '',
  onChange,
  defaultSelectedOption,
  options,
  error = false,
  compact = false,
  hideClearButton = false,
  disabled = false,
  disabledTooltip = '',
  id,
}) => {
  const [item, setItem] = React.useState<string | null>(null)
  const classes = useStyles({ error })
  const { t } = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)

  useEffect(() => {
    if (defaultSelectedOption) {
      setItem(defaultSelectedOption)
    }
  }, [])

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const {
      target: { value },
    } = event
    const selectedValue = options?.find(o => o.value === value) || null
    onChange(selectedValue)
    setItem(typeof value === 'string' ? value : null)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const isSelected = (option: OptionProps): boolean => option.value === item

  const renderLabel = () => {
    const selectedValue = options?.find(o => o.value === item) || null
    return (
      <div className={classes.labelContainer}>
        <Type variant={TypeVariants.regular}>
          {selectedValue?.label || label}
        </Type>
      </div>
    )
  }

  const handleClear = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setItem(null)
    onChange(null)
    event.stopPropagation()
  }

  return (
    <Tooltip info={disabled ? `${disabledTooltip}` : ''} arrow placement='left'>
      <Select
        labelId={uniqueId('multiple-checkbox-label-')}
        id={id || uniqueId('multiple-checkbox-')}
        displayEmpty
        disabled={disabled}
        value={item}
        key={id || uniqueId('multiple-checkbox-')}
        onChange={handleChange}
        variant='outlined'
        input={<OutlinedInput />}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={renderLabel}
        inputProps={{
          classes: {
            icon: classes.icon,
            outlined: classes.inputRoot,
            root: classnames({
              [classes.inputCompact]: compact,
            }),
          },
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          classes: {
            list: classes.dropdownList,
          },
        }}
        IconComponent={ChevronDown}
      >
        {options?.map(option => (
          <MenuItem
            classes={{ selected: classes.itemSelected }}
            value={option.value}
            key={option.value}
          >
            <CheckBoxIcon checked={isSelected(option)} />
            <ListItemText
              className={classes.optionText}
              primary={option.label}
            />
          </MenuItem>
        ))}
        {!hideClearButton && (
          <div className={classes.buttonsContainer}>
            <Button size='small' onClick={handleClear} variant='text'>
              {t('clear')}
            </Button>
          </div>
        )}
      </Select>
    </Tooltip>
  )
}
