import {
  type UserProfile,
  type UpdatePatientDemographicsQueryInput,
  useRequestPatientDemographicsMutation,
  useUpdatePatientDemographicsQueryMutation,
} from './types'

interface UsePatientDemographicsHook {
  requestPatientDemographics: (patient_identifier: string) => Promise<
    | {
        query_id: string
        total: number
        entry: Array<UserProfile>
        success: boolean
      }
    | undefined
  >
  updatePatientDemographicsQuery: (
    input: UpdatePatientDemographicsQueryInput,
  ) => Promise<void>
}

export const usePatientDemographics = (): UsePatientDemographicsHook => {
  const [requestPatientDemographicsMutation] =
    useRequestPatientDemographicsMutation()

  const [updatePatientDemographicsQueryMutation] =
    useUpdatePatientDemographicsQueryMutation()

  const requestPatientDemographics = async (
    patient_identifier: string,
  ): Promise<
    | {
        total: number
        entry: Array<UserProfile>
        query_id: string
        success: boolean
      }
    | undefined
  > => {
    try {
      const { data } = await requestPatientDemographicsMutation({
        variables: {
          input: {
            patient_identifier,
          },
        },
      })
      const entry = data?.requestPatientDemographics.entry ?? []
      const total = data?.requestPatientDemographics.total ?? 0
      const query_id = data?.requestPatientDemographics.query_id ?? ''
      const status = data?.requestPatientDemographics.status ?? ''

      return {
        total,
        entry,
        query_id,
        success: status === 'success',
      }
    } catch (error) {
      // do nothing at this stage. The error is logged in the DB as well as the user is able to see that in the care app.
    }
    return undefined
  }

  const updatePatientDemographicsQuery = async ({
    created_patient_entry_index,
    created_patient_id,
    query_id,
  }: UpdatePatientDemographicsQueryInput): Promise<void> => {
    try {
      await updatePatientDemographicsQueryMutation({
        variables: {
          input: {
            created_patient_entry_index,
            created_patient_id,
            query_id,
          },
        },
      })
    } catch (error) {
      // do nothing at this stage. The error is logged in the DB as well as the user is able to see that in the care app.
    }
    return undefined
  }
  return {
    requestPatientDemographics,
    updatePatientDemographicsQuery,
  }
}
