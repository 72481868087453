import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spacing.xxs,
  },
  titleText: {
    whiteSpace: 'pre-line',
    flex: 1,
  },
  asterisk: {
    color: colors.red,
    paddingLeft: spacing.xxs,
  },
  dateAndNumberInputWidth: {
    // set by design
    width: '150px',
  },
  shortAndLongInputField: {
    margin: `${spacing.xxs} 0`,
  },
  slider: {
    padding: `${spacing.l} ${spacing.xs} 0`,
  },
  error: {
    paddingTop: 2,
    height: spacing.xs,
    marginTop: 0,
  },
  /* multiple choice start */
  formControl: {
    width: '100%',
  },
  formControlLabel: {
    alignItems: 'flex-start',
    marginLeft: 0,
    '&:not(:last-child)': {
      marginBottom: spacing.xxs,
    },
    '&:is(label)': {
      margin: 0,
    },
  },
  radioCheckboxInputs: {
    margin: `0 ${spacing.xxs} 0 0`,
    '&:is(span, input)': {
      padding: 0,
      marginLeft: '-2px',
    },
  },
  label: {
    display: 'block',
    marginBottom: spacing.xxxs,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  alignHorizontally: {
    paddingRight: spacing.xs,
  },
  horizontalLabel: {
    // labels are slightly spaced from the radio button in case of horizontal align
    marginTop: '2px',
  },
  requiredFieldMarker: {
    color: colors.red100,
    marginLeft: spacing.xxxs,
  },
  content: {
    contentVisibility: 'auto',
    containIntrinsicSize: '200px',
  },
  sublabel: {
    display: 'block',
    color: colors.gray500,
    fontSize: '0.875rem',
    lineHeight: 1.2,
    marginTop: spacing.xxxs,
    '&.disabled': {
      opacity: 0.3,
    },
    fontWeight: 'normal',
    '& > a': {
      color: colors.blue400,
      textDecoration: 'underline',
    },
  },
})
