import getVideoId from 'get-video-id'
import { isNil } from 'lodash'

export function get_video_embed_url(url: string): string | undefined {
  const { id, service } = getVideoId(url)
  // need to have this check on id because we could get back { id: undefined, service: 'vimeo' }
  if (isNil(id)) {
    return undefined
  }
  switch (service) {
    case 'youtube':
      return `https://www.youtube.com/embed/${id}`
    case 'vimeo':
      return `https://player.vimeo.com/video/${id}`
    default:
      return undefined
  }
}
