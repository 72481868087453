import { type FetchPolicy, type ApolloError } from '@apollo/client'
import { useGetFormQuery } from './types'
import type { Form } from './types'
import { useNotifications } from '../useNotifications'

interface UseFormHook {
  loading: boolean
  error?: ApolloError
  form: Form
}

export const useFormQuery = ({
  formId,
  careflowId,
  fetchPolicy = 'cache-first',
}: {
  formId: string
  careflowId: string
  fetchPolicy?: FetchPolicy
}): UseFormHook => {
  const { notifyError } = useNotifications()
  const { data, loading, error } = useGetFormQuery({
    variables: {
      id: formId,
      careflowId,
    },
    fetchPolicy,
  })

  if (error) {
    notifyError({
      error,
      message: 'Something went wrong while loading a form',
    })
  }

  const form = data?.form.form

  return {
    loading,
    error,
    form,
  }
}
