import React from 'react'
import { Informative } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Text } from '../Typography'
import { colors, spacing } from '../../utils/style-guide'
import { useStyles } from './useStyles'

export const InfoCard = ({
  children,
  variant = 'standard',
  showIcon = true,
}: {
  children: string | JSX.Element
  variant?: 'neutral' | 'neutralNoPadding' | 'standard'
  showIcon?: boolean
}): JSX.Element => {
  const customPadding = variant === 'neutralNoPadding' ? '0' : `${spacing.xs} 0`

  const backgroundColor = variant === 'standard' ? undefined : 'none'
  const color = variant === 'standard' ? undefined : colors.neutralLight100
  const padding = variant === 'standard' ? undefined : customPadding
  const customSpacing =
    variant === 'standard' ? `0 0 0 ${spacing.xs}` : `0 0 0 ${spacing.xxs}`

  const classes = useStyles({ backgroundColor, color, padding })
  return (
    <div className={classes.noteContainer}>
      <div className={classes.reminderNote}>
        {showIcon && (
          <IconWrapper
            background
            color={colors.neutralLight0}
            size='s'
            className={classes.noteIconWrapper}
          >
            <Informative />
          </IconWrapper>
        )}
      </div>
      <Text color={`${color ?? ''}`} spacing={customSpacing}>
        {children}
      </Text>
    </div>
  )
}

InfoCard.displayName = 'InfoCard'
