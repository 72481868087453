import { isNil } from 'lodash'
import React, { useEffect, useState, type FC } from 'react'
import { type Stakeholder, type StakeholderItem } from './types'
import { GenericSelectionDialog } from '../GenericSelectionDialog'
import { useTranslation } from 'react-i18next'

interface StakeholderSelectionDialogProps {
  stakeholders: Array<Stakeholder>
  onSelect: (stakeholderId: string) => void
}

export const StakeholderSelectionDialog: FC<
  StakeholderSelectionDialogProps
> = ({ stakeholders, onSelect }) => {
  const { t } = useTranslation()
  const [searchedStakeholders, setSearchedStakeholders] = useState<
    Array<StakeholderItem>
  >([])

  const formatStakeholders = (
    stakeholdersArr: Array<Stakeholder>,
  ): Array<StakeholderItem> => {
    return stakeholdersArr.reduce<Array<StakeholderItem>>(
      (items, { id, label }) => {
        return isNil(label.en) ? items : items.concat({ id, label: label.en })
      },
      [],
    )
  }

  useEffect(() => {
    setSearchedStakeholders(formatStakeholders(stakeholders))
  }, [stakeholders])

  const handleClick = (item: StakeholderItem): void => {
    onSelect(item.id)
  }

  const handleSearch = (query: string): void => {
    const filteredList = stakeholders.filter(el => {
      const searchAttribute = el.label.en ?? ''
      const normalizedQuery = query.toLowerCase()
      return searchAttribute.toLowerCase().includes(normalizedQuery)
    })
    setSearchedStakeholders(formatStakeholders(filteredList))
  }

  return (
    <GenericSelectionDialog<StakeholderItem>
      items={searchedStakeholders}
      title={t('stakeholder_hosted_pages_title')}
      onSelect={handleClick}
      onSearch={handleSearch}
      searchPlaceholder={t('search_stakeholders')}
      description={t('stakeholder_link_description')}
      knowledgeBaseLink={
        'http://help.awellhealth.com/en/articles/7021469-embed-an-awell-hosted-pages-link'
      }
    />
  )
}

StakeholderSelectionDialog.displayName = 'StakeholderSelectionDialog'
