import React from 'react'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../utils/style-guide'
import iconSvg from '../../assets/icons/sprite.svg'
import { type IconNames } from './IconNames'

export type Size =
  | 'xxs'
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'auto'

interface Props {
  size?: Size
  className?: string
  light?: boolean
  name: IconNames
  style?: React.CSSProperties
  bare?: boolean
  transparent?: boolean
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    transformOrigin: 'center',
  },
  light: {
    fill: colors.neutralLight0,
  },
  dark: {
    fill: colors.neutralDark800,
  },
  transparent: {
    fill: 'transparent',
  },
  xxs: {
    width: spacing.xxs,
  },
  xs: {
    width: spacing.xs,
  },
  s: {
    width: spacing.s,
  },
  m: {
    width: spacing.m,
  },
  l: {
    width: spacing.l,
  },
  xl: {
    width: spacing.xl,
  },
  xxl: {
    width: spacing.xxl,
  },
  xxxl: {
    width: spacing.xxxl,
  },
  auto: {
    width: '100%',
  },
})

/**
 * @deprecated
 * Please use IconWrapper, because IconWrapper uses the new React Components for svg icons instead
 * of relying on svg sprites.
 *
 * @example
 * <IconWrapper {...same props as Icon}><IconName /></IconWrapper>
 */
export const Icon: React.FC<Props> = ({
  name,
  size = 's',
  className = '',
  light = false,
  transparent = false,
  style = {},
  bare = false,
}) => {
  const classes = useStyles()

  return (
    <motion.svg
      className={classnames(className, classes[size], {
        [classes.container]: !bare,
      })}
      style={style}
      viewBox='0 0 24 24'
    >
      <motion.use
        className={classnames(classes[light ? 'light' : 'dark'], {
          [classes.transparent]: transparent,
        })}
        xlinkHref={`${iconSvg}#${name}`}
      />
    </motion.svg>
  )
}

Icon.displayName = 'Icon'
