import { type TDescendant, type TNode } from '@udecode/plate'
import { isEmpty, isNil, snakeCase } from 'lodash'
import { Node } from 'slate'
import {
  ELEMENT_HOSTED_PAGES_LINK,
  type HostedPagesLinkNode,
} from './HostedPagesLinkPlugin/types'
import {
  ELEMENT_CONSTANT,
  ELEMENT_DYNAMIC_VARIABLE,
  type MentionNode,
} from './Mention'

export const initialValue: Array<TNode> = [
  {
    type: 'p',
    children: [{ text: '' }],
  },
]

export const initialJsonValue: Array<TNode> = [
  {
    type: 'code_block',
    lang: 'json',
    children: [
      {
        type: 'code_line',
        children: [{ text: '{}' }],
      },
    ],
  },
] as Array<TNode>

export const errorValue: Array<TNode> = [
  {
    type: 'p',
    children: [{ text: 'Error parsing' }],
  },
]

/**
 * This function parses a stringified slate document and returns a cleaned up, stringified slate document.
 * The cleaning up process consists of recursively finding Slate nodes that are of type ELEMENT_DYNAMIC_VARIABLE,
 * ELEMENT_CONSTANT OR ELEMENT_HOSTED_PAGES_LINK and replacing their children.text with {{{node.type:node.variableId}}}
 * @param content - stringified slate document
 * @returns - cleaned up, stringified slate document
 * @throws - error if the content is not a valid stringified slate document
 * @example
 * const content = '[{"type":"dynamic-variable","children":[{"text":"{{abcd}}"}]}]'
 * const parsed = parseStringSlateContent(content)
 * => [{"type":"p","children":[{"text":"{{{dynamic_variable:abcd}}}"}]}]
 * Note the change in casing of the type and the new text value
 *
 * NB: In the backend, the types are in snake_case, whereas in the FE they are in kebab-case
 *
 */
export const parseStringSlateContent = (content: string): Array<TNode> => {
  try {
    if (isTextEmpty(content)) {
      return initialValue
    }
    if (!isNil(content)) {
      const parsed = JSON.parse(content)
      // recursively find Slate nodes that are of type ELEMENT_DYNAMIC_VARIABLE, ELEMENT_CONSTANT OR
      // ELEMENT_HOSTED_PAGES_LINK and replace their children.text with {{{node.type:node.variableId}}}
      const convertToDataVariableFormat = (
        node: TDescendant | MentionNode,
      ): Node => {
        if (
          node.type === ELEMENT_DYNAMIC_VARIABLE ||
          node.type === ELEMENT_CONSTANT ||
          node.type === ELEMENT_HOSTED_PAGES_LINK
        ) {
          const { type, variableId, stakeholderId } = node as
            | MentionNode
            | HostedPagesLinkNode
          const nodeId: string =
            node.type === ELEMENT_HOSTED_PAGES_LINK ? stakeholderId : variableId
          return {
            ...node,
            children: [
              {
                text: `{{{${snakeCase(type)}:${nodeId}}}}`,
              },
            ],
          }
        }
        if (!isNil(node.children) && node.children.length > 0) {
          return {
            ...node,
            children: node.children.map(convertToDataVariableFormat),
          }
        }
        return node
      }
      const parsedAndConverted = parsed.map(convertToDataVariableFormat)
      return parsedAndConverted
    }
    return initialValue
  } catch (error) {
    console.warn('Error parsing editor content', { error })
    return initialValue
  }
}

export const isTextEmpty = (content: string): boolean => {
  if (isNil(content) || isEmpty(content)) {
    return true
  }
  const nodes = (JSON.parse(content) ?? []) as Array<Node>
  return !nodes.some(n => Node.string(n) !== '')
}

export const replaceGlobalPrefix = (string: string): string => {
  return string.replace('GLB_', '🌐 ')
}
