import React, { createContext, FC, useContext, useState } from 'react'

interface LabelInteractionContextInterface {
  labelOpen: boolean
  setLabelOpen: (labelOpen: boolean) => void
}
const initialContext: LabelInteractionContextInterface = {
  labelOpen: false,
  setLabelOpen: () => null,
}

const LabelInteractionContext = createContext<LabelInteractionContextInterface>(
  initialContext,
)

/**
 *
 * LabelInteractionContextProvider handles the label state across preview and orchestration
 * It can be used to handle other page-wide UI interactions in the future (and renamed accordingly)
 *
 */
export const LabelInteractionContextProvider: FC = ({ children }) => {
  const [labelOpen, setLabelOpen] = useState(false)

  return (
    <LabelInteractionContext.Provider
      value={{
        labelOpen,
        setLabelOpen,
      }}
    >
      {children}
    </LabelInteractionContext.Provider>
  )
}

export const useLabelInteractionContextProvider = (): LabelInteractionContextInterface =>
  useContext(LabelInteractionContext)
