import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import {
  type AdHocTrack,
  useAddTrackMutation,
  useGetAdHocTracksQuery,
} from './types'

interface UseAdHocTracksHookInput {
  pathway_id: string
}

interface UseAdHocTracksHook {
  handleStartTrack: (track_definition_id: string) => Promise<void>
  adHocTracks: Array<AdHocTrack> | undefined
  adHocTracksLoading: boolean
}

export const useAdHocTracks = ({
  pathway_id,
}: UseAdHocTracksHookInput): UseAdHocTracksHook => {
  const { notify, notifyError } = useNotifications()
  const { t } = useTranslation()

  const [startTrackMutation] = useAddTrackMutation()

  const { data, loading, error } = useGetAdHocTracksQuery({
    variables: {
      pathway_id,
    },
    skip: isNil(pathway_id),
  })

  const handleStartTrack = async (
    track_definition_id: string,
  ): Promise<void> => {
    try {
      const result = await startTrackMutation({
        variables: {
          input: {
            pathway_id,
            track_id: track_definition_id,
          },
        },
        refetchQueries: ['PathwayActivities'],
      })
      if (result.data?.addTrack.success === true) {
        notify({
          message: t('add_track_track_started'),
          notifyType: NotificationTypes.success,
        })
      }
    } catch (error) {
      notifyError({
        message: t('add_track_track_not_started'),
        error,
      })
    }
  }

  if (!isNil(error)) {
    notifyError({
      message: t('get_ad_hoc_tracks_error'),
      error,
    })
  }

  return {
    handleStartTrack,
    adHocTracks: data?.adHocTracksByPathway.tracks,
    adHocTracksLoading: loading,
  }
}
