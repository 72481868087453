import { makeStyles } from '@material-ui/core'
import { spacing } from '../utils/style-guide'

export const useStyles = makeStyles({
  graphic: {
    width: 'fit-content',
    maxWidth: '100%',
  },
  emptyTracksGraphic: {
    width: 'fit-content',
    maxWidth: '100%',
    minHeight: '110px',
  },
  step: {
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
  stepText: {
    paddingTop: spacing.l,
    '& *:last-child': {
      marginTop: spacing.xsm,
    },
  },
  stepConnector: {
    marginTop: spacing.l,
    alignSelf: 'start',
  },
})
