import { isArray, isNil } from 'lodash'
import {
  type CountryIso2,
  defaultCountries,
  parseCountry,
  buildCountryData,
} from 'react-international-phone'
import { type CountryData } from 'react-international-phone/build/types'

const FALLBACK_COUNTRY_ISO = 'gb'

interface UseCountryIsoHook {
  getInitialCountry: (defaultCountry: string | undefined) => CountryIso2
  getCountriesList: (
    fixedCountryConfig: Array<string> | undefined,
  ) => Array<CountryData>
}

export const useCountryIso = (): UseCountryIsoHook => {
  /**
   * This function will return the country iso2 code to use for the input select,
   * based on the defaultCountry prop and the client's browser language (if a valid
   * country iso2 code can be parsed from it), otherwise it will return the fallback
   * @param defaultCountry the default country to use if configured for the question
   * @returns the country iso2 code to use for the input select
   */
  const getInitialCountry = (
    defaultCountry: string | undefined,
  ): CountryIso2 => {
    if (!isNil(defaultCountry)) {
      return defaultCountry.toLocaleLowerCase() as CountryIso2
    }
    return FALLBACK_COUNTRY_ISO
  }

  /**
   * This function will return the list of countries to use for the input select,
   * based on the fixedCountryConfig prop, otherwise it will return the default list
   * @param fixedCountryConfig the fixed country to use if configured for the question
   * @returns the list of countries to use for the input select
   * @returns the country iso2 code to use for the input select
   */
  const getCountriesList = (
    fixedCountryConfig: Array<string> | undefined,
  ): Array<CountryData> => {
    // remove all other characters from country formatting to display E164 format
    let countries = defaultCountries.map(country => {
      const parsedCountry = parseCountry(country)
      if (isNil(parsedCountry.format)) return country
      // remove all formatting mask characters except dots
      const newFormatMask = parsedCountry.format.replace(/[^\\.]*/g, '')
      // fix for countries with no formatting mask
      const fallbackFormatMask = '...............' // max 15 characters for E164 format
      return buildCountryData({
        ...parsedCountry,
        format: newFormatMask === '' ? fallbackFormatMask : newFormatMask,
      })
    })
    if (isArray(fixedCountryConfig) && fixedCountryConfig.length > 0) {
      countries = countries.filter(c =>
        fixedCountryConfig.map(c => c.toLowerCase()).includes(c[2]),
      )
    }
    // move United States, UK and Belgium to the top of the list
    const usIndex = countries.findIndex(c => c[2] === 'us')
    const ukIndex = countries.findIndex(c => c[2] === 'gb')
    const beIndex = countries.findIndex(c => c[2] === 'be')
    const us = countries.splice(usIndex, 1)
    const uk = countries.splice(ukIndex, 1)
    const be = countries.splice(beIndex, 1)
    countries.unshift(...us, ...uk, ...be)

    return countries
  }

  return {
    getInitialCountry,
    getCountriesList,
  }
}
