// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { IconWrapper } from '../IconWrapper'
import { Document, Video, Link as LinkIcon } from '../Icons'
import { colors, spacing } from '../../utils/style-guide'
import { MessageAttachmentType } from './types'

interface AttachmentIconProps {
  icon: string
}

const useStyles = makeStyles({
  container: {
    height: spacing.m,
    width: spacing.m,
    padding: spacing.xxxs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: spacing.xxxs,
  },
  [MessageAttachmentType.File]: {
    backgroundColor: colors.teal50,
    color: colors.teal400,
  },
  [MessageAttachmentType.Link]: {
    backgroundColor: colors.blue50,
    color: colors.blue300,
  },
  [MessageAttachmentType.Video]: {
    backgroundColor: colors.red50,
    color: colors.red400,
  },
})

export const AttachmentIcon: FC<AttachmentIconProps> = ({ icon }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.container, classes[icon])}>
      <IconWrapper size='s' className={classes[icon]}>
        {(() => {
          switch (icon) {
            case MessageAttachmentType.File:
              return <Document />
            case MessageAttachmentType.Video:
              return <Video />
            case MessageAttachmentType.Link:
              return <LinkIcon />
            default:
              return <LinkIcon />
          }
        })()}
      </IconWrapper>
    </div>
  )
}

AttachmentIcon.displayName = 'AttachmentIcon'
