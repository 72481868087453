import React, { CSSProperties, FC } from 'react'
import classnames from 'classnames'
import { findChildByType } from '../../utils/findChildByType'
import { useStyles } from './useStyles'

interface Slots {
  Header: FC
  Content: FC
}

const Header: FC = ({ children }) => {
  return <>{children}</>
}

const Content: FC = ({ children }) => {
  return <>{children}</>
}

interface Props {
  className?: string
  headerStyle?: CSSProperties
  hasTabs?: boolean
}

export const SidePanel: FC<Props> & Slots = ({
  children,
  className,
  headerStyle,
  hasTabs,
}) => {
  const classes = useStyles()
  const header = findChildByType(children, Header)
  const content = findChildByType(children, Content)
  return (
    <div className={classnames(classes.container, className)}>
      <div
        className={hasTabs ? classes.headerWithTabs : classes.header}
        style={headerStyle}
      >
        <div className={classes.headerContent}>{header}</div>
      </div>
      <div className={hasTabs ? classes.contentWithTabs : classes.content}>
        {content}
      </div>
    </div>
  )
}

SidePanel.displayName = 'SidePanel'
SidePanel.Header = Header
SidePanel.Content = Content
