import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { useDeletePatientMutation } from './types'

interface UseDeletePatientHook {
  deletePatient: (patientId: string) => Promise<boolean>
}

export const useDeletePatient = (): UseDeletePatientHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const [deletePatientMutation] = useDeletePatientMutation()

  const deletePatient = async (patientId: string): Promise<boolean> => {
    try {
      await deletePatientMutation({
        variables: {
          input: {
            patient_id: patientId,
          },
        },
      })
      return true
    } catch (error) {
      notifyError({
        message: t('delete_patient_error'),
        error,
      })
    }
    return false
  }

  return {
    deletePatient,
  }
}
