import { Card, CardActions, IconButton } from '@material-ui/core'
import classnames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'
import { spacing } from '../../utils/style-guide'
import { Flex } from '../Flex'
import { ChevronDown, Cross } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

export enum NotificationTypes {
  'warning' = 'warning',
  'error' = 'error',
  'informative' = 'informative',
  'success' = 'success',
}
export interface NotificationMessageProps {
  icon: JSX.Element
  message: string
  onClose?: () => void
  notifyType?: NotificationTypes
  children?: ReactNode | ReactNode[]
  persist?: boolean
  action?: ReactNode
}

export const NotificationMessage: FC<NotificationMessageProps> = ({
  icon,
  message,
  onClose = () => null,
  children,
  persist = false,
  notifyType = NotificationTypes.warning,
  action = null,
}) => {
  const classes = useStyles({ notifyType })
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => setExpanded(!expanded)

  const handleDismiss = () => onClose()

  return (
    <Card className={classes.card}>
      <CardActions disableSpacing className={classes.actions}>
        <IconWrapper
          className={classnames(classes.notificationIcon, classes[notifyType])}
        >
          {icon}
        </IconWrapper>
        <div className={classes.message}>
          <Flex direction='column' gap={spacing.xxs}>
            {message}
            {action}
          </Flex>
        </div>
        {children && (
          <IconButton
            size='small'
            aria-label='Show more'
            className={classnames(classes.expandIcon, {
              [classes.expandIconOpen]: expanded,
            })}
            onClick={handleExpandClick}
          >
            <IconWrapper>
              <ChevronDown />
            </IconWrapper>
          </IconButton>
        )}
        {persist && (
          <IconButton
            size='small'
            className={classes.expandIcon}
            onClick={handleDismiss}
          >
            <IconWrapper>
              <Cross />
            </IconWrapper>
          </IconButton>
        )}
      </CardActions>
      {expanded && (
        <div className={classes.expanded}>
          <Type variant={TypeVariants.small}>{children}</Type>
        </div>
      )}
    </Card>
  )
}

NotificationMessage.displayName = 'NotificationMessage'
