import * as React from 'react'

function SvgRefresh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.42 9.294a1 1 0 01-.89.979l-.126.006-3.656-.056a3 3 0 01-2.951-2.847l-.003-.198.055-3.656a1 1 0 011.994-.095l.006.125-.056 3.656a1 1 0 00.86 1.006l.125.01 3.657.055a1 1 0 01.984 1.015z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.905 7.373a5.804 5.804 0 011.534 3.34A5.821 5.821 0 0117.4 14.68a6.11 6.11 0 01-3.437 2.378c-1.399.37-2.884.242-4.188-.362a5.938 5.938 0 01-2.92-2.923.5.5 0 00-.653-.252L5 14.04a.5.5 0 00-.258.664 8.22 8.22 0 004.04 4.065 8.54 8.54 0 005.777.506 8.43 8.43 0 004.747-3.29 8.08 8.08 0 001.433-5.518 8.076 8.076 0 00-2.414-4.929 8.413 8.413 0 00-5.047-2.325 8.554 8.554 0 00-5.432 1.302C7.086 5 6.49 5.42 6.015 5.953c-.433.485-.746 1.041-1.017 1.783-.085.053-.078.098-.066.168.007.038.014.082.01.14-.02.275.008.595.283.615l1.414.106a.5.5 0 00.472-.25A6.054 6.054 0 019.88 6.003a6.22 6.22 0 013.752-.437c1.264.247 2.41.88 3.274 1.806z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgRefresh
