import React, { type FC, memo } from 'react'
import { DescriptionQuestionViewer } from '../DescriptionQuestionViewer'
import { useStyles } from './useStyles'
import { type Question, UserQuestionType } from '../types'
import { AnswerInput } from './AnswerInput'
import { Type, TypeVariants } from '../../Typography'
import { useFormSettingsContext } from '../contexts'
import { FormMode } from '../../../enums'

interface QuestionInputProps {
  question: Question
  placeholderText?: string
}

export const QuestionInput: FC<QuestionInputProps> = memo(
  ({ question, placeholderText }: QuestionInputProps) => {
    const { mode, labels, preview = false } = useFormSettingsContext()
    const classes = useStyles()
    const isDescriptionQuestion =
      question.userQuestionType === UserQuestionType.Description
    const isIcd10ClassificationQuestion =
      question.userQuestionType === UserQuestionType.Icd10Classification
    return (
      <div className={classes.content}>
        <div className={classes.title}>
          <div className={classes.titleText}>
            {isDescriptionQuestion && (
              <DescriptionQuestionViewer question={question} />
            )}
            {!isDescriptionQuestion && (
              <Type span variant={TypeVariants.h5}>
                {question.title}
                {question.questionConfig?.mandatory === true && (
                  <span className={classes.asterisk}>*</span>
                )}
                {isIcd10ClassificationQuestion &&
                  mode !== FormMode.ReadOnly &&
                  mode !== FormMode.PatientReadOnly && (
                    <span className={classes.sublabel}>
                      {labels.icd_10_description}{' '}
                      {!preview && (
                        <a
                          href='https://icd.who.int/browse10/2019/en#/J00'
                          target='blank'
                        >
                          (ICD Catalogue ↗️)
                        </a>
                      )}
                    </span>
                  )}
              </Type>
            )}
          </div>
        </div>
        <AnswerInput
          question={question}
          placeholderText={placeholderText}
          isPreview={preview}
        />
      </div>
    )
  },
  (prev, next) => prev.question.id === next.question.id,
)

QuestionInput.displayName = 'QuestionInput'
