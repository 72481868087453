import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0 auto',
    maxWidth: '600px', // from design for form preview
  },
})
