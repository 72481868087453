import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@awell/ui-kit/utils'
import { capitalize } from 'lodash'
import { useFormQuery } from '../../hooks/useFormQuery'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { type Answer } from './types'
import { useStyles } from './useStyles'
import { Text } from '@awell/ui-kit/components/Typography'
import {
  DropdownButton,
  DropdownOption,
} from '@awell/ui-kit/components/DropdownButton'

interface FormPrefillSelectProps {
  formId: string
  careflowId: string
  onSelectPrefill: (value: Array<Answer> | undefined) => void
}

export const FormPrefillSelect = ({
  formId,
  careflowId,
  onSelectPrefill,
}: FormPrefillSelectProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const { loading, form } = useFormQuery({
    formId,
    careflowId,
    fetchPolicy: 'network-only',
  })

  if (loading || !form) {
    return <Spinner />
  }

  const previousAnswers = form.previous_answers ?? []

  const onChange = (value: string): void => {
    const previousFormAnswers = previousAnswers.find(
      ({ date }) => date === value,
    )
    const answers = value === 'no-prefill' ? [] : previousFormAnswers?.answers
    onSelectPrefill(answers)
    setIsOpen(false)
  }

  const dateOptions = previousAnswers.map(({ date }) => ({
    label: formatDate({ isoDateStr: date }) ?? date,
    value: date,
  }))

  const options = [
    { label: t('form_no_prefill_option_label'), value: 'no-prefill' },
    ...dateOptions,
  ]
  const showPrefill = dateOptions.length > 0

  return (
    <>
      {showPrefill && (
        <div className={classes.formPrefillSelect}>
          <Text variant='textSmall'>{t('form_prefill_info')}</Text>
          <DropdownButton
            key={`prefill-${formId}`}
            label={capitalize(t('form_prefill_cta'))}
            isOpen={isOpen}
            onClick={setIsOpen}
            variant='outlined'
          >
            {options.map(({ label, value }) => (
              <DropdownOption
                title={label}
                onClick={() => { onChange(value); }}
                key={value}
              />
            ))}
          </DropdownButton>
        </div>
      )}
    </>
  )
}
