import { makeStyles } from '@material-ui/core'
import { colors, fonts, rounded } from '../../utils/style-guide'

export const useStyles = makeStyles({
  textArea: {
    ...rounded.m,
    fontFamily: 'monospace',
    paddingLeft: '30px',
    border: `2px solid ${colors.neutralLight40} !important`,
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    ...rounded.m,
    // Needs latest Compass, add '@import "compass"' to your scss
    // Permalink - use to edit and share this gradient:
    // https://colorzilla.com/gradient-editor/#ffb76b+0,ffa73d+50,ff7c00+51,ff7f04+100;Orange+Gloss
    backgroundColor: 'white',
    background:
      'linear-gradient(to right, #ebecf0 0%,#ebecf0 32px,#fff 31px,#fff 100%);' /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */,
  },

  pre: {
    padding: '9px 10px 6px 38px !important',
    lineHeight: '1rem',
    height: '100%',
    // @ts-expect-error we need important here to overwrite library inline styles
    whiteSpace: 'pre-wrap !important',
    // @ts-expect-error we need important here to overwrite library inline styles
    wordBreak: 'break-word !important',
  },
})

export const inlineEditorStyles = {
  ...fonts.code,
  width: '100%',
  height: '100%',
  lineHeight: '18px',
  fontSize: '0.857rem',
}
