import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import logo from '../../assets/images/logo.svg'
import { spacing } from '../../utils/style-guide'

export enum LogoSizes {
  'small' = 'small',
  'medium' = 'medium',
}

interface LogoProps {
  size?: LogoSizes
}

const useStyles = makeStyles({
  small: {
    width: '1.5rem',
    height: '1.5rem',
  },
  medium: {
    // 32x32 px
    width: spacing.m,
    height: spacing.m,
  },
})

export const Logo: FC<LogoProps> = ({ size = LogoSizes.small }) => {
  const classes = useStyles()
  return <img className={classes[size]} src={logo} alt='logo' />
}
