import React, { useContext, ReactNode } from 'react'
import classnames from 'classnames'
import { InteractiveListContext } from '../InteractiveListContext'
import { useStyles } from './useStyles'
import { DraggableListItem } from './DraggableListItem'
import { ListItemContent } from './ListItemContent'

interface ListItemProps<RootProps = Record<string, unknown>> {
  itemId: string
  index: number
  RootComponent?: ReactNode
  rootComponentProps?: RootProps
  active?: boolean
}

export const ListItem: React.FC<ListItemProps> = ({
  children,
  itemId,
  index,
  RootComponent,
  rootComponentProps,
  active = false,
}) => {
  const classes = useStyles()
  const { reorderable } = useContext(InteractiveListContext)

  return reorderable ? (
    <DraggableListItem itemId={itemId} index={index}>
      <ListItemContent
        itemId={itemId}
        RootComponent={RootComponent}
        rootComponentProps={rootComponentProps}
      >
        {children}
      </ListItemContent>
    </DraggableListItem>
  ) : (
    <li
      className={classnames(classes.container, {
        [classes.activeContent]: active,
      })}
    >
      <ListItemContent
        itemId={itemId}
        RootComponent={RootComponent}
        rootComponentProps={rootComponentProps}
      >
        {children}
      </ListItemContent>
    </li>
  )
}

ListItem.displayName = 'InteractiveList.ListItem'
