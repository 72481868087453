/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { motion, type Variants } from 'framer-motion'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AwellActivityLayout } from '../../components/AwellActivity'
import { AwellTimeline } from '../../components/AwellTimeline'
import { DateGroup } from '../../components/DateGroup'
import { ClockSquare } from '../../components/Icons'
import { IconWrapper } from '../../components/IconWrapper'
import { Table } from '../../components/Table'
import { type ScheduledElement } from './types'
import { useScheduledElementsColumns } from './useScheduledElementsColumns'
import { useStyles } from './useStyles'

interface Props {
  scheduledElements: Array<ScheduledElement>
  animations?: Variants
}

export const ScheduledElementsCard: FC<Props> = ({
  scheduledElements,
  animations,
}) => {
  const { t } = useTranslation('activityfeed')
  const { columns } = useScheduledElementsColumns()
  const classes = useStyles()
  return (
    <motion.div>
      <AwellTimeline withoutTopConnector>
        <DateGroup date={t('upcoming')} />
      </AwellTimeline>
      <motion.div>
        <AwellTimeline>
          <motion.div
            initial='initial'
            animate='visible'
            exit='hidden'
            variants={animations}
            className={classes.feedItemContainer}
          >
            <AwellActivityLayout useCard>
              <AwellActivityLayout.Icon>
                <IconWrapper background rounded size='l'>
                  <ClockSquare />
                </IconWrapper>
              </AwellActivityLayout.Icon>
              <AwellActivityLayout.Summary>
                {t('scheduled_elements_summary')}
              </AwellActivityLayout.Summary>
              <AwellActivityLayout.Extra>
                <Table
                  columns={columns}
                  rows={scheduledElements}
                  fullWidth
                  noPaddingX
                  nonTablePageContentHeight={500}
                />
              </AwellActivityLayout.Extra>
            </AwellActivityLayout>
          </motion.div>
        </AwellTimeline>
      </motion.div>
    </motion.div>
  )
}

ScheduledElementsCard.displayName = 'ScheduledElementsCard'
