import * as React from 'react'

function SvgChevronRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.293 6.293a1 1 0 011.32-.083l.094.083L15 10.586a2 2 0 01.117 2.701l-.117.127-4.293 4.293a1 1 0 01-1.497-1.32l.083-.094L13.586 12 9.293 7.707a1 1 0 010-1.414z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgChevronRight
