// @ts-nocheck TODO: remove this comment and fix errors
import { CircularProgress, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { colors, spacing } from '../../utils/style-guide'

type size = 'xs' | 's' | 'm'

interface SpinnerProps {
  size?: size
  color?: string
  padding?: string
}

const useStyles = makeStyles({
  spinner: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: ({ size, padding }) =>
      padding || (size === 'xs' ? 0 : `${spacing.xs}`),
  },
  circularProgress: {
    color: ({ color }: SpinnerProps) => color || colors.brand100,
  },
  center: {
    margin: '0 auto',
  },
})

export const Spinner: FC<SpinnerProps> = ({ size = 'm', color, padding }) => {
  const classes = useStyles({ color, size, padding })
  let internalSize: null | number = null

  switch (size) {
    case 'xs':
      internalSize = 7
      break
    case 's':
      internalSize = 20
      break
    case 'm':
      internalSize = 40
      break
    default:
      break
  }

  return (
    <div className={classes.spinner}>
      <CircularProgress
        className={classes.circularProgress}
        color='inherit'
        size={internalSize}
      />
    </div>
  )
}
