import { ActivityObjectType } from '../../ActivityDetails/types'

export const defaultActivityTypes = [
  ActivityObjectType.ApiCall,
  ActivityObjectType.Calculation,
  ActivityObjectType.Checklist,
  ActivityObjectType.ClinicalNote,
  ActivityObjectType.Form,
  ActivityObjectType.Message,
  ActivityObjectType.EmrReport,
  ActivityObjectType.PluginAction,
]

export const possiblePatientActivityTypes = [
  ActivityObjectType.Checklist,
  ActivityObjectType.Form,
  ActivityObjectType.Message,
]
