// @ts-nocheck TODO: remove this comment and fix errors
import * as React from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { spacing, colors, paddingButton } from '../../../utils/style-guide'

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    '& > *': {
      flex: 1,
      textAlign: 'left',
      justifyContent: 'flex-start !important',
    },
    '& > *:focus, & > *:hover, & > *:active': {
      color: colors.brand100,
    },
    '&.focus': {
      backgroundColor: colors.brand40,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  buttonIcon: {
    marginRight: spacing.xxs,
  },
  focus: {
    backgroundColor: colors.brand40,
  },
  option: {
    padding: paddingButton.smallPlus,
    border: 'none',
    background: colors.neutralLight0,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    '&:hover': {
      background: colors.neutralLight10,
      color: 'initial',
    },
  },
})

interface ListActionProps extends ButtonProps {
  icon?: JSX.Element
  compact?: boolean
}

type Ref = HTMLButtonElement

export const ListAction = React.forwardRef<Ref, ListActionProps>(
  function ListAction({ children, onClick, icon: Icon, ...rest }, ref) {
    const classes = useStyles()
    return (
      <li className={classes.listItem}>
        <button
          type='button'
          className={classnames(classes.option, {
            [classes.disabled]: rest.disabled,
          })}
          onClick={event => onClick(event)}
          ref={ref}
          {...rest}
        >
          {Icon && <div className={classes.buttonIcon}>{Icon}</div>}
          {children}
        </button>
      </li>
    )
  },
)
