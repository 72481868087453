// @ts-nocheck TODO: remove this comment and fix errors
import { Spinner } from '@awell/ui-kit/components/Spinner'
import React, { FC } from 'react'
import { environment } from '@awell/libs-web/environment'
import { useWhoami } from '../hooks/useWhoami'

export function withClinicalAppRedirect<T>(
  WrappedComponent: React.ComponentType<T>,
): FC<T> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  // Creating the inner component.
  const NonDefaultTenantRoute = props => {
    const { user, loading } = useWhoami()
    if (loading) {
      return <Spinner />
    }

    if (user.tenant?.is_default) {
      window.location.replace(`${environment.urls.clinical_app}/sso_login`)
      return null
    }
    return <WrappedComponent {...(props as T)} />
  }

  NonDefaultTenantRoute.displayName = `withClinicalAppRedirect(${displayName})`

  return NonDefaultTenantRoute
}
