import { AlertModal } from '@awell/ui-kit/components/AlertModal'
import { Option, Select } from '@awell/ui-kit/components/Select'
import { isNil } from 'lodash'
import React, { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Track } from './types'
import { useStyles } from './useStyles'
import { InfoCard } from '@awell/ui-kit/components/InfoCard/InfoCard'

interface StartTrackModalProps {
  onClose: () => void
  onStartTrack: (trackId: string) => void
  tracks: Array<Track> | undefined
}

export const StartTrackModal: FC<StartTrackModalProps> = ({
  onClose,
  onStartTrack,
  tracks = [],
}) => {
  const { t } = useTranslation()
  const [trackId, setTrackId] = useState<string>()
  const classes = useStyles()

  const handleAction = (): void => {
    if (!isNil(trackId)) {
      onStartTrack(trackId)
      onClose()
    }
  }

  const labels = {
    heading: t('add_track_heading'),
    primaryAction: t('add_track'),
    secondaryAction: t('cancel'),
    multiTrackActivationWarning: t('add_track_delay_warning'),
  }

  return (
    <AlertModal labels={labels} open onAction={handleAction} onClose={onClose}>
      <div className={classes.modal_content}>
        <Select
          label={t('add_track_label')}
          onChange={value => {
            setTrackId(value)
          }}
          value={trackId}
          disabled={tracks.length === 0}
          placeholder={
            tracks.length === 0 ? t('add_track_no_options') : undefined
          }
        >
          {tracks.map(track => (
            <Option key={track.id} value={track.id} label={track.title}>
              {track.title}
            </Option>
          ))}
        </Select>
        <div className={classes.addTrackInfoCard}>
          <InfoCard>{labels.multiTrackActivationWarning}</InfoCard>
        </div>
      </div>
    </AlertModal>
  )
}
