import React, { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Home, Lightning, MultipleChoiceGridQuestion, Profile } from '../Icons'
import { Menu } from '../Menu'
import { Text } from '../Typography'
import { AppOption } from './AppOption'
import { type AppSwitcherProps } from './types'
import { useStyles } from './useStyles'

export const AppSwitcher: FC<AppSwitcherProps> = ({ currentApp, onSwitch }) => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const classes = useStyles()
  return (
    <Menu
      open={isMenuOpen}
      onClose={() => {
        setIsMenuOpen(false)
      }}
      onOpen={() => {
        setIsMenuOpen(true)
      }}
      toggleButtonContent={<MultipleChoiceGridQuestion />}
      dropdownLeft
      dataCy='pathway-header-user-menu'
      data-product-tour-target='app-switcher-btn'
      tooltipText={t('switch_to')}
      style={{
        padding: '0',
      }}
      iconSize='m'
    >
      <div className={classes.appOptionsHeading}>
        <Text variant='smallHeadline'>{t('switch_to')}</Text>
      </div>

      <AppOption
        title='Home'
        subtitle='Manage settings and invite team members'
        icon={<Home />}
        onClick={() => {
          onSwitch('home')
        }}
        hidden={currentApp === 'home'}
      />

      <AppOption
        title='Design'
        subtitle='Build and validate your careflows'
        icon={<Profile />}
        onClick={() => {
          onSwitch('design')
        }}
        hidden={currentApp === 'design'}
      />

      <AppOption
        title='Operate'
        subtitle='Enroll patients in your care flows'
        icon={<Lightning />}
        onClick={() => {
          onSwitch('operate')
        }}
        hidden={currentApp === 'operate'}
      />
    </Menu>
  )
}
