import { useStytchOrganization, useStytchMember } from '@stytch/react/b2b'
import { environment } from '@awell/libs-web/environment'
import type { Worklist, RetoolMetadata } from '../../pages/Worklists/types'

interface RetoolConfig {
  worklists: Array<Worklist>
  permission_groups: Array<string>
}
export const useRetoolConfig = (): RetoolConfig => {
  const { organization } = useStytchOrganization()
  const { member } = useStytchMember()
  const env = environment.environment

  // check if member has specific worklists permissions which should override the organization config
  const memberPermissions = (member?.trusted_metadata?.worklists ??
    []) as Array<string>

  const retoolConfig = (
    organization?.trusted_metadata?.retool as RetoolMetadata | undefined
  )?.[env] ?? { worklists: [], permission_groups: [] }

  if (memberPermissions.length > 0) {
    retoolConfig.worklists = retoolConfig.worklists.filter(worklist =>
      memberPermissions.includes(worklist.id),
    )
  }

  return {
    ...retoolConfig,
  }
}
