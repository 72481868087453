import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { type FilterOptionsState } from '@material-ui/lab'
import Autocomplete, {
  type AutocompleteRenderGroupParams,
  type AutocompleteRenderOptionState,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import classnames from 'classnames'
import React, {
  type CSSProperties,
  type FC,
  type RefObject,
  useState,
} from 'react'
import { CreateNewOptionLabel } from './CreateNewOptionLabel'
import { colors, spacing } from '../../utils/style-guide'
import { type CategoryTypes } from '../CategoryIcon'
import { ChevronDown, Cross } from '../Icons'
import InfoQuestion from '../Icons/InfoQuestion'
import { IconWrapper } from '../IconWrapper'
import { Tooltip } from '../Tooltip'
import { InlineText, Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'
import { isNil, noop } from 'lodash'

const filter = createFilterOptions<OptionProps>()

/**
 * ComboBox requires either an array of options (see OptionProps for structure)
 * or a single option passed in as selectedOption or selectedOptions respectively.
 * Usage:
 * - group is required in each option object if using the GroupBy prop
 * - renderGroup is optional when using the GroupBy prop. Use only if you
 *  wish to render a JSX.Element
 * instead of a string-based group divider
 * - createNewOptionLabel and onOptionCreated must be passed in together, if not then the ComboBox will behave like a Select input, with no ability to add options
 * - renderOption, if not passed in will use each option's label value as default
 * - only one of iconName or categoryName should be used per option object
 */
export interface OptionProps {
  label: string
  value: string
  manually_created_option?: boolean // default is false
  icon?: JSX.Element
  categoryName?: CategoryTypes
  group?: string
  color?: string
  type?: string
}

interface ComboBoxProps {
  id: string
  options: Array<OptionProps>
  startAdornmentIcon?: JSX.Element
  startAdornmentText?: string
  label?: string
  placeholder?: string
  multiple?: boolean
  alwaysShowNewOption?: boolean
  compact?: boolean
  disableClearable?: boolean
  selectedOption?: OptionProps
  selectedOptions?: Array<OptionProps>
  disabled?: boolean
  groupBy?: (option: OptionProps) => string
  onChange: (options: Array<OptionProps> | OptionProps | null) => void
  onGetNewOptionLabel?: (input: string) => string
  newOptionActionLabel?: string
  onOptionCreated?: (option: OptionProps) => void
  renderGroup?: (params: AutocompleteRenderGroupParams) => JSX.Element
  renderOption?: (
    option: OptionProps,
    state?: AutocompleteRenderOptionState,
  ) => JSX.Element
  dataCy?: string
  className?: string
  inputStyle?: CSSProperties
  tooltip?: string
  error?: boolean
  errorMessage?: string
  fullWidth?: boolean
  onOpen?: (() => void) | (() => Promise<void>)
  required?: boolean
  inputRef?: RefObject<HTMLInputElement | null>
  textFieldRef?: RefObject<HTMLInputElement | null>
  onBlur?: () => void
  rootClass?: string
  onInputChange?: (value: string, reason: string) => void
  noOptionsText?: string
}

export const ComboBox: FC<ComboBoxProps> = ({
  label,
  id,
  startAdornmentIcon,
  startAdornmentText,
  placeholder,
  disabled,
  selectedOption,
  alwaysShowNewOption,
  selectedOptions,
  multiple,
  compact,
  options,
  onChange,
  newOptionActionLabel,
  onGetNewOptionLabel,
  onOptionCreated,
  renderOption = (option: OptionProps) => option.label,
  groupBy,
  renderGroup,
  disableClearable = false,
  dataCy = null,
  className,
  inputStyle = {},
  tooltip,
  error = false,
  errorMessage = '',
  fullWidth = false,
  onOpen = () => undefined,
  required = false,
  inputRef = null,
  textFieldRef = null,
  onBlur = noop,
  rootClass,
  onInputChange,
  noOptionsText,
}) => {
  const classes = useStyles({ error })
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  /**
   * HandleChange handles scenarios:
   * - Scenario 1: when only 1 value is allowed (i.e. multiple = undefined or false)
   * - Scenario 2: when there are multiple selected options allowed (i.e. multiple = true)
   * When newValue is null newOptions becomes an array of null
   * The data structure of newValue changes:
   * - Scenario 1: newValue is an object
   * - Scenario 2: in this case, newValue is an array of objects
   *
   * We deal with these 2 scenarios by converting scenario 1 into scenario 2 at the start and at the end
   *
   */
  const handleChange = (
    __: React.ChangeEvent<Record<string, unknown>>,
    newValue: OptionProps | Array<OptionProps> | null,
  ): void => {
    // when the combo box is cleared (text input is cleared but not the selected value)
    // newValue becomes an array of null eg [null]
    // when this happens we fire onChange and break off the logic
    if (isNil(newValue)) {
      onChange(multiple === true ? [] : null)
      return
    }

    const cleanupOption = ({
      value,
      label: _label,
      group = '',
      manuallyCreatedOption = false,
      type,
    }: {
      value: string
      label: string
      group?: string
      manuallyCreatedOption?: boolean
      type?: string
    }): OptionProps => ({
      value,
      label: manuallyCreatedOption ? value : _label,
      group,
      type,
    })

    // converting scenario 1 to scenario 2
    const newOptions = Array.isArray(newValue) ? newValue : [newValue]

    const createdOption = newOptions.find(
      o => o?.manually_created_option === true,
    )

    const formattedNewOptions = newOptions.map(cleanupOption)

    // If no onOptionCreated passed in then don't allow options to be created
    if (!isNil(createdOption) && !isNil(onOptionCreated)) {
      onOptionCreated(cleanupOption(createdOption))
      return
    }

    // untangling scenario 1 to scenario 2
    const updateValue =
      multiple === true ? formattedNewOptions : formattedNewOptions[0]
    onChange(updateValue)
  }

  /**
   * Shows new option if:
   * - there are no matches found
   * - alwaysShowNewOption prop is set to true
   */
  const filterOptions = (
    _options: Array<OptionProps>,
    params: FilterOptionsState<OptionProps>,
  ): Array<OptionProps> => {
    const filteredOptions = filter(_options, params)
    const { inputValue } = params
    // If no onOptionCreated or createNewOptionLabel passed in
    // then don't worry about filtering
    if (onOptionCreated == null || onGetNewOptionLabel == null) {
      return filteredOptions
    }
    if (alwaysShowNewOption === true && inputValue === '') {
      return [
        {
          manually_created_option: true,
          value: inputValue,
          label: onGetNewOptionLabel(inputValue),
        },
        ...filteredOptions,
      ]
    }
    if (inputValue !== '') {
      filteredOptions.push({
        manually_created_option: true,
        value: inputValue,
        label: onGetNewOptionLabel(inputValue),
      })
    }

    return filteredOptions
  }

  const getOptionLabel = (option: OptionProps): string => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    if (option.manually_created_option === true) {
      return option.value
    }
    // Regular option
    return option.label ?? ''
  }

  const popupIcon = (
    <IconWrapper>
      <ChevronDown />
    </IconWrapper>
  )
  const closeIcon = (
    <IconWrapper>
      <Cross />
    </IconWrapper>
  )

  const handleRenderOption = (option: OptionProps): JSX.Element | string => {
    if (option.manually_created_option === true) {
      return (
        <CreateNewOptionLabel
          title={option.label}
          action={newOptionActionLabel ?? ''}
        />
      )
    }
    return renderOption(option)
  }

  const handleOpen = (): void => {
    onOpen()
    setIsOpen(true)
  }

  const handleInputChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    value: string,
    reason: string,
  ): void => {
    setInputValue(value) // Update the local state
    if (onInputChange) {
      onInputChange(value, reason) // Call the provided callback
    }
  }

  return (
    <div
      data-cy={dataCy}
      className={classnames(rootClass, { [classes.fullWidth]: fullWidth })}
    >
      <div className={classes.labelContainer}>
        {!isNil(label) && (
          <Type
            variant={TypeVariants.medium}
            className={classnames(classes.label, {
              disabled,
            })}
          >
            {label}
            {required && <span className={classes.requiredFieldMarker}>*</span>}
          </Type>
        )}
        {!isNil(tooltip) && (
          <Tooltip info={tooltip} key={id} arrow placement='left'>
            <div>
              <IconWrapper color={colors.brand80}>
                <InfoQuestion />
              </IconWrapper>
            </div>
          </Tooltip>
        )}
      </div>
      <Autocomplete
        onOpen={handleOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        onBlur={onBlur}
        value={multiple === true ? selectedOptions : selectedOption}
        getOptionSelected={(option, _selectedOption) =>
          option.value === _selectedOption.value
        }
        classes={{
          inputRoot: classes.autoCompleteInputRoot,
          focused: classes.focused,
        }}
        onChange={handleChange}
        filterOptions={filterOptions}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        disabled={disabled}
        ref={inputRef}
        id={id}
        multiple={multiple}
        size={compact === true ? 'small' : 'medium'}
        selectOnFocus
        clearOnBlur
        groupBy={groupBy}
        renderGroup={renderGroup}
        handleHomeEndKeys
        options={options}
        popupIcon={popupIcon}
        getOptionLabel={getOptionLabel}
        renderOption={handleRenderOption}
        className={className}
        renderInput={params => (
          //  Params as used in the material ui TextField to get all inputProps and inputLabelProps predefined by the autocomplete component
          // Reference https://material-ui.com/components/autocomplete/#creatable for usage
          <>
            <TextField
              inputRef={textFieldRef}
              {...params}
              InputProps={{
                style: { paddingLeft: 0, ...inputStyle },
                ...(params?.InputProps ?? {}),
                ...(startAdornmentIcon != null
                  ? {
                      startAdornment: (
                        <InputAdornment position='start'>
                          {startAdornmentIcon}
                        </InputAdornment>
                      ),
                    }
                  : {}),
                ...(!isNil(startAdornmentText) && isOpen
                  ? {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <InlineText
                            variant='tinyHeadlineCaps'
                            color={colors.neutralLight100}
                            spacing={`0 -${spacing.xxxs} 0 0.857rem`}
                          >
                            {startAdornmentText}
                          </InlineText>
                        </InputAdornment>
                      ),
                    }
                  : {}),
              }}
              placeholder={placeholder}
              variant='outlined'
              helperText={error && errorMessage}
              error={error}
            />
          </>
        )}
        closeIcon={closeIcon}
        clearText='Clear Selection'
        disableClearable={disableClearable}
        noOptionsText={noOptionsText}
      />
    </div>
  )
}

ComboBox.displayName = 'ComboBox'
