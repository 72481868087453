/* eslint-disable @typescript-eslint/no-empty-function */
import { makeStyles } from '@material-ui/core'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { isNil } from 'lodash'
import React, { type FC } from 'react'
import { spacing, rounded, colors } from '../../utils/style-guide'

interface Props {
  date: string | undefined
  label: string
  dateOptions?: Array<string>
  onSelectDate?: (date: string | undefined) => void
  format?: string
  key?: string
}

export const useStyles = makeStyles({
  container: {
    marginRight: spacing.xs,
  },
})

export const DateTimeInput: FC<Props> = ({
  label,
  onSelectDate,
  date,
  key,
  format,
}) => {
  const classes = useStyles()

  const handleDateChange = (value: string | null): void => {
    if (isNil(onSelectDate)) {
      return
    }

    if (!isNil(value)) {
      const dateInIsoString = new Date(value).toISOString()
      onSelectDate(dateInIsoString)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={classes.container}>
        <DateTimePicker
          format={format ?? 'yyyy-MM-dd hh:mm a'}
          key={key}
          // @ts-expect-error this line keeps complaining but it works
          defaultValue={!isNil(date) ? new Date(date) : null}
          label={label}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          onChange={handleDateChange}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: colors.neutralLight10,
              outline: 'none',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: colors.brand100,
                borderWidth: '2px',
                borderStyle: 'solid',
              },
            '& .MuiInputBase-input': {
              ...rounded.m,
              paddingY: '10.6px',
              outline: 'none',
              border: 'none',
              borderColor: colors.neutralLight40,
              '&:focus': {
                borderColor: colors.brand100,
              },
              '&:hover': {
                borderColor: colors.neutralLight50,
              },
            },
            '& .MuiInputLabel-root': {
              lineHeight: 'normal',
              transform: 'translate(11px, 13px) scale(1)',
            },
            '& .MuiInputLabel-shrink': {
              transform: 'scale(0);',
            },
            '& legend': {
              width: '0px',
            },
          }}
        />
      </div>
    </LocalizationProvider>
  )
}

DateTimeInput.displayName = 'DateTimeInput'
