import { ActivityStatus } from '@awell/ui-kit/generated/types-design'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

interface UseActivityStatusesHook {
  activityStatuses: Array<{
    value: ActivityStatus
    label: string
  }>
}

export const useActivityStatuses = (): UseActivityStatusesHook => {
  const { t } = useTranslation()

  const labelDictionary = {
    [ActivityStatus.Active]: t('activityfeed:to_do'),
    [ActivityStatus.Done]: t('activityfeed:done'),
    [ActivityStatus.Canceled]: t('activityfeed:canceled'),
    [ActivityStatus.Failed]: t('activityfeed:failed'),
    [ActivityStatus.Expired]: t('activityfeed:expired'),
  }

  const activityStatuses = Object.values(ActivityStatus).map(activityStatus => {
    return {
      value: activityStatus,
      label: capitalize(labelDictionary[activityStatus]),
    }
  })

  return {
    activityStatuses,
  }
}
