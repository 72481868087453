import { type FetchPolicy } from '@apollo/client'
import { useNotifications } from 'app-care/src/hooks/useNotifications'
import { isNil } from 'ramda'
import { useGetHostedPagesLinkQuery } from './types'

interface UseHostedPageLinkHook {
  loading: boolean
  url?: string | undefined
}

export const useGetHostedPagesLink = ({
  pathway_id,
  stakeholder_id,
  fetchPolicy = 'cache-first',
}: {
  pathway_id: string
  stakeholder_id: string
  fetchPolicy?: FetchPolicy
}): UseHostedPageLinkHook => {
  const { notifyError } = useNotifications()

  const { data, loading, error } = useGetHostedPagesLinkQuery({
    variables: {
      pathwayId: pathway_id,
      stakeholderId: stakeholder_id,
    },
    fetchPolicy,
  })

  if (!isNil(error)) {
    notifyError({
      error,
      message: 'Something went wrong while loading a hosted page link',
    })
    return {
      loading: false,
      url: undefined,
    }
  } else {
    return {
      loading,
      url: data?.hostedPagesLink?.hosted_pages_link?.url as string | undefined,
    }
  }
}
