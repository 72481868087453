import * as React from 'react'

function SvgMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M19 11a1 1 0 01.117 1.993L19 13H5a1 1 0 01-.117-1.993L5 11h14z'
      />
    </svg>
  )
}

export default SvgMinus
