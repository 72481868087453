import React from 'react'
import { Flex } from '../../../Flex'
import { spacing } from '../../../../utils/style-guide'
import { Skeleton } from '../../../Skeleton'
import { useStyles } from '../useStyles'

export const QuestionInputSkeleton = (): JSX.Element => {
  const classes = useStyles()
  return (
    <div style={{ marginBottom: spacing.xs }}>
      <div className={classes.title}>
        <div className={classes.titleText}>
          <Skeleton width={250} height={30} variant='text' />
        </div>
      </div>
      <div>
        <Flex align='center'>
          <Skeleton
            width={20}
            height={30}
            style={{ marginRight: spacing.xxs }}
          />{' '}
          <Skeleton width={200} variant='text' />
        </Flex>
        <Flex align='center'>
          <Skeleton
            width={20}
            height={30}
            style={{ marginRight: spacing.xxs }}
          />{' '}
          <Skeleton width={200} variant='text' />
        </Flex>
        <Flex align='center'>
          <Skeleton
            width={20}
            height={30}
            style={{ marginRight: spacing.xxs }}
          />{' '}
          <Skeleton width={200} variant='text' />
        </Flex>
      </div>
    </div>
  )
}

QuestionInputSkeleton.displayName = 'QuestionInputSkeleton'
