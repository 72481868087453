import * as React from 'react'

function SvgTiming(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M8 2a6 6 0 110 12A6 6 0 018 2zm1 2H7v5h4V7l-2-.001V4z'
      />
    </svg>
  )
}

export default SvgTiming
