import * as React from 'react'

function SvgTimeline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 5h10a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V7a2 2 0 012-2zM3 7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7zm9 0a1 1 0 100 2h4a1 1 0 100-2h-4zm-4 8a1 1 0 100 2h4a1 1 0 100-2H8zm1-3a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTimeline
