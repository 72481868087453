import { isNil } from 'lodash'
import { useNotifications } from '../useNotifications'
import { type PatientPathwayResult, useGetPatientPathwaysQuery } from './types'

interface UsePatientPathwaysHook {
  patientPathways?: PatientPathwayResult
  refetch: () => void
  loading: boolean
}

export const usePatientPathways = (
  patientId?: string,
): UsePatientPathwaysHook => {
  if (isNil(patientId))
    return {
      loading: true,
      refetch: () => {},
    }

  const { notifyError } = useNotifications()

  const { data, refetch, loading, error } = useGetPatientPathwaysQuery({
    variables: {
      patient_id: patientId,
    },
  })

  const handleRefetch = (): void => {
    void refetch()
  }

  if (!isNil(error)) {
    notifyError({
      message: 'something went  wrong while loading patient pathways',
      error,
    })
    return { loading: false, patientPathways: [], refetch: handleRefetch }
  }

  const patientPathways = data?.patientPathways.patientPathways ?? []

  return {
    patientPathways,
    loading,
    refetch: handleRefetch,
  }
}
