import { colors, shadows } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

interface SidePanelStyleProps {
  panelWidth: number
}

export const useStyles = makeStyles({
  panel: {
    backgroundColor: colors.neutralLight20,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  endPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    height: '100%',
  },
  panelWithDivider: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    right: 0,
    top: '54px', // action bar height
    transition: 'transform .5s ease-in-out',
    zIndex: 100,
  },
  panelDivider: {
    zIndex: 30,
  },
  sidePanelContainer: {
    ...shadows.m,
  },
  // SlideIn animation was removed from this usecase due to working incorrectly on resize events
  open: {
    width: (props: SidePanelStyleProps) => `${props.panelWidth}px`,
    transform: 'translateX(0)',
  },
  close: {
    transform: 'translateX(100vh)',
  },
})
