/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { Text } from '../Typography'
import { useStyles } from './useStyles'

interface AppOptionProps {
  title: string
  subtitle: string
  icon: JSX.Element | string
  onClick: () => void
  hidden: boolean
}

export const AppOption: FC<AppOptionProps> = ({
  title,
  subtitle,
  icon,
  onClick,
  hidden = false,
}) => {
  const classes = useStyles()

  if (hidden) {
    return null
  }

  return (
    <div role='button' className={classes.appOptionContainer} onClick={onClick}>
      <div className={classes.appOptionIcon}>{icon}</div>
      <div className={classes.appOptionContent}>
        <Text color='inherit' variant='tinyHeadline'>
          {title}
        </Text>
        <Text color='inherit' variant='textSmall'>
          {subtitle}
        </Text>
      </div>
    </div>
  )
}
