// @ts-nocheck TODO: remove this comment and fix errors
import { Button } from '@awell/ui-kit/components/Button'
import {
  ComboBox,
  findOption,
  OptionProps,
} from '@awell/ui-kit/components/ComboBox'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { HeadingSecondary, Text } from '@awell/ui-kit/components/Typography'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { RouteComponentProps } from '@reach/router'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePublishedPathwayDefinitions } from '../../../hooks/usePublishedPathwayDefinitions'
import { useStyles } from './useStyles'
import { WebhookLogsForOrganization } from './WebhookLogsForOrganization'
import { WebhookLogsForPathwayDefinition } from './WebhookLogsForPathwayDefinition'

export const WebhookLogs: FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    loading,
    publishedPathwayDefinitions,
  } = usePublishedPathwayDefinitions()

  const [pathwayDefinitionId, setPathwayDefinitionId] = useState<string>()
  const [displayAllLogs, setDisplayAllLogs] = useState<boolean>(false)

  if (loading) {
    return <Spinner />
  }

  const pathwayOptions: Array<OptionProps> = publishedPathwayDefinitions.map(
    ({ id, title }) => ({
      label: title,
      value: id,
    }),
  )

  const handlePathwayChange = (value: string) => {
    setPathwayDefinitionId(value)
    setDisplayAllLogs(false)
  }

  const handleViewAllLogs = () => {
    setPathwayDefinitionId(null)
    setDisplayAllLogs(true)
  }

  const selectedOption = findOption(pathwayOptions, pathwayDefinitionId)

  return (
    <>
      <div className={classes.webhook_logs_top}>
        <div>
          <HeadingSecondary variant='subHeadline'>
            {t('webhook_plural')}
          </HeadingSecondary>
          <Text color={colors.neutralMid200}>
            {t('webhook_logs_description')}
          </Text>
        </div>
        {/* TODO: Move this component to UI-kit */}
        <div className={classes.info_banner_container}>
          <div>{t('webhook_logs_info')}</div>
        </div>

        <div className={classes.webhook_logs_selector}>
          <ComboBox
            options={pathwayOptions}
            onChange={(option: OptionProps) =>
              handlePathwayChange(option?.value)
            }
            selectedOption={selectedOption}
            id='pathway_definition_id'
            compact
            className={classes.webhook_logs_pathway_select}
            placeholder={t('select_a_pathway')}
          />

          <Text color={colors.neutralMid200}>{t('or').toLowerCase()}</Text>

          <Button onClick={() => handleViewAllLogs()} variant='text'>
            {t('view_all_logs')}
          </Button>
        </div>
      </div>

      {pathwayDefinitionId && (
        <WebhookLogsForPathwayDefinition
          pathwayDefinitionId={pathwayDefinitionId}
          pathwayDefinitionTitle={selectedOption.label}
        />
      )}
      {displayAllLogs && <WebhookLogsForOrganization />}
    </>
  )
}
