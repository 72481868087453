import React, { type FC } from 'react'
import { useStyles } from './useStyles'
import { ActivitySummary } from './ActivitySummary'
import { type ActivityContent } from '../../compositions/ActivityFeed'

export interface ActivityMainProps {
  content: ActivityContent
}

/**
 * Renders the activity summary and the list of events for that activity.
 */
export const ActivityMain: FC<ActivityMainProps> = ({ content }) => {
  const classes = useStyles()
  const {
    summaryText,
    stepName,
    stepLabelName,
    stepLabelColor,
    ActivityIcon,
    timestampLabel,
    timestamp,
    trackName,
  } = content
  return (
    <div className={classes.cardMain}>
      <ActivitySummary
        timestampLabel={timestampLabel}
        ActivityIcon={ActivityIcon}
        timestamp={timestamp}
        stepName={stepName}
        stepLabelName={stepLabelName}
        stepLabelColor={stepLabelColor}
        trackName={trackName}
      >
        {summaryText}
      </ActivitySummary>
    </div>
  )
}

ActivityMain.displayName = 'ActivityMain'
