import { IconButton } from '@awell/ui-kit/components/Button'
import { Link } from '@awell/ui-kit/components/Icons'
import React from 'react'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../../hooks/useNotifications'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'

interface CopyToClipboardButtonProps {
  url: string
}

export const CopyToClipboardButton = ({
  url,
}: CopyToClipboardButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const { notify, notifyError } = useNotifications()

  const handleCopyToClipboard = (): void => {
    try {
      void navigator.clipboard.writeText(url)
      notify({
        message: t('copy_to_clipboard_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (err) {
      notifyError({
        message: t('copy_to_clipboard_error'),
      })
    }
  }

  return (
    <IconButton
      onClick={handleCopyToClipboard}
      style={{ padding: spacing.xxxs }}
      iconColor={colors.neutralLight100}
      tooltip={t('copy_to_clipboard', { item: t('link_to_activity') })}
    >
      <Link />
    </IconButton>
  )
}
