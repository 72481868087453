import {
  compareAsc,
  format,
  isAfter,
  isPast,
  isSameDay,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns'
import { capitalize, groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { type Activity, type GroupsOfActivities } from './types'

export const useGroupByTime = (
  activities: Array<Activity>,
): GroupsOfActivities => {
  const { t } = useTranslation()

  const sortedActivities = activities
    .slice()
    .sort((a, b) => compareAsc(new Date(b.date), new Date(a.date)))

  const groupedDates = groupBy(sortedActivities, activity => {
    const currentDate = new Date()
    const dateValue = parseISO(activity.date)

    // const startOfToday = startOfDay(currentDate)
    const startOfYesterday = startOfDay(subDays(currentDate, 1))

    if (isSameDay(dateValue, currentDate)) {
      return capitalize(t('today'))
    }

    if (isPast(dateValue) && isAfter(dateValue, startOfYesterday)) {
      return capitalize(t('yesterday'))
    }

    return format(dateValue, `iiii',' d MMMM yyyy`)
  })

  return groupedDates
}
