import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing.m,
  },
  cancelButton: {
    marginRight: spacing.xs,
  },
  createPatientHeader: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing.m,
  },
  createPatientFormContainer: {
    maxWidth: '780px',
    margin: 'auto',
  },
  profilePageContainer: {
    display: 'flex',
  },
  profileCardsSection: {
    width: '300px',
  },
  profileContentSection: {
    width: 'calc(100% - 300px)',
    marginLeft: spacing.s,
  },
  tabsContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${colors.neutralLight30}`,
  },
  routeContainer: {
    marginTop: spacing.s,
  },
  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  stepContentContainer: {
    ...spacing.paddingX.m,
    display: 'flex',
    justifyContent: 'center',
  },
  importConfigurationLoadingWarningIcon: {
    margin: 'auto',
    background: colors.signalWarning100,
    borderRadius: 24,
    marginBottom: spacing.xxs,
  },
  importConfigurationLoadingContainer: {
    justifyContent: 'center',
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '420px',
  },
})
