import { isEmpty, isNil } from 'lodash'
import { type QuestionConfig, AllowedDatesOptions } from '../../types'
import { type Maybe } from '../../../../utils'
import { isToday } from 'date-fns'

export const validateDateTypeQuestion = (
  questionConfig: Maybe<QuestionConfig> | undefined,
  value: string,
): boolean | string => {
  const inputRequired = questionConfig?.mandatory

  // skip validation if input is not required and empty
  if (inputRequired === false && isEmpty(value)) {
    return true
  }

  const dateValidationIsEnabled = !isNil(questionConfig?.date)

  const parsedDate = new Date(value)
  const dateIsToday = isToday(parsedDate)

  // No validation needed if date config is not configured
  if (!dateValidationIsEnabled) {
    return true
  }

  const { allowed_dates, include_date_of_response = false } =
    questionConfig.date!

  if (allowed_dates === AllowedDatesOptions.All) {
    return true
  }

  if (include_date_of_response === true && dateIsToday) {
    return true
  }

  if (allowed_dates === AllowedDatesOptions.Past && parsedDate > new Date()) {
    // TODO: add translation
    return 'value should be in the past. You entered a future date, which'
  }

  if (allowed_dates === AllowedDatesOptions.Future && parsedDate < new Date()) {
    // TODO: add translation
    return 'value should be in the future. You entered a past date, which'
  }
  return true
}
