import { type RouteComponentProps } from '@reach/router'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@awell/ui-kit/components/Table'
import { TableSkeleton } from '@awell/ui-kit/components/Skeleton/TableSkeleton'
import { usePathwayListColumns } from './usePathwayListColumns'
import { useDetailsForPublishedPathwayDefinitions } from '../../../hooks/usePublishedPathwayDefinitions'

export const PathwayList: FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const { columns } = usePathwayListColumns()

  const { detailsForPathwayDefinitions = [], loading } =
    useDetailsForPublishedPathwayDefinitions()

  if (loading) {
    return <TableSkeleton rowsCount={4} cellCount={1} columns={columns} />
  }

  return (
    <Table
      rows={detailsForPathwayDefinitions}
      actionText=''
      emptyDataSourceMessage={t('no_pathway_found')}
      columns={columns}
      paging
      fullWidth
      compactCells={false}
    />
  )
}

PathwayList.displayName = 'PathwayList'
