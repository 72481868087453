import React, { FC, ReactNode } from 'react'
import MuiAvatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { spacing, type } from '../../utils/style-guide'
import { AvatarAva } from '../Icons'
import { IconWrapper } from '../IconWrapper'

export interface AvatarProps {
  ava?: boolean
  size?: 's' | 'm' | 'l'
  alt?: string
  children?: ReactNode
  variant?: 'circular' | 'rounded' | 'square'
  src?: string
}

const useStyles = makeStyles({
  small: {
    width: spacing.s,
    height: spacing.s,
    fontSize: type.s.fontSize,
  },
  medium: {
    width: spacing.m,
    height: spacing.m,
  },
  large: {
    width: spacing.xxl,
    height: spacing.xxl,
    fontSize: type.xl.fontSize,
  },
})

/**
 * Avatar component for showing images, icons or letters representing
 * a persona, whether belonging to the user or the system. Avatars can
 * be displayed in various sizes and variants. When providing an image or
 * icon, also provide fallback initials as children and/or an alt prop
 * to the Avatar component (in case the image does not render). If
 * neither fallback is provided, a generic avatar icon is used.
 * MUI Avatar API: https://material-ui.com/api/avatar/
 * MUI Avatar Demo: https://material-ui.com/components/avatars/
 */
export const Avatar: FC<AvatarProps> = ({
  ava,
  size = 'm',
  alt,
  children,
  variant = 'circular',
  src,
}) => {
  const classes = useStyles()
  return ava ? (
    <IconWrapper size={size}>
      <AvatarAva />
    </IconWrapper>
  ) : (
    <MuiAvatar
      src={src}
      alt={alt}
      className={classnames(classes.medium, {
        [classes.small]: size === 's',
        [classes.large]: size === 'l',
      })}
      variant={variant}
      component='div'
    >
      {children}
    </MuiAvatar>
  )
}

Avatar.displayName = 'Avatar'
