import * as React from 'react'

function SvgHelpOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.25 5.5a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM4 12.25a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.444 9.268a1.5 1.5 0 00-1.669.98.75.75 0 11-1.415-.497 3 3 0 015.83 1c0 1.147-.851 1.905-1.459 2.31a6.04 6.04 0 01-1.264.637l-.027.009-.008.003h-.003v.001c-.001 0-.002 0-.239-.711l.238.711a.75.75 0 01-.476-1.422l.227.68-.227-.68m0 0l.012-.005.056-.02c.05-.02.126-.05.217-.09a4.54 4.54 0 00.662-.36c.517-.345.791-.712.791-1.064v-.001a1.5 1.5 0 00-1.246-1.48m-.492 3.02zM11.5 16a.75.75 0 01.75-.75h.008a.75.75 0 010 1.5h-.008a.75.75 0 01-.75-.75z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgHelpOutline
