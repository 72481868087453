export enum Colors {
  'stepDefault' = 'green',
  'blue' = 'blue',
  'slateBlue' = 'slateBlue',
  'green' = 'green',
  'orange' = 'orange',
  'purple' = 'purple',
  'pink' = 'pink',
  // REMOVED
  'teal' = 'slateBlue',
  'grey' = 'green',
  'yellow' = 'orange',
  'red' = 'pink',
}
