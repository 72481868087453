/* eslint-disable @typescript-eslint/no-empty-function */
import React, { type SyntheticEvent, type FC } from 'react'
import MuiMenu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { IconWrapper } from '../IconWrapper'
import { spacing, colors } from '../../utils/style-guide'
import { ChevronDown } from '../Icons'
import { isNil } from 'lodash'
import { SlidersH } from 'styled-icons/fa-solid'

interface MenuStyleProps {
  menuListMinWidth?: string
}
interface MenuProps extends MenuStyleProps {
  content?: JSX.Element | string
  style?: React.CSSProperties
  children?: React.ReactNode | Array<React.ReactNode>
  icon?: React.ReactNode
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxxs,
    '& *': {
      color: colors.neutralMid200,
    },
  },
  menuPaper: {
    minWidth: ({ menuListMinWidth }: MenuStyleProps) =>
      menuListMinWidth ?? '200px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  listItemButton: {
    '& button:hover': {
      backgroundColor: colors.brand10,
    },
  },
  menuListRoot: {
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
  },
  gutters: {
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
  },
  menu: {
    transform: `translateY(${spacing.xxs})`,
    '& svg': {
      marginRight: spacing.xxs,
    },
  },
  buttonToggle: {
    '& svg': {
      transform: 'scale(2.6)',
    },
  },
  buttonRotated: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  buttonUnrotated: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
  },
})

export const IconTextMenu: FC<MenuProps> = ({
  menuListMinWidth,
  children,
  style = {},
  content,
  icon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const classes = useStyles({ menuListMinWidth })
  const open = Boolean(anchorEl)

  const handleClick = (event: SyntheticEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const kids = React.Children.map(
    children as Array<JSX.Element>,
    (child: React.ReactElement) => {
      const originalOnClick = child?.props?.onClick

      return React.cloneElement(child, {
        onClick: (event: React.MouseEvent) => {
          if (!isNil(originalOnClick)) {
            originalOnClick(event)
          }
          handleClose()
        },
      })
    },
  )

  return (
    <div style={style} className={classes.container}>
      {icon ?? (
        <IconWrapper size='xs' style={{ marginRight: spacing.xxxs }}>
          <SlidersH />
        </IconWrapper>
      )}
      <div
        role='button'
        color='inherit'
        ref={null}
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: spacing.xxs }}
        >
          {content}
          <IconWrapper
            size='xxs'
            className={classnames(classes.buttonToggle, {
              [classes.buttonRotated]: open,
              [classes.buttonUnrotated]: !open,
            })}
          >
            <ChevronDown />
          </IconWrapper>
        </div>
      </div>

      <MuiMenu
        className={classes.menu}
        classes={{
          paper: classes.menuPaper,
          list: classes.listItemButton,
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant='selectedMenu'
        MenuListProps={{
          classes: {
            root: classes.menuListRoot,
          },
        }}
      >
        {kids}
      </MuiMenu>
    </div>
  )
}
