import { AnimatePresence, motion, type Variants } from 'framer-motion'
import React, { type FC, useRef } from 'react'
import { last } from 'lodash'
import { useGroupByTime } from '../../hooks/useGroupByTime'
import { type BaselineDataPoint } from '../../components/AwellActivity'
import { AwellTimeline } from '../../components/AwellTimeline'
import { DateGroup } from '../../components/DateGroup'
import { FeedItem } from './FeedItem'
import {
  type Activity,
  ActivityObjectType,
  type ScheduledElement,
} from './types'
import { useStyles } from './useStyles'
import { isDate } from '../../utils'
import { ScheduledElementsCard } from './ScheduledElementsCard'

interface Props {
  activities: Array<Activity>
  selectedActivityId?: string
  onSelectActivity: (activity: Activity) => void
  baselineDataPoints?: Array<BaselineDataPoint>
  scheduledElements?: Array<ScheduledElement>
  showScheduledElements?: boolean
  pathwayStatusExplanation?: string
}

const animations: Variants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}

export const Feed: FC<Props> = ({
  activities,
  selectedActivityId,
  onSelectActivity,
  baselineDataPoints,
  scheduledElements = [],
  showScheduledElements = false,
  children,
  pathwayStatusExplanation,
}) => {
  const classes = useStyles()
  const dateGroups = useGroupByTime(activities)
  const dateRef = useRef<HTMLDivElement>(null)

  // HTML element ids cannot use special characters used in ISO format, so
  // we use a timestamp representation instead.
  const getDateElementId = (date: string): string => {
    // date in some cases is 'Today' or 'Yesterday' (c/f useGroupByTime)
    if (!isDate(date)) {
      return date
    }
    return `DATE-${new Date(date).getTime()}`
  }

  const handleDateSelect = (date: string): void => {
    if (dateRef.current && date) {
      setTimeout(() => {
        dateRef.current
          ?.querySelector(`#${getDateElementId(date)}`)
          ?.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    }
  }

  const getDateOptions = (date: string): Array<string> => {
    return Object.keys(dateGroups).filter(key => date !== key)
  }

  const isNotSystemActivity = (_activity: Activity): boolean =>
    _activity.isUserActivity ||
    _activity.object.type === ActivityObjectType.Calculation ||
    _activity.object.type === ActivityObjectType.EmrReport ||
    _activity.object.type === ActivityObjectType.EmrRequest ||
    _activity.object.type === ActivityObjectType.ApiCall ||
    _activity.object.type === ActivityObjectType.ClinicalNote ||
    _activity.object.type === ActivityObjectType.PluginAction ||
    _activity.object.type === ActivityObjectType.Decision

  return (
    <AnimatePresence>
      <motion.div
        className={classes.container}
        ref={dateRef}
        data-cy='activity-feed'
      >
        {showScheduledElements && (
          <ScheduledElementsCard
            animations={animations}
            scheduledElements={scheduledElements}
          />
        )}

        {Object.keys(dateGroups).map((dateKey, index) => {
          const showLoadingSkeleton = last(Object.keys(dateGroups)) === dateKey
          return (
            <motion.div
              key={`${dateKey}${index.toString()}`}
              id={getDateElementId(dateKey)}
            >
              <AwellTimeline withoutTopConnector={!showScheduledElements}>
                <DateGroup
                  date={dateKey}
                  dateOptions={getDateOptions(dateKey)}
                  onSelectDate={handleDateSelect}
                />
              </AwellTimeline>
              {showLoadingSkeleton && children}
              {dateGroups[dateKey].map((activity: Activity) => (
                <motion.div key={activity.id}>
                  <AwellTimeline longConnector={isNotSystemActivity(activity)}>
                    <motion.div
                      initial='initial'
                      animate='visible'
                      exit='hidden'
                      variants={animations}
                      className={classes.feedItemContainer}
                    >
                      <FeedItem
                        activity={activity}
                        selected={activity.id === selectedActivityId}
                        onSelect={() => {
                          onSelectActivity(activity)
                        }}
                        baselineDataPoints={baselineDataPoints}
                        pathwayStatusExplanation={pathwayStatusExplanation}
                      />
                    </motion.div>
                  </AwellTimeline>
                </motion.div>
              ))}
            </motion.div>
          )
        })}
      </motion.div>
    </AnimatePresence>
  )
}

Feed.displayName = 'Feed'
