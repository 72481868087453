import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    borderTop: `1px solid ${colors.neutralLight40}`,
    borderBottom: `1px solid ${colors.neutralLight40}`,
    marginBottom: -1,
    backgroundColor: 'white',
    minHeight: `calc(${spacing.xxl} + 2px)`, // ButtonOld icon size + border width
    '& a': {
      textDecoration: 'none',
    },
    '&:first-of-type': {
      borderTop: 'none',
    },
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  activeContent: {
    backgroundColor: colors.neutralLight20,
  },
  reorderableContentContainer: {
    paddingLeft: 0,
  },
  actionable: {
    position: 'relative',
    color: colors.neutralLight50,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover, &:focus, &:active': {
      outline: 'none',
      color: colors.neutralDark800,
    },
  },
  draggableIconContainer: {
    flexBasis: spacing.l,
    minWidth: spacing.l,
  },
  draggableIcon: {
    color: colors.neutralLight50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  itemContent: {
    flexGrow: 1,
  },
  deleteButtonContainer: {
    paddingTop: spacing.xxs,
  },
  actionIconContainer: {
    position: 'absolute',
    pointerEvents: 'none',
    top: spacing.xs,
    right: spacing.xs,
  },
})
