export const colors = {
  accent100: '#fdb833',
  accent40: '#fee2ad',
  accent60: '#fdd484',
  blue: '#2E87FC',
  blue50: '#DEEBFF',
  blue75: '#B3D4FF',
  blue200: '#2684FF',
  blue300: '#0065FF',
  blue400: '#0052CC',
  blue500: '#0747A6',
  blueBorder: '#126DEF',
  brand10: '#e6edf9',
  brand40: '#99b6e6',
  brand50: '#739FE7',
  brand60: '#4E7AC2',
  brand75: '#B3D4FF',
  brand80: '#3476E0',
  brand100: '#004ac2',
  brand200: '#2684FF',
  brand300: '#0065FF',
  green: '#67D172',
  green25: '#EBFDF7',
  green50: '#E3FCEF',
  green100: '#27AB83',
  green500: '#006644',
  greenBorder: '#5DB566',
  neutralDark500: '#42526E',
  neutralDark600: '#344563',
  neutralDark700: '#253858',
  neutralDark800: '#172B4D',
  neutralDark900: '#091E42',
  neutralLight0: '#ffffff',
  neutralLight10: '#fafbfc',
  neutralLight100: '#7A869A',
  neutralLight20: '#f4f5f7',
  neutralLight30: '#ebecf0',
  neutralLight40: '#dfe1e6',
  neutralLight50: '#c1c7d0',
  neutralMid100: '#7a869a',
  neutralMid200: '#6b778c',
  neutralMid300: '#5e6c84',
  neutralMid400: '#505F79',
  neutralMid60: '#b3bac5',
  neutralMid70: '#a5adba',
  neutralMid80: '#97a0af',
  neutralMid90: '#8993a4',
  orange: '#FEC32D',
  orangeBorder: '#CB9C22',
  purple: '#6558BE',
  purpleBorder: '#4F4498',
  red: '#FF3A54',
  red100: '#E12D39',
  red400: '#DE350B',
  red50: '#FFEBE6',
  red500: '#BF2600',
  redBorder: '#D12D43',
  secondaryAqua100: '#01c5e3',
  secondaryBrown100: '#df692f',
  secondaryLightblue100: '#2590fb',
  secondaryLimegreen100: '#86e34b',
  secondaryMagenta100: '#e255ff',
  secondaryOrange100: '#ff6000',
  secondaryPink100: '#ff37be',
  secondaryPink40: '#ffafe5',
  secondaryYellow100: '#f7d32b',
  secondaryYellow20: '#fdf6d5',
  secondaryYellow40: '#f4edcb',
  signalError100: '#e12d39',
  signalError60: '#ed8188',
  signalError20: '#F9D5D7',
  signalInformative100: '#001d4d',
  signalInformative60: '#667794',
  signalSuccess100: '#27ab83',
  signalSuccess60: '#7dccb4',
  signalWarning100: '#fdd333',
  signalWarning60: '#fde484',
  teal: '#21C7E4',
  teal400: '#00A3BF',
  teal50: '#E6FCFF',
  tealBorder: '#179BB2',
  yellow100: '#FFE380',
  yellow75: '#FFF0B3',
  gray500: '#667085',
}

export const labelColors = {
  pink: '#f994a1',
  yellow: '#F6D684',
  green: '#C0E7C4',
  purple: '#A79DE8',
  blue: '#80B6FC',
  slateBlue: '#75E1F4',
  // OLD VALUES - TRANSLATED TO NEW
  orange: '#F6D684', // yellow
  teal: '#75E1F4', // slateBlue
}

export const fonts = {
  /** FontFamily: Roboto */
  roboto: { fontFamily: 'Roboto' },
  code: {
    fontFamily: `Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace`,
  },
  weight: {
    normal: 400,
    bold: 500,
    bolder: 700,
  },
  size: {
    xs: '1.142857rem',
  },
}

export const type = {
  /** font-size: 42px (3rem) - line-height: 56px (4rem) */
  xxl: { fontSize: '3rem', lineHeight: '4rem' },

  /** font-size: 28px (2rem) - line-height: 35px (2.5rem) */
  xl: { fontSize: '2rem', lineHeight: '2.5rem' },

  /** font-size: 24.5px (1.75rem) - line-height: 28px (2rem) */
  l: { fontSize: '1.75rem', lineHeight: '2rem' },

  /** font-size: 21px (1.5rem) - line-height: 24.5px (1.75rem) */
  m: { fontSize: '1.5rem', lineHeight: '1.75rem' },

  /** font-size: 15.75px (1.125rem) - line-height: 21px (1.5rem) */
  sm: { fontSize: '1.125rem', lineHeight: '1.5rem' },

  /** font-size: 14px (1rem) - line-height: 17.5px (1.25rem) */
  s: { fontSize: '1rem', lineHeight: '1.25rem' },

  /** font-size: 12.25px (0.875rem) - line-height: 17.5px (1.25rem) */
  xs: { fontSize: '0.875rem', lineHeight: '1.25rem' },

  /** font-size: 10.5px (0.75rem) - line-height: 14px (1rem) */
  xxs: { fontSize: '0.75rem', lineHeight: '1rem' },

  recommended: {
    // maxWidth: '60ch', // Recommended width for comfortable reading
    maxWidth: '250px', // Temp fix for simulator layout, review this to avoid magic number
    wordBreak: 'break-word', // Make sure that content doesn't overflow
  },
}

const spacingXXXS = '0.286rem'
const spacingXXS = '0.571rem'
const spacingXSM = '0.857rem'
const spacingXS = '1.142857rem'
const spacingS = '1.714rem'
const spacingM = '2.286rem'
const spacingL = '2.857rem'
const spacingXL = '3.429rem'
const spacingXXL = '4rem'
const spacingXXXL = '4.571rem'
export const spacing = {
  /** 4px (0.286rem) */
  xxxs: spacingXXXS,

  /** 8px (0.571rem) */
  xxs: spacingXXS,

  /** 12px (0.857rem) */
  xsm: spacingXSM,

  /** 16px (1.142857rem) */
  xs: spacingXS,

  /** 24px (1.714rem) */
  s: spacingS,

  /** 32px (2.286rem) */
  m: spacingM,

  /** 40px (2.857rem) */
  l: spacingL,

  /** 48px (3.429rem) */
  xl: spacingXL,

  /** 56px (4rem) */
  xxl: spacingXXL,

  /** 64px (4.571rem) */
  xxxl: spacingXXXL,

  /** Size of an icon: 24px (1.714rem) */
  icon: spacingS,

  padding: {
    /** padding: 8px (0.571rem) */
    xxxs: {
      paddingTop: spacingXXXS,
      paddingLeft: spacingXXXS,
      paddingBottom: spacingXXXS,
      paddingRight: spacingXXXS,
    },

    /** padding: 8px (0.571rem) */
    xxs: {
      paddingTop: spacingXXS,
      paddingLeft: spacingXXS,
      paddingBottom: spacingXXS,
      paddingRight: spacingXXS,
    },

    /** padding: 16px (1.142857rem) */
    xs: {
      paddingTop: spacingXS,
      paddingLeft: spacingXS,
      paddingBottom: spacingXS,
      paddingRight: spacingXS,
    },

    /** padding: 24px (1.714rem) */
    s: {
      paddingTop: spacingS,
      paddingLeft: spacingS,
      paddingBottom: spacingS,
      paddingRight: spacingS,
    },

    /** padding: 32px (2.286rem) */
    m: {
      paddingTop: spacingM,
      paddingLeft: spacingM,
      paddingBottom: spacingM,
      paddingRight: spacingM,
    },

    /** padding: 40px (2.857rem) */
    l: {
      paddingTop: spacingL,
      paddingLeft: spacingL,
      paddingBottom: spacingL,
      paddingRight: spacingL,
    },

    /** padding: 48px (3.429rem) */
    xl: {
      paddingTop: spacingXL,
      paddingLeft: spacingXL,
      paddingBottom: spacingXL,
      paddingRight: spacingXL,
    },

    /** padding: 56px (4rem) */
    xxl: {
      paddingTop: spacingXXL,
      paddingLeft: spacingXXL,
      paddingBottom: spacingXXL,
      paddingRight: spacingXXL,
    },

    /** padding: 64px (4.571rem) */
    xxxl: {
      paddingTop: spacingXXXL,
      paddingLeft: spacingXXXL,
      paddingBottom: spacingXXXL,
      paddingRight: spacingXXXL,
    },
  },

  /** Adds padding left and right */
  paddingX: {
    /** padding left/right: 4px (0.286rem) */
    xxxs: {
      paddingLeft: spacingXXXS,
      paddingRight: spacingXXXS,
    },

    /** padding left/right: 8px (0.571rem) */
    xxs: { paddingRight: spacingXXS, paddingLeft: spacingXXS },

    /** padding left/right: 16px (1.142857rem) */
    xs: { paddingRight: spacingXS, paddingLeft: spacingXS },

    /** padding left/right: 24px (1.714rem) */
    s: { paddingRight: spacingS, paddingLeft: spacingS },

    /** padding left/right: 32px (2.286rem) */
    m: { paddingRight: spacingM, paddingLeft: spacingM },

    /** padding left/right: 40px (2.857rem) */
    l: { paddingRight: spacingL, paddingLeft: spacingL },

    /** padding left/right: 48px (3.429rem) */
    xl: { paddingRight: spacingXL, paddingLeft: spacingXL },

    /** padding left/right: 56px (4rem) */
    xxl: { paddingRight: spacingXXL, paddingLeft: spacingXXL },

    /** padding left/right: 64px (4.571rem) */
    xxxl: { paddingRight: spacingXXXL, paddingLeft: spacingXXXL },
  },

  /** Adds padding top and bottom */
  paddingY: {
    /** padding top/bottom: 4px (0.286rem) */
    xxxs: {
      paddingTop: spacingXXXS,
      paddingBottom: spacingXXXS,
    },

    /** padding top/bottom 8px (0.571rem) */
    xxs: { paddingTop: spacingXXS, paddingBottom: spacingXXS },

    /** padding top/bottom 16px (1.142857rem) */
    xs: { paddingTop: spacingXS, paddingBottom: spacingXS },

    /** padding top/bottom 24px (1.714rem) */
    s: { paddingTop: spacingS, paddingBottom: spacingS },

    /** padding top/bottom 32px (2.286rem) */
    m: { paddingTop: spacingM, paddingBottom: spacingM },

    /** padding top/bottom 40px (2.857rem) */
    l: { paddingTop: spacingL, paddingBottom: spacingL },

    /** padding top/bottom 48px (3.429rem) */
    xl: { paddingTop: spacingXL, paddingBottom: spacingXL },

    /** padding top/bottom 56px (4rem) */
    xxl: { paddingTop: spacingXXL, paddingBottom: spacingXXL },

    /** padding top/bottom 64px (4.571rem) */
    xxxl: { paddingTop: spacingXXXL, paddingBottom: spacingXXXL },
  },

  margin: {
    /** margin: 8px (0.571rem) */
    xxs: { margin: spacingXXS },

    /** margin: 16px (1.142857rem) */
    xs: { margin: spacingXS },

    /** margin: 24px (1.714rem) */
    s: { margin: spacingS },

    /** margin: 32px (2.286rem) */
    m: { margin: spacingM },

    /** margin: 40px (2.857rem) */
    l: { margin: spacingL },

    /** margin: 48px (3.429rem) */
    xl: { margin: spacingXL },

    /** margin: 56px (4rem) */
    xxl: { margin: spacingXXL },

    /** margin: 64px (4.571rem) */
    xxxl: { margin: spacingXXXL },
  },

  /** Adds margin left and right */
  marginX: {
    /** margin left/right: 8px (0.571rem) */
    xxs: { marginRight: spacingXXS, marginLeft: spacingXXS },

    /** margin left/right: 16px (1.142857rem) */
    xs: { marginRight: spacingXS, marginLeft: spacingXS },

    /** margin left/right: 24px (1.714rem) */
    s: { marginRight: spacingS, marginLeft: spacingS },

    /** margin left/right: 32px (2.286rem) */
    m: { marginRight: spacingM, marginLeft: spacingM },

    /** margin left/right: 40px (2.857rem) */
    l: { marginRight: spacingL, marginLeft: spacingL },

    /** margin left/right: 48px (3.429rem) */
    xl: { marginRight: spacingXL, marginLeft: spacingXL },

    /** margin left/right: 56px (4rem) */
    xxl: { marginRight: spacingXXL, marginLeft: spacingXXL },

    /** margin left/right: 64px (4.571rem) */
    xxxl: { marginRight: spacingXXXL, marginLeft: spacingXXXL },
  },

  /** Adds margin top and bottom */
  marginY: {
    /** margin top/bottom: 8px (0.571rem) */
    xxs: { marginTop: spacingXXS, marginBottom: spacingXXS },

    /** margin top/bottom: 16px (1.142857rem) */
    xs: { marginTop: spacingXS, marginBottom: spacingXS },

    /** margin top/bottom: 24px (1.714rem) */
    s: { marginTop: spacingS, marginBottom: spacingS },

    /** margin top/bottom: 32px (2.286rem) */
    m: { marginTop: spacingM, marginBottom: spacingM },

    /** margin top/bottom: 40px (2.857rem) */
    l: { marginTop: spacingL, marginBottom: spacingL },

    /** margin top/bottom: 48px (3.429rem) */
    xl: { marginTop: spacingXL, marginBottom: spacingXL },

    /** margin top/bottom: 56px (4rem) */
    xxl: { marginTop: spacingXXL, marginBottom: spacingXXL },

    /** margin top/bottom: 64px (4.571rem) */
    xxxl: { marginTop: spacingXXXL, marginBottom: spacingXXXL },
  },
  input: {
    m: '90px',
    l: '240px',
    xl: '360px',
  },
  list: {
    s: '164px',
    m: '224px',
  },
}

export const shadows = {
  xs: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  },
  xsm: {
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
  },
  s: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.05)',
  },
  m: {
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  },
  l: {
    boxShadow: '0 4px 16px 0 rgba(23, 43, 77, 0.1)',
  },
}

export const rounded = {
  /** border-radius: 2px */
  s: { borderRadius: '2px' },

  /** border-radius: 3px */
  sm: { borderRadius: '3px' },

  /** border-radius: 4px */
  m: { borderRadius: '4px' },

  /** border-radius: 6px */
  l: { borderRadius: '6px' },

  /** border-radius: 8px */
  xl: { borderRadius: '8px' },

  /** border-radius: 16px */
  xxl: { borderRadius: '16px' },
}

export const borders = {
  /** 1px border width, don't forget to set the color */
  s: {
    borderWidth: 1,
    borderStyle: 'solid',
  },
  /** 2px border width, don't forget to set the color */
  m: {
    borderWidth: 2,
    borderStyle: 'solid',
  },
  /** 4px border width, don't forget to set the color */
  l: {
    borderWidth: 4,
    borderStyle: 'solid',
  },
}
export const paddingButton = {
  small: '0.5rem 0.643rem',
  smallPlus: '0.643rem 0.786rem',
  medium: '0.786rem 1.357rem',
  mediumPlus: '0.929rem 1.5rem',
}
export const responsiveContentContainer = {
  width: '1280px',
  margin: '0 auto',
}

export const appHeaderHeight = '4rem' // 56px
export const condensedTabHeader = '4rem' // 56px

export default {
  colors,
  fonts,
  type,
  spacing,
  shadows,
}
