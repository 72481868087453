import * as React from 'react'

function SvgGraphql(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.797 16.32l.66.382 7.352-12.734-.66-.381L4.797 16.32z'
        fill='currentColor'
      />
      <path d='M19.35 15.303H4.646v.762H19.35v-.762z' fill='currentColor' />
      <path
        d='M4.936 15.732l7.355 4.246.381-.66-7.355-4.247-.38.66zM11.326 4.664l7.355 4.247.38-.66-7.354-4.247-.381.66z'
        fill='currentColor'
      />
      <path
        d='M4.938 8.248l.381.66 7.355-4.247-.38-.66-7.356 4.247z'
        fill='currentColor'
      />
      <path
        d='M11.193 3.968l7.352 12.734.66-.381-7.352-12.734-.66.38zM5.99 7.742H5.23v8.493h.762V7.742z'
        fill='currentColor'
      />
      <path d='M18.771 7.742h-.762v8.493h.762V7.742z' fill='currentColor' />
      <path
        d='M11.829 19.079l.333.576 6.397-3.693-.333-.576-6.397 3.693z'
        fill='currentColor'
      />
      <path
        d='M19.781 16.483a1.605 1.605 0 01-2.19.588 1.605 1.605 0 01-.587-2.19 1.605 1.605 0 012.19-.588 1.598 1.598 0 01.587 2.19zM6.991 9.097a1.605 1.605 0 01-2.19.587 1.605 1.605 0 01-.587-2.19 1.605 1.605 0 012.19-.587 1.606 1.606 0 01.587 2.19zM4.219 16.483a1.606 1.606 0 01.587-2.19 1.606 1.606 0 012.19.588 1.604 1.604 0 01-2.777 1.602zM17.009 9.097a1.606 1.606 0 01.587-2.19 1.606 1.606 0 012.19.587 1.605 1.605 0 01-2.777 1.602zM12 20.978a1.6 1.6 0 01-1.602-1.603A1.6 1.6 0 0112 17.773a1.6 1.6 0 011.602 1.602c0 .882-.716 1.603-1.602 1.603zM12 6.204a1.6 1.6 0 01-1.602-1.602A1.6 1.6 0 0112 3a1.6 1.6 0 011.602 1.602A1.6 1.6 0 0112 6.204z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgGraphql
