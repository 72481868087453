import React from 'react'
import { colors, spacing } from '../../utils/style-guide'
import { InlineText } from '../Typography'

interface CreateNewOptionLabelProps {
  title: string
  action: string
}
export const CreateNewOptionLabel = ({
  title,
  action,
}: CreateNewOptionLabelProps): JSX.Element => {
  return (
    <>
      <InlineText
        variant='textMedium'
        color={colors.neutralLight100}
        spacing={`0 ${spacing.xxxs} 0 0`}
      >
        {action}
      </InlineText>
      {title}
    </>
  )
}
