import { makeStyles } from '@material-ui/core'
import { colors } from '../../../utils/style-guide'

interface StyleProps {
  offsetTop: number
}
export const useStyles = makeStyles({
  counterContainer: {
    display: 'flex',
    counterIncrement: 'block',
    flexDirection: 'column',
    padding: '9px 0 6px',
    position: 'absolute',
    left: 2,
    top: ({ offsetTop }: StyleProps) => `${offsetTop}px`,

    backgroundColor: colors.neutralLight30,
    width: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },

  counter: {
    height: '18px',
    fontSize: '0.857rem', // 12px
    lineHeight: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: colors.neutralLight100,
  },
})
