import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing, type } from '../../utils/style-guide'

export const useStyles = makeStyles({
  sliderContainer: {
    paddingTop: spacing.s,
    paddingBottom: spacing.m,
  },
  labelsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  withMarks: {},
  awellRangeInputWrapper: {
    backgroundColor: '#004ac266',
    position: 'relative',
    transition: 'all 0.25s ease-in',
  },
  range: {
    '--awellTickWidth': '1px',
    '--awellTicksHeight': '12px',
    display: 'flex',
    flexDirection: 'column',
    background:
      'linear-gradient(to right, var(--awellTicksColor) var(--awellTickWidth), transparent 1px) repeat-x',
    backgroundSize:
      'calc(100%/((var(--awellMax) - var(--awellMin)) / var(--awellStep)) - .1%) var(--awellTicksHeight)',
    backgroundPosition: '0 calc(var(--awellTicksHeight) * 1.7)',
  },
  tooltip: {
    position: 'absolute',
    backgroundColor: '#004ac233',
    color: colors.brand100,
    padding: '0.25rem 0.5rem',
    fontSize: type.xs.fontSize,
    borderRadius: rounded.xl.borderRadius,
    transform: 'translateX(-50%)',
    [`@media (minWidth: '640px')`]: {
      fontSize: type.s.fontSize,
    },
  },
  tooltipTouched: {
    ...type.s,
    display: 'block',
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: colors.blue50,
    color: colors.blue500,
    padding: `${spacing.xxs} ${spacing.xs}`,
    borderRadius: rounded.xl.borderRadius,
    top: '3.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    [`@media (minWidth: '640px')`]: {
      top: '-32px',
      fontSize: type.s.fontSize,
    },
  },
  awellRangeInput: {
    ...rounded.xl,
    width: '100%',
    margin: '1.3px 0',
    backgroundColor: colors.brand40,
    appearance: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-slider-runnable-track': {
      position: 'relative',
      border: '0',
      borderRadius: '25px',
      width: '100%',
      cursor: 'pointer',
    },
  },
  hideThumb: {
    '&::-webkit-slider-thumb': {
      visibility: 'hidden',
    },
    '&::-moz-range-thumb': {
      visibility: 'hidden',
    },
    '&::-ms-thumb': {
      visibility: 'hidden',
    },
  },
  showThumb: {
    '&::-webkit-slider-thumb': {
      visibility: 'visible',
    },
    '&::-moz-range-thumb': {
      visibility: 'visible',
    },
    '&::-ms-thumb': {
      visibility: 'visible',
    },
  },
  awellRangeInputDatalist: {
    marginTop: spacing.s,
    position: 'relative',
  },
  minLabel: {
    position: 'absolute',
    left: '0',
    textAlign: 'left',
  },
  maxLabel: {
    textAlign: 'right',
  },
})
