/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  StatusPill,
  type StatusType,
} from '@awell/ui-kit/components/StatusPill'
import { type Column } from '@awell/ui-kit/components/Table'
import { Text } from '@awell/ui-kit/components/Typography'
import { formatDate } from '@awell/ui-kit/utils'
import { capitalize } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { type OrchestrationFact } from '../../../hooks/useGetOrchestrationFacts'
import { FactContentCell } from './FactContentCell'

export const useAuditLogsColumns = ({
  sortDirection = 'asc',
}: {
  sortDirection: string
}): {
  columns: Array<Column<OrchestrationFact>>
} => {
  const { t } = useTranslation()

  const columns: Array<Column<OrchestrationFact>> = [
    {
      title: capitalize(t('timestamp')),
      field: 'date',
      defaultSort: 'desc',
      type: 'date',
      sorting: true,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 60,
      // eslint-disable-next-line react/display-name
      render: ({ date }) => (
        <Text>
          {formatDate({
            isoDateStr: date,
            dateFormat: 'yyyy-MM-dd hh:mm:ss:SSS a',
          })}
        </Text>
      ),
    },
    {
      title: capitalize(t('level')),
      field: 'level',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 40,
      // eslint-disable-next-line react/display-name
      render: ({ level }) => (
        <StatusPill status={level as StatusType} label={t(level)} />
      ),
    },
    {
      title: t('log_details'),
      field: 'content',
      sorting: false,
      filtering: false,
      searchable: false,
      grouping: false,
      width: 200,
      // eslint-disable-next-line react/display-name
      render: ({ content }) => <FactContentCell facts={content} />,
    },
  ]
  return { columns }
}
