import { makeStyles } from '@material-ui/core'
import { colors, spacing, rounded } from '../../utils/style-guide'

export const useStyles = makeStyles({
  label: {
    display: 'block',
    marginBottom: spacing.xxs,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  searchContainer: {
    position: 'relative',
  },
  inputRoot: {
    ...rounded.m,
    marginTop: 0,
    height: 41.625, // To match height of other inputs (use compact prop if you want something shorter)
    padding: spacing.xxs,
    width: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight10,
    '&.error, &.error:hover': {
      borderColor: colors.signalError100,
      backgroundColor: colors.neutralLight0,
    },
  },
  inputRootCompact: {
    ...rounded.m,
    height: spacing.m,
    marginTop: 0,
    padding: `3px ${spacing.xxs}`,
    width: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight10,
    '&.error, &.error:hover': {
      borderColor: colors.signalError100,
      backgroundColor: colors.neutralLight0,
    },
    '&:hover:not(:focus)': {
      borderColor: colors.neutralLight40,
    },
    '& input': {
      paddingTop: spacing.xxxs,
      paddingBottom: spacing.xxxs,
    },
  },
  disabled: {
    borderColor: 'rgba(204, 204, 204, 0.3)',
  },
  hover: {
    borderColor: colors.neutralLight40,
  },
  focused: {
    borderColor: colors.brand100,
    backgroundColor: colors.neutralLight0,
  },
  inputElement: {
    padding: 0,
  },
  warningMessage: {
    position: 'absolute',
    bottom: -spacing.xs,
    left: 0,
  },
  fullWidth: {
    width: '100%',
  },
  clearButton: {
    padding: '0 !important',
    margin: 0,
    backgroundColor: 'transparent !important',
    minWidth: '0 !important',
  },
})
