import type { Activity } from './types'
import { useGetDecisionOutputsQuery } from './types'

interface UseFormActivityHook {
  loading: boolean
  outputs: string | undefined
}

export const useDecisionActivity = ({
  activity,
}: {
  activity: Activity
}): UseFormActivityHook => {
  const { id: activity_id } = activity

  const {
    data,
    loading,
  } = useGetDecisionOutputsQuery({
    variables: {
      pathway_id: activity.stream_id,
      activity_id,
    },
  })

  return {
    loading,
    outputs: data?.decisionOutputs.outputs,
  }
}
