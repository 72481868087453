// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { isEmpty, isNil } from 'lodash'
import { Text } from '@awell/ui-kit/components/Typography'
import { sanitizeHtml } from '@awell/ui-kit/utils'
import { useEmrDetail } from '../../hooks/useEmrDetail'
import { Activity, EmrReportMetadataField } from './types'

interface EmrActivityDetailsProps {
  activity: Activity
  onLoadEMRReport: (metadata?: Array<EmrReportMetadataField>) => void
}

export const EmrActivityDetails: FC<EmrActivityDetailsProps> = ({
  activity,
  onLoadEMRReport,
}) => {
  const { t } = useTranslation(['translation', 'activityfeed'])
  const emrReportId = activity.object.id
  const { report, loading } = useEmrDetail(emrReportId)

  useEffect(() => {
    if (!loading && report?.metadata) {
      onLoadEMRReport(report.metadata)
    }
  }, [report])

  if (loading) {
    return <Spinner />
  }

  return (
    <div id='message-preview'>
      <Text variant='textRegular'>
        {isNil(report?.message_html) && isEmpty(report?.message_html) ? (
          t('translation:message_content_missing')
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(report.message_html),
            }}
          />
        )}
      </Text>
    </div>
  )
}

EmrActivityDetails.displayName = 'EMR Activity Details'
