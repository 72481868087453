// @ts-nocheck TODO: remove this comment and fix errors
import { makeStyles } from '@material-ui/core'
import { colors, spacing, rounded, shadows } from '../../utils/style-guide'

export const useDropdownStyles = makeStyles({
  dropdown: (props: {
    optionsToDisplay?: number
    hasSubheader?: boolean
    smallGroup?: boolean
  }) => ({
    ...rounded.m,
    ...shadows.l,
    fontSize: '14px',
    backgroundColor: colors.neutralLight0,
    left: 0,
    padding: '8px',
    position: 'absolute',
    width: '100%',
    zIndex: 100,
    overflowY: 'auto',
    maxHeight:
      // FIXME: While dimensions are exact (due to 8pt system), this
      // styling is still ~brittle as it relies on unrelated spacing
      // styles in order: per option, subheader, dropdown padding
      `calc(${props.optionsToDisplay * parseFloat(spacing.l)}rem + ${
        props.hasSubheader === true ? spacing.s : '0rem'
      } + ${spacing.s})`,
  }),
  subheader: {
    paddingLeft: '8px',
    paddingTop: 0,
  },
  optionGroup: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  bottom: {
    top: '100%',
  },
  top: {
    bottom: '100%',
  },
})
