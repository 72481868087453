import React, { type FC } from 'react'
import { type RouteComponentProps } from '@reach/router'

import { Alert } from '@awell-health/design-system'
import { useTranslation } from 'react-i18next'

export const FallbackPage: FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  return (
    <div className='p-6'>
      <Alert variant='warning' title={t('fallback_title')}>
        <div>{t('fallback_description')}</div>
      </Alert>
    </div>
  )
}

FallbackPage.displayName = 'FallbackPage'
