/* eslint-disable react/require-default-props */
import React from 'react'
import {
  FormHelperText,
  InputLabel as MuiInputLabel,
  makeStyles,
} from '@material-ui/core'
import { InlineText } from '../Typography'
import { colors } from '../../utils/style-guide'
import { isEmpty, isNil } from 'lodash'

interface InputLabelProps {
  label: string
  required?: boolean
  description?: string
  htmlFor?: string
  knowledgeBaseLink?: {
    link: string
    label: string
  }
}

const useStyles = makeStyles({
  description: {
    marginBottom: 0,
    marginTop: 0,
  },
})

export const InputLabel = ({
  htmlFor,
  required = false,
  description,
  label,
  knowledgeBaseLink,
}: InputLabelProps): JSX.Element => {
  const classes = useStyles()
  const hasDescription = !isNil(description) && !isEmpty(description)
  const hasKnowledgeBaseLink =
    !isNil(knowledgeBaseLink) && !isEmpty(knowledgeBaseLink.link)
  return (
    <div style={{ flexDirection: 'column' }}>
      <MuiInputLabel
        htmlFor={htmlFor}
        required={required}
        style={{
          color: colors.red100,
        }}
      >
        <InlineText variant='textMedium'>{label}</InlineText>
      </MuiInputLabel>
      {hasDescription && (
        <FormHelperText className={classes.description}>
          <InlineText variant='textRegular' color={colors.neutralMid200}>
            {description}
            {hasKnowledgeBaseLink && (
              <a href={knowledgeBaseLink.link} target='_blank' rel='noreferrer'>
                {` `}
                {knowledgeBaseLink.label}
              </a>
            )}
          </InlineText>
        </FormHelperText>
      )}
    </div>
  )
}
