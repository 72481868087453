// @ts-nocheck TODO: remove this comment and fix errors
import React, { ReactNode, CSSProperties } from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { colors } from '../../utils/style-guide'
import { lineClamp } from '../../utils/line-clamp'

export interface TypographyProps {
  children: string | string[] | JSX.Element | ReactNode
  variant?: TypeVariants | string
  color?: string
  span?: boolean
  fitContent?: boolean
  responsive?: boolean
  className?: string
  style?: CSSProperties
  clamp?: number
}

export enum TypeVariants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h7',
  h8 = 'h8',
  regular = 'regular',
  medium = 'medium',
  subtle = 'subtle',
  small = 'small',
  input = 'input',
  link = 'link',
}

export const typographyStyles = {
  regular: {
    color: colors.neutralDark800,
    fontSize: '1rem', // 14px
    fontWeight: 400,
    lineHeight: '1.429rem', // 20px
  },
  medium: {
    color: colors.neutralDark800,
    fontSize: '1rem', // 14px
    fontWeight: 500,
    lineHeight: '1.429rem', // 20px
  },
  small: {
    color: colors.neutralDark500,
    fontSize: '0.857rem', // 12px
    fontWeight: 400,
    lineHeight: '1rem', // 14px
  },
  xsmall: {
    color: colors.neutralDark500,
    fontSize: '0.714rem', // 10px
    fontWeight: 500,
    lineHeight: '1.143rem', // 16px
  },
  subtle: {
    color: colors.neutralDark500,
    fontSize: '1rem', // 14px
    fontWeight: 400,
    lineHeight: '1.429rem', // 20px
  },
  input: {
    color: colors.neutralMid100,
    fontSize: '1rem', // 14px
    fontWeight: 400,
    lineHeight: '1.429rem', // 20px
  },
  link: {
    color: colors.brand100,
    fontSize: '1rem', // 14px
    fontWeight: 400,
    lineHeight: '1.429rem', // 20px
  },
  h1: {
    color: colors.neutralDark800,
    fontSize: '2.286rem', // 32px
    fontWeight: 500,
    lineHeight: '2.857rem', // 40px
  },
  h2: {
    color: colors.neutralDark800,
    fontSize: '2rem', // 28px
    fontWeight: 500,
    lineHeight: '2.286rem', // 32px
  },
  h3: {
    color: colors.neutralDark800,
    fontSize: '1.714rem', // 24px
    fontWeight: 500,
    lineHeight: '2rem', // 28px
  },
  h4: {
    color: colors.neutralDark800,
    fontSize: '1.286rem', // 18px
    fontWeight: 500,
    lineHeight: '1.714rem', // 24px
  },
  h5: {
    color: colors.neutralDark800,
    fontSize: '1.143rem', // 16px
    fontWeight: 500,
    lineHeight: '1.429rem', // 20px
  },
  h6: {
    color: colors.neutralDark800,
    fontSize: '1rem', // 14px
    fontWeight: 500,
    lineHeight: '1.143rem', // 16px
  },
  h7: {
    color: colors.neutralDark800,
    fontSize: '0.857rem', // 12px
    fontWeight: 800,
    lineHeight: '1.143rem', // 16px
  },
  h8: {
    color: colors.neutralMid200,
    fontSize: '0.786rem', // 11px
    fontWeight: 800,
    lineHeight: '1.143rem', // 16px
  },
}

const useStyles = makeStyles({
  ...typographyStyles,
  baseType: ({
    fitContent,
    clamp,
    span,
  }: {
    fontSize?: string
    lineHeight?: string
    span?: boolean
    fitContent?: boolean
    clamp?: number
  }) => ({
    ...lineClamp(clamp),
    width: fitContent ? 'fit-content' : '100%',
    display: fitContent && span ? 'inline' : '-webkit-box',
  }),
  responsive: ({
    fontSize,
    lineHeight,
  }: {
    fontSize?: string
    lineHeight?: string
    span?: boolean
    fitContent?: boolean
    clamp?: number
  }) => ({
    '@media (max-width: 500px)': {
      fontSize: `calc(${fontSize} * 0.8571)`,
      lineHeight: `calc(${lineHeight} * 0.8571)`,
    },
  }),
})

export const Type: React.FC<TypographyProps> = ({
  children,
  variant = TypeVariants.regular,
  color = undefined,
  span = false,
  responsive = false,
  style = {},
  className = '',
  fitContent = false,
  clamp = 1000,
  ...props
}) => {
  const getFontSizeNumber = fontSizeInRems =>
    Number(fontSizeInRems.split('rem')[0])

  const classes = useStyles({
    fontSize:
      getFontSizeNumber(typographyStyles[variant].fontSize) <= 1
        ? null
        : typographyStyles[variant].fontSize,
    lineHeight:
      getFontSizeNumber(typographyStyles[variant].fontSize) <= 1
        ? null
        : typographyStyles[variant].fontSize,
    fitContent,
    clamp,
    span,
  })

  return span ? (
    <span
      className={classnames(className, classes[variant], classes.baseType, {
        [classes.responsive]: responsive,
      })}
      style={{ color, ...style }}
      {...props}
    >
      {children}
    </span>
  ) : (
    <div
      className={classnames(className, classes[variant], classes.baseType, {
        [classes.responsive]: responsive,
      })}
      style={{ color, ...style }}
      {...props}
    >
      {children}
    </div>
  )
}

Type.displayName = 'Type'
