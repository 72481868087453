import { makeStyles } from '@material-ui/core'
import { spacing, shadows, colors } from '../../utils/style-guide'

export const useHeaderStyles = makeStyles({
  appHeader: {
    ...spacing.paddingX.s,
  },
  appHeaderBoxShadow: {
    ...shadows.xs,
  },
  appHeaderWithBorder: {
    borderBottom: `1px solid ${colors.neutralLight50}`,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.neutralLight50,
  },
})
