import { Input, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { colors, spacing } from '../../../utils/style-guide'
import { useFormBuilderContext } from '../useFormBuilderContext'

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: `${spacing.xxxs} 0 0 ${spacing.xxs}`,
    fontWeight: 500,

    '&::before': {
      content: 'normal',
    },

    '&::after': {
      content: 'normal',
    },
  },
  required: {
    color: colors.red,
    paddingLeft: spacing.xxs,
  },
})

interface QuestionTitleEditorProps {
  title: string
  onChange: (title: string) => void
  autoFocus: boolean
  mandatory: boolean
}

export const QuestionTitleEditor: FC<QuestionTitleEditorProps> = ({
  title,
  onChange,
  mandatory,
}) => {
  const classes = useStyles()
  const { labels } = useFormBuilderContext()

  // TODO: Make autofocus depend on prop (when a new question is added)
  // Problem right now if I understand correctly is that
  // 1. Input component is rendered without autofocus
  // 2. Question is selected by form builder context
  // 3. Input component is updated with autofocus
  // => Autofocus doesn't work at this stage
  // Tried using a ref to manually focus the input component, didn't work either
  // Limitation of the current approach is that when a form is initially loaded, the
  // last question title input is focused. Better that than no autofocus at all.
  return (
    <>
      <Input
        value={title}
        placeholder={labels.question_title_placeholder}
        onChange={event => {
          onChange(event.target.value)
        }}
        classes={{
          root: classes.root,
        }}
        autoFocus
        multiline
      />
      {mandatory && <span className={classes.required}>*</span>}
    </>
  )
}

QuestionTitleEditor.displayName = 'QuestionTitleEditor'
