import * as React from 'react'

function SvgStep(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm1 5h6v6H5V5zm8 8h6v6h-6v-6z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgStep
