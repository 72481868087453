import { Skeleton as MuiSkeleton, SkeletonProps } from '@material-ui/lab'
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { rounded } from '../../utils/style-guide'

const useStyles = makeStyles({
  wave: {
    ...rounded.m,
    backgroundColor: '#e7e7e7',
    '&::after': {
      animationDuration: '.8s',
      background: 'linear-gradient(90deg, transparent, #f1f1f1, transparent)',
    },
  },
})
export const Skeleton: FC<SkeletonProps> = ({
  animation = 'wave',
  ...props
}) => {
  const classes = useStyles()
  return (
    <MuiSkeleton
      {...props}
      animation={animation}
      classes={{ wave: classes.wave }}
    />
  )
}
