// @ts-nocheck TODO: remove this comment and fix errors
/** flattens an object into a single level
 *
 * EXAMPLE INPUT:
 * const object = {
 *   message_id: "XTZhsonhXTpj",
 *   attachment: {
 *     name: "",
 *     url: "",
 *     type: "VIDEO"
 *   }
 * }
 *
 * RESULTING OUTPUT:
 * {
 *   message_id: 'XTZhsonhXTpj',
 *   'attachment-name': '',
 *   'attachment-url': '',
 *   'attachment-type': 'VIDEO'
 * }
 */
export const flattenObject = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  if (typeof obj !== 'object' || Array.isArray(obj)) return {}
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([iKey, iValue]) => {
        acc[`${key}-${iKey}`] = iValue
      })
    } else {
      acc[key] = value
    }
    return acc
  }, {})
}
