import * as React from 'react'

function SvgFeedCalculation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 4a4 4 0 014-4h24a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4z'
        fill='#F4F5F7'
      />
      <path
        d='M21 19.5h-2M14 20.859l-2.718-2.718M11.282 20.859L14 18.14'
        stroke='#5E6C84'
        strokeLinecap='round'
      />
      <rect
        x={8.75}
        y={8.75}
        width={14.5}
        height={14.5}
        rx={2.25}
        stroke='#5E6C84'
        strokeWidth={1.5}
      />
      <path
        stroke='#5E6C84'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.75 15.758h14.5M16.525 16.289v6.444'
      />
    </svg>
  )
}

export default SvgFeedCalculation
