import { makeStyles } from '@material-ui/core'
import { spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing.s,
  },
})
