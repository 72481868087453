import * as React from 'react'

function SvgFeedForm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 4a4 4 0 014-4h24a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4z'
        fill='#F4F5F7'
      />
      <rect
        x={7.75}
        y={8.75}
        width={16.5}
        height={14.5}
        rx={2.25}
        stroke='#5E6C84'
        strokeWidth={1.5}
      />
      <rect
        x={11.75}
        y={15.75}
        width={8.5}
        height={3.5}
        rx={1.25}
        stroke='#5E6C84'
        strokeWidth={1.5}
      />
      <path
        stroke='#5E6C84'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M11.75 12.25h5.5'
      />
    </svg>
  )
}

export default SvgFeedForm
