import React, { type FC, type KeyboardEvent } from 'react'
import { ChevronRight } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { colors } from '../../utils/style-guide'
import { useStyles } from './useStyles'

export interface CardListItemProps {
  onListItemClick: () => void
  onKeyboardEvent?: (event: KeyboardEvent<HTMLDivElement>) => void
  icon: JSX.Element | null
}

export const CardListItem: FC<CardListItemProps> = ({
  onKeyboardEvent,
  onListItemClick,
  children,
  icon,
}) => {
  const classes = useStyles()
  return (
    <div
      role='button'
      tabIndex={0}
      onKeyDown={onKeyboardEvent}
      onClick={onListItemClick}
      className={classes.activityItemContainer}
    >
      <div>
        <IconWrapper bare size='m'>
          {icon}
        </IconWrapper>
      </div>
      <div className={classes.activityDetails}>{children}</div>
      <div>
        <IconWrapper size='s' color={colors.neutralMid300}>
          <ChevronRight />
        </IconWrapper>
      </div>
    </div>
  )
}

CardListItem.displayName = 'CardListItem'
