import React, { type ReactElement } from 'react'
import { getHandler } from '@udecode/plate'
import { getRootProps } from '@udecode/plate-styled-components'
import { useFocused, useSelected } from 'slate-react'
import {
  type MentionInputElementProps,
  type MentionInputElementStyleProps,
} from './MentionInputElement.types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: (props: MentionInputElementStyleProps) => ({
    margin: '0 2px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    padding: '1px 8px 0px',
    borderRadius: 4,
    backgroundColor: '#eee',
    boxShadow:
      props.selected === true && props.focused === true
        ? '0 0 0 2px #B4D5FF'
        : 'none',
  }),
})

export const MentionInputElement = (
  props: MentionInputElementProps,
): ReactElement => {
  const { attributes, children, nodeProps, element, as, onClick } = props

  const rootProps = getRootProps(props)

  const selected = useSelected()
  const focused = useFocused()

  const styles = useStyles({
    ...props,
    selected,
    focused,
  })

  return (
    <span
      {...attributes}
      // eslint-disable-next-line react/no-unknown-property
      as={as}
      data-slate-value={element.value}
      onClick={getHandler(onClick, element)}
      {...rootProps}
      {...nodeProps}
      className={styles.root}
    >
      {children}
    </span>
  )
}
