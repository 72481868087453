import {
  type DynamicVariable,
  type DynamicVariableNode,
  ELEMENT_DYNAMIC_VARIABLE,
  NON_EXISTENT_ELEMENT_DYNAMIC_VARIABLE,
} from './types'

/**
 * Create a Slate node for a dynamic variable.
 * The custom `variableId` property is used to render the variable name
 * in the editor.
 * The child text value is using handlebars syntax to make it easy to replace
 * variable values at runtime.
 */
export const createDynamicVariableNode = (
  variable: Pick<DynamicVariable, 'id'>,
): DynamicVariableNode => ({
  type: ELEMENT_DYNAMIC_VARIABLE,
  variableId: variable.id,
  children: [{ text: `{{{${variable.id}}}}` }],
})

/**
 * Create a Slate node for non-existent variable ONLY for visual representation.
 * This is placeholder informing user that there is missing variable after pasting content between the different pathways
 * Variables that have an id that does not exists in that pathway are replaced on 'on save'.
 * LIMITATIONS this will not work when the user will create and use variable and then remove it from different view
 * ->publishing will still lead to error
 */
export const createNonExistentDynamicVariableNode = (): Omit<
  DynamicVariableNode,
  'variableId'
> => ({
  type: NON_EXISTENT_ELEMENT_DYNAMIC_VARIABLE,
  children: [{ text: '' }],
})
