import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { isNil } from 'lodash'
import { updateQuery } from '@awell/libs-web/graphql'
import { useApolloClient } from '@apollo/client'
import { useNotifications } from '../useNotifications'
import {
  useGetApiCallsQuery,
  useOnApiCallCreatedSubscription,
  useOnApiCallUpdatedSubscription,
  ApiCall,
} from './types'
import {
  GetApiCallsDocument,
  GetApiCallsQuery,
} from '../../generated/types-orchestration'

interface useGetApiCallsHook {
  loading: boolean
  apiCalls?: Array<ApiCall>
}

export const useGetApiCalls = (id?: string): useGetApiCallsHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()
  const variables = { pathway_id: id || '' }
  const { data, loading, error } = useGetApiCallsQuery({
    variables,
  })
  const client = useApolloClient()

  const onApiCallCreated = useOnApiCallCreatedSubscription({
    variables,
  })

  useOnApiCallUpdatedSubscription({ variables })

  const apiCalls = data?.apiCalls.api_calls ?? []
  const updateApiCallsQuery = ({
    updatedApiCalls,
  }: {
    updatedApiCalls: Array<ApiCall>
  }) => {
    if (!data) {
      return
    }

    const updatedQuery = updateQuery<GetApiCallsQuery, Array<ApiCall>>(
      data,
      ['apiCalls', 'api_calls'],
      updatedApiCalls,
    )
    client.writeQuery({
      query: GetApiCallsDocument,
      variables,
      data: updatedQuery,
    })
  }
  useEffect(() => {
    if (!isNil(onApiCallCreated.data)) {
      const {
        data: { apiCallCreated },
      } = onApiCallCreated
      const updatedApiCalls: Array<ApiCall> = [apiCallCreated, ...apiCalls]

      updateApiCallsQuery({
        updatedApiCalls,
      })
    }
  }, [onApiCallCreated.data])
  if (error) {
    notifyError({
      message: t('error_load_api_call_plural'),
      error,
    })
    return { loading: false }
  }

  if (loading) {
    return { loading: true }
  }
  return {
    loading: false,
    apiCalls,
  }
}
