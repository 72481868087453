/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Clear from '@material-ui/icons/Clear'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import { Search } from '@material-ui/icons'
import { Cross } from '../Icons'

const iconStyle = {
  fontSize: 16,
}

type Props = { TablePros: any }

export const TableIcons = {
  Clear: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <Clear {...props} ref={ref} style={iconStyle} />
  )),
  Filter: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <Search {...props} ref={ref} style={iconStyle} />
  )),
  Search: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <Search {...props} ref={ref} style={iconStyle} />
  )),
  ResetSearch: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <Cross {...props} ref={ref} style={iconStyle} />
  )),
  FirstPage: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <FirstPage {...props} ref={ref} style={iconStyle} />
  )),
  LastPage: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <LastPage {...props} ref={ref} style={iconStyle} />
  )),
  NextPage: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <ChevronRight {...props} ref={ref} style={iconStyle} />
  )),
  PreviousPage: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <ChevronLeft {...props} ref={ref} style={iconStyle} />
  )),
  SortArrow: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <ArrowDownward {...props} ref={ref} style={iconStyle} />
  )),
  ChevronRight: forwardRef<Props, null>((props, ref) => (
    // @ts-expect-error I'm not sure how to fix this but it does not look like it causes issues
    <ChevronRight {...props} ref={ref} style={iconStyle} />
  )),
}
