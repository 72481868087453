import * as React from 'react'

function SvgCheckmarkRound(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={32} cy={32} r={32} fill='#E3FCEF' />
      <circle cx={32} cy={32} r={23} fill='#ABF5D1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.589 24.55a1.333 1.333 0 012.246 1.426l-.09.142-9.088 13.496-6.409-9.506a1.333 1.333 0 012.065-1.68l.105.13 4.258 6.494L37.59 24.55z'
        fill='#00875A'
      />
    </svg>
  )
}

export default SvgCheckmarkRound
