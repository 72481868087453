import React from 'react'
import classnames from 'classnames'
import { useStyles } from './useStyles'
import { Type, TypeVariants } from '../Typography'

export enum LozengeColors {
  RED = 'red',
  GREY = 'grey',
  YELLOW = 'yellow',
  BLUE = 'blue',
  GREEN = 'green',
}

export interface LozengeProps {
  content: string
  color?: LozengeColors
  className?: string
  compact?: boolean
}

export const Lozenge = ({
  content,
  color = LozengeColors.BLUE,
  className = '',
  compact = false,
}: LozengeProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.status, classes[color], className, {
        [classes.compact]: compact,
      })}
    >
      <Type className={classes.text} variant={TypeVariants.h7}>
        {content}
      </Type>
    </div>
  )
}

Lozenge.displayName = 'Lozenge'
