/* eslint-disable no-alert */
import React, { type FC, useState, useRef } from 'react'
import { makeStyles, Menu, MenuItem, Button } from '@material-ui/core'
import { Type } from '../../Typography'
import { IconWrapper } from '../../IconWrapper'
import { Attach, Document, Video, Link as LinkIcon } from '../../Icons'
import { colors, spacing } from '../../../utils/style-guide'
import { MessageAttachmentType } from '../../AttachmentItem'
import { Tooltip } from '../../Tooltip'
import { type AddAttachmentInput } from '../types'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

interface AttachmentToolProps {
  onAdd: (attachmentInput: AddAttachmentInput) => void
}

const useStyles = makeStyles({
  menu: {
    minWidth: '200px',
  },
  button: {
    padding: 0,
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg': {
        color: colors.brand100,
      },
    },
  },
  menuItemContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: spacing.xs,
    },
  },
})

export const AttachmentTool: FC<AttachmentToolProps> = ({ onAdd }) => {
  const { t } = useTranslation()

  const menuRef = useRef<HTMLElement>()
  const toggleRef = useRef<HTMLAnchorElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const openMenu = (): void => {
    if (!isNil(toggleRef?.current)) {
      setAnchorEl(toggleRef.current)
    }
    setTimeout(() => {
      if (!isNil(menuRef) && !isNil(menuRef?.current)) {
        const element = menuRef.current.querySelector('[role="menu"]')
        if (!isNil(element)) {
          ;(element as HTMLButtonElement).focus()
        }
      }
    }, 0)
  }

  const closeMenu = () => {
    setAnchorEl(undefined)
  }

  const handleMenuItemClick = (item: MessageAttachmentType): void => {
    closeMenu()
    setOpen(false)
    onAdd({
      name: '',
      url: '',
      type: item,
    })
  }

  React.useEffect(() => {
    open ? openMenu() : closeMenu()
  }, [open])

  return (
    <>
      <Tooltip info='Add Attachment' arrow>
        <Button
          href=''
          size='small'
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={() => {
            setOpen(!open)
          }}
          ref={toggleRef}
          disableRipple
          variant='text'
          classes={{ root: classes.button }}
        >
          <IconWrapper>
            <Attach />
          </IconWrapper>
        </Button>
      </Tooltip>
      <Menu
        classes={{ paper: classes.menu }}
        ref={menuRef}
        id='simple-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setOpen(false)
        }}
        variant='selectedMenu'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          dense
          onClick={() => {
            handleMenuItemClick(MessageAttachmentType.File)
          }}
        >
          <div className={classes.menuItemContent}>
            <IconWrapper size='s'>
              <Document />
            </IconWrapper>
          </div>
          <Type span fitContent>
            {t('attach_file')}
          </Type>
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            handleMenuItemClick(MessageAttachmentType.Video)
          }}
        >
          <div className={classes.menuItemContent}>
            <IconWrapper size='s'>
              <Video />
            </IconWrapper>
            <Type span fitContent>
              {t('attach_video')}
            </Type>
          </div>
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            handleMenuItemClick(MessageAttachmentType.Link)
          }}
        >
          <div className={classes.menuItemContent}>
            <IconWrapper size='s'>
              <LinkIcon />
            </IconWrapper>
            <Type span fitContent>
              {t('attach_link')}
            </Type>
          </div>
        </MenuItem>
      </Menu>
    </>
  )
}

AttachmentTool.displayName = 'AttachmentTool'
