// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable react/require-default-props */
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { TableSkeleton } from './TableSkeleton'
import { Skeleton } from './Skeleton'
import { spacing } from '../../utils/style-guide'

const useStyles = makeStyles<{ hasHeaderSubtitle: string }>({
  header: ({ hasHeaderSubtitle }: { hasHeaderSubtitle: string }) => ({
    height: '84px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: hasHeaderSubtitle ? spacing.m : 0,
  }),
  titleAndSearchBar: {
    width: '130px',
  },
  pageTitle: {
    marginBottom: spacing.xsm,
  },
})

interface FullPageListSkeletonProps<T> {
  cellCount?: number
  rowCount?: number
  hasHeaderButton?: boolean
  hasHeaderSubtitle?: boolean
  columns?: Array<T>
}

export const FullPageListSkeleton = <
  T extends { title?: string | React.ReactElement }
>({
  cellCount = 5,
  rowCount = 4,
  hasHeaderButton = true,
  hasHeaderSubtitle = true,
  columns = [],
}: FullPageListSkeletonProps<T>): JSX.Element => {
  const classes = useStyles({
    hasHeaderSubtitle,
  })

  return (
    <>
      <div className={classes.header}>
        <div className={classes.titleAndSearchBar}>
          <div className={classes.pageTitle}>
            <Skeleton variant='rect' width={240} height={40} />
          </div>
          {hasHeaderSubtitle && (
            <Skeleton variant='rect' width={240} height={36} />
          )}
        </div>
        {hasHeaderButton && <Skeleton variant='rect' width={130} height={40} />}
      </div>
      <TableSkeleton<T>
        rowsCount={rowCount}
        cellCount={cellCount}
        columns={columns}
      />
    </>
  )
}
