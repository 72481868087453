import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmrReportMetadataField } from '../../compositions/ActivityFeed'
import { colors } from '../../utils/style-guide'
import { Spinner } from '../Spinner'
import { Heading5, Text } from '../Typography'
import { useStyles } from './useStyles'

interface EmrMetadataTabContentProps {
  metadata: Array<EmrReportMetadataField> | undefined
}

export const EmrMetadataTabContent = ({
  metadata,
}: EmrMetadataTabContentProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  if (!metadata) {
    return <Spinner />
  }
  return (
    <motion.div className={classes.metadataContainer}>
      {metadata.map(_metadata => {
        const { label, value } = _metadata
        return (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            key={label}
            className={classes.metadata}
          >
            <Text variant='textSmall' color={colors.neutralLight100}>
              {label}
            </Text>
            {value ? (
              <Heading5>{value}</Heading5>
            ) : (
              <Text variant='textRegular' color={colors.neutralLight100}>
                {t('translation:no_value_provided')}
              </Text>
            )}
          </motion.div>
        )
      })}
    </motion.div>
  )
}

EmrMetadataTabContent.displayName = 'EmrMetadataTabContent'
