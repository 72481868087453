import { type Column } from '@awell/ui-kit/components/Table'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'
import { Delete } from '@awell/ui-kit/components/Icons'
import { IconButton } from '@awell/ui-kit/components/Button'
import { type PatientPathway } from '../types'
import { formatRelative, parseISO } from 'date-fns'
import { capitalize, isNil } from 'lodash'
import SvgStepStop from '@awell/ui-kit/components/Icons/StepStop'

interface PatientPathwayListColumnsProps {
  onDeletePathway: (patientPathway: PatientPathway) => void
  onStopPathway: (patientPathway: PatientPathway) => void
}

interface UsePatientPathwaysListColumnsHook {
  columnsForActivePathways: Array<Column<PatientPathway>>
  columnsForCompletedPathways: Array<Column<PatientPathway>>
  columnsForStoppedPathways: Array<Column<PatientPathway>>
}

export const usePatientPathwayListColumns = ({
  onDeletePathway,
  onStopPathway,
}: PatientPathwayListColumnsProps): UsePatientPathwaysListColumnsHook => {
  const { t } = useTranslation()

  const renderPathwayTitle = (pathway: PatientPathway): ReactElement => (
    <Link to={`/pathway/${pathway.id}`}>{pathway.title}</Link>
  )

  const renderStopAndDeleteButton = (pathway: PatientPathway): ReactElement => (
    <>
      <IconButton
        onClick={() => {
          onStopPathway(pathway)
        }}
      >
        <SvgStepStop />
      </IconButton>
      <IconButton
        onClick={() => {
          onDeletePathway(pathway)
        }}
      >
        <Delete />
      </IconButton>
    </>
  )

  const renderDeleteButton = (pathway: PatientPathway): ReactElement => (
    <IconButton
      onClick={() => {
        onDeletePathway(pathway)
      }}
    >
      <Delete />
    </IconButton>
  )

  const renderRelativeDate = (pathway: PatientPathway, field: string): string =>
    !isNil(pathway[field as keyof PatientPathway])
      ? capitalize(
          formatRelative(
            parseISO(pathway[field as keyof PatientPathway] as string),
            new Date(),
          ),
        )
      : '-'

  const baseColumns: Array<Column<PatientPathway>> = [
    {
      title: t('pathway_table_column_pathway_name'),
      field: 'title',
      type: 'string',
      sorting: true,
      filtering: false,
      searchable: false,
      width: 80,
      render: renderPathwayTitle,
    },
  ]

  const activityColumns: Array<Column<PatientPathway>> = [
    {
      title: t('active_activities'),
      field: 'activeActivities',
      sorting: false,
      filtering: false,
      searchable: false,
      render: data => <>{data.active_activities ?? 0}</>,
      width: 50,
      align: 'center',
    },
    {
      title: t('failed_activities'),
      field: 'failedActivities',
      sorting: false,
      filtering: false,
      searchable: false,
      render: data => <>{data.failed_activities ?? 0}</>,
      width: 50,
      align: 'center',
    },
  ]

  const lastActivityColumn: Array<Column<PatientPathway>> = [
    {
      title: t('last_activity'),
      field: 'lastActivity',
      sorting: true,
      filtering: false,
      searchable: false,
      defaultSort: 'desc',
      customSort: (a, b) => {
        if (isNil(a.latest_activity_date) || isNil(b.latest_activity_date))
          return 0
        const aDate = parseISO(a.latest_activity_date)
        const bDate = parseISO(b.latest_activity_date)
        return aDate.getTime() - bDate.getTime()
      },
      render: data => renderRelativeDate(data, 'latest_activity_date'),
      width: 50,
      align: 'center',
    },
  ]

  const careflowStatusColumns: Array<Column<PatientPathway>> = [
    {
      title: t('completed_date'),
      field: 'completedDate',
      sorting: true,
      filtering: false,
      searchable: false,
      defaultSort: 'desc',
      customSort: (a, b) => {
        if (isNil(a.complete_date) || isNil(b.complete_date)) return 0
        const aDate = parseISO(a.complete_date)
        const bDate = parseISO(b.complete_date)
        return aDate.getTime() - bDate.getTime()
      },
      render: data => renderRelativeDate(data, 'complete_date'),
      width: 50,
      align: 'center',
    },
  ]

  const reasonColumn: Array<Column<PatientPathway>> = [
    {
      title: t('stopped_date'),
      field: 'stoppedDate',
      sorting: false,
      filtering: false,
      searchable: false,
      customSort: (a, b) => {
        if (isNil(a.stop_date) || isNil(b.stop_date)) return 0
        const aDate = parseISO(a.stop_date)
        const bDate = parseISO(b.stop_date)
        return aDate.getTime() - bDate.getTime()
      },
      render: data => renderRelativeDate(data, 'stop_date'),
      width: 50,
      align: 'center',
    },
    {
      title: t('reason'),
      field: 'status_explanation',
      sorting: false,
      filtering: false,
      searchable: false,
      width: 64,
    },
  ]

  const actionColumnsForActive: Array<Column<PatientPathway>> = [
    {
      field: 'action',
      sorting: false,
      filtering: false,
      searchable: false,
      render: renderStopAndDeleteButton,
      width: 38,
    },
  ]

  const actionColumns: Array<Column<PatientPathway>> = [
    {
      field: 'action',
      sorting: false,
      filtering: false,
      searchable: false,
      render: renderDeleteButton,
      width: 25,
    },
  ]

  return {
    columnsForActivePathways: [
      ...baseColumns,
      ...activityColumns,
      ...lastActivityColumn,
      ...actionColumnsForActive,
    ],
    columnsForCompletedPathways: [
      ...baseColumns,
      ...careflowStatusColumns,
      ...lastActivityColumn,
      ...actionColumns,
    ],
    columnsForStoppedPathways: [
      ...baseColumns,
      ...reasonColumn,
      ...actionColumns,
    ],
  }
}
