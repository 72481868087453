import React, { FC } from 'react'
import { useStyles } from './useStyles'

export const NotificationMessageContentActions: FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.contentActions}>{children}</div>
}

NotificationMessageContentActions.displayName =
  'NotificationMessageContentActions'
