import { format, formatDistanceToNow } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '../Tooltip'
import { TIME_FORMAT_FOR_ACTIVITY } from './types'
import { colors } from '../../utils/style-guide'
import { IconWrapper } from '../IconWrapper'
import { Clock } from '../Icons'
import { Text } from '../Typography'
import { useItemStyles } from './useStyles'

export const TimeAgoIndicator = ({
  timestampDate,
}: {
  timestampDate: Date
}): JSX.Element => {
  const { t } = useTranslation()
  const classes = useItemStyles()

  return (
    <Tooltip
      info={t('activity_activation_time', {
        time: format(timestampDate, TIME_FORMAT_FOR_ACTIVITY),
      })}
      arrow
      placement='bottom'
    >
      <div className={classes.iconWithText}>
        <IconWrapper size='xs' color={colors.neutralMid300}>
          <Clock />
        </IconWrapper>
        <Text variant='textSmall' color={colors.neutralMid300}>
          {formatDistanceToNow(timestampDate, { addSuffix: true })}
        </Text>
      </div>
    </Tooltip>
  )
}

TimeAgoIndicator.displayName = 'TimeAgoIndicator'
