// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable no-console */
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  format,
} from 'date-fns'
import { isNil, max, min } from 'lodash'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GanttStatic } from './dhtmlxGantt/dhtmlxgantt'
import { getGanttInstance } from './GanttInstance'
import { zoomConfig, ZoomLevel } from './GanttConfig'
import { GanttData, GanttFilters, GanttLink } from './types'
import { useFilters } from './useFilters'

interface ZoomLevelDisabledState {
  zoomInDisabled: boolean
  zoomOutDisabled: boolean
}

interface UseDhtmlxGanttHook {
  container: MutableRefObject<HTMLDivElement>
  zoomIn: () => void
  zoomOut: () => void
  zoomToFit: () => void
  zoomState: ZoomLevelDisabledState
}

interface Props {
  data: Array<GanttData>
  links: Array<GanttLink>
  filters: GanttFilters
  onTaskSelected: (task: GanttData) => void
  onTaskRowClick: (task: GanttData) => void
  showTodayMarker?: boolean
}

export const useDhtmlGantt = ({
  data,
  links,
  filters,
  onTaskSelected,
  onTaskRowClick,
  showTodayMarker = false,
}: Props): UseDhtmlxGanttHook => {
  const { t } = useTranslation()
  const container = useRef<HTMLDivElement>(null)
  const [gantt, setGantt] = useState<GanttStatic>(undefined)
  const [zoomState, setCurrentZoomLevel] = useState<ZoomLevelDisabledState>({
    zoomInDisabled: false,
    zoomOutDisabled: false,
  })
  const { applyFilters } = useFilters(filters)

  const setZoomLevelState = (ganttInstance: GanttStatic): void => {
    if (!isNil(ganttInstance)) {
      const currentZoomLevel = ganttInstance.ext.zoom.getCurrentLevel()
      setCurrentZoomLevel({
        zoomInDisabled: currentZoomLevel <= 0,
        zoomOutDisabled: currentZoomLevel >= 5,
      })
    }
  }
  const sortByDate = (ganttInstance: GanttStatic): void => {
    if (!isNil(ganttInstance)) {
      ganttInstance.sort('start_date', false)
    }
  }
  const setGanttRange = (ganttInstance: GanttStatic) => {
    if (!isNil(ganttInstance)) {
      const zoomLevel = ganttInstance.ext.zoom.getCurrentLevel()
      const addTimeUnitBasedOnCurrentZoomLevel = () => {
        switch (zoomLevel) {
          case 0:
            return addDays
          case 1:
            return addDays
          case 2:
            return addWeeks
          case 3:
            return addMonths
          case 4:
            return addQuarters
          case 5:
            return addYears
          default:
            return addYears
        }
      }

      // we need to update config after zoom changes
      // eslint-disable-next-line no-param-reassign
      ganttInstance.config.start_date = addTimeUnitBasedOnCurrentZoomLevel()(
        min(data.map(e => e.start_date)) as Date,
        -10,
      )
      // eslint-disable-next-line no-param-reassign
      ganttInstance.config.end_date = addTimeUnitBasedOnCurrentZoomLevel()(
        max(data.map(e => e.end_date ?? e.start_date)) as Date,
        10,
      )
    }
  }
  const scrollToTimelineStart = (ganttInstance: GanttStatic) => {
    if (!isNil(ganttInstance)) {
      const borderLeftOffset = 50
      const position =
        ganttInstance.posFromDate(new Date(min(data.map(e => e.start_date)))) -
        borderLeftOffset
      const currentScrollVertical = ganttInstance.getScrollState().y
      ganttInstance.scrollTo(position, currentScrollVertical)
    }
  }

  const scrollToLastTask = (
    ganttInstance: GanttStatic,
    ganttData: Array<GanttData>,
  ) => {
    const lastTaskId = ganttData[ganttData.length - 1]?.id
    if (!isNil(lastTaskId)) {
      const lastTask = ganttInstance.getTask(lastTaskId)
      const sizes = ganttInstance.getTaskPosition(
        lastTask,
        lastTask.planned_start,
        lastTask.planned_end,
      )
      ganttInstance.scrollTo(sizes.left, sizes.top)
    }
  }
  useEffect(() => {
    if (container.current) {
      const newGanttInstance = getGanttInstance(t)
      newGanttInstance.init(container.current)
      setGantt(newGanttInstance)
    }
  }, [container])

  useEffect(() => {
    if (!isNil(gantt)) {
      const onBeforeParseEventId = gantt.attachEvent(
        'onBeforeParse',
        () => {
          gantt.clearAll()
        },
        {},
      )

      let todayMarkerId: string
      if (showTodayMarker) {
        const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
        todayMarkerId = gantt.addMarker({
          start_date: new Date(),
          css: 'today',
          title: dateToStr(new Date()),
        })
      }
      setGanttRange(gantt)
      gantt.render()
      scrollToTimelineStart(gantt)
      return () => {
        gantt.detachEvent(onBeforeParseEventId)
        if (!isNil(todayMarkerId)) {
          gantt.deleteMarker(todayMarkerId)
        }
        gantt.destructor()
      }
    }
    return undefined
  }, [gantt])

  useEffect(() => {
    if (!isNil(gantt)) {
      const onTaskSelectedEventId = gantt.attachEvent(
        'onTaskSelected',
        id => onTaskSelected(gantt.getTask(id)),
        {},
      )
      const onTaskRowClickEventId = gantt.attachEvent(
        'onTaskRowClick',
        id => onTaskRowClick(gantt.getTask(id)),
        {},
      )
      return () => {
        gantt.detachEvent(onTaskSelectedEventId)
        gantt.detachEvent(onTaskRowClickEventId)
      }
    }
    return undefined
  }, [gantt, data])

  // This avoids extra rendering. See here: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const memoizedData = useMemo(() => data, [data])

  useEffect(() => {
    let markerId: string
    if (!isNil(gantt) && data?.length > 0) {
      gantt.parse({ data, links })
      if (!showTodayMarker) {
        // Marker is shown on the latest completed or activate task
        const markerDate = new Date(
          max(data.map(task => task.end_date ?? task.start_date)),
        )
        markerId = gantt.addMarker({
          start_date: markerDate,
          css: 'today',
          title: format(markerDate, 'd MMMM yyyy'),
        })
      }
      scrollToLastTask(gantt, memoizedData)
    }
    return () => {
      if (!isNil(gantt) && !isNil(markerId)) {
        gantt.deleteMarker(markerId)
      }
    }
  }, [gantt, memoizedData])

  useEffect(() => {
    let onBeforeTaskDisplayEventId: string
    if (!isNil(gantt)) {
      onBeforeTaskDisplayEventId = gantt.attachEvent(
        'onBeforeTaskDisplay',
        applyFilters,
        {},
      )
      sortByDate(gantt)
      gantt.refreshData()
    }
    return () => {
      if (!isNil(gantt) && !isNil(onBeforeTaskDisplayEventId)) {
        gantt.detachEvent(onBeforeTaskDisplayEventId)
      }
    }
  }, [filters, gantt])

  const zoomIn = () => {
    if (!isNil(gantt)) {
      gantt.ext.zoom.zoomIn()
      setZoomLevelState(gantt)
      setGanttRange(gantt)
      gantt.render()
      scrollToTimelineStart(gantt)
    }
  }
  const zoomOut = () => {
    if (!isNil(gantt)) {
      gantt.ext.zoom.zoomOut()
      setZoomLevelState(gantt)
      setGanttRange(gantt)
      gantt.render()
      scrollToTimelineStart(gantt)
    }
  }

  const zoomToFit = () => {
    if (!isNil(gantt)) {
      const {
        start_date,
        end_date,
      }: { start_date?: Date; end_date?: Date } = gantt.getSubtaskDates()
      // @ts-expect-error Gantt type unfortunately does not include this property
      // but it does hold the width available to show tasks
      const tasksWidth = gantt.$task.offsetWidth

      const doTasksFit = (
        zoomLevel: ZoomLevel,
        availableWidth: number,
        startDate: Date,
        endDate: Date,
      ): boolean => {
        const computeRelativeDifference = () => {
          switch (zoomLevel.name) {
            case 'minute':
              return differenceInMinutes(endDate, startDate) / 15
            case 'hour':
              return differenceInHours(endDate, startDate)
            case 'day':
              return differenceInCalendarDays(endDate, startDate)
            case 'week':
              return differenceInWeeks(endDate, startDate)
            case 'month':
              return differenceInMonths(endDate, startDate)
            case 'quarter':
              return differenceInQuarters(endDate, startDate)
            default:
              return differenceInYears(endDate, startDate)
          }
        }
        const relativeDifference = computeRelativeDifference()
        return relativeDifference * zoomLevel.min_column_width <= availableWidth
      }

      const level = zoomConfig.levels.find(zoomLevel =>
        doTasksFit(zoomLevel, tasksWidth, start_date, end_date),
      )
      gantt.ext.zoom.setLevel(level.name)
      setGanttRange(gantt)
      gantt.render()
      scrollToTimelineStart(gantt)
    }
  }

  return { container, zoomIn, zoomOut, zoomToFit, zoomState }
}
