import React from 'react'
import { useStyles } from './useStyles'

interface CodeLineCounterProps {
  rowsCount: number
  // eslint-disable-next-line react/require-default-props
  offsetTop?: number // default prop is provided, the compiler just does not see it
}

export const CodeLineCounter = ({
  rowsCount,
  offsetTop = 0,
}: CodeLineCounterProps): JSX.Element => {
  const classes = useStyles({ offsetTop })
  const rows = Array.from({ length: rowsCount }, (_, i) => i + 1)

  return (
    <div className={classes.counterContainer}>
      {rows.map(row => (
        <div key={`row-count-${row}`} className={classes.counter}>
          {row}
        </div>
      ))}
    </div>
  )
}

CodeLineCounter.displayName = 'CodeLineCounter'
