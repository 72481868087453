import React, { type FC, type ChangeEvent } from 'react'
import {
  Switch as MaterialSwitch,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import { colors, fonts } from '../../utils/style-guide'

interface SwitchProps {
  checked: boolean
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string
  labelVariant?: 'normal' | 'bold'
  disabled?: boolean
  contentBoxOnly?: boolean
}

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: '.5rem',
  },
  contentBoxOnly: {
    margin: 0,
    padding: 0,
    border: 0,
  },
  switchBase: {
    padding: '4px',
    '&$checked': {
      transform: 'translateX(16px)',
      color: colors.neutralLight0,
      '& + $track': {
        backgroundColor: colors.signalSuccess100,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 24 / 2,
    backgroundColor: colors.neutralLight50,
    opacity: 1,
  },
  checked: {
    transform: 'translateX(16px)',
    '& + $track': {
      backgroundColor: colors.signalSuccess100,
      opacity: 1,
      border: 'none',
    },
  },
  labelNormal: {
    fontWeight: fonts.weight.normal,
  },
  labelBold: {
    fontWeight: fonts.weight.bold,
  },
})

export const Switch: FC<SwitchProps> = ({
  checked,
  onChange,
  labelPlacement,
  label,
  disabled,
  contentBoxOnly = false,
  labelVariant = 'normal',
}) => {
  const classes = useStyles()
  const hasLabel = label != null
  return (
    <div>
      {!hasLabel && (
        <MaterialSwitch
          checked={checked ?? false}
          onChange={onChange}
          classes={{
            root: classnames(classes.root, {
              [classes.contentBoxOnly]: contentBoxOnly,
            }),
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          disabled={disabled}
        />
      )}
      {hasLabel && (
        <FormControlLabel
          labelPlacement={labelPlacement}
          label={label}
          classes={{
            label: classnames({
              [classes.labelNormal]: labelVariant === 'normal',
              [classes.labelBold]: labelVariant === 'bold',
            }),
          }}
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <MaterialSwitch
              checked={checked ?? false}
              onChange={onChange}
              disabled={disabled}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          }
        />
      )}
    </div>
  )
}
Switch.displayName = 'Switch'
