import {
  createPlateUI,
  ELEMENT_PARAGRAPH,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LINK,
  StyledElement,
  withProps,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
} from '@udecode/plate'
import { colors, fonts } from '../../utils/style-guide'
import { LinkElement } from './LinkElement'

export const components = createPlateUI({
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    as: 'p',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.429rem',
      },
    },
  }),
  [ELEMENT_H1]: withProps(StyledElement, {
    as: 'h1',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1.875em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
  [ELEMENT_H2]: withProps(StyledElement, {
    as: 'h2',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1.5em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
  [ELEMENT_H3]: withProps(StyledElement, {
    as: 'h3',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1.25em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
  [ELEMENT_H4]: withProps(StyledElement, {
    as: 'h4',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1.1em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
  [ELEMENT_H5]: withProps(StyledElement, {
    as: 'h5',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1.1em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
  [ELEMENT_H6]: withProps(StyledElement, {
    as: 'h6',
    styles: {
      root: {
        color: colors.neutralDark800,
        fontSize: '1.1em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
  [ELEMENT_LINK]: withProps(LinkElement, {
    as: 'a',
    styles: {
      root: {
        color: colors.brand100,
      },
    },
  }),
  [ELEMENT_CODE_BLOCK]: withProps(StyledElement, {
    as: 'pre',
    styles: {
      root: {
        counterReset: 'line',
        counterIncrement: 'block',
        color: colors.brand100,
        margin: 0,
        background: 'transparent',
        overflow: 'hidden',
        paddingLeft: '40px',
        '&:after': {
          content: 'counter(block)',
          marginRight: 10,
          display: `inline-flex`,
          justifyContent: 'center',
          width: '30px',
          position: 'absolute',
          left: 0,
          top: 30,
          bottom: 0,
          backgroundColor: colors.neutralLight30,
          color: colors.neutralLight30,
        },
      },
    },
  }),
  [ELEMENT_CODE_LINE]: withProps(StyledElement, {
    as: 'div',
    styles: {
      root: {
        fontSize: 12,
        ...fonts.code,
        position: 'relative',
        zIndex: 2,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        marginRight: 6,

        '&:before': {
          counterIncrement: 'line',
          content: 'counter(line)',
          display: `inline-flex`,
          justifyContent: 'center',
          width: '30px',
          marginLeft: '-40px',
          marginRight: '6px',
          backgroundColor: colors.neutralLight30,
          fontSize: '0.857rem', // 12px
          lineHeight: '20px',
          fontWeight: 'bold',
          alignItems: 'center',
          fontFamily: 'Roboto, sans-serif',
          color: colors.neutralLight100,
        },
        '&:first-child:before': {
          paddingTop: '6px',
        },
        '&:last-child:before': {
          paddingBottom: '6px',
        },
      },
    },
  }),
})
