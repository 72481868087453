/* eslint-disable react/prop-types */
import { type PlatePluginComponent, type TElement } from '@udecode/plate'
import classnames from 'classnames'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { type RenderElementProps } from 'slate-react'
import { colors } from '../../../utils/style-guide'
import { Logo } from '../../Icons'
import { IconWrapper } from '../../IconWrapper'
import { Tooltip } from '../../Tooltip'
import { useStakeholdersContext } from './StakeholdersContextProvider'

import { useStyles } from './useStyles'

interface HostedPagesLinkElementProps {
  stakeholderId: string
}

interface Props {
  attributes: RenderElementProps['attributes']
  element: TElement<HostedPagesLinkElementProps>
  children: JSX.Element
}

export const HostedPagesLinkElement: PlatePluginComponent<Props> = ({
  attributes,
  element,
  children,
}) => {
  const hostedPagesLinkElementRef = useRef<HTMLElement>(null)
  const classes = useStyles()
  const { t } = useTranslation()

  const { stakeholders } = useStakeholdersContext()

  const handleLinkNodeClick = (): void => {
    // do nothing ? (TBD)
  }

  const currentStakeholder = stakeholders.find(
    s => s.id === element.stakeholderId,
  )

  const label = currentStakeholder?.label?.en ?? ''
  const colon = label === '' ? '' : ':'
  const fullLabel = `${t('activity_link')}${colon} ${label}`

  return (
    <div {...attributes} className={classes.wrapper}>
      <Tooltip
        info={t('stakeholder_hosted_pages_tooltip', {
          stakeholder: label,
        })}
        arrow
      >
        <div>
          <span
            onClick={handleLinkNodeClick}
            role='presentation'
            className={classnames(classes.container, {})}
            ref={hostedPagesLinkElementRef}
          >
            <span style={{ marginRight: 2 }}>
              <IconWrapper color={colors.brand100} size='xs'>
                <Logo />
              </IconWrapper>
            </span>
            {fullLabel}
            {children}
          </span>
        </div>
      </Tooltip>
    </div>
  )
}
