import * as React from 'react'

function SvgEmrFailure(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill='#E12D39' />
      <path
        d='M12.014 15.003c-1.021-.039-2.021.27-2.794.862a.61.61 0 00-.005.934.83.83 0 001.062.019 2.853 2.853 0 011.737-.481 2.856 2.856 0 011.734.478c.3.233.755.219 1.036-.034a.612.612 0 00.02-.916c-.772-.591-1.77-.9-2.79-.862zM10 12c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.897 2-2zM16 10c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z'
        fill='#fff'
      />
      <path
        d='M19.5 9.5h.5a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5h-.5v-6zM2.5 11A1.5 1.5 0 014 9.5h.5v6H4A1.5 1.5 0 012.5 14v-3zM14 5.5A1.5 1.5 0 0115.5 7v.5h-7V7A1.5 1.5 0 0110 5.5h4z'
        stroke='#fff'
      />
      <rect x={4.5} y={7.5} width={15} height={12} rx={1.5} stroke='#fff' />
      <circle cx={12} cy={3} r={1.5} stroke='#fff' />
    </svg>
  )
}

export default SvgEmrFailure
