import * as React from 'react'

function SvgLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.222 19.778a4.984 4.984 0 003.535 1.462 4.989 4.989 0 003.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 01-4.243 0 3.005 3.005 0 010-4.243l2.83-2.828L7.05 9.879l-2.829 2.828a5.006 5.006 0 000 7.071zm15.556-8.485a5.008 5.008 0 000-7.071 5.006 5.006 0 00-7.07 0L9.878 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 014.243 0 3.005 3.005 0 010 4.243l-2.829 2.828 1.414 1.414 2.83-2.828z'
        fill='currentColor'
      />
      <path
        d='M8.464 16.95L7.05 15.536l8.487-8.486 1.414 1.415-8.486 8.485z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgLink
