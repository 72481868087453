import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { type ScheduledTrack, useGetScheduledTracksQuery } from './types'

interface UseScheduledTracksHook {
  loading: boolean
  scheduledTracks?: Array<ScheduledTrack>
}

export const useScheduledTracks = (
  pathwayId: string,
): UseScheduledTracksHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()
  const variables = {
    pathway_id: pathwayId,
  }
  const { data, loading, error } = useGetScheduledTracksQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  })

  const scheduledTracks = (
    data?.scheduledTracksForPathway.scheduled_tracks ?? []
  ).map(scheduledTrack => {
    return {
      id: scheduledTrack.id,
      name: scheduledTrack.title,
      type: 'track',
      start_date: scheduledTrack.scheduled_date,
    }
  })

  if (!isNil(error)) {
    notifyError({
      message: t('error_loading_scheduled_tracks'),
      error,
    })
  }

  return { loading, scheduledTracks }
}
