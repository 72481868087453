import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Maybe } from '../../utils'
import { colors } from '../../utils/style-guide'
import { Button } from '../Button'
import { Plus, Search } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { InputField } from '../InputField'
import { List } from '../List'
import { ScrollContainer } from '../ScrollContainer'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

interface Item {
  id: string
  label?: Maybe<string>
}

interface GenericSelectionDialogProps<T> {
  items: Array<T>
  title: string
  description?: string
  onSelect: (item: T) => void
  onSearch: (query: string) => void
  onCreateItem?: () => void | Promise<void>
  createButtonTitle?: string
  searchPlaceholder?: string
  knowledgeBaseLink?: string
}

export const GenericSelectionDialog = <T extends Item>({
  items = [],
  title,
  onSelect,
  onCreateItem,
  onSearch,
  createButtonTitle = 'Create new',
  searchPlaceholder = 'Search',
  description,
  knowledgeBaseLink,
}: GenericSelectionDialogProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleClick = (item: T) => () => {
    onSelect(item)
  }

  const handleSearch = (query: string): void => {
    setSearchTerm(query)
    onSearch(query)
  }

  return (
    <div className={classes.container}>
      <Type className={classes.title} variant={TypeVariants.h4}>
        {title}
      </Type>
      {!isNil(description) && (
        <Type className={classes.description} variant={TypeVariants.subtle}>
          {description}
        </Type>
      )}
      {!isNil(knowledgeBaseLink) && (
        <Type
          className={classes.knowledgeBaseLink}
          variant={TypeVariants.subtle}
        >
          <a href={knowledgeBaseLink} target='_blank' rel='noreferrer'>
            {t('learn_more')}
          </a>
        </Type>
      )}
      <InputField
        value={searchTerm}
        onChange={handleSearch}
        iconEnd={<Search />}
        compact
        placeholder={searchPlaceholder}
        className={classes.searchBar}
      />
      <ScrollContainer className={classes.searchResultsContainer}>
        <List>
          {items.map(item => (
            <button
              type='button'
              onClick={handleClick(item)}
              className={classes.searchItem}
              key={item.id}
            >
              {!isNil(item.label) ? (
                <Type variant={TypeVariants.regular}>{item.label}</Type>
              ) : (
                <Type variant={TypeVariants.subtle}>{t('untitled')}</Type>
              )}
            </button>
          ))}
        </List>
      </ScrollContainer>
      {!isNil(onCreateItem) && (
        <div className={classes.buttonContainer}>
          <Button
            variant='text'
            onClick={onCreateItem}
            className={classes.createButton}
            startIcon={
              /* eslint-disable react/jsx-wrap-multilines */
              <IconWrapper style={{ color: colors.blue400 }} size='xxs'>
                <Plus />
              </IconWrapper>
            }
          >
            {createButtonTitle}
          </Button>
        </div>
      )}
    </div>
  )
}
