import React from 'react'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { TableSkeleton } from '@awell/ui-kit/components/Skeleton/TableSkeleton'

export const useStyles = makeStyles({
  actionBar: {
    gridArea: 'actionBar',
  },
  previewContent: {
    gridArea: 'previewContent',
  },
  actionSkeletonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${spacing.xs}`,
    borderBottom: `1px solid ${colors.neutralLight40}`,
    backgroundColor: colors.neutralLight0,
    paddingBottom: spacing.s,
    marginBottom: spacing.xs,
  },
  actionSkeleton: {
    marginRight: spacing.xs,
  },
})

export const WebhookCallListSkeleton = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div>
      <div
        className={classnames(
          classes.actionBar,
          classes.actionSkeletonContainer,
        )}
      >
        <div style={{ display: 'flex' }}>
          <Skeleton
            variant='rect'
            width={320}
            height={40}
            className={classes.actionSkeleton}
          />
          <Skeleton
            variant='rect'
            width={126}
            height={40}
            className={classes.actionSkeleton}
          />
        </div>

        <Skeleton
          variant='rect'
          width={160}
          height={40}
          className={classes.actionSkeleton}
        />
      </div>

      <TableSkeleton rowsCount={5} cellCount={5} />
    </div>
  )
}

WebhookCallListSkeleton.displayName = 'WebhookCallListSkeleton'
