import { useTranslation } from 'react-i18next'
import { ApiCall } from '../useApiCall'
import { useNotifications } from '../useNotifications'
import { useGetApiCallQuery } from './types'

interface useGetApiCallsHook {
  loading: boolean
  apiCall?: ApiCall
}

export const useGetPathwayApiCall = (id: string): useGetApiCallsHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const { data, loading, error } = useGetApiCallQuery({
    variables: { id },
  })

  if (error) {
    notifyError({
      message: t('error_load_api_call_plural'),
      error,
    })
    return { loading: false }
  }

  if (loading) {
    return { loading: true }
  }
  const apiCall = data?.apiCall.api_call
  return {
    loading: false,
    apiCall,
  }
}
