import React, { type FC } from 'react'
import { Button } from '../Button'
import { Text } from '../Typography'
import { colors, spacing } from '../../utils/style-guide'
import { IconWrapper } from '../IconWrapper'

interface DropdownButtonProps {
  title: string
  onClick: () => void
  type?: 'default' | 'danger'
  withSeparator?: boolean
  startIcon?: React.ReactNode
  disabled?: boolean
}

export const DropdownOption: FC<DropdownButtonProps> = ({
  onClick,
  title,
  type = 'default',
  withSeparator = false,
  startIcon,
  disabled = false,
  ...dataAttributes
}) => {
  const buttonStyle: React.CSSProperties = {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: withSeparator ? `1px solid ${colors.neutralLight40}` : 'none',
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    borderRadius: 0,
  }

  const contentContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-start',
  }

  const textColor = disabled
    ? colors.neutralLight50
    : type === 'danger'
    ? colors.red100
    : colors.neutralDark800

  return (
    <Button
      {...dataAttributes}
      onClick={onClick}
      variant='text'
      style={buttonStyle}
      appearance={type === 'danger' ? 'danger' : undefined}
      disabled={disabled}
    >
      <span style={contentContainerStyle}>
        {startIcon !== undefined && (
          <IconWrapper
            size='xxs'
            style={{
              marginRight: spacing.xs,
            }}
            color={textColor}
            inline
          >
            {startIcon}
          </IconWrapper>
        )}
        <Text color={textColor}>{title}</Text>
      </span>
    </Button>
  )
}

DropdownOption.displayName = 'DropdownOption'
