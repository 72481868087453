import React, { type FC, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertModal } from '../AlertModal'

export interface DeleteConfirmationLabels {
  heading?: string
  primaryAction?: string
  secondaryAction?: string
}

interface DeleteConfirmationProps {
  onClose: () => void
  onDelete: (item_id?: string) => void
  labels?: DeleteConfirmationLabels
  children?: ReactNode
}

/**
 * Asks the user to confirm action
 */
export const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
  onClose,
  onDelete,
  labels,
  children,
}) => {
  const { t } = useTranslation()

  const modalLabels = {
    heading: t('delete_modal_header_default'),
    primaryAction: t('delete_modal_primary_button'),
    secondaryAction: t('delete_modal_secondary_button'),
    ...labels,
  }
  const { heading, primaryAction, secondaryAction } = modalLabels

  const onConfirm = () => {
    onDelete()
    onClose()
  }

  return (
    <AlertModal
      labels={{ heading, primaryAction, secondaryAction }}
      open
      onAction={onConfirm}
      onClose={onClose}
      appearance='danger'
    >
      {children}
    </AlertModal>
  )
}

DeleteConfirmation.displayName = 'DeleteConfirmation'
