import classnames from 'classnames'
import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { borders, colors, rounded, spacing } from '../../utils/style-guide'
import { Checkmark, Dot } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { useIconStyles } from './styles'

const useStyles = makeStyles({
  radioIcon: {
    '& > *': {
      borderRadius: spacing.icon,
    },
  },
  checkboxIcon: {
    '& > *': {
      ...rounded.m,
    },
  },
  unchecked: {
    ...borders.m,
    borderColor: colors.neutralDark500,
  },
})

interface IconProps {
  checked?: boolean
  hover?: boolean
  focus?: boolean
  disabled?: boolean
  error?: boolean
  className?: string
}

const Icon: React.FC<IconProps & { type: 'checkbox' | 'radio' }> = ({
  type,
  checked = false,
  hover = false,
  focus = false,
  disabled = false,
  error = false,
  className,
}) => {
  const icon = useIconStyles()
  const classes = useStyles()

  const DotIcon = type === 'radio' && (
    <IconWrapper light>
      <Dot />
    </IconWrapper>
  )

  const CheckIcon = type === 'checkbox' && (
    <IconWrapper
      className={classnames({
        [classes.unchecked]: !checked,
      })}
      color={colors.neutralLight0}
    >
      {checked ? <Checkmark /> : null}
    </IconWrapper>
  )

  return (
    <span
      className={classnames(className, icon.base, {
        [classes.checkboxIcon]: type === 'checkbox',
        [classes.radioIcon]: type === 'radio',
        [icon.error]: error,
        filled: checked,
        empty: !checked,
        hover,
        focus,
        disabled,
      })}
    >
      {DotIcon}
      {CheckIcon}
    </span>
  )
}
Icon.displayName = 'Icon'

export const RadioIcon: React.FC<IconProps> = props => (
  <Icon type='radio' {...props} />
)
RadioIcon.displayName = 'RadioIcon'
export const CheckBoxIcon: React.FC<IconProps> = props => (
  <Icon type='checkbox' {...props} />
)
CheckBoxIcon.displayName = 'CheckBoxIcon'
