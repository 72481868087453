import React, {
  FC,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  DeleteConfirmation,
  DeleteConfirmationLabels,
} from '../DeleteConfirmation'
import { Delete } from '../Icons'
import { IconButton } from '../Button'

interface DeleteIconButtonProps {
  onDelete: () => void
  tooltip?: string
  labels?: DeleteConfirmationLabels
  icon?: ReactElement
  children?: ReactNode
  disabled?: boolean
}

export const DeleteIconButton: FC<DeleteIconButtonProps> = ({
  onDelete,
  tooltip = '',
  labels,
  icon = <Delete />,
  children,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const [confirmDeleteElement, setConfirmDeleteElement] = useState(false)

  const deleteConfirmationLabels = {
    heading: t('delete_modal_header_default'),
    primaryAction: t('delete_modal_primary_button'),
    secondaryAction: t('delete_modal_secondary_button'),
    ...labels,
  }

  const askForConfirmation = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setConfirmDeleteElement(true)
  }

  return (
    <>
      <IconButton
        iconSize='xsm'
        tooltip={tooltip || t('delete')}
        onClick={askForConfirmation}
        disabled={disabled}
        aria-label='delete' // don't remove or change this, it's used to detect should we focus Element
      >
        {icon}
      </IconButton>
      {confirmDeleteElement && (
        <DeleteConfirmation
          onDelete={onDelete}
          onClose={() => setConfirmDeleteElement(false)}
          labels={deleteConfirmationLabels}
        >
          {children}
        </DeleteConfirmation>
      )}
    </>
  )
}

DeleteIconButton.displayName = 'DeleteButton'
