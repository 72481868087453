import { useGetBaselineInfoQuery, type BaselineDataPoint } from './types'
import { useNotifications } from '../useNotifications'

interface UseBaselineInfoHook {
  loading: boolean
  baselineDataPoints?: Array<BaselineDataPoint>
}

export const useBaselineInfo = (pathway_id: string): UseBaselineInfoHook => {
  const { notifyError } = useNotifications()
  const { data, loading, error } = useGetBaselineInfoQuery({
    variables: {
      pathway_id,
    },
  })

  if (error != null) {
    notifyError({
      message: 'something went  wrong while loading baseline info data points',
      error,
    })
    return { loading: false }
  }
  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    baselineDataPoints: data?.baselineInfo?.baselineDataPoints ?? [],
  }
}
