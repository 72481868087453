import { makeStyles } from '@material-ui/core'
import { colors, type, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  title: {
    paddingTop: spacing.xs,
    paddingLeft: spacing.xs,
  },
  container: {
    background: colors.neutralLight0,
    width: 300,
  },
  searchBar: {
    padding: spacing.xs,
  },
  description: {
    color: colors.neutralMid200,
    paddingTop: spacing.xs,
    paddingLeft: spacing.xs,
  },
  searchResultsContainer: {
    ...type.s,
    height: spacing.list.s,
    borderTop: `2px solid ${colors.neutralLight40}`,
    borderBottom: `2px solid ${colors.neutralLight40}`,
  },
  searchItem: {
    padding: `${spacing.xxs} ${spacing.xs}`,
    width: '100%',
    textAlign: 'left',
    '&:hover': {
      background: colors.neutralLight20,
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    padding: `${spacing.xxs} ${spacing.xs}`,
  },
  createButton: {
    width: '100%',
    backgroundColor: colors.neutralLight20,
    textTransform: 'capitalize',
  },
  knowledgeBaseLink: {
    paddingLeft: spacing.xs,
    textDecoration: 'underline',
  },
})
