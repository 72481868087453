/**
 * Find a child within a childs list that match a given component type.
 *
 * @example
 * children.find(findChild(ComponentType))
 * // or
 * findChild(ComponentType)(ReactComponent)
 */
export function findChild(type: React.ElementType) {
  return function execute(child: React.ReactElement) {
    return child && child.type === type
  }
}
