import { makeStyles } from '@material-ui/core'
import { spacing, colors, rounded } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  // ACTIVITY DETAILS DIALOG CONTENT
  contentHeader: {
    padding: `${spacing.xs} ${spacing.xs}`,
    borderTop: `1px solid ${colors.neutralLight30}`,
    borderBottom: `1px solid ${colors.neutralLight30}`,
  },
  contentHeaderTitle: {
    marginBottom: spacing.xxs,
  },
  contentSubheader: {
    '& div:first-child': {
      marginBottom: spacing.xxs,
    },
  },
  contentBody: {
    padding: `${spacing.xs} ${spacing.xs}`,
  },
  retryButton: {
    display: 'none',
  },
  // CALCULATION ACTIVITY DETAILS
  calculationData: {
    paddingTop: spacing.xs,
  },
  calculationDisclaimer: {
    paddingBottom: spacing.xs,
  },
  calculationDocumentation: {
    paddingTop: spacing.xs,
  },
  messageSummaryContainer: {
    paddingBottom: spacing.xs,
  },
  activityAssignmentContainer: {
    paddingBottom: spacing.xs,
    paddingTop: spacing.xs,
  },
  messageHeader: {
    marginBottom: spacing.xsm,
  },
  summaryContainer: {
    paddingBottom: spacing.xs,
  },
  jsonContainer: {
    ...rounded.m,
    background: colors.neutralLight20,
    marginTop: spacing.xxs,
    padding: spacing.s,
  },
  formPrefillSelect: {
    marginBottom: spacing.xs,
  },
})
