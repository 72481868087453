// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fade, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { type TransitionProps } from '@material-ui/core/transitions'
import { isEmpty, isNil } from 'lodash'
import React, { type FC, type ReactElement, useState } from 'react'
import { isUri } from 'valid-url'
import { Button } from '../../Button'
import { Divider } from '../../Divider'
import { Link } from '../../Icons'
import { InputField } from '../../InputField'
import { Type, TypeVariants } from '../../Typography'
import { DialogType } from '../types'
import { useStyles } from './useStyles'

interface ToolbarModalLabels {
  heading: string
  primaryAction: string
  deleteAction?: string
  cancelAction: string
  placeholderUrl: string
  placeholderDisplayName?: string
}

interface ToolbarModalProps {
  labels?: ToolbarModalLabels
  onSubmit: (value: string) => void
  onRemove?: () => void
  onClose: () => void
  open: boolean
  initialValue?: string
  type?: DialogType
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<never, never> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />
})

const DEFAULT_INITIAL_URL = 'https://'

export const MediaToolbarModal: FC<ToolbarModalProps> = ({
  labels = {
    heading: 'Insert media',
    primaryAction: 'Insert',
    deleteAction: 'Remove',
    cancelAction: 'Cancel',
    placeholderUrl: 'Paste the link here',
  },
  onClose,
  onRemove,
  onSubmit,
  open,
  initialValue = DEFAULT_INITIAL_URL,
  type,
}) => {
  const classes = useStyles()
  const { heading, primaryAction, deleteAction, cancelAction } = labels
  const [url, setUrl] = useState<string>(initialValue)
  const [urlError, setUrlError] = useState(null)

  const handleChangeUrl = (newValue: string) => {
    setUrlError(null)
    if (isEmpty(newValue)) {
      return
    }
    if (!isNil(isUri(newValue))) {
      setUrl(newValue)
    } else {
      setUrlError('Not a valid URL')
    }
  }

  const handleSubmit = () => {
    onSubmit(url)
    onClose()
  }

  const handleRemove = () => {
    onRemove()
    onClose()
  }

  const showRemoveButton =
    initialValue !== DEFAULT_INITIAL_URL && initialValue !== ''

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      hideBackdrop
      disablePortal
      onClose={onClose}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      style={{
        position: 'absolute',
        inset: 'auto',
        width: 'fit-content',
        height: 'fit-content',
      }}
      classes={{
        paper: classes.paper,
      }}
    >
      <div id='alert-dialog-slide-title' className={classes.header}>
        <div className={classes.title}>
          <Type variant={TypeVariants.h4}>{heading}</Type>
        </div>
      </div>
      {type !== DialogType.image && (
        <>
          <DialogContent classes={{ root: classes.rootContent }}>
            <InputField
              placeholder={labels.placeholderUrl}
              autoFocus
              compact
              debounced
              value={url}
              error={urlError}
              onChange={newValue => {
                handleChangeUrl(newValue)
              }}
              label=''
              className={classes.inputField}
              iconEnd={<Link />}
            />
          </DialogContent>
          <Divider height={2} />
          <DialogActions classes={{ root: classes.rootFooter }}>
            <Button onClick={onClose} variant='text' fullWidth>
              {cancelAction}
            </Button>
            {showRemoveButton && (
              <Button onClick={handleRemove} appearance='danger' fullWidth>
                {deleteAction}
              </Button>
            )}
            <Button
              onClick={handleSubmit}
              color='primary'
              disabled={!isEmpty(urlError) || isEmpty(url)}
              fullWidth
            >
              {primaryAction}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

MediaToolbarModal.displayName = 'MediaToolbarModal'
