import React, { type KeyboardEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMultipleSelectOptionsEditor } from '../FormBuilder/builderContexts/useMultipleSelectQuestionOptions'
import { colors, spacing } from '../../utils/style-guide'
import { Button } from '../Button'
import { AlertModal } from '../AlertModal'
import { IconWrapper } from '../IconWrapper'
import { Duplicate } from '../Icons'
import { Tooltip } from '../Tooltip'
import { useStylesCopy } from './useStyles'
import { InlineText, Text } from '../Typography'
import { type CopyMultipleChoiceOptionsProps } from './types'
import { getParsedOptions } from './utils/utils'

export const CopyMultipleChoiceOptions = ({
  onSave,
  options,
  onCopyQuestionOptions,
  dataPointValueType,
}: CopyMultipleChoiceOptionsProps): JSX.Element => {
  const classes = useStylesCopy()
  const { t } = useTranslation()
  const { isCopyOptionsEditorOpen, onToggleOptionsEditor } =
    useMultipleSelectOptionsEditor()
  const [optionsString, setOptionsString] = useState('')
  const handleRemoveAllOptions = () => {
    onSave([])
    onToggleOptionsEditor(false)
  }

  const preventQuestionChange: KeyboardEventHandler<
    HTMLTextAreaElement
  > = event => {
    event.stopPropagation()
  }

  useEffect(() => {
    setOptionsString(options.map(e => `${e.value}=${e.label}`).join('\n'))
  }, [isCopyOptionsEditorOpen])

  const handleSave = () => {
    if (optionsString.trim().length === 0) {
      handleRemoveAllOptions()
      return
    }

    const parsedOptions = getParsedOptions(optionsString, dataPointValueType)
    onSave(parsedOptions.options)
    onToggleOptionsEditor(false)
  }
  return (
    <div className={classes.wrapper}>
      <AlertModal
        labels={{
          heading: t('import_export_options_modal_title'),
          primaryAction: t('save'),
          secondaryAction: t('cancel'),
        }}
        open={isCopyOptionsEditorOpen}
        onAction={() => {
          handleSave()
        }}
        onClose={() => {
          onToggleOptionsEditor(false)
        }}
        additionalActionButton={
          /* eslint-disable react/jsx-wrap-multilines */
          <Tooltip info={t('question_copy_options_keyboard_shortcut_tip')}>
            <Button
              onClick={() => {
                onCopyQuestionOptions(optionsString)
                onToggleOptionsEditor(false)
              }}
              variant='text'
              startIcon={
                /* eslint-disable react/jsx-wrap-multilines */
                <IconWrapper size='xxs' color={colors.brand100}>
                  <Duplicate />
                </IconWrapper>
              }
            >
              <InlineText color={colors.brand100} variant='textMedium'>
                {t('copy_to_clipboard')}
              </InlineText>
            </Button>
          </Tooltip>
        }
      >
        <div className={classes.wrapper}>
          <Text spacing={`0 0 ${spacing.s}`}>
            {t('import_export_options_modal_description')}
          </Text>
          <div>
            <textarea
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className={classes.optionsArea}
              rows={10}
              value={optionsString}
              onKeyDown={preventQuestionChange}
              placeholder={t('import_export_options_placeholder')}
              onChange={e => {
                setOptionsString(e.target.value)
              }}
            />
          </div>
        </div>
      </AlertModal>
    </div>
  )
}

CopyMultipleChoiceOptions.displayName = 'CopyMultipleChoiceOptions'
