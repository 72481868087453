import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  editButtonContainer: {
    paddingRight: spacing.xxxs,
    paddingLeft: spacing.xxxs,
    height: 'auto',
  },
  addItemButtonContainer: {
    ...spacing.paddingY.xs,
  },
  listContainer: {
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    '& li:last-child': {
      borderBottom: 'none',
    },
  },
  paddedX: {
    ...spacing.paddingX.xs,
  },
  flexGrow: {
    flexGrow: 1,
  },
  inlineItems: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1.5rem',
  },
  listItem: {
    paddingLeft: '1.5rem',
  },
})
