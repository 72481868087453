import { type RouteComponentProps } from '@reach/router'
import React, { type FC } from 'react'
import { type Patient } from '../types'
import {
  PatientContactInformation,
  PatientPersonalInformation,
  PatientIdentifiers,
} from './Profile'

interface ProfileTabProps {
  patient: Patient
}

export const ProfileTab: FC<RouteComponentProps & ProfileTabProps> = ({
  patient,
}) => {
  return (
    <>
      <PatientIdentifiers patient={patient} />
      <PatientPersonalInformation patient={patient} />
      <PatientContactInformation patient={patient} />
    </>
  )
}
