/* eslint-disable react/require-default-props */
import React from 'react'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { sanitizeHtml } from '../../utils'
import { ChevronDown } from '../Icons'
import { Text } from '../Typography'
import { IconWrapper } from '../IconWrapper'
import { colors, spacing } from '../../utils/style-guide'
import { MuiAccordion, MuiAccordionSummary } from './useStyles'

interface AccordionProps {
  title: string
  subTitle?: string
  body: string
  id: string
  expanded: boolean
  onExpandToggle: (id: string) => void
}

export const Accordion = ({
  title,
  subTitle,
  body,
  id,
  onExpandToggle,
  expanded,
}: AccordionProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <MuiAccordion expanded={expanded} onChange={() => { onExpandToggle(id); }}>
      <MuiAccordionSummary
        expandIcon={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <IconWrapper color={colors.neutralMid70}>
            <ChevronDown />
          </IconWrapper>
        }
        aria-controls={`accordion-panel-${id}`}
        id={id}
      >
        <Text color={colors.neutralMid300} spacing={`0 ${spacing.xxs} 0 0`}>
          {title}
        </Text>
        {subTitle && (
          <Text variant='textTinyHint' color={colors.neutralMid300}>
            {subTitle}
          </Text>
        )}
      </MuiAccordionSummary>
      <AccordionDetails>
        {isNil(body) || isEmpty(body) ? (
          <Text variant='textRegular' color={colors.neutralMid200}>
            {t('translation:message_content_missing')}
          </Text>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(body),
            }}
          />
        )}
      </AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.displayName = 'Accordion'
