import {
  MARK_BOLD,
  MARK_ITALIC,
  MarkToolbarButton,
  BalloonToolbar,
  getPluginType,
  ELEMENT_LINK,
  getAbove,
  type TAncestor,
  type AnyObject,
  type PlateEditor,
} from '@udecode/plate'
import React, { type FC } from 'react'
import FormatBold from '@material-ui/icons/FormatBold'
import FormatItalic from '@material-ui/icons/FormatItalic'
import { type NodeEntry } from 'slate'
import { Tooltip } from '../../Tooltip'
import { ToolbarExternalLink } from './ToolbarExternalLink'
import { useEditor } from '../useEditor'
import { isNil } from 'lodash'

export const HoveringToolbar: FC<{ editorId: string }> = ({ editorId }) => {
  const { editorState: editor } = useEditor(editorId)
  if (isNil(editor) || isNil(editor.selection)) return null

  // Hovering toolbar options
  const arrow = true
  const theme = 'dark'

  // Look for a URL in the selection to decide whether to show the ExternalLink button
  const getSelectedLinkNode = (_editor: PlateEditor) => {
    const type = getPluginType(_editor, ELEMENT_LINK)
    return getAbove(_editor, {
      at: _editor?.selection?.anchor,
      match: { type },
    })
  }

  const getUrl = (linkNode: NodeEntry<TAncestor<AnyObject>>) =>
    !isNil(linkNode) ? (linkNode[0].url as string) : ''

  const url = getUrl(getSelectedLinkNode(editor)!)

  return (
    <BalloonToolbar theme={theme} arrow={arrow}>
      <Tooltip info='Bold' arrow>
        <span>
          <MarkToolbarButton
            type={getPluginType(editor, MARK_BOLD)}
            icon={<FormatBold />}
          />
        </span>
      </Tooltip>
      <Tooltip info='Italic' arrow>
        <span>
          <MarkToolbarButton
            type={getPluginType(editor, MARK_ITALIC)}
            icon={<FormatItalic />}
          />
        </span>
      </Tooltip>
      {url && (
        <Tooltip info='Open Link in New Tab' arrow>
          <span>
            <ToolbarExternalLink url={url} />
          </span>
        </Tooltip>
      )}
    </BalloonToolbar>
  )
}

HoveringToolbar.displayName = 'HoverToolbar'
