import { makeStyles } from '@material-ui/core'
import { spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  messageSummaryContainer: {
    paddingBottom: spacing.xs,
  },
  activityAssignmentContainer: {
    paddingBottom: spacing.xs,
    paddingTop: spacing.xs,
  },
  messageViewerContainer: {
    '& img': {
      maxWidth: '100%',
    },
    '& p,h1,h2,h3': {
      // reset paragraph styling to user-agent default
      display: 'block',
      marginBlockStart: spacing.xs,
      marginBlockEnd: spacing.xs,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
    // Hides the url input for video embed, which breaks the iframe if edited
    '& .slate-MediaEmbedElement-input': {
      display: 'none',
    },
  },
})
