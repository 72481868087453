import React, {
  type FC,
  type ReactElement,
  type ReactNode,
  type CSSProperties,
} from 'react'
import classnames from 'classnames'
import { type TextVariants } from './types'
import { defaultHeadingColor, useStyles } from './useStyles'
import { isNil } from 'lodash'

interface TypographyProps {
  children: string | Array<string> | JSX.Element | ReactNode
  variant?: TextVariants
  color?: string
  spacing?: string
  classes?: CSSProperties | string
}

interface StylesProps {
  color?: string
  spacing?: string
}

const getDynamicStyles = ({ color, spacing }: StylesProps): CSSProperties => ({
  ...{ color: color ?? defaultHeadingColor },
  ...(!isNil(spacing) ? { margin: spacing } : {}),
})

export const HeadingMain: FC<TypographyProps> = ({
  variant = 'hugeHeadline',
  color,
  spacing,
  children,
}) => {
  const classes = useStyles({ color, spacing })
  return (
    <h1
      className={classes[variant]}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </h1>
  )
}
HeadingMain.displayName = 'HeadingMain'

export const HeadingSecondary: FC<TypographyProps> = ({
  variant = 'bigHeadline',
  color,
  children,
}) => {
  const classes = useStyles({ color })
  return (
    <h2 className={classes[variant]} style={getDynamicStyles({ color })}>
      {children}
    </h2>
  )
}
HeadingSecondary.displayName = 'HeadingSecondary'

export const HeadingTertiary: FC<TypographyProps> = ({
  variant = 'headline',
  color,
  children,
}) => {
  const classes = useStyles({ color })
  return (
    <h3 className={classes[variant]} style={getDynamicStyles({ color })}>
      {children}
    </h3>
  )
}
HeadingTertiary.displayName = 'HeadingTertiary'

export const SubHeading: FC<TypographyProps> = ({
  variant = 'subHeadline',
  color,
  spacing,
  children,
}) => {
  const classes = useStyles({ color, spacing })
  return (
    <h4
      className={classes[variant]}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </h4>
  )
}
SubHeading.displayName = 'SubHeading'

export const Heading5: FC<TypographyProps> = ({
  variant = 'smallHeadline',
  color,
  spacing,
  children,
  classes,
}) => {
  const customClasses = useStyles({ color, spacing })
  return (
    <h5
      className={classnames(classes, customClasses[variant])}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </h5>
  )
}
Heading5.displayName = 'Heading5'

export const Heading6: FC<TypographyProps> = ({
  variant = 'tinyHeadline',
  color,
  spacing,
  children,
}) => {
  const classes = useStyles({ color, spacing })
  return (
    <h6
      className={classes[variant]}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </h6>
  )
}
Heading6.displayName = 'Heading6'

export const Text: FC<TypographyProps> = ({
  variant = 'textRegular',
  color,
  spacing,
  children,
  classes,
}) => {
  const customClasses = useStyles({ color, spacing })
  return (
    <p
      className={classnames(classes, customClasses[variant])}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </p>
  )
}
Text.displayName = 'Text'

export const InlineText: FC<TypographyProps> = ({
  variant = 'textRegular',
  color,
  spacing,
  children,
}) => {
  const classes = useStyles({ color, spacing })
  return (
    <span
      className={classes[variant]}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </span>
  )
}
InlineText.displayName = 'InlineText'

export const ExternalLink: FC<
  TypographyProps & { href: string; rel: string; target: string }
> = ({ color, href, rel, target, children, ...props }) => {
  const classes = useStyles({ color })
  return (
    <a
      className={classes.link}
      href={href}
      rel={rel}
      target={target}
      {...props}
    >
      {children}
    </a>
  )
}
ExternalLink.displayName = 'ExternalLink'

export const InnerText: FC<TypographyProps> = ({
  variant = 'textRegular',
  color,
  spacing,
  children,
}): ReactElement => {
  const classes = useStyles({ color, spacing })
  return (
    <span
      className={classes[variant]}
      style={getDynamicStyles({ color, spacing })}
    >
      {children}
    </span>
  )
}
InnerText.displayName = 'InnerText'
