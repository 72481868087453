import {
  type Breadcrumb,
  Breadcrumbs,
} from '@awell/ui-kit/components/Breadcrumbs'
import { HeadingMain, Text } from '@awell/ui-kit/components/Typography'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { type RouteComponentProps } from '@reach/router'
import React, { type FC, useCallback } from 'react'
import { useStyles } from './useStyles'
import { isEmpty, isNil } from 'lodash'

export const PageTitle: FC<RouteComponentProps> = (): JSX.Element => {
  const { breadcrumbs } = useBreadcrumbs()
  const classes = useStyles()

  const getLastBreadcrumb = useCallback((): Breadcrumb | undefined => {
    // using the last breadcrumb label to get the page title
    const lastBreadCrumb = breadcrumbs.find(
      breadcrumb => breadcrumb.level === breadcrumbs.length - 1,
    )
    return lastBreadCrumb
  }, [breadcrumbs])

  const getPageTitle = (): string => {
    // using the last breadcrumb label to get the page title
    const lastBreadCrumb = getLastBreadcrumb()
    return !isNil(lastBreadCrumb) &&
      (isNil(lastBreadCrumb?.isToShowTitle) || lastBreadCrumb.isToShowTitle)
      ? lastBreadCrumb.label
      : ''
  }
  const getPageSubtitle = (): string => {
    // using the last breadcrumb label to get the page title
    const lastBreadCrumb = getLastBreadcrumb()
    return lastBreadCrumb?.description ?? ''
  }

  return (
    <div className={classes.titleContainer}>
      <div className={classes.breadCrumbs}>
        <Breadcrumbs truncate={false} list={breadcrumbs} />
      </div>
      {!isNil(getPageTitle()) && !isEmpty(getPageTitle()) && (
        <HeadingMain variant='headline'>{getPageTitle()}</HeadingMain>
      )}
      {!isEmpty(getPageSubtitle()) && (
        <Text color={colors.neutralMid200}>{getPageSubtitle()}</Text>
      )}
    </div>
  )
}
