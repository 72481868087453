import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { IconWrapper } from '../../IconWrapper'
import { ExternalLink } from '../../Icons'
import { colors } from '../../../utils/style-guide'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    width: '28px',
    height: '24px',
    verticalAlign: 'middle',
    '& > svg': {
      display: 'block',
      height: '1rem',
    },
  },
})

export const ToolbarExternalLink: FC<{ url: string }> = ({ url }) => {
  const classes = useStyles()

  return (
    <span data-testid='ToolbarButton' className={classes.root}>
      <a href={url} target='_blank' rel='noreferrer'>
        <IconWrapper size='xs' color={colors.neutralLight30}>
          <ExternalLink />
        </IconWrapper>
      </a>
    </span>
  )
}

ToolbarExternalLink.displayName = 'ToolbarExternalLink'
