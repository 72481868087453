import { makeStyles } from '@material-ui/core/styles'
import { colors, rounded, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  field: {
    marginBottom: spacing.xxs,
  },
  htmlViewerContainer: {
    '& img': {
      maxWidth: '100%',
    },
    '& p,h1,h2,h3': {
      // reset paragraph styling to user-agent default
      display: 'block',
      marginBlockStart: spacing.xxs,
      marginBlockEnd: spacing.xxs,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
    // Hides the url input for video embed, which breaks the iframe if edited
    '& .slate-MediaEmbedElement-input': {
      display: 'none',
    },
  },
  jsonWrapper: {
    ...rounded.m,
    background: colors.neutralLight30,
    padding: spacing.xxs,
  },
  header: {
    marginBottom: spacing.xxs,
  },
})
