import * as React from 'react'

function SvgInformative(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 18h2v-8h-2v8zm0-10h2V6h-2v2z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgInformative
