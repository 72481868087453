import { makeStyles } from '@material-ui/core/styles'
import {
  spacing,
  colors,
  rounded,
  shadows,
  appHeaderHeight,
} from '../../utils/style-guide'

export const useStyles = makeStyles({
  buildTitleSection: {
    padding: `${spacing.xs} ${spacing.xsm} ${spacing.xxs} ${spacing.xxs}`,
  },
  title: {
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
  },
  body: {
    height: '100%',
  },
  dragIcon: {
    visibility: 'hidden',
    marginRight: spacing.xxs,
  },
  dropContainer: {
    minHeight: '100%',
    paddingBottom: '25%',
    backgroundColor: colors.brand10,
  },
  draggingOver: {
    paddingTop: spacing.xxs,
    '& [class*="question-"]': {
      marginTop: spacing.xxs,
      marginBottom: spacing.xxs,
    },
  },
  draggedItem: {
    ...rounded.m,
    boxShadow: `0 0 0 1px ${colors.brand100}`,
    backgroundColor: `${colors.neutralLight0} !important`,
    '& [class*="icon"]': {
      color: colors.secondaryLightblue100,
    },
    '& > div': {
      ...shadows.l,
    },
  },
  questionDropArea: {
    textAlign: 'center',
    margin: spacing.xxs,
    padding: `${spacing.xs} ${spacing.xxs}`,
    backgroundColor: colors.neutralLight10,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
  draggableItemWrapper: {
    display: 'flex',
    width: '100%',
    backgroundColor: colors.neutralLight10,
  },
  dragHandleButton: {
    display: 'flex',
    marginLeft: '8px',
    zIndex: 1,
  },
})

export const usePreviewStyles = makeStyles({
  previewWrapper: {
    height: `calc(100vh - ${appHeaderHeight})`,
  },
})
