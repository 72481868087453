import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  pill: {
    borderRadius: spacing.xs,
    display: 'flex',
    alignItems: 'center',
    borderColor: colors.neutralLight40,
    color: colors.neutralDark800,
    backgroundColor: colors.neutralLight0,
    width: 'fit-content',
    minHeight: spacing.m,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    paddingTop: spacing.xxxs,
    paddingBottom: spacing.xxxs,
    zIndex: 1,
    '& > *:last-child': {
      padding: 0,
    },
  },
  dateGroupContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: spacing.m,
  },
  horizontalLine: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    backgroundColor: colors.neutralMid200,
    height: '1px',
    zIndex: 0,
  },
})
