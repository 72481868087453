import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { WebhookCall, useWebhookCallsForTenantQuery } from './types'

interface UseWebhookCallsForTenantHook {
  loading: boolean
  webhookCalls?: Array<WebhookCall>
}

export const useWebhookCallsForTenant = (): UseWebhookCallsForTenantHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const { data, loading, error } = useWebhookCallsForTenantQuery()

  const webhookCalls = data?.webhookCallsForTenant.webhook_calls ?? []

  if (error) {
    notifyError({
      message: t('error_load_webhook_plural'),
      error,
    })
    return { loading: false }
  }

  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    webhookCalls,
  }
}
