import React, { FC } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Skeleton } from './Skeleton'

const TableRowSkeleton: FC<{ cellCount: number }> = ({ cellCount }) => {
  return (
    <TableRow>
      {[...Array(cellCount).keys()].map(count => (
        <TableCell key={`row-cell-${count}`}>
          <Skeleton variant='text' />
        </TableCell>
      ))}
    </TableRow>
  )
}

interface TableSkeletonProps<T> {
  rowsCount: number
  cellCount: number
  // eslint-disable-next-line react/require-default-props
  columns?: Array<T>
}
export const TableSkeleton = <
  T extends { title?: string | React.ReactElement }
>({
  rowsCount,
  cellCount,
  columns = [],
}: TableSkeletonProps<T>): JSX.Element => {
  const renderHeaderWithColumnsNames =
    columns &&
    columns.map(column => (
      <TableCell key={`header-cell-${column.title}`}>
        {column.title || <Skeleton variant='text' />}
      </TableCell>
    ))

  const renderHeaderWithSkeleton = [...Array(cellCount).keys()].map(count => (
    <TableCell key={`header-cell-${count}`}>
      <Skeleton variant='text' />
    </TableCell>
  ))
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {columns ? renderHeaderWithColumnsNames : renderHeaderWithSkeleton}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rowsCount).keys()].map(count => (
            <TableRowSkeleton key={`body-row-${count}`} cellCount={cellCount} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}
TableSkeleton.displayName = 'TableSkeleton'
