import { makeStyles } from '@material-ui/core'
import MuiTabs from '@material-ui/core/Tabs'
import React, { type FC } from 'react'
import { colors } from '../../utils/style-guide'

interface Props {
  value: number
  onChange?: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number,
  ) => void
  isFullWidth?: boolean
  containerStyle?: React.CSSProperties | undefined
}

const useStyles = makeStyles({
  tabs: {
    boxShadow: `inset 0 -2px 0 0 ${colors.brand10}`,
  },
  container: {
    boxShadow: `inset 0 -2px 0 0 ${colors.brand10}`, // full width lower border for "condensed" tabs view
  },
  root: {
    width: ({ isFullWidth }: { isFullWidth: boolean | undefined }) =>
      isFullWidth ?? false ? '100%' : 'fit-content',
    '@media (min-width: 768px)': {
      '& button': {
        minWidth: 'auto',
      },
    },
  },
})

export const Tabs: FC<Props> = ({
  value,
  isFullWidth = true,
  onChange,
  children,
  containerStyle,
}) => {
  const classes = useStyles({ isFullWidth })
  return (
    <div className={classes.container} style={containerStyle}>
      <MuiTabs
        value={value}
        onChange={onChange}
        variant={isFullWidth ? 'fullWidth' : 'standard'}
        indicatorColor='primary'
        className={classes.tabs}
        classes={{
          root: classes.root,
        }}
        centered={isFullWidth}
      >
        {children}
      </MuiTabs>
    </div>
  )
}

Tabs.displayName = 'Tabs'
