import { makeStyles } from '@material-ui/core/styles'
import { spacing, colors, shadows, rounded } from '../../utils/style-guide'
import { typographyStyles } from '../Typography/Typography'

export const useStyles = makeStyles({
  cardContainer: {
    ...shadows.xs,
    ...rounded.m,
    padding: spacing.xs,
    backgroundColor: colors.neutralLight0,
    border: `1px solid ${colors.neutralLight30}`,
    position: 'relative',
  },
  selectedCard: {
    border: `2px solid ${colors.brand100}`,
  },
  cardTop: {
    paddingBottom: spacing.xxs,
    display: 'flex',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelContainer: {
    position: 'absolute',
    left: 0,
    top: `-${spacing.m}`,
  },
  stepName: {
    marginBottom: spacing.xxxs,
  },
  status: {
    marginBottom: spacing.xxxs,
  },
  blue: {
    backgroundColor: colors.blue,
  },
  teal: {
    backgroundColor: colors.teal,
  },
  green: {
    backgroundColor: colors.green,
  },
  orange: {
    backgroundColor: colors.orange,
  },
  purple: {
    backgroundColor: colors.purple,
  },
  red: {
    backgroundColor: colors.red,
  },
  grey: {
    backgroundColor: colors.neutralLight50,
  },
  labelDot: {
    display: 'inline-block',
    minWidth: 0,
    minHeight: 0,
    borderRadius: '50%',
    padding: spacing.xxxs,
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
    lineHeight: 1,
    '&:before': {
      content: '',
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingTop: '100%',
      height: 0,
    },
  },
  lozenge: {
    marginBottom: spacing.xxxs,
  },
  cardMain: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardIcon: {
    ...rounded.m,
    marginRight: spacing.xxs,
  },
  footer: {
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerIcons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  footerItem: {
    marginRight: spacing.xxs,
    color: colors.neutralMid300,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerIcon: {
    marginRight: spacing.xxxs,
  },
  cta: {
    '& > *': {
      width: 180,
    },
  },
  summaryDetails: {
    '& > div:first-child': {
      minWidth: spacing.xl,
      flexBasis: spacing.xl,
    },
    width: '100%',
  },
  cardDescription: {
    display: 'flex',
    alignItems: 'center',
  },
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  cardTitle: {
    marginBottom: spacing.xxxs,
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing.xxxs,
    paddingBottom: spacing.xs,
    borderBottom: `1px solid ${colors.neutralLight40}`,
  },
  eventDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *:first-child': {
      minWidth: spacing.xl,
      flexBasis: spacing.xl,
    },
  },
  updatesButton: {
    ...typographyStyles.small,
    textTransform: 'none',
    fontWeight: 500,
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
  },
  activityEventText: {
    // Line height is 16px in figma designs
    lineHeight: '1.1428rem',
    whiteSpace: 'pre-line',
  },
  activityContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  activityContentDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIcon: {
    marginRight: spacing.xxs,
  },
  // METADATA TAB
  metadata: {
    marginBottom: spacing.xxs,
  },
  metadataContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
})
