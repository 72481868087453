import { makeStyles } from '@material-ui/core/styles'
import { spacing, colors } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  questionDropArea: {
    textAlign: 'center',
    margin: spacing.xxs,
    padding: `${spacing.xs} ${spacing.xxs}`,
    backgroundColor: colors.neutralLight10,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
})
