import { IconButton } from '@awell/ui-kit/components/Button'
import { Refresh } from '@awell/ui-kit/components/Icons'
import React from 'react'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { useTranslation } from 'react-i18next'
import { useRetryPushToEmr } from '../../hooks/useRetryPushToEmr'

interface RetryPushToEmrButtonProps {
  id: string
  disabled?: boolean
}
export const RetryPushToEmrButton = ({
  id,
  disabled = false,
}: RetryPushToEmrButtonProps): JSX.Element => {
  const { retryPushToEmr } = useRetryPushToEmr(id)
  const { t } = useTranslation()

  return (
    <IconButton
      onClick={async () => {
        await retryPushToEmr()
      }}
      style={{ padding: spacing.xxxs }}
      iconColor={colors.neutralLight100}
      tooltip={t('emr_report_retry')}
      disabled={disabled}
    >
      <Refresh />
    </IconButton>
  )
}
