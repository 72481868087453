import { makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  panel: {
    backgroundColor: colors.neutralLight20,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  startPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    position: 'relative',
  },
  endPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    height: '100%',
  },
  panelWithDivider: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    right: 0,
    top: 0,
  },
})
