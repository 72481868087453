import * as React from 'react'

function SvgSearchRound(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={32} cy={32} r={32} fill='#E6EDF9' />
      <circle cx={32} cy={32} r={23} fill='#C5D8F9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.666 38.666a8 8 0 100-16 8 8 0 000 16zm10.667-8c0 5.891-4.776 10.667-10.667 10.667-5.89 0-10.666-4.776-10.666-10.667C20 24.776 24.775 20 30.666 20s10.667 4.775 10.667 10.666z'
        fill='#3476E0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.369 36.369a1.26 1.26 0 011.782 0l5.48 5.48a1.26 1.26 0 01-1.782 1.782l-5.48-5.48a1.26 1.26 0 010-1.782z'
        fill='#3476E0'
      />
    </svg>
  )
}

export default SvgSearchRound
