import { makeStyles } from '@material-ui/core'
import { compareDesc } from 'date-fns'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { colors, spacing } from '../../utils/style-guide'
import { type ActivityEventType } from '../../compositions/ActivityFeed'
import { ActivityEvent } from './ActivityEvent'
import { Text } from '../Typography'

const useStyles = makeStyles({
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing.xxxs,
  },
  event: {
    '&:not(:first-child)': {
      paddingTop: spacing.xs,
    },
    '&:not(:last-child)': {
      paddingBottom: spacing.xs,
      borderBottom: `1px solid ${colors.neutralLight30}`,
    },
  },
})

interface EventsTabContentProps {
  events: Array<ActivityEventType>
}

export const EventsTabContent = ({
  events,
}: EventsTabContentProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const sortedEvents = events.sort((a, b) =>
    compareDesc(new Date(a.content.timestamp), new Date(b.content.timestamp)),
  )

  return (
    <motion.div className={classes.eventsContainer}>
      {sortedEvents.length === 0 && (
        <Text variant='textRegular'>
          {t('activityfeed:no_activity_updates')}
        </Text>
      )}
      {sortedEvents.map(({ id, content: eventContent }) => {
        return (
          <motion.div
            key={id}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            className={classes.event}
          >
            <ActivityEvent
              avatar={eventContent.avatar}
              timestamp={eventContent.timestamp}
              summaryText={eventContent.summaryText}
            />
          </motion.div>
        )
      })}
    </motion.div>
  )
}

EventsTabContent.displayName = 'EventsTabContent'
