import { makeStyles } from '@material-ui/core'
import { spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  formPrefillSelect: {
    marginBottom: spacing.xs,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
