/* eslint-disable react/prop-types */
import React, { type FC, useEffect, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize, isEmpty, isNil } from 'lodash'
import { InputField } from '../InputField'
import { Button, IconButton } from '../Button'
import { Delete, Plus } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { colors } from '../../utils/style-guide'
import { CopyMultipleChoiceOptions } from './CopyMultipleChoiceOptions'
import { useStyles } from './useStyles'
import {
  type MultipleChoiceEditorProps,
  type Option,
  type HandleFormChangeProps,
} from './types'
import { DataPointValueType } from '../AwellActivity'

export const MultipleChoiceEditor: FC<MultipleChoiceEditorProps> = memo(
  ({ multipleChoice, onChangeOptions, onCopyQuestionOptions }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [fields, setFields] = useState<Array<Option>>(multipleChoice.options)

    useEffect(() => {
      onChangeOptions(fields)
    }, [fields])

    useEffect(() => {
      setFields(multipleChoice.options)
    }, [multipleChoice.options])

    const handleAddOption = () => {
      const newOption = {
        value:
          multipleChoice.dataPointValueType === DataPointValueType.String ||
          multipleChoice.dataPointValueType === DataPointValueType.StringsArray
            ? `option_${fields.length + 1}`
            : `${fields.length}`,
        label: '',
      }
      setFields([...fields, newOption])
    }

    const handleAddOptionAtPosition = (index: number) => {
      const newOption = {
        value:
          multipleChoice.dataPointValueType === DataPointValueType.String ||
          multipleChoice.dataPointValueType === DataPointValueType.StringsArray
            ? `option_${fields.length + 1}`
            : `${fields.length}`,
        label: '',
      }
      const newItems = [...fields]
      newItems.splice(index + 1, 0, newOption)

      setFields([...newItems])
    }

    const getUpdatedOption = (
      option: Option,
      { key, value }: Omit<HandleFormChangeProps, 'index'>,
      dataPointValueType?: DataPointValueType,
    ) => {
      const isNumber = dataPointValueType === DataPointValueType.Number

      return {
        ...option,
        [key]: key === 'value' && isNumber ? Number(value) : value,
      }
    }
    const handleFormChange = ({
      index,
      value,
      key,
      dataPointValueType,
    }: HandleFormChangeProps) => {
      const updatedFields = fields.map((option, i) =>
        index === i
          ? getUpdatedOption(option, { key, value }, dataPointValueType)
          : option,
      )
      setFields(updatedFields)
    }

    const handleRemoveFields = (index: number) => {
      const updatedFields = fields.filter((el, i) => index !== i)
      setFields(updatedFields)
    }

    const handleAddNewOptionOnEnter = (e: any, index: number) => {
      handleAddOptionAtPosition(index)

      setTimeout(() => {
        const { form } = e.target
        const nextInputIndex = [...form].indexOf(e.target) + 3
        form.elements[nextInputIndex].focus()
        e.preventDefault()
      }, 0)
    }

    const getNextIndexToFocus = (e: any): number | null => {
      const { form } = e.target
      const currentTarget = [...form].indexOf(e.target)
      const horizontalStep = 1
      const verticalStep = 4

      switch (e.key) {
        case 'ArrowLeft':
          e.preventDefault()
          return currentTarget - horizontalStep
        case 'ArrowRight':
          e.preventDefault()
          return currentTarget + horizontalStep
        case 'ArrowUp':
          e.preventDefault()
          return currentTarget - verticalStep
        case 'ArrowDown':
          e.preventDefault()
          return currentTarget + verticalStep
        default:
          return null
      }
    }
    const handleFocusChange = (e: any) => {
      const { form } = e.target
      const nextInputIndex = getNextIndexToFocus(e)
      if (!isNil(nextInputIndex)) {
        form.elements[nextInputIndex].focus()
      }
    }

    const handleSaveImportedOptions = (options: Array<Option>) => {
      setFields(options)
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.multipleChoiceContainer}>
          <form>
            <div>
              {fields.map(({ value, label }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classes.inputFieldsContainer} key={index}>
                  <InputField
                    label={capitalize(t('value'))}
                    hideLabel
                    compact
                    className={classes.optionValue}
                    value={`${value}`}
                    type={
                      multipleChoice.dataPointValueType ===
                        DataPointValueType.String ||
                      multipleChoice.dataPointValueType ===
                        DataPointValueType.StringsArray
                        ? 'text'
                        : 'number'
                    }
                    variant='transparent'
                    onKeyPressCustomHandler={e => {
                      handleFocusChange(e)
                    }}
                    onChange={val => {
                      handleFormChange({
                        value: val,
                        index,
                        key: 'value',
                        dataPointValueType: multipleChoice.dataPointValueType,
                      })
                    }}
                  />
                  <InputField
                    label={capitalize(t('label'))}
                    hideLabel
                    compact
                    variant='transparent'
                    className={classes.optionLabel}
                    value={label ?? ''}
                    onEnterPress={e => {
                      handleAddNewOptionOnEnter(e, index)
                    }}
                    onKeyPressCustomHandler={e => {
                      handleFocusChange(e)
                    }}
                    onChange={val => {
                      handleFormChange({
                        value: val,
                        index,
                        key: 'label',
                      })
                    }}
                  />

                  <div className={classes.sideButtonSection}>
                    <IconButton
                      iconSize='xsm'
                      tooltip={t('delete')}
                      onKeyDown={e => {
                        handleFocusChange(e)
                      }}
                      onClick={() => {
                        handleRemoveFields(index)
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      iconSize='xsm'
                      tooltip={t('add')}
                      onKeyDown={e => {
                        handleFocusChange(e)
                      }}
                      onClick={() => {
                        handleAddOptionAtPosition(index)
                      }}
                    >
                      <Plus />
                    </IconButton>
                  </div>
                </div>
              ))}

              {isEmpty(fields) && (
                <div className={classes.bottomSectionContainer}>
                  <Button
                    size='small'
                    variant='text'
                    onClick={handleAddOption}
                    startIcon={
                      /* eslint-disable react/jsx-wrap-multilines */
                      <IconWrapper size='xxs' color={colors.brand100}>
                        <Plus />
                      </IconWrapper>
                    }
                  >
                    {t('add_new_option')}
                  </Button>
                </div>
              )}
            </div>
          </form>
        </div>
        <CopyMultipleChoiceOptions
          options={fields}
          title={multipleChoice.title}
          onSave={handleSaveImportedOptions}
          onCopyQuestionOptions={onCopyQuestionOptions}
          dataPointValueType={multipleChoice.dataPointValueType}
        />
      </div>
    )
  },
  (prev, next) =>
    prev.multipleChoice.id === next.multipleChoice.id &&
    prev.multipleChoice.mandatory === next.multipleChoice.mandatory &&
    // @ts-expect-error isFocused is not in the type
    prev.isFocused === next.isFocused &&
    prev.multipleChoice.dataPointValueType ===
      next.multipleChoice.dataPointValueType &&
    prev.multipleChoice.options === next.multipleChoice.options,
)

MultipleChoiceEditor.displayName = 'MultipleChoiceEditor'
