import * as React from 'react'

function SvgFeedSystemAction(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 4a4 4 0 014-4h24a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4z'
        fill='transparent'
      />
      <path
        d='M19.702 21.518a.26.26 0 00-.323-.035 6.538 6.538 0 01-9.815-4.063.263.263 0 00-.254-.205H7.261a.262.262 0 00-.257.31c.753 4.247 4.463 7.474 8.927 7.474a9.027 9.027 0 005.188-1.63.262.262 0 00.036-.4l-1.453-1.45zM17.214 9.311c0 .124.086.227.206.255a6.538 6.538 0 014.063 9.815.26.26 0 00.035.322l1.452 1.452a.262.262 0 00.4-.036A9.024 9.024 0 0025 15.93c0-4.464-3.226-8.174-7.475-8.927a.261.261 0 00-.31.257v2.05zM9.566 14.44a6.547 6.547 0 014.876-4.875.263.263 0 00.206-.255V7.261a.262.262 0 00-.308-.257 9.073 9.073 0 00-7.336 7.334.262.262 0 00.258.309h2.05a.263.263 0 00.254-.206z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgFeedSystemAction
