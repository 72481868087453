import {
  DetailsTabContent,
  EmrMetadataTabContent,
  EventsTabContent,
} from '@awell/ui-kit/components/ActivityFeedPanel'
import { TabPanel } from '@awell/ui-kit/components/Tabs'
import { useActivityContent } from '@awell/ui-kit/compositions/ActivityFeed/useActivityContent'
import React, { type FC, useEffect } from 'react'
import { ActivityDetailsTabs } from '@awell/ui-kit/components/ActivityDetailsTabs'
import {
  ActivityObjectType,
  type EmrReportMetadataField,
  type Activity,
} from './types'
import { ActivityContent } from './ActivityContent'
import { ClinicalNotesContextTab } from './ClinicalNote'
import { ExtensionDataPoints } from './Extension'

interface Props {
  activity: Activity
  onClose: () => void
}

export const ActivityDetails: FC<Props> = ({ activity, onClose }) => {
  const [activeTab, setActiveTab] = React.useState(0)
  const [metaData, setMetaData] = React.useState<
    Array<EmrReportMetadataField> | undefined
  >(undefined)
  const { events } = useActivityContent(activity)
  const activityEventCount = events.length
  const hasFourTabs = [
    ActivityObjectType.EmrReport,
    ActivityObjectType.PluginAction,
  ].includes(activity.object.type)
  const isClinicalNote =
    activity.object.type === ActivityObjectType.ClinicalNote
  // Ensures that selected tab is reset when viewing another activity
  useEffect(() => {
    setActiveTab(0)
  }, [activity.id])

  const handleEmrReport = (metadata: Array<EmrReportMetadataField>): void => {
    setMetaData(metadata)
  }

  return (
    <>
      <ActivityDetailsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activity={activity}
        activityEventCount={activityEventCount}
        hasFourTabs={hasFourTabs}
      />
      <TabPanel fill={false} index={0} value={activeTab}>
        {/* TODO: Decide if/how to show this */}
        {/* <div className={classes.retryButton}> */}
        {/*  <Button onClick={() => retryActivity()}>Retry</Button> */}
        {/* </div> */}
        <ActivityContent
          activity={activity}
          // @ts-expect-error FIXME metadata is optional in subsequent component typings
          onLoadEMRReport={handleEmrReport}
          onClose={onClose}
        />
      </TabPanel>
      {hasFourTabs && (
        <TabPanel fill={false} index={1} value={activeTab}>
          {/* 
            TODO: Care app does not have a mechanism to fetch calculation inputs
            {activity.object.type === ActivityObjectType.Calculation && (
              <CalculationInputsTab calculationInputs={calculationInputs} />
            )} 
          */}
          {activity.object.type === ActivityObjectType.EmrReport && (
            <EmrMetadataTabContent metadata={metaData} />
          )}
          {activity.object.type === ActivityObjectType.PluginAction && (
            <ExtensionDataPoints activity={activity} />
          )}
        </TabPanel>
      )}

      {isClinicalNote ? (
        <TabPanel fill={false} index={1} value={activeTab}>
          <ClinicalNotesContextTab activity={activity} />
        </TabPanel>
      ) : (
        <TabPanel fill={false} index={hasFourTabs ? 2 : 1} value={activeTab}>
          <DetailsTabContent activity={activity} />
        </TabPanel>
      )}

      <TabPanel fill={false} index={hasFourTabs ? 3 : 2} value={activeTab}>
        <EventsTabContent events={events} />
      </TabPanel>
    </>
  )
}

ActivityDetails.displayName = 'ActivityDetails'
