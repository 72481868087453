import { useTranslation } from 'react-i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useRetryAllWebhookCallsMutation } from './types'

interface useGetWebhooksHook {
  retryAllWebhookCalls: () => void
}

export const useRetryAllWebhookCalls = (
  pathwayId: string,
): useGetWebhooksHook => {
  const { notifyError, notify } = useNotifications()
  const { t } = useTranslation()
  const [retryWebhooksMutation] = useRetryAllWebhookCallsMutation()

  const retryAllWebhookCalls = async () => {
    try {
      await retryWebhooksMutation({
        variables: {
          input: { pathway_id: pathwayId },
        },
        refetchQueries: ['GetWebhooks'],
      })
      notify({
        message: t('webhook_retry_all_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('error_retry_all_webhook_calls'),
        error,
      })
    }
  }

  return { retryAllWebhookCalls }
}
