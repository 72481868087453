import {
  Badge,
  type BadgeKind,
  BadgeKindNames,
} from '@awell/ui-kit/components/Badge'
import { LinkButton } from '@awell/ui-kit/components/Button'
import { Flex } from '@awell/ui-kit/components/Flex'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { ActivityStatus } from '@awell/ui-kit/generated/types-design'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { useNavigate } from '@reach/router'
import { isNil } from 'lodash'
import React, { type ReactElement, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityStatuses } from '../../hooks/useActivityStatuses'

interface IconLabelCellProps {
  label: string
  icon: ReactElement
  status?: string
  to?: string
}

export const IconLabelCell: FC<IconLabelCellProps> = ({
  label,
  icon,
  status,
  to,
}): ReactElement => {
  const { t } = useTranslation()
  const getBadgeKind = (): BadgeKind => {
    switch (status) {
      case ActivityStatus.Active:
        return BadgeKindNames.brandOutline
      case ActivityStatus.Done:
        return BadgeKindNames.successOutline
      case ActivityStatus.Failed:
        return BadgeKindNames.errorOutline
      case ActivityStatus.Canceled:
      default:
        return BadgeKindNames.informativeOutline
    }
  }

  const navigate = useNavigate()
  const { activityStatuses } = useActivityStatuses()

  const handleClick = (): void => {
    if (!isNil(to)) {
      void navigate(to)
    }
  }

  const statusText =
    activityStatuses.find(s => s.value === status)?.label ?? t('unknown')
  // FIXME : setting the gap via the style prop because there seem to be a bug
  // with the Text component ignoring the marginLeft property as applied via the Flex gap prop.
  return (
    <Flex direction='row' style={{ gap: spacing.xxs }} align='center'>
      <IconWrapper bare size='s' color={colors.neutralDark500}>
        {icon}
      </IconWrapper>
      {isNil(to) ? (
        <span>{label}</span>
      ) : (
        <LinkButton onClick={handleClick}>{label}</LinkButton>
      )}
      <Badge kind={getBadgeKind()}>{statusText}</Badge>
    </Flex>
  )
}

IconLabelCell.displayName = 'ActivityTypeIcon'
