import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { colors, spacing, type } from '../../utils/style-guide'

export type BadgeKind =
  | 'warning'
  | 'success'
  | 'informative'
  | 'error'
  | 'brand'
  | 'outlineWarning'
  | 'outlineSuccess'
  | 'outlineInformative'
  | 'outlineError'
  | 'outlineBrand'
  | 'brandLight'

export enum BadgeKindNames {
  warning = 'warning',
  success = 'success',
  informative = 'informative',
  error = 'error',
  brand = 'brand',
  warningOutline = 'outlineWarning',
  successOutline = 'outlineSuccess',
  informativeOutline = 'outlineInformative',
  errorOutline = 'outlineError',
  brandOutline = 'outlineBrand',
  brandLight = 'brandLight',
}
interface BadgeProps {
  kind: BadgeKind
  style?: React.CSSProperties
}

interface NotificationProps extends BadgeProps {
  size?: 's' | 'm'
  number?: number
}

interface Component {
  Notification: React.FC<NotificationProps>
}

const useStyles = makeStyles({
  base: {
    ...type.xxs,
    borderRadius: spacing.xs,
    width: 'max-content',
    ...spacing.paddingX.xxs,
    // TODO add vertical padding to spacing in style-guide
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  outlineBrand: {
    border: `2px solid ${colors.brand100}`,
    color: colors.brand100,
  },
  brand: { backgroundColor: colors.brand100, color: colors.neutralLight0 },
  outlineWarning: {
    border: `2px solid ${colors.signalWarning100}`,
    color: colors.signalWarning100,
  },
  warning: { backgroundColor: colors.signalWarning100 },
  outlineSuccess: {
    border: `2px solid ${colors.signalSuccess100}`,
    color: colors.signalSuccess100,
  },
  success: {
    backgroundColor: colors.signalSuccess100,
    color: colors.neutralLight0,
  },
  outlineInformative: {
    border: `2px solid ${colors.signalInformative60}`,
    color: colors.signalInformative60,
  },
  informative: {
    backgroundColor: colors.signalInformative60,
    color: colors.neutralLight0,
  },
  outlineError: {
    border: `2px solid ${colors.signalError100}`,
    color: colors.signalError100,
  },
  error: {
    backgroundColor: colors.signalError100,
    color: colors.neutralLight0,
  },
  brandLight: {
    backgroundColor: colors.blue50,
    color: colors.blue500,
  },
})

const useNotificationStyles = makeStyles({
  base: {
    display: 'inline-block',
    border: `2px solid ${colors.neutralLight0}`,
    boxSizing: 'content-box',
  },
  s: {
    width: spacing.xxs,
    height: spacing.xxs,
    borderRadius: spacing.xxs,
  },
  m: {
    width: spacing.xs,
    height: spacing.xs,
    borderRadius: spacing.xs,
  },
  withNumber: {
    borderRadius: spacing.xs,
    padding: '0 5px',
  },
})

export const Badge: Component & React.FC<BadgeProps> = ({
  children,
  kind,
  style,
}: {
  children?: React.ReactNode | Array<React.ReactNode>
  kind: BadgeKind
  style?: React.CSSProperties
}) => {
  const classes = useStyles()
  return (
    <span className={classnames(classes.base, classes[kind])} style={style}>
      {children}
    </span>
  )
}

const BadgeNotification: React.FC<NotificationProps> = ({
  kind,
  size = 's',
  number,
  style,
}) => {
  const classes = useStyles()
  const notificationClasses = useNotificationStyles()
  let sizeClass = notificationClasses[size]
  if (number != null) {
    sizeClass = notificationClasses.withNumber
  }

  return (
    <span
      className={classnames(classes[kind], notificationClasses.base, sizeClass)}
      style={style}
    >
      {number != null && number}
    </span>
  )
}

Badge.displayName = 'Badge'
BadgeNotification.displayName = 'Badge.Notification'

Badge.Notification = BadgeNotification
