import * as React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { isNil } from 'lodash'

interface FlexProps {
  /** `display: inline-flex;` */
  inline?: boolean
  direction?: 'row' | 'column'
  justify?: 'center' | 'end' | 'start' | 'space-around' | 'space-between'
  align?: 'baseline' | 'start' | 'center' | 'end' | 'stretch'
  tag?: string
  style?: React.CSSProperties
  gap?: string
  className?: string
  applyBaseStyles?: boolean
  children: React.ReactNode | Array<React.ReactNode>
}

const useStyles = makeStyles({
  base: { width: '100%', height: '100%' },
  flex: { display: 'flex' },
  inline: { display: 'inline-flex' },
  directionRow: { flexDirection: 'row' },
  directionColumn: { flexDirection: 'column' },
  justifyCenter: { justifyContent: 'center' },
  justifyEnd: { justifyContent: 'flex-end' },
  justifyStart: { justifyContent: 'flex-start' },
  justifyAround: { justifyContent: 'space-around' },
  justifyBetween: { justifyContent: 'space-between' },
  alignBaseline: { alignItems: 'baseline' },
  alignStart: { alignItems: 'flex-start' },
  alignCenter: { alignItems: 'center' },
  alignEnd: { alignItems: 'flex-end' },
  alignStretch: { alignItems: 'stretch' },
})

// abstraction of display:flex into a component: https://css-tricks.com/snippets/css/a-guide-to-flexbox/
export const Flex: React.FC<FlexProps> = ({
  children,
  inline = false,
  direction = 'row',
  justify = 'start',
  align = 'start',
  tag = 'div',
  style = {},
  gap,
  className,
  applyBaseStyles = true,
}) => {
  const classes = useStyles()
  return React.createElement(
    tag,
    {
      style,
      className: classnames(className, {
        [classes.base]: applyBaseStyles,
        [classes.flex]: !inline,
        [classes.inline]: inline,
        [classes.directionColumn]: direction === 'column',
        [classes.directionRow]: direction === 'row',
        [classes.justifyCenter]: justify === 'center',
        [classes.justifyEnd]: justify === 'end',
        [classes.justifyStart]: justify === 'start',
        [classes.justifyAround]: justify === 'space-around',
        [classes.justifyBetween]: justify === 'space-between',
        [classes.alignBaseline]: align === 'baseline',
        [classes.alignStart]: align === 'start',
        [classes.alignCenter]: align === 'center',
        [classes.alignEnd]: align === 'end',
        [classes.alignStretch]: align === 'stretch',
      }),
    },
    // @ts-expect-error FIXME: figure out how to type this properly
    React.Children.map(children, (child: React.ReactElement, index) => {
      const childGap = index > 0 ? gap : '0rem'

      const gapStyles =
        direction === 'row' ? { marginLeft: childGap } : { marginTop: childGap }

      return React.isValidElement(child)
        ? React.cloneElement(child as React.ReactElement, {
            style: {
            ...(!isNil(gap) ? gapStyles : {}),
              // @ts-expect-error TODO: figure out how to type this properly
              ...(child?.props?.style ?? {}),
            },
          })
        : child
    }),
  )
}

Flex.displayName = 'Flex'
