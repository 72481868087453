import { makeStyles } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import React, { type FC } from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import { colors } from '../../utils/style-guide'

const useStyles = makeStyles(() => ({
  error: {
    backgroundColor: colors.signalError100,
  },
}))

interface NotificationProviderProps {
  domRoot?: string
}

export const NotificationProvider: FC<NotificationProviderProps> = ({
  domRoot = 'react-notifications',
  children,
}) => {
  const classes = useStyles()
  const { isMobile } = useWindowSize()

  return (
    <SnackbarProvider
      domRoot={document.getElementById(domRoot) ?? undefined}
      classes={{ variantError: classes.error }}
      maxSnack={3}
      hideIconVariant
      autoHideDuration={10000}
      dense={isMobile}
    >
      {children}
    </SnackbarProvider>
  )
}
