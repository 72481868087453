import React, { FC } from 'react'
import { Router as ReachRouter, RouterProps } from '@reach/router'

export const FullHeightRouter: FC<RouterProps> = ({ children, ...props }) => {
  return (
    <ReachRouter
      {...props}
      style={{ height: '100%', overflow: 'hidden', position: 'relative' }}
    >
      {children}
    </ReachRouter>
  )
}
