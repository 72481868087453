// @ts-nocheck TODO: remove this comment and fix errors
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { StartPathwayInput, useStartPathwayMutation } from './types'

interface UseStartPathwayHook {
  startPathway: (input: StartPathwayInput) => Promise<string>
}

export const useStartPathway = (): UseStartPathwayHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const [startPathwayMutation] = useStartPathwayMutation()

  const startPathway = async (input: StartPathwayInput) => {
    try {
      const { data } = await startPathwayMutation({
        variables: {
          input,
        },
        refetchQueries: ['GetPatientPathways'],
      })
      return data.startPathway.pathway_id
    } catch (error) {
      notifyError({
        message: t('start_pathway_error'),
        error,
      })
    }
    return undefined
  }
  return {
    startPathway,
  }
}
