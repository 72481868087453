import * as React from 'react'

function SvgMultipleChoiceGridQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7.75 9h-1.5C5.56 9 5 8.44 5 7.75V6.26C5 5.56 5.56 5 6.25 5h1.49C8.44 5 9 5.56 9 6.25v1.49C9 8.44 8.44 9 7.75 9zM12.747 9h-1.494C10.561 9 10 8.44 10 7.75v-1.5c0-.69.561-1.25 1.253-1.25h1.494C13.438 5 14 5.56 14 6.25v1.49c.01.7-.552 1.26-1.253 1.26zM17.747 9h-1.494C15.562 9 15 8.44 15 7.75v-1.5A1.24 1.24 0 0116.253 5h1.494C18.439 5 19 5.56 19 6.25v1.49c0 .7-.561 1.26-1.253 1.26zM7.75 14h-1.5C5.56 14 5 13.439 5 12.747v-1.494C5 10.562 5.56 10 6.25 10h1.49c.7-.01 1.26.552 1.26 1.243v1.494C9 13.439 8.44 14 7.75 14zM12.747 14h-1.494C10.561 14 10 13.44 10 12.75v-1.5c0-.69.561-1.25 1.253-1.25h1.494c.691 0 1.253.56 1.253 1.25v1.49c.01.7-.552 1.26-1.253 1.26zM17.747 14h-1.494C15.561 14 15 13.44 15 12.75v-1.5c0-.69.561-1.25 1.253-1.25h1.494c.692 0 1.253.56 1.253 1.25v1.49c0 .7-.561 1.26-1.253 1.26zM7.75 19h-1.5C5.56 19 5 18.439 5 17.747v-1.494C5 15.562 5.56 15 6.25 15h1.49c.7-.01 1.26.552 1.26 1.253v1.494C9 18.439 8.44 19 7.75 19zM12.747 19h-1.494A1.254 1.254 0 0110 17.747v-1.494c0-.692.561-1.253 1.253-1.253h1.494c.691 0 1.253.561 1.253 1.253v1.494A1.24 1.24 0 0112.747 19zM17.747 19h-1.494A1.254 1.254 0 0115 17.747v-1.494c0-.692.561-1.253 1.253-1.253h1.494c.692 0 1.253.561 1.253 1.253v1.494c0 .692-.561 1.253-1.253 1.253z' />
    </svg>
  )
}

export default SvgMultipleChoiceGridQuestion
