import { makeStyles } from '@material-ui/core'
import { spacing, colors, rounded } from '../../utils/style-guide'

export const useStyles = makeStyles({
  common: {
    // Hides the url input for video embed, which breaks the iframe if edited
    '& [data-testid="MediaEmbedUrlInput"]': {
      display: 'none',
    },
  },
  mention: {
    ...rounded.m,
    ...spacing.padding.xxs,
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight10,
    '&:focus': {
      borderColor: colors.brand100,
      backgroundColor: colors.neutralLight0,
    },
  },
  editor: {
    ...rounded.m,
    ...spacing.padding.xxs,
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight10,
    '&:focus': {
      borderColor: colors.brand100,
      backgroundColor: colors.neutralLight0,
    },
    '& p,h1,h2,h3': {
      // reset paragraph styling to user-agent default
      display: 'block',
      marginBlockEnd: ({ mode }: { mode: string }) =>
        mode === 'condensed' ? 0 : spacing.xs,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
    // Hides the url input for video embed, which breaks the iframe if edited
    '& .slate-MediaEmbedElement-input': {
      display: 'none',
    },
  },
  viewer: {},
  jsonEditor: {
    '& select': {
      display: 'none',
    },
    '&:focus': {
      background: 'transparent',
    },
    background: 'transparent',
  },
  singleLineEditor: {
    ...spacing.paddingY.xxxs,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& div:first-child': {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      overflow: 'hidden',
      textWrap: 'nowrap',
    },
  },
  attachmentsContainer: {
    width: '100%',
    backgroundColor: colors.neutralLight0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  hiddenToolbar: {
    // adding !important to override plate styles
    display: 'none !important',
  },
  variableIndicator: {
    fontWeight: 'bold',
    background: colors.neutralLight30,
    color: colors.neutralMid300,
    userSelect: 'none',
    cursor: 'help',
    width: 'max-content',
    padding: spacing.xxxs,
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translate(0%, -50%)',
    ...rounded.sm,
    height: '19px',
    '& p': {
      margin: 0,
      'margin-block-end': '0 !important',
      'font-size': '1rem',
      'line-height': '0.8rem',
    },
  },
})

const toolbarLeft = {
  borderBottom: 'none',
  justifyContent: 'space-between',
  width: 'fit-content',
  padding: 0,
  margin: 0,
  flexWrap: 'nowrap',
  position: 'relative',
  minHeight: 'inherit',
}

const toolbarRight = {
  borderBottom: 'none',
  justifyContent: 'flex-end',
  padding: 0,
  margin: 0,
  flexWrap: 'nowrap',
}
const toolbarJsonEditor = {
  borderBottom: 'none',
  justifyContent: 'flex-start',
  width: 'fit-content',
  padding: 0,
  margin: 0,
  marginBottom: 2,
  minHeight: 'auto',
  flexWrap: 'nowrap',
  position: 'relative',
}

export const toolbarStyle = {
  left: toolbarLeft,
  right: toolbarRight,
  condensed: toolbarJsonEditor,
}
