import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../utils'
import { colors, spacing } from '../../utils/style-guide'
import { Tooltip } from '../Tooltip'
import { Text } from '../Typography'
import classnames from 'classnames'
import { useStyles } from './useStyles'
import { Timing } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { isNil } from 'lodash'

interface TimestampProps {
  timestamp: string
  className?: string
  timestampLabel: string
}

export const Timestamp = ({
  timestamp,
  className,
  timestampLabel,
}: TimestampProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  if (isNil(timestamp)) return <></>
  return (
    <Tooltip
      info={`${t(timestampLabel)} ${
        formatDate({
          isoDateStr: timestamp,
          dateFormat: 'MMM dd, yyyy, h:mm:ss a', // Apr 12, 2023, 3:00:00 PM
        }) ?? ''
      }`}
      arrow
    >
      <span className={classnames([className, classes.timestampContainer])}>
        <IconWrapper
          size='xs'
          color={colors.neutralDark500}
          style={{ marginRight: spacing.xxxs }}
        >
          <Timing />
        </IconWrapper>
        <Text variant='textSmall' color={colors.neutralMid300}>
          {formatDistanceToNow(new Date(timestamp), { addSuffix: true })}
        </Text>
      </span>
    </Tooltip>
  )
}
