// @ts-nocheck TODO: remove this comment and fix errors
import { last } from 'lodash'
import { WebhookCall } from './types'

/*
 * Webhook calls are created but no request is sent while we are previewing a pathway.
 * This leads to adding extra logic to fetch Webhook Call date when in preview.
 * This helper function uses last response date or the date when webhook was created to get the webhook call date.
 * @param WebhookCall
 * @return Date
 */
export const getWebhookCallDate = (
  webhookCall: Pick<WebhookCall, 'created_at' | 'responses'>,
): Date => {
  const dateString: string =
    (webhookCall.responses ?? []).length > 0
      ? last(webhookCall.responses).date
      : webhookCall.created_at

  return new Date(dateString)
}
