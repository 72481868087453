import React, { type FC } from 'react'
import { TopNavigationWithBreadcrumbs } from '@awell/ui-kit/components/TopNavigationWithBreadcrumbs'
import { PathwayStatus, usePathway } from '../../hooks/usePathway'

export const PathwayNavigationBar: FC<{
  id: string
  isNotInDefaultTenant: boolean
}> = ({ id, isNotInDefaultTenant }) => {
  const { pathway, loading } = usePathway(id)

  if (loading) return null
  return (
    <TopNavigationWithBreadcrumbs
      activeItemTitle={pathway?.title ?? ''}
      parentPageName={pathway?.patient?.profile?.name ?? ''}
      isPathwayStopped={pathway?.status === PathwayStatus.Stopped}
      navigationLink={
        isNotInDefaultTenant
          ? `/patients/${pathway?.patient?.id ?? ''}/pathways`
          : ''
      }
    />
  )
}

PathwayNavigationBar.displayName = 'PathwayNavigationBar'
