import * as React from 'react'

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 11a4 4 0 100-8 4 4 0 000 8zm0 10c4.418 0 8-1.79 8-4s-3.582-4-8-4-8 1.79-8 4 3.582 4 8 4z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgUser
