// @ts-nocheck TODO: remove this comment and fix errors
import * as React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    color: '#0078d4',
    textDecoration: 'underline',
    '&:hover': {
      color: '#004578',
    },
  },
})

export const LinkElement = props => {
  const { attributes, children, element, nodeProps } = props
  const classes = useStyles()

  return (
    <a
      {...attributes}
      href={element.url}
      className={classes.root}
      target='_blank'
      rel='noreferrer'
      {...nodeProps}
    >
      {children}
    </a>
  )
}
