import * as React from 'react'

function SvgCopyOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#copy-outline_svg__clip0_633_6751)'>
        <path
          d='M10.667 5.333V3.467c0-.747 0-1.12-.146-1.406a1.333 1.333 0 00-.582-.582c-.286-.146-.659-.146-1.406-.146H3.467c-.747 0-1.12 0-1.406.146-.25.128-.455.331-.582.582-.146.286-.146.659-.146 1.406v5.066c0 .747 0 1.12.146 1.406.127.25.331.455.582.582.285.146.659.146 1.406.146h1.866m2.134 4h5.066c.747 0 1.12 0 1.406-.146.25-.127.454-.331.582-.582.146-.286.146-.659.146-1.406V7.467c0-.747 0-1.12-.146-1.406a1.333 1.333 0 00-.582-.582c-.286-.146-.659-.146-1.406-.146H7.467c-.747 0-1.12 0-1.406.146-.25.128-.455.332-.582.582-.146.286-.146.659-.146 1.406v5.066c0 .747 0 1.12.146 1.406.127.25.331.455.582.582.285.146.659.146 1.406.146z'
          stroke='currentColor'
          strokeWidth={1.333}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='copy-outline_svg__clip0_633_6751'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgCopyOutline
