import { makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  dividerHitbox: {
    cursor: 'col-resize',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    padding: `0 0 0 2px`,
    backgroundColor: colors.neutralLight0,
    '&:hover': {
      backgroundColor: colors.neutralLight30,
    },
    '&:hover $divider': {
      border: `1px solid ${colors.brand100}`,
    },
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  divider: {
    height: '100%',
    border: `1px solid ${colors.neutralLight10}`,
    '&:hover': {
      border: `1px solid ${colors.brand100}`,
    },
  },
  transparentDivider: {
    height: '100%',
    border: `1px solid transparent`,
    '&:hover': {
      border: `1px solid ${colors.brand100}`,
    },
  },
  dragging: {
    cursor: 'grabbing',
  },
  '@media (max-width: 500px)': {
    dividerHitbox: {
      display: 'none',
    },
    divider: {
      display: 'none',
    },
  },
})
