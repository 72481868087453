export type {
  DataPointDefinition,
  BaselineInfoInput,
  AnswerInput,
  Question,
} from '../../generated/types-orchestration'
export {
  QuestionType,
  useSaveBaselineInfoMutation,
} from '../../generated/types-orchestration'
export enum UserQuestionType {
  MultipleSelect = 'multiple_select',
  MultipleChoice = 'multiple_choice',
  Number = 'number',
  YesNo = 'yes_no',
  Date = 'date',
  ShortText = 'short_text',
  LongText = 'long_text',
  MultipleChoiceGrid = 'multiple_choice_grid',
  Slider = 'slider',
  Signature = 'signature',
  Telephone = 'telephone',
}
