import { makeStyles } from '@material-ui/core/styles'
import { colors, rounded, spacing, shadows } from '../../utils/style-guide'

export const configurationStyles = makeStyles(theme => ({
  configuration: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing.s,
    [theme.breakpoints.down('md')]: {
      padding: spacing.xxs,
    },
  },
  configurationOption: {
    marginBottom: spacing.xs,
  },
  label: {
    marginBottom: spacing.xxxs,
  },
  input: {
    ...rounded.m,
    flexGrow: 1,
  },
  smallInput: {
    ...rounded.m,
    width: spacing.xxxl,
  },
  select: {
    ...rounded.m,
    margin: `0 0 auto`,
    width: spacing.xxxl,
    padding: spacing.xxs,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.neutralLight40,
    backgroundColor: colors.neutralLight10,
    '&.disabled': {
      borderColor: 'rgba(204, 204, 204, 0.3)',
    },
    '&:focus': {
      borderColor: colors.brand100,
      backgroundColor: colors.neutralLight0,
    },
    '&:hover:not($focused)': {
      borderColor: colors.neutralLight40,
      backgroundColor: colors.neutralLight30,
    },
  },
  options: {
    ...rounded.m,
    ...shadows.l,
    backgroundColor: colors.neutralLight0,
    left: 0,
    padding: spacing.xxs,
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 1,
    overflowY: 'auto',
  },
  sliderInput: {
    display: 'flex',
    flex: 1,
    '& $smallInput': {
      marginRight: spacing.xxs,
    },
  },
  displayConditions: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  displayConditionsHeadline: {
    marginBottom: spacing.xs,
    marginTop: spacing.xxs,
  },
  displayConditionsRule: {
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xs,
    borderTop: `1px solid ${colors.neutralLight50}`,
    minHeight: spacing.xl,
  },
  underlinedDotted: {
    textDecorationStyle: 'dotted',
    textDecorationColor: colors.neutralDark500,
    textDecorationLine: 'underline',
    width: 'fit-content',
  },
  configurationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: spacing.xxs,
  },
  configurationGroup: {
    borderTop: `1px solid ${colors.neutralLight50}`,
    borderBottom: `1px solid ${colors.neutralLight50}`,
    marginBottom: spacing.xs,
  },
  numberConfigInput: {
    display: 'flex',
    flex: 1,
    '& $smallInput': {
      marginRight: spacing.xxs,
    },
  },
  dateConfigInput: {
    display: 'flex',
    flex: 1,
    '& $smallInput': {
      marginRight: spacing.xxs,
    },
  },
  labelWithSwitch: {
    display: 'flex',
    flex: 1,
    marginBottom: spacing.xxs,
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}))
