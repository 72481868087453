import { makeStyles } from '@material-ui/core'
import { spacing, colors, fonts, type } from '../../utils/style-guide'

export const useStyles = makeStyles({
  chatContainerWrapper: {
    /* Positioning and size */
    position: 'fixed',
    bottom: '2rem',
    right: '1.5rem' /* 6 divided by 4 */,
    height: '30%',
    width: '30%',
    minWidth: '75%',
    marginBottom: '3rem' /* 12 divided by 4 */,
    marginLeft: '25%',

    /* Borders */
    borderRadius:
      '0.375rem' /* this translates to a medium-sized border-radius in Tailwind */,
    borderWidth: '0.5px',
    borderColor: '#cccccc',

    /* Shadows */
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',

    /* Colors */
    backgroundColor: 'white',

    /* Typography */
    fontFamily: 'sans-serif',

    /* Z-index */
    zIndex: 50,

    '@media (min-width: 640px)': {
      marginLeft: '25%',
      minWidth: '75%',
    },

    '@media (min-width: 768px)': {
      marginLeft: '50%',
      minWidth: '50%',
    },

    '@media (min-width: 1280px)': {
      marginLeft: '75%',
      minWidth: '30%',
    },
  },
  chatWindow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '96%',
    margin: spacing.xs,
    paddingTop: 0,
    color: colors.neutralDark600,
  },
  answerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: '-10px',
    zIndex: 0,
    height: '85%',
    maxWidth: '100%',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    padding: `${(spacing.xs, spacing.xxs, spacing.s)}`,
    fontSize: fonts.size.xs,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  startIcon: {
    height: '3rem',
    width: '3rem',
    marginBottom: spacing.xs,
  },
  startText: {
    marginTop: 0,
    fontFamily: 'sans-serif',
    fontSize: type.s.fontSize,
  },
  spacer: {
    height: '1rem',
  },
  questionContainer: {
    position: 'absolute',
    bottom: spacing.xs,
    height: '2.5rem',
    width: '100%',
    backgroundColor: '#f2f2f2',
    borderRadius: '0.375rem',
  },
  form: {
    display: 'flex',
  },
  questionInput: {
    height: '2.5rem',
    width: '90%',
    appearance: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: '0.375rem',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    paddingTop: '0.375rem',
    fontSize: '0.875rem',
    outline: 'none',
    boxShadow: 'none',
    '&:disabled': {
      cursor: 'progress',
      color: '#8b95a5',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    '&::placeholder': {
      color: colors.neutralMid400,
      opacity: 1,
    },
  },
  submitButton: {
    appearance: 'none',
    position: 'absolute',
    right: spacing.xs,
    border: 'none',
    height: '100%',
    width: 'fit-content',
    cursor: 'pointer',
  },
  loadingIcon: {
    color: colors.accent100,
    height: '1.5rem',
    width: '1.5rem',
    animation: 'spin 1s linear infinite',
  },
  sendIcon: {
    height: '1.5rem',
    width: '1.5rem',
    stroke: '#8b95a5',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: spacing.s,
    right: spacing.s,
    zIndex: 10,
  },
  chatIcon: {
    marginRight: spacing.xxs,
    height: '1.25rem',
    width: '1.25rem',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  answerLoading: {
    ':not(:last-child) span[data-selector="typingCarret"]': {
      display: 'none',
    },
    '.prompt-answer-loading :last-child span[data-selector="typingCarret"]': {
      '@apply': 'visible',
    },
  },
  answerDone: {
    'span[data-selector="typingCarret"]': {
      display: 'none',
    },
  },
  answerContent: {
    color: colors.neutralDark600,
    a: {
      fontWeight: 'bold',
      borderBottom: `1px solid ${colors.accent100}`,
      '&:hover': {
        borderBottomWidth: '2px',
      },
    },
    'strong, bold': {
      color: colors.accent100,
    },
    'h1:first-child, h2:first-child, h3:first-child, p:first-child': {
      marginTop: 0,
    },
    'h1:not(:first-child), h2:not(:first-child), h3:not(:first-child), p:not(:first-child)':
      {
        marginTop: spacing.xs,
      },
    'h1 + p, h2 + p, h3 + p': {
      marginTop: '0 !important',
    },
    ol: {
      listStyleType: 'none',
    },
    'ul:not(.code-block ul)': {
      listStyleType: 'disc',
    },
    'ol:not(.code-block ol), ul:not(.code-block ul)': {
      fontSize: type.m.fontSize,
      paddingLeft: spacing.s,
      marginBottom: spacing.s,
      lineHeight: spacing.s,
    },
    'ol:not(.code-block ol)': {
      paddingLeft: 0,
    },
    'ol.no-pl, ul.no-pl': {
      paddingLeft: 0,
    },
    'ol:not(.code-block ol) li, ul:not(.code-block ul) li': {
      marginBottom: spacing.xs,
      paddingLeft: spacing.xs,
    },
    'ol:not(.code-block ol) li': {
      counterIncrement: 'step-counter',
      position: 'relative',
      paddingLeft: spacing.m,
    },
    p: {
      fontSize: type.m.fontSize,
      marginTop: spacing.s,
      marginBottom: spacing.s,
      lineHeight: spacing.s,
    },
    blockquote: {
      borderLeftWidth: '2px',
      borderColor: colors.accent100,
      paddingLeft: spacing.s,
      marginTop: spacing.s,
      marginBottom: spacing.s,
      wordBreak: 'break-word',
    },
    'blockquote p': {
      paddingTop: spacing.xs,
      paddingBottom: spacing.xs,
      margin: 0,
    },
    'blockquote code, code.code': {
      fontSize: type.sm.fontSize,
      lineHeight: type.sm.lineHeight,
    },
    code: {
      fontWeight: fonts.weight.bolder,
      fontSize: type.sm.fontSize,
      color: colors.neutralDark900,
      fontVariantLigatures: 'none',
    },
  },
})
