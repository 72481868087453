// @ts-nocheck TODO: remove this comment and fix errors
import classNames from 'classnames'
import React, { ReactNode, useContext } from 'react'
import { colors } from '../../../utils/style-guide'
import { IconButton } from '../../Button'
import { ChevronRight, Delete } from '../../Icons'
import { IconWrapper } from '../../IconWrapper'
import { InteractiveListContext } from '../InteractiveListContext'
import { useStyles } from './useStyles'

interface ListItemContentProps<RootProps = Record<string, unknown>> {
  itemId: string
  RootComponent?: ReactNode
  rootComponentProps?: RootProps
}

const DefaultRootComponent = ({ children, className }) => (
  <div className={className}>{children}</div>
)

export const ListItemContent: React.FC<ListItemContentProps> = ({
  children,
  itemId,
  RootComponent = DefaultRootComponent,
  rootComponentProps = {},
}) => {
  const classes = useStyles()
  const { editing, onDelete, actionable, reorderable, labels } = useContext(
    InteractiveListContext,
  )

  const handleDelete = event => {
    event.stopPropagation()
    onDelete(itemId)
  }

  const showEditButton = editing
  const showActionIcon = !editing && actionable
  const Component = editing ? DefaultRootComponent : RootComponent

  return (
    <Component
      className={classNames(classes.contentContainer, {
        [classes.reorderableContentContainer]: reorderable,
        [classes.actionable]: actionable,
      })}
      {...rootComponentProps}
    >
      <div className={classes.itemContent}>{children}</div>
      {showEditButton && (
        <div className={classes.deleteButtonContainer}>
          <IconButton
            label={labels.delete}
            onClick={handleDelete}
            iconColor={colors.red100}
          >
            <Delete />
          </IconButton>
        </div>
      )}
      {showActionIcon && (
        <div className={classes.actionIconContainer}>
          <IconWrapper>
            <ChevronRight />
          </IconWrapper>
        </div>
      )}
    </Component>
  )
}
ListItemContent.displayName = 'InteractiveList.ListItemContent'
