import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  trademark: {
    fontStyle: 'italic',
    marginTop: spacing.xs,
    marginBottom: spacing.xxs,
  },
})
