import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  ganttContainer: {
    height: '100%',
    position: 'relative',
  },
  controls: {
    position: 'absolute',
    width: '34px',
    bottom: '25px',
    right: '20px',
    zIndex: 99,
    border: `1px solid ${colors.neutralLight40}`,
    borderRadius: spacing.xxxs,
    boxShadow: '0px, 4px rgba(0, 0, 0, 0.1)',
  },
  zoomButton: {
    border: 'none',
    padding: spacing.xxs,
    background: colors.neutralLight0,

    '&:first-of-type': {
      borderTopRightRadius: spacing.xxxs,
      borderTopLeftRadius: spacing.xxxs,
    },
    '&:last-of-type': {
      borderBottomRightRadius: spacing.xxxs,
      borderBottomLeftRadius: spacing.xxxs,
    },

    '&:hover, &:focus': {
      background: colors.neutralLight20,
      cursor: 'pointer',
    },

    '&:disabled': {
      opacity: 0.3,
    },
  },
})
