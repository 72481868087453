export {
  Sex,
  useCreatePatientMutation,
  GetPatientsDocument,
} from '../../generated/types-orchestration'

export type {
  CreatePatientInput,
  PatientFragment as Patient,
  UserProfile as PatientProfile,
  CreatePatientMutation,
  GetPatientsQuery,
  GetPatientsQueryVariables,
} from '../../generated/types-orchestration'

export enum Language {
  dutch = 'nl',
  english = 'en',
  french = 'fr',
  estonian = 'et',
  romanian = 'ro',
  russian = 'ru',
}
