import { ApiCall, useApiCallQuery } from './types'

interface UseApiCallHook {
  loading: boolean
  apiCall?: ApiCall
}

export const useApiCall = (id: string): UseApiCallHook => {
  const { data, loading } = useApiCallQuery({
    variables: {
      id,
    },
  })
  const apiCall = data?.apiCall.api_call
  return { loading, apiCall }
}
