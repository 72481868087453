import { capitalize } from 'lodash'
import { isEmpty, isNil, omit } from 'lodash/fp'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentItem } from '../AttachmentItem'
import { InteractiveList } from '../InteractiveList'
import type { MessageAttachment } from './types'
import { useStyles } from './useStyles'

interface AttachmentsLabels {
  placeholder?: string
}
interface AttachmentsProps {
  attachments: Array<MessageAttachment>
  onReorder?: (attachments: Array<MessageAttachment>) => void
  onSelect?: (attachment: string) => void
  onDelete?: (attachment_id: string) => void
  editing?: boolean
  labels?: AttachmentsLabels
}

export const Attachments: FC<AttachmentsProps> = ({
  attachments = [],
  onReorder,
  onSelect,
  onDelete,
  editing = false,
}) => {
  const { t } = useTranslation()
  if (isNil(attachments) || isEmpty(attachments)) {
    return null
  }

  const handleReorder = ({
    source,
    destination,
  }: {
    source: number
    destination: number
  }): void => {
    const attachment_list = [...attachments]
    const [listItem] = attachment_list.splice(source, 1)
    attachment_list.splice(destination, 0, listItem)
    if (!isNil(onReorder)) {
      onReorder(attachment_list.map(omit(['__typename'])))
    }
  }

  const classes = useStyles({
    mode: 'left',
  })
  return (
    <InteractiveList
      className={classes.attachmentsContainer}
      reorderable={onReorder !== undefined}
      onReorder={handleReorder}
      onDelete={onDelete}
      editable={editing}
    >
      <InteractiveList.ListItems>
        {attachments.map(({ id, name, url, type: attachmentType }, index) => {
          return (
            <InteractiveList.ListItem key={id} index={index} itemId={id}>
              <AttachmentItem
                id={id}
                name={name}
                url={url}
                type={attachmentType}
                isBuilding={editing}
                onSelect={onSelect}
                placeholder={capitalize(t('untitled'))}
              />
            </InteractiveList.ListItem>
          )
        })}
      </InteractiveList.ListItems>
      <InteractiveList.AddItemButton />
    </InteractiveList>
  )
}

Attachments.displayName = 'Attachments'
