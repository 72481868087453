import { type OptionProps } from '@awell/ui-kit/components/ComboBox'
import { useWhoami } from '../useWhoami'
import { type IdentifierSystem } from './types'
interface UseIdentifierSystemsHook {
  identifierSystems: Array<IdentifierSystem>
  identifierSystemsOptions: Array<OptionProps>
}

export const useIdentifierSystems = (): UseIdentifierSystemsHook => {
  const { loading, user } = useWhoami()

  if (loading) {
    return {
      identifierSystems: [],
      identifierSystemsOptions: [],
    }
  }
  const identifierSystems = user?.tenant.identifier_systems ?? []
  return {
    identifierSystems,
    identifierSystemsOptions: identifierSystems.map(
      ({ name, display_name, system }) => ({
        label: display_name,
        value: system,
        type: name,
      }),
    ),
  }
}
