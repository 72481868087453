import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentContainer } from '@awell/ui-kit/components/ContentContainer'
import {
  SubHeading,
  Text,
} from '@awell/ui-kit/components/Typography/TypographyNew'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import { TableSkeleton } from '@awell/ui-kit/components/Skeleton/TableSkeleton'
import { NavigationButton } from '@awell/ui-kit/components/NavigationButton'
import { useStyles } from '../useStyles'

interface WebhookPreviewSkeletonProps {
  backNavigationList: string
}
export const WebhookDetailsSkeleton: FC<WebhookPreviewSkeletonProps> = ({
  backNavigationList,
}): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ScrollContainer className={classes.container}>
      <ContentContainer>
        <>
          <div className={classes.navContainer}>
            <NavigationButton linkUrl={backNavigationList} />

            <div className={classes.textContainer}>
              <Skeleton variant='text' height={16} width={200} />
            </div>
          </div>

          <div className={classes.basicInfoContainer}>
            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('URL')}
              </Text>
              <Skeleton variant='text' height={16} width={300} />
            </div>

            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('triggered_at')}
              </Text>
              <Skeleton variant='text' height={16} width={100} />
            </div>

            <div className={classes.basicInfoGroup}>
              <Text variant='tinyHeadlineCaps' color={colors.neutralMid200}>
                {t('trigger_event')}
              </Text>
              <Skeleton variant='text' height={16} width={150} />
            </div>
          </div>

          <section>
            <SubHeading>{t('logs')}</SubHeading>
            <TableSkeleton cellCount={5} rowsCount={5} />
          </section>

          <div className={classes.bodyContainer}>
            <div>
              <Text variant='textMedium'> {t('request_body')}</Text>
              <Skeleton variant='rect' width='100%' height={200} />
            </div>
          </div>
        </>
      </ContentContainer>
    </ScrollContainer>
  )
}

WebhookDetailsSkeleton.displayName = 'WebhookDetailsSkeleton'
