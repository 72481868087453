import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'
import { type IconProps } from './types'
import { isNil } from 'lodash'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    transformOrigin: 'center',
    '& > svg': {
      color: (props: IconProps) => props.color,
      height: '100%',
      width: '100%',
    },
  },
  inline: {
    display: 'inline-flex',
  },
  defaultColor: {
    color: colors.neutralDark800,
    fill: colors.neutralDark800,
  },
  background: {
    padding: spacing.xxxs,
    backgroundColor: (props: IconProps) =>
      isNil(props.backgroundColor)
        ? colors.neutralLight20
        : props.backgroundColor,
  },
  rounded: {
    ...rounded.m,
  },
  light: {
    fill: colors.neutralLight0,
    color: colors.neutralLight0,
  },
  transparent: {
    fill: 'transparent',
  },
  xxs: {
    width: spacing.xxs,
    height: spacing.xxs,
  },
  xs: {
    width: spacing.xs,
    height: spacing.xs,
  },
  xsm: {
    width: '1.429rem',
    height: '1.429rem', // 20px - needed for list icons
  },
  s: {
    width: spacing.s,
    height: spacing.s,
  },
  m: {
    width: spacing.m,
    height: spacing.m,
  },
  l: {
    width: spacing.l,
    height: spacing.l,
  },
  xl: {
    width: spacing.xl,
    height: spacing.xl,
  },
  xxl: {
    width: spacing.xxl,
    height: spacing.xxl,
  },
  xxxl: {
    width: spacing.xxxl,
    height: spacing.xxxl,
  },
  auto: {
    width: '100%',
    height: '100%',
  },
})
