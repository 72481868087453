import React, { type FC } from 'react'
import { type Activity } from '@awell/ui-kit/components/AwellActivity'
import { useTranslation } from 'react-i18next'
import { useExtensionActivityRecord } from '../../../hooks/useExtensionActivity'
import { isEmpty, isNil } from 'lodash'
import { Heading5, Text } from '@awell/ui-kit/components/Typography'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { Spinner } from '@awell/ui-kit/components/Spinner'

interface ExtensionDataPointsProps {
  activity: Activity
}

export const ExtensionDataPoints: FC<ExtensionDataPointsProps> = ({
  activity,
}) => {
  const { t } = useTranslation()
  const extensionRecordId = activity.object.id

  const { loading, extensionActivityRecord } =
    useExtensionActivityRecord(extensionRecordId)

  if (loading) {
    return <Spinner />
  }

  const dataPoints = extensionActivityRecord?.data_points

  const noDataPoints = isNil(dataPoints) || isEmpty(dataPoints)
  const getNewLineBreaks = (value: string): string => {
    return value.replace(/\n/g, '<br />')
  }

  return (
    <div>
      {noDataPoints && (
        <Text>{t('activityfeed:extension_no_data_points')}</Text>
      )}
      {!noDataPoints &&
        dataPoints.map(({ label, value }) => (
          <div key={label}>
            <Heading5 variant='textMedium'>{label}:</Heading5>
            <Text spacing={`0 0 ${spacing.xxxs}`}>
              <p
                dangerouslySetInnerHTML={{ __html: getNewLineBreaks(value) }}
              />
            </Text>
          </div>
        ))}
    </div>
  )
}
