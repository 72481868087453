import {
  DynamicConfigName,
  FeatureGateName,
  type DynamicConfigTypes,
} from '@awell/libs-web/src/experiments'
import { Chat } from '@awell/ui-kit/components/Chat'
import { ScrollContainer } from '@awell/ui-kit/components/ScrollContainer'
import {
  SearchFilterBar,
  type FilterVariants,
} from '@awell/ui-kit/components/SearchFilterBar'
import { ActivityFeed } from '@awell/ui-kit/compositions/ActivityFeed'
import { Feed } from '@awell/ui-kit/compositions/ActivityFeed/Feed'
import { capitalize, filter, isEmpty, isNil, startCase, uniqBy } from 'lodash'
import React, { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityObjectType,
  ActivityStatus,
  type Activity,
} from '../../hooks/useActivityFeed'
import { useBaselineInfo } from '../../hooks/useBaselineInfo'
import { useOrchestrationFactsPrompt } from '../../hooks/useOrchestrationFactsPrompt'
import { useScheduledSteps } from '../../hooks/useScheduledSteps'
import { useScheduledTracks } from '../../hooks/useScheduledTracks'
import { usePathwayContextProvider } from '../PathwayContextProvider'
import { PathwayActivityFeedSkeleton } from './skeleton/PathwayActivityFeedSkeleton'
import { useDynamicConfig, useFeatureGate } from '@statsig/react-bindings'

interface PathwayActivityFeedFilters {
  showSystemActivities: boolean
  showScheduledElements: boolean
  stakeholder: Array<string>
  type: Array<ActivityObjectType>
  status: Array<ActivityStatus>
  query: string
}
interface Props {
  pathwayId: string
}

const userVisibleSystemActivities = [
  ActivityObjectType.ApiCall,
  ActivityObjectType.EmrRequest,
  ActivityObjectType.EmrReport,
  ActivityObjectType.Calculation,
  ActivityObjectType.ClinicalNote,
  ActivityObjectType.PluginAction,
  ActivityObjectType.Decision,
]

export const PathwayActivityFeed: FC<Props> = ({ pathwayId }) => {
  const { baselineDataPoints } = useBaselineInfo(pathwayId)
  const { scheduledSteps } = useScheduledSteps(pathwayId)
  const { scheduledTracks } = useScheduledTracks(pathwayId)
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState<Array<ActivityStatus>>([])
  const [stakeholderFilter, setStakeholderFilter] = useState<Array<string>>([])
  const [typeFilter, setTypeFilter] = useState<Array<ActivityObjectType>>([])
  const [prompt, setPrompt] = useState('')
  const { t } = useTranslation()
  const [showSystemActivitiesFilter, setShowSystemActivitiesFilter] =
    useState(false)
  const [showScheduledElementsFilter, setShowScheduledElementsFilter] =
    useState<boolean>(true)
  const {
    onSelectActivity,
    selectedActivity,
    setSidePanelOpen,
    activities,
    loading,
    pathwayStatusExplanation,
  } = usePathwayContextProvider()

  const { value: showChat } = useFeatureGate(FeatureGateName.AUDIT_LOGS_AVA)
  const config = useDynamicConfig(DynamicConfigName.AUDIT_LOGS_AVA)

  const { loading: chatLoading, answer } = useOrchestrationFactsPrompt(
    pathwayId,
    prompt,
  )
  const handleActivitySelect = (activity: Activity): void => {
    setSidePanelOpen(true)
    // @ts-expect-error TODO fix type error
    onSelectActivity(activity)
  }

  if (loading) {
    return <PathwayActivityFeedSkeleton />
  }

  const scheduledElements = [
    ...(scheduledSteps ?? []),
    ...(scheduledTracks ?? []),
  ]

  const matchesQuery =
    (query: string) =>
      (activity: Activity): boolean => {
        if (isEmpty(query)) {
          return true
        }
        return (
          activity.object.name.toLowerCase().includes(query) ||
          (activity.container_name ?? '').toLowerCase().includes(query) ||
          (activity.track?.title ?? '').toLowerCase().includes(query)
        )
      }

  const matchesStatuses =
    (statuses: Array<string>) =>
      (activity: Activity): boolean => {
        if (isEmpty(statuses)) {
          return true
        }
        return statuses.includes(activity.status)
      }

  const matchesTypes =
    (types: Array<string>) =>
      (activity: Activity): boolean => {
        if (isEmpty(types)) {
          return true
        }
        if (isNil(activity.object)) {
          return false
        }
        return types.includes(activity.object.type)
      }

  const matchesStakeholders =
    (stakeholders: Array<string>) =>
      (activity: Activity): boolean => {
        if (isEmpty(stakeholders)) {
          return true
        }
        if (activity.indirect_object == null) {
          return false
        }
        return stakeholders.includes(activity.indirect_object?.id)
      }

  const matchesShowSystemActivities =
    (showSystemActivities: boolean) =>
      (activity: Activity): boolean => {
        const isPathwayActivity =
          activity.object.type === ActivityObjectType.Pathway
        if (
          activity.isUserActivity ||
          isPathwayActivity ||
          userVisibleSystemActivities.includes(activity.object.type)
        ) {
          return true
        }
        return showSystemActivities
      }

  const compositeFilter =
    ({
      showSystemActivities = true,
      stakeholders = [],
      types = [],
      statuses = [],
      query = '',
    }: {
      showSystemActivities: boolean
      stakeholders: Array<string>
      types: Array<ActivityObjectType>
      statuses: Array<ActivityStatus>
      query: string
    }) =>
      (activity: Activity) => {
        return (
          matchesShowSystemActivities(showSystemActivities)(activity) &&
          matchesStakeholders(stakeholders)(activity) &&
          matchesTypes(types)(activity) &&
          matchesQuery(query.toLowerCase())(activity) &&
          matchesStatuses(statuses)(activity)
        )
      }

  const getResults = () =>
    filter(
      activities,
      compositeFilter({
        types: typeFilter,
        stakeholders: stakeholderFilter,
        statuses: statusFilter,
        showSystemActivities: showSystemActivitiesFilter,
        query: searchTerm,
      }),
    )

  const handleSearchFilter = ({
    query,
    status,
    stakeholder,
    type,
    showSystemActivities,
    showScheduledElements,
  }: PathwayActivityFeedFilters): void => {
    setSearchTerm(query)
    setStatusFilter(status)
    setStakeholderFilter(stakeholder)
    setTypeFilter(type)
    setShowSystemActivitiesFilter(showSystemActivities)
    setShowScheduledElementsFilter(showScheduledElements)
  }

  const statusDictionary = {
    [ActivityStatus.Active]: t('activityfeed:to_do'),
    [ActivityStatus.Done]: t('activityfeed:done'),
    [ActivityStatus.Canceled]: t('activityfeed:canceled'),
    [ActivityStatus.Failed]: t('activityfeed:failed'),
    [ActivityStatus.Expired]: t('activityfeed:expired'),
    in_progress: t('activityfeed:in_progress'),
  }

  const getStatusOptions = (_activities: Array<Activity>) => {
    return uniqBy(
      _activities.map(activity => {
        if (
          activity.object.type === ActivityObjectType.EmrReport &&
          activity.status === ActivityStatus.Active
        ) {
          return {
            label: capitalize(statusDictionary.in_progress),
            value: activity.status,
          }
        }
        return {
          label: capitalize(statusDictionary[activity.status]),
          value: activity.status,
        }
      }),
      'label',
    )
  }

  const getStakeholderOptions = (_activities: Array<Activity>) => {
    return uniqBy(_activities, activity => activity.indirect_object?.id)
      .map(activity => {
        return {
          label: activity.indirect_object?.name ?? '',
          value: activity.indirect_object?.id ?? '',
        }
      })
      .filter(option => option.label)
  }
  const getActivityTypeOptions = (_activities: Array<Activity>) => {
    return uniqBy(_activities, activity => activity.object.type).map(
      activity => {
        return {
          label: capitalize(startCase(activity.object.type)),
          value: activity.object.type,
        }
      },
    )
  }

  // FIXME: Replace with translation strings
  const filters: Array<FilterVariants> = [
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: getStatusOptions(activities),
    },
    {
      label: 'Stakeholders',
      name: 'stakeholder',
      type: 'select',
      options: getStakeholderOptions(activities),
    },
    {
      label: 'Activity type',
      name: 'type',
      type: 'select',
      options: getActivityTypeOptions(activities),
    },
    {
      label: 'System activities',
      name: 'showSystemActivities',
      type: 'checkbox',
      defaultChecked: false,
    },
    {
      label: t('upcoming_steps'),
      name: 'showScheduledSteps',
      type: 'checkbox',
      defaultChecked: true,
      disabled: scheduledElements?.length === 0,
    },
  ]

  const showScheduledElements =
    scheduledElements?.length !== 0 && showScheduledElementsFilter

  const moreInformation = config.get<
    DynamicConfigTypes[DynamicConfigName.AUDIT_LOGS_AVA]['more_information']
  >('more_information', {
    message: 'Important disclaimer about Personal Health Information (PHI)',
    link: 'https://developers.awellhealth.com/faq#data-privacy',
  })

  return (
    <>
      {showChat && (
        <Chat
          open={false}
          state={
            chatLoading
              ? 'streaming-answer'
              : isEmpty(answer)
                ? 'indeterminate'
                : 'done'
          }
          answer={answer}
          onSubmit={setPrompt}
          buttonLabel={'Ask Ava'}
          moreInformation={moreInformation as { message: string; link: string }}
        />
      )}
      <ActivityFeed>
        <ActivityFeed.Feed>
          <SearchFilterBar<PathwayActivityFeedFilters>
            onSearchFilter={handleSearchFilter}
            filters={filters}
            appliedFilters={{
              showSystemActivities: showSystemActivitiesFilter,
              stakeholder: stakeholderFilter,
              type: typeFilter,
              status: statusFilter,
              query: searchTerm,
              showScheduledElements: showScheduledElementsFilter,
            }}
          />
          <ScrollContainer>
            <Feed
              activities={getResults()}
              selectedActivityId={selectedActivity?.id}
              onSelectActivity={handleActivitySelect}
              baselineDataPoints={baselineDataPoints}
              scheduledElements={scheduledElements}
              showScheduledElements={showScheduledElements}
              pathwayStatusExplanation={pathwayStatusExplanation}
            />
          </ScrollContainer>
        </ActivityFeed.Feed>
      </ActivityFeed>
    </>
  )
}

PathwayActivityFeed.displayName = 'PathwayActivityFeed'
