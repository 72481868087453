import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { Skeleton } from '../../Skeleton'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    background: 'white',
    position: 'relative',
    height: '100%',
  },
  gridRow: {
    minWidth: '280px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridRowHeader: {
    minWidth: '280px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  timelineRow: {
    width: '100%',
    height: '40px',
    display: 'flex',
    '&:first-of-type': {
      borderBottom: '1px solid #F4F5F7',
    },
  },
  timeCell: {
    borderLeft: '1px solid #F4F5F7',
    display: 'flex',
    flexGrow: 1,
    height: '40px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bar: {
    flexGrow: 1,
    position: 'absolute',
  },
  timelineRowHeader: {
    borderBottom: '1px solid #F4F5F7',
    height: '45px',
  },
})
export const GanttSkeleton: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.gridRowHeader}>
          <Skeleton variant='text' width={50} />
          <Skeleton variant='text' width={50} />
          <Skeleton variant='text' width={50} />
        </div>

        <div
          className={classnames(classes.timelineRow, classes.timelineRowHeader)}
        >
          {[...Array(30).keys()].map(c => (
            <div key={`row-cell-${c}`} className={classes.timeCell}>
              <Skeleton variant='text' width={15} />
            </div>
          ))}
        </div>
      </div>
      {[...Array(5).keys()].map(count => (
        <div className={classes.row} key={`grid-count-${count}`}>
          <div className={classes.gridRow}>
            <Skeleton variant='text' width={230} />
          </div>

          <div className={classes.timelineRow}>
            {[...Array(30).keys()].map(c => (
              <div key={`row-cell-${c}`} className={classes.timeCell} />
            ))}
          </div>
        </div>
      ))}
      <div className={classes.bar} style={{ top: '60px', left: '25%' }}>
        <Skeleton variant='rect' width={300} height={24} />
      </div>
      <div className={classes.bar} style={{ top: '100px', left: '30%' }}>
        <Skeleton variant='rect' width={300} height={24} />
      </div>
      <div className={classes.bar} style={{ top: '140px', left: '40%' }}>
        <Skeleton variant='rect' width={300} height={24} />
      </div>
      <div className={classes.bar} style={{ top: '180px', left: '50%' }}>
        <Skeleton variant='rect' width={300} height={24} />
      </div>
    </div>
  )
}
