import { ClickAwayListener, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React, {
  type CSSProperties,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'
import { colors, rounded, shadows, spacing } from '../../utils/style-guide'
import { ChevronDown } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Type, TypeVariants } from '../Typography'

interface DropdownButtonProps {
  label: string
  style?: CSSProperties
  className?: string
  disabled?: boolean
  isOpen: boolean
  width?: number
  onClick: Dispatch<SetStateAction<boolean>>
  variant?: 'primary' | 'accent' | 'outlined'
}

const useStyles = makeStyles(({ width }: { width: number }) => ({
  widthContainer: {
    position: 'relative',
    ...shadows.s,
  },
  container: {
    ...spacing.paddingX.xs,
    ...spacing.paddingY.xxs,
    ...rounded.m,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
  },
  accent: {
    backgroundColor: colors.brand10,
    color: colors.brand100,
    '&:hover, &.hover': {
      backgroundColor: colors.brand75,
    },
    '&:focus, &.focus': {
      backgroundColor: colors.brand75,
      outline: `1px solid ${colors.brand100}`,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLight40,
      color: colors.neutralLight0,
    },
  },
  primary: {
    backgroundColor: colors.brand100,
    color: colors.neutralLight0,
    boxShadow: `0 0 0 0.0714rem ${colors.brand100}`,
    '&:hover, &.hover': {
      backgroundColor: colors.brand300,
      boxShadow: 'none',
    },
    '&:focus:not(:hover), &.focus': {
      backgroundColor: colors.brand300,
      boxShadow: `0 0 0 0.0714rem ${colors.brand100}`,
    },
    '&:active, &.active': {
      backgroundColor: colors.brand100,
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: colors.neutralLight40,
      color: colors.neutralLight0,
      boxShadow: 'none',
    },
  },
  outlined: {
    ...spacing.paddingY.xxxs,
    backgroundColor: colors.neutralLight0,
    color: colors.brand100,
    border: `2px solid ${colors.brand100}`,
    '&:hover, &.hover': {
      backgroundColor: colors.brand10,
    },
    '&:focus, &.focus': {
      backgroundColor: colors.brand10,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLight40,
      color: colors.neutralLight0,
      border: `2px solid ${colors.neutralLight30}`,
    },
  },
  buttonRotated: {
    transform: 'rotate(180deg)',
  },
  content: {
    ...shadows.l,
    ...rounded.m,
    minWidth: width || '210px',
    width: '100%',
    transform: `translateY(${spacing.xxxs})`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.neutralLight0,
    position: 'absolute',
    zIndex: 100, // Make sure this opens above other layers
    right: 0,
  },
  nonInteractive: {
    opacity: 0.6,
    pointerEvents: 'none',
    userSelect: 'none',
    cursor: 'not-allowed',
  },
}))

export const DropdownButton: FC<DropdownButtonProps> = ({
  label,
  className,
  style,
  disabled = false,
  children,
  onClick,
  isOpen,
  width,
  variant = 'accent',
}) => {
  const classes = useStyles({ width, variant })
  const handleCloseDropdown = () => {
    onClick(false)
  }
  const handleToggleDropdown = () => {
    onClick(!isOpen)
  }

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <div
        className={classnames(className, classes.widthContainer, {
          [classes.nonInteractive]: disabled,
        })}
        style={style}
      >
        <div
          role='button'
          tabIndex={0}
          onClick={handleToggleDropdown}
          onKeyUp={e =>
            e.key === 'Enter' &&
            (() => {
              handleCloseDropdown()
            })
          }
          className={classnames(classes.container, classes[variant])}
        >
          <Type
            color={
              variant === 'primary' ? colors.neutralLight0 : colors.brand100
            }
            variant={TypeVariants.medium}
            style={{ lineHeight: spacing.s }}
          >
            {label}
          </Type>
          <IconWrapper
            className={classnames({
              [classes.buttonRotated]: isOpen,
            })}
            color={
              variant === 'primary' ? colors.neutralLight0 : colors.brand100
            }
          >
            <ChevronDown />
          </IconWrapper>
        </div>
        {isOpen && <div className={classes.content}>{children}</div>}
      </div>
    </ClickAwayListener>
  )
}

DropdownButton.displayName = 'DropdownButton'
