import React, { CSSProperties, FC } from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles({
  container: {
    // for scrolling calc we use element.offsetTop = number of pixels from the top of the closest relatively positioned parent element
    position: 'relative',
    height: '100%',
    overflowY: 'auto', // ensures we only show a scrollbar when the child element overflow
  },
})

interface ScrollContainerProps {
  className?: string
  style?: CSSProperties
  dataCy?: string
}

export const ScrollContainer: FC<ScrollContainerProps> = ({
  children,
  className,
  style = {},
  dataCy = null,
}) => {
  const classes = useStyles()
  return (
    <div
      className={`scroll-container ${classnames(className, classes.container)}`}
      style={style}
      data-cy={dataCy}
    >
      {children}
    </div>
  )
}

ScrollContainer.displayName = 'ScrollContainer'
