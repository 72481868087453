import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  tableGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: spacing.m,
  },
  container: {
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
  },
  contentGrid: {
    flexGrow: 1,
  },
  cellContainer: {
    borderBottom: `${colors.neutralLight50} 1px solid`,
    paddingRight: spacing.xxs,
  },
  cellContent: {
    overflowX: 'auto',
  },
  headerCell: {
    textTransform: 'capitalize',
  },
  tableGridHeaderCell: {
    fontWeight: 'bold',
  },
  toolbar: {
    marginLeft: spacing.xs,
    marginBottom: spacing.xs,
    '&> div': {
      minWidth: '25%',
    },
  },
  fullWidthToolbar: {
    marginBottom: spacing.m,
    '&> div': {
      minWidth: spacing.input.l,
    },
  },
  textCell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})
