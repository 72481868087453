import * as React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

import { colors, spacing } from '../../../utils/style-guide'

const useStyles = makeStyles({
  listSubheader: {
    paddingTop: spacing.xs,
    paddingBottom: spacing.xxs,
  },
  primary: {
    color: colors.brand100,
  },
})

interface ListSubheaderProps {
  primary?: boolean
  className?: string
}

export const ListSubheader: React.FC<ListSubheaderProps> = ({
  children,
  primary = false,
  className,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(className, {
        [classes.listSubheader]: true,
        [classes.primary]: primary,
      })}
    >
      {children}
    </div>
  )
}

ListSubheader.displayName = 'ListSubheader'
