import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Dialog as MuiDialog, useTheme } from '@material-ui/core'
import { type DialogProps } from '@material-ui/core/Dialog/Dialog'
import React, { type FC } from 'react'
import { isNil } from 'lodash'

export const Dialog: FC<DialogProps> = props => {
  const theme = useTheme()
  const fullScreen = !isNil(props?.fullScreen)
    ? props.fullScreen
    : useMediaQuery(theme.breakpoints.down('sm'))

  return <MuiDialog {...props} fullScreen={fullScreen} />
}

Dialog.displayName = 'Dialog'
