import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from './Skeleton'

const useStyles = makeStyles({
  tab: {
    padding: '1.142857rem',
    marginRight: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
})
export const TabSkeleton: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.tab}>
      <Skeleton variant='rect' width={100} height={20} />
    </div>
  )
}
