import { isEmpty, isNil } from 'lodash'
import { type QuestionConfig } from '../../types'
import { type Maybe } from '../../../../utils'

export const validateNumberTypeQuestion = (
  questionConfig: Maybe<QuestionConfig> | undefined,
  value: string,
): boolean | string => {
  const inputRequired = questionConfig?.mandatory

  // skip validation if input is not required and empty
  if (inputRequired === false && isEmpty(value)) {
    return true
  }

  const isNumber = !isNaN(Number(value))
  if (!isNumber) {
    // TODO: add translation
    return 'value should be a valid number. You entered a non-number value, which'
  }

  const isRangeEnabled =
    !isNil(questionConfig?.number?.range) &&
    questionConfig.number.range.enabled === true

  if (isRangeEnabled) {
    const range = questionConfig.number!.range
    const min = range?.min ?? 0
    const max = range?.max ?? 0
    const number = Number(value)

    if (number < min || number > max) {
      // TODO: add translation
      return `value should be between ${min} and ${max}. You entered ${value}, which`
    }
  }

  return true
}
