// @ts-nocheck TODO: remove this comment and fix errors
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import type { Activity, Message } from './types'
import {
  useGetMessageQuery,
  useMarkMessageAsReadMutation,
  ActivityObjectType,
} from './types'

interface UseMessageActivityHook {
  loading: boolean
  message?: Message
  onRead?: () => Promise<void>
}

export const useMessageActivity = ({
  activity,
}: {
  activity: Activity
}): UseMessageActivityHook => {
  const {
    id: activity_id,
    object: { id: message_id },
  } = activity
  const { t } = useTranslation()
  const { notifyError } = useNotifications()

  const [markMessageAsRead] = useMarkMessageAsReadMutation()

  const { data, loading, error } = useGetMessageQuery({
    variables: { id: message_id },
  })

  // FIXME: Reading a message does not update message activity status in side panel
  const onRead = async () => {
    // Patient message can only be marked as read from patient app
    if (activity.indirect_object.type === ActivityObjectType.Patient) {
      return
    }

    try {
      await markMessageAsRead({
        variables: {
          input: {
            activity_id,
          },
        },
      })
    } catch (err) {
      notifyError({
        error: err,
        message: t('message_error_notification'),
      })
    }
  }

  if (error) {
    notifyError({
      error,
      message: t('message_load_error_notification'),
    })
    return {
      loading: false,
    }
  }

  if (loading) {
    return {
      loading,
    }
  }

  return {
    loading,
    message: data.message.message,
    onRead,
  }
}
