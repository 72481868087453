import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  body: {
    height: '100%',
  },
  question: {
    marginBottom: spacing.xxs,
  },
  pagination: {
    margin: `${spacing.xxs} 0 ${spacing.xs}`,
  },
  errorList: {
    marginTop: spacing.xs,
    marginBottom: spacing.xs,
  },
})
