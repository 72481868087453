import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../utils/style-guide'

export const useSkeletonStyles = makeStyles(theme => ({
  feedItemContainer: {
    width: 750, // From figma design
    [theme.breakpoints.down('lg')]: {
      width: 600,
    },
    [theme.breakpoints.down('sm')]: {
      width: 400,
    },
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },

  bottomInformationWrapper: {
    display: 'flex',
    marginTop: '12px',
    marginBottom: '16px',
  },
  bottomInformationElement: {
    display: 'flex',
    marginRight: '8px',
  },
  systemCardContainer: {
    padding: `${spacing.xs}`,
  },
}))
