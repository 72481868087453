import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  appOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ...spacing.paddingX.xxs,
    ...spacing.paddingY.xxs,
    ...rounded.m,
    '&:hover': {
      backgroundColor: colors.brand10,
      color: colors.brand100,
    },
  },
  appOptionsHeading: {
    marginBottom: spacing.xxs,
  },
  appOptionIcon: {
    width: spacing.s,
  },
  appOptionContent: {
    paddingLeft: spacing.xxxs,
    paddingRight: spacing.xxs,
  },
})
