import * as React from 'react'

function SvgTrack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x={0.5} y={1.5} width={5} height={3} rx={1} stroke='currentColor' />
      <rect
        x={10.5}
        y={6.5}
        width={5}
        height={3}
        rx={1}
        stroke='currentColor'
      />
      <rect x={0.5} y={6.5} width={5} height={3} rx={1} stroke='currentColor' />
      <rect
        x={10.5}
        y={11.5}
        width={5}
        height={3}
        rx={1}
        stroke='currentColor'
      />
      <path d='M6 8.5h4v-1H6v1z' fill='currentColor' />
      <path
        d='M6 2.5h1v1H6v-1zM8.5 4v8h-1V4h1zm.5 8.5h1v1H9v-1zm-.5-.5a.5.5 0 00.5.5v1A1.5 1.5 0 017.5 12h1zM7 2.5A1.5 1.5 0 018.5 4h-1a.5.5 0 00-.5-.5v-1z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTrack
