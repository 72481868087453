import { makeStyles } from '@material-ui/core'
import { spacing, colors, labelColors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  categoryIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing.s,
    height: spacing.s,
    transformOrigin: 'center',
    position: 'relative',
  },
  small: {
    width: spacing.xs,
    height: spacing.xs,
  },
  pathway: {
    color: colors.secondaryOrange100,
  },
  action: {
    color: colors.secondaryMagenta100,
  },
  step: {
    color: colors.secondaryLimegreen100,
  },
  track: {
    color: colors.secondaryAqua100,
  },
  transition: {
    color: colors.secondaryPink100,
  },
  'sticky-note': {
    color: colors.secondaryYellow100,
  },
  neutral: {
    color: colors.neutralMid100,
    fill: colors.neutralMid100,
    fallbacks: {
      color: colors.neutralDark500,
    },
  },
  inline: {
    display: 'inline-block',
  },
  blue: {
    color: labelColors.blue,
    fill: labelColors.blue,
  },
  teal: {
    color: labelColors.slateBlue,
    fill: labelColors.slateBlue,
  },
  green: {
    color: labelColors.green,
    fill: labelColors.green,
  },
  orange: {
    color: labelColors.yellow,
    fill: labelColors.yellow,
  },
  purple: {
    color: labelColors.purple,
    fill: labelColors.purple,
  },
  red: {
    color: labelColors.pink,
    fill: labelColors.pink,
  },
  grey: {
    color: colors.neutralLight50,
    fill: colors.neutralLight50,
  },
})
