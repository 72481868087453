import {
  DropdownButton,
  DropdownOption,
} from '@awell/ui-kit/components/DropdownButton'
import { Delete, Plus, StepStop } from '@awell/ui-kit/components/Icons'
import React, { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ManagePathwayButtonProps {
  onPathwayDelete: () => void
  onPathwayStop: () => void
  onTrackAdd: () => void
  disableTrackAdd?: boolean
  disablePathwayStop?: boolean
  disablePathwayDelete?: boolean
}

export const ManagePathwayButton: FC<ManagePathwayButtonProps> = ({
  onPathwayDelete,
  onPathwayStop,
  onTrackAdd,
  disableTrackAdd = false,
  disablePathwayStop = false,
  disablePathwayDelete = false,
}) => {
  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <DropdownButton
      label={t('manage')}
      isOpen={dropdownOpen}
      onClick={setDropdownOpen}
    >
      <DropdownOption
        title={t('add_track')}
        onClick={onTrackAdd}
        data-heap='manage-care-flow-track-add'
        withSeparator
        startIcon={<Plus />}
        disabled={disableTrackAdd}
      />
      <DropdownOption
        title={t('stop_care_flow')}
        onClick={onPathwayStop}
        data-heap='manage-care-flow-track-add'
        type='danger'
        startIcon={<StepStop />}
        disabled={disablePathwayStop}
      />
      <DropdownOption
        title={t('delete_care_flow')}
        onClick={onPathwayDelete}
        data-heap='manage-care-flow-track-add'
        type='danger'
        startIcon={<Delete />}
        disabled={disablePathwayDelete}
      />
    </DropdownButton>
  )
}
