import { type TElement } from '@udecode/plate'
export const ELEMENT_HOSTED_PAGES_LINK = 'hosted-pages-link'
export type { Stakeholder } from '../../../generated/types-design'

interface HostedPagesLinkNodeData {
  stakeholderId: string
}

export type HostedPagesLinkNode = TElement<HostedPagesLinkNodeData> &
  HostedPagesLinkNodeData
