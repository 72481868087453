/* eslint-disable @typescript-eslint/no-empty-function */
import React, { type RefObject, useRef, useState } from 'react'
import MuiMenu from '@material-ui/core/Menu'
import { type PopoverOrigin } from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { IconWrapper } from '../IconWrapper'
import { IconButton, Button } from '../Button'
import { spacing, colors } from '../../utils/style-guide'
import { type IconProps, type Size as IconSize } from '../IconWrapper/types'
import { ChevronDown, More } from '../Icons'
import { Tooltip } from '../Tooltip'
import { isNil } from 'lodash'

interface MenuStyleProps {
  menuListMinWidth?: string
}
interface MenuProps extends MenuStyleProps {
  open?: boolean
  onClose?: (event: unknown) => void
  onOpen?: (event: unknown) => void
  ToggleButtonIcon?: React.FC<IconProps>
  toggleButtonContent?: JSX.Element | string
  variant?: 'button' | 'icon'
  dropdownRight?: boolean
  dropdownLeft?: boolean
  dropdownCenter?: boolean
  dataCy?: string
  style?: React.CSSProperties
  'data-product-tour-target'?: string
  tooltipText?: string
  iconSize?: IconSize
  children?: React.ReactNode | Array<React.ReactNode>
}

const useStyles = makeStyles({
  menuPaper: {
    minWidth: ({ menuListMinWidth }: MenuStyleProps) =>
      menuListMinWidth ?? '200px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  listItemButton: {
    '& button:hover': {
      backgroundColor: colors.brand10,
    },
  },
  menuListRoot: {
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
  },
  gutters: {
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
  },
  menu: {
    transform: `translateY(${spacing.xxxs})`,
    '& svg': {
      marginRight: spacing.xxs,
    },
  },
  activeMenu: {
    backgroundColor: colors.brand10,
  },
  activeMenuButton: {
    backgroundColor: colors.brand75,
  },
  buttonToggle: {
    '& svg': {
      transform: 'scale(2.6)',
    },
  },
  buttonRotated: {
    transform: 'rotate(180deg)',
  },
})

export const Menu: React.FC<MenuProps> = ({
  children,
  open,
  dropdownRight = null,
  dropdownLeft = null,
  dropdownCenter = null,
  onClose = () => {},
  onOpen = () => {},
  dataCy = null,
  ToggleButtonIcon = More,
  toggleButtonContent,
  variant = 'icon',
  menuListMinWidth,
  style = {},
  tooltipText = '',
  iconSize = 's',
  ...props
}) => {
  const { 'data-product-tour-target': dataProductTourTarget } = props
  const menuRef = useRef<HTMLElement | null>(null)
  const toggleRef = useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const classes = useStyles({ menuListMinWidth })
  // Values are inverted to prevent surprise
  // e.g. dropdownLeft should create a menu to the bottom-left
  // which is achieved with a horizontal *origin* value of 'right'
  // Returns null at last to indicate no prop provided
  const dropdownSide = (() => {
    if (dropdownLeft === true) {
      return 'right'
    }
    if (dropdownRight === true) {
      return 'left'
    }
    if (dropdownCenter === true) {
      return 'center'
    }
    return 'right'
  })()

  // Defaults horizontal and vertical to center if no prop provided
  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: dropdownSide ?? 'center',
  }

  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: dropdownSide ?? 'center',
  }

  const openMenu = (): void => {
    setAnchorEl(toggleRef.current)
    setTimeout(() => {
      if (!isNil(menuRef.current)) {
        const menu = menuRef.current.querySelector('[role="menu"]')
        if (!isNil(menu)) {
          ;(menu as HTMLElement).focus()
        }
      }
    }, 0)
  }

  const closeMenu = (): void => {
    setAnchorEl(null)
  }

  function toggle(event: unknown): void {
    open === true ? onClose(event) : onOpen(event)
  }

  React.useEffect(() => {
    open === true ? openMenu() : closeMenu()
  }, [open])

  // @ts-expect-error FIXME: types
  const kids = React.Children.map(children, (child: React.ReactElement) => {
    const originalOnClick = child?.props?.onClick

    return React.cloneElement(child, {
      onClick: (event: React.MouseEvent) => {
        if (!isNil(originalOnClick)) {
          originalOnClick(event)
        }
        onClose(event)
      },
    })
  })

  return (
    <>
      <Tooltip info={tooltipText} arrow placement='left'>
        {variant === 'icon' ? (
          <IconButton
            style={style}
            className={classnames({ [classes.activeMenu]: open })}
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={toggle}
            ref={toggleRef as RefObject<HTMLButtonElement>}
            data-cy={dataCy}
            data-product-tour-target={dataProductTourTarget}
            iconSize={iconSize}
          >
            {toggleButtonContent ?? <ToggleButtonIcon />}
          </IconButton>
        ) : (
          <Button
            className={classnames({ [classes.activeMenuButton]: open })}
            style={style}
            color='primary'
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={toggle}
            ref={toggleRef as RefObject<HTMLButtonElement>}
            data-cy={dataCy}
            data-product-tour-target={dataProductTourTarget}
            endIcon={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <IconWrapper
                size='xxs'
                color={colors.brand100}
                className={classnames(classes.buttonToggle, {
                  [classes.buttonRotated]: open,
                })}
              >
                <ChevronDown />
              </IconWrapper>
            }
          >
            {toggleButtonContent}
          </Button>
        )}
      </Tooltip>

      <MuiMenu
        className={classes.menu}
        classes={{
          paper: classes.menuPaper,
          list: classes.listItemButton,
        }}
        ref={menuRef}
        id='simple-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        variant='selectedMenu'
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        MenuListProps={{
          classes: {
            root: classes.menuListRoot,
          },
        }}
      >
        {kids}
      </MuiMenu>
    </>
  )
}
