// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { type FC } from 'react'
import classnames from 'classnames'
import { Icon, IconNames } from '../Icon'
import { CategoryIcon, CategoryTypes } from '../CategoryIcon'
import { Flex } from '../Flex'
import { useStyles } from './useStyles'
import { Colors } from '../../enums'
import { IconWrapper } from '../IconWrapper'
import { ChevronDown } from '../Icons'
import { isNil } from 'lodash'

interface SelectInputProps {
  disabled?: boolean
  dropdownOpen?: boolean
  error?: boolean
  focused?: boolean
  getTagProps: ({ index }: { index: number }) => Record<string, unknown>
  hideLabel?: boolean
  iconStart: IconNames | CategoryTypes
  inputLabelProps: unknown
  inputProps: { value: string }
  internalValue
  label?: string | JSX.Element
  multiple?: boolean
  placeholder?: string
  rootProps: unknown
  totalOptions: number
  warning?: boolean
  labelColor?: Colors
  description?: string
}

// If the options string is too long, we truncate it...
const TRUNCATE_LENGTH = 35
const truncateOptionsString = (text, inputProps): React.ReactNode => {
  const classes = useStyles()
  const multiTagClasses = classes.multiTags
  if (text && text.length > TRUNCATE_LENGTH) {
    return (
      <span className={multiTagClasses} {...inputProps}>
        {`${text.substring(0, TRUNCATE_LENGTH)}...`}
      </span>
    )
  }
  return (
    <span className={multiTagClasses} {...inputProps}>
      {text}
    </span>
  )
}

const getTagForMultipleOptions = (options, inputProps): React.ReactNode => {
  const commaSeparatedOptions = options
    .map(
      ({ content, label, value }) =>
        (typeof content === 'string' && content) || label || value,
    )
    .join(', ')

  return truncateOptionsString(commaSeparatedOptions, inputProps)
}

interface StartIconProps {
  iconStart: IconNames | CategoryTypes
  labelColor?: Colors
}

export const StartIcon: FC<StartIconProps> = ({
  iconStart,
  labelColor = Colors.stepDefault,
}) => {
  const classes = useStyles()

  let startIcon: JSX.Element | undefined

  // TODO: clean this up. Document weird pattern or remove it.
  switch (true) {
    case Boolean(CategoryTypes[iconStart]):
      startIcon = (
        <CategoryIcon
          labelColor={labelColor}
          type={iconStart as CategoryTypes}
        />
      )
      break
    case Boolean(IconNames[iconStart]):
      // TODO: Replace Icon with IconWrapper
      startIcon = <Icon name={iconStart as IconNames} />
      break
    default:
      startIcon = undefined
  }

  return <span className={classes.startIcon}>{startIcon}</span>
}

export const SelectInput: React.FC<SelectInputProps> = ({
  disabled,
  dropdownOpen,
  error,
  focused,
  hideLabel,
  iconStart,
  inputLabelProps,
  inputProps,
  internalValue,
  label,
  labelColor,
  multiple,
  placeholder,
  rootProps,
  warning,
  description,
}) => {
  const classes = useStyles()

  let startIcon

  switch (true) {
    case Boolean(CategoryTypes[iconStart]):
      startIcon = (
        <CategoryIcon
          labelColor={labelColor}
          type={iconStart as CategoryTypes}
        />
      )
      break
    case Boolean(IconNames[iconStart]):
      startIcon = <Icon name={iconStart as IconNames} />
      break
    default:
      startIcon = undefined
  }

  return (
    <div
      {...rootProps}
      style={{ position: 'relative' }}
      className={classnames({ [classes.disabledInputWrapper]: disabled })}
    >
      <label
        className={classnames(classes.label, { 'sr-only': hideLabel })}
        {...inputLabelProps}
      >
        {label}
      </label>
      {!isNil(description) && (
        <label
          className={classnames(classes.description, { 'sr-only': hideLabel })}
          {...inputLabelProps}
        >
          {description}
        </label>
      )}
      <Flex
        className={classnames(classes.inputWrapper, {
          [classes.inputWrapperFocused]: focused,
          [classes.inputWrapperError]: error,
          [classes.inputWrapperWarning]: warning,
        })}
        align='center'
      >
        {startIcon && <StartIcon iconStart={iconStart} />}
        <Flex
          tag='span'
          align='center'
          style={{ position: 'relative' }}
          className={classnames({
            [classes.inputWithMultiSelect]:
              multiple && (internalValue || []).length === 0,
          })}
        >
          {multiple && getTagForMultipleOptions(internalValue, inputProps)}
          {placeholder &&
            (multiple || inputProps.value === '') &&
            (internalValue || []).length === 0 && (
              <span className={classnames(classes.placeholder)} aria-hidden>
                {placeholder}
              </span>
            )}
          {!multiple && (
            <input
              className={classes.inputElement}
              {...inputProps}
              disabled={disabled}
            />
          )}
        </Flex>
        <span
          {...inputProps}
          className={classnames(classes.chevronIcon, {
            [classes.chevronIconRotated]: dropdownOpen,
            [classes.disabled]: disabled,
          })}
        >
          <IconWrapper>
            <ChevronDown />
          </IconWrapper>
        </span>
      </Flex>
    </div>
  )
}

SelectInput.displayName = 'SelectInput'
