import * as React from 'react'

function SvgLightning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 18 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 11.91L11.182 1 9 9h8L6.818 19.91l2.182-8H1z'
        stroke='currentColor'
        strokeWidth={1.2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgLightning
