import { isEmpty } from 'lodash'
import React, { type FC } from 'react'
import { colors } from '../../utils/style-guide'
import { ActivityLocationPin, ActivityPerson, Emr } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Lozenge, type LozengeColors } from '../Lozenge'
import { Text } from '../Typography'
import { useStyles } from './useStyles'

interface ActivityFooterProps {
  stakeholder?: string
  location?: string
  activityStatus: string
  activityStatusColor?: LozengeColors
  isExtension?: boolean
}

export const ActivityFooter: FC<ActivityFooterProps> = ({
  stakeholder,
  location,
  activityStatus = '',
  activityStatusColor,
  isExtension = false,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <div className={classes.footerIcons}>
        {stakeholder && (
          <div className={classes.footerItem}>
            <IconWrapper size='s' className={classes.footerIcon}>
              {isExtension ? <Emr /> : <ActivityPerson />}
            </IconWrapper>
            <Text variant='textRegular' color={colors.neutralMid300}>
              {stakeholder}
            </Text>
          </div>
        )}
        {location && (
          <div className={classes.footerItem}>
            <IconWrapper inline size='s' className={classes.footerIcon}>
              <ActivityLocationPin />
            </IconWrapper>
            <Text variant='textRegular' color={colors.neutralMid300}>
              {location}
            </Text>
          </div>
        )}
        {!isEmpty(activityStatus) && (
          <Lozenge
            content={activityStatus}
            color={activityStatusColor}
            compact
          />
        )}
      </div>
    </div>
  )
}

ActivityFooter.displayName = 'ActivityFooter'
