import { makeStyles } from '@material-ui/core/styles'
import React, { type FC } from 'react'
import { sanitizeHtml } from '../../../utils'
import { spacing } from '../../../utils/style-guide'
import { Viewer } from '../../RichText'
import { type Question } from '../types'

interface DescriptionQuestionViewerProps {
  question: Question
}

const useStyles = makeStyles({
  description: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: spacing.xs,
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: spacing.s,
    // be careful with these styles, they impact how the html below is rendered
    // modify it only to spot-fix style issues
    '& figure': {
      display: 'inline',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
})

const isSlateFormat = (value: string): boolean => {
  return value.startsWith('[{') && value.endsWith('}]')
}

export const DescriptionQuestionViewer: FC<DescriptionQuestionViewerProps> = ({
  question,
}) => {
  const cleanDescription = sanitizeHtml(unescape(question.title ?? ''))
  const classes = useStyles()
  const isSlate = isSlateFormat(question.title ?? '')

  if (isSlate) {
    return (
      <div className={classes.description}>
        <Viewer
          id={`description-question-${question.id}`}
          content={question.title ?? ''}
        />
      </div>
    )
  }

  return (
    <div
      className={classes.description}
      dangerouslySetInnerHTML={{ __html: cleanDescription }}
      id={`description-question-${question.id}`}
    />
  )
}

DescriptionQuestionViewer.displayName = 'DescriptionQuestionViewer'
