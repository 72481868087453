import { Spinner } from '@awell/ui-kit/components/Spinner'
import { CodeBlock } from '@awell/ui-kit/components/CodeBlocks'
import React from 'react'
import { type Activity } from './types'
import { useDecisionActivity } from '../../hooks/useDecisionActivity'

interface DecisionActivityDetailsProps {
  activity: Activity
}

export const DecisionActivityDetails = ({
  activity,
}: DecisionActivityDetailsProps): JSX.Element => {
  const { loading, outputs } = useDecisionActivity({ activity })

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='p-6'>
      <label className='block font-medium pb-1'>Decision outputs</label>
      <CodeBlock text={outputs ?? ''} />
    </div>
  )
}

DecisionActivityDetails.displayName = 'DecisionActivityDetails'
