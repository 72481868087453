import * as React from 'react'

function SvgWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.865 2.513a1 1 0 00-1.743 0L2.124 18.51A1 1 0 002.995 20h17.997a1 1 0 00.872-1.49L12.865 2.513zM11 8a1 1 0 112 0v5a1 1 0 11-2 0V8zm0 9a1 1 0 112 0 1 1 0 01-2 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgWarning
