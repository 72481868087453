import React, { FC } from 'react'
import { TabSkeleton } from '@awell/ui-kit/components/Skeleton/TabSkeleton'
import { useStyles } from '../../useStyles'
import { ActivePathwaysCardSkeleton } from '../../../../components/ActivePathwaysCard/skeletons/ActivePathwaysCardSkeleton'
import { PatientProfileCardSkeleton } from '../../../../components/PatientProfileCard/skeleton/PatientProfileCardSkeleton'

export const PatientPageSkeleton: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.profilePageContainer}>
      <div className={classes.profileCardsSection}>
        <PatientProfileCardSkeleton />
        <ActivePathwaysCardSkeleton />
      </div>
      <div className={classes.profileContentSection}>
        <div className={classes.tabsContainer}>
          <TabSkeleton />
          <TabSkeleton />
          <TabSkeleton />
        </div>
      </div>
    </div>
  )
}
