import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { ChevronDown } from '@awell/ui-kit/components/Icons'
import { truncate } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useStyles } from './useStyles'
import classnames from 'classnames'

interface FactContentCellProps {
  facts: Array<string>
}

const MAX_LENGTH = 80

export const FactContentCell: React.FC<FactContentCellProps> = ({ facts }) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setIsExpanded(false)
  }, [facts])

  /**
   * This function is not ideal. It just recognises the IDs and labels in a fact and highlights them.
   * Finding an ideal solution for this is a work in progress. So until then, we will keep using
   * regex and assume IDs to start with # and labels to be wrapped in inverted commas.
   *
   * FYI: If ever we changed the pattern in the Facts template, this function will need to be updated.
   */
  const hightlightFactContent = (text: string): React.ReactNode => {
    const splitText = text.split(/(#[\w-]+|"[^"]*"|\n)/)

    return splitText.map((part, index) => {
      // Convert newline character to visual line break
      if (part === '\n') {
        return <br key={`break-${index}`} />
      }

      // Facts have IDs with '#' in the start. This is a pattern that we added to differentiate identifiers from other text.
      // So we need to highlight the text that starts with '#' as an ID.
      if (/^#[\w-]+$/.test(part)) {
        return (
          <span key={`id-${index}`} className={classes.idStyle}>
            {part.slice(1)}
          </span>
        )
      }

      // Facts have titles/labels wrapped with inverted commas "", this is a pattern that we added to differentiate identifiers from other text.
      if (/^"[^"]*"$/.test(part)) {
        return (
          <>
            &quot;
            <span key={`quote-${index}`} className={classes.quoteStyle}>
              {part.slice(1, part.length - 1)}
            </span>
            &quot;
          </>
        )
      }

      return part
    })
  }

  const FactsList = (): JSX.Element => (
    <ul>
      {facts.map((item, index) => (
        <>{item !== '' && <li key={index}>{hightlightFactContent(item)}</li>}</>
      ))}
    </ul>
  )

  const rawText = facts.join(', ')

  const truncatedText = truncate(rawText, { length: MAX_LENGTH })

  const stylizedContent =
    facts.length > 1 ? <FactsList /> : hightlightFactContent(rawText)

  const displayContent = isExpanded
    ? stylizedContent
    : hightlightFactContent(truncatedText)

  return (
    <div className={classes.factCellcontainer} key={'fact'}>
      <div className={classes.textContainer}>{displayContent}</div>
      {rawText.length > MAX_LENGTH && (
        <span
          className={classnames(classes.chevron, {
            [classes.rotate180]: isExpanded,
          })}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          <IconWrapper>
            <ChevronDown />
          </IconWrapper>
        </span>
      )}
    </div>
  )
}

FactContentCell.displayName = 'FactContentCell'
