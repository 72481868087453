/* eslint-disable react/prop-types */
import {
  ClickAwayListener,
  Popper,
  type PopperPlacementType,
} from '@material-ui/core'
import classNames from 'classnames'
import { uniqueId } from 'lodash'
import React, {
  useState,
  type ElementType,
  type FC,
  type MouseEvent,
} from 'react'
import { findChildByType } from '../../utils/findChildByType'
import { useStyles } from './useStyles'

const POPOVER_CLOSE_DELAY = 250
interface Component {
  Content?: FC<Record<string, unknown>>
  Target?: FC<Record<string, unknown>>
}

interface PopoverProps {
  id?: string
  openOnlyOnHover?: boolean
  className?: string
  placement?: PopperPlacementType
  closeOnPopoverMouseLeave?: boolean
  setPopoverOpen?: (open: boolean) => void
  isInline?: boolean
}

const Content: ElementType = ({ children }) => children
const Target: ElementType = ({ children }) => children

const popperOptions = {
  arrow: {
    enabled: true,
  },
}

/*
 * Displays a popover with Content on a Target.

 * id: Unique ID used for differentiating multiple popovers.
 * -- Default: ID will be autogenerated.
 
 * openOnlyOnHover: If set to true, the popover will be displayed on hover.
 * -- Default: The popover will be displayed on clicking the Target and dismissed when clicked again.
 */

export const Popover: FC<PopoverProps> & Component = ({
  children,
  id,
  openOnlyOnHover = false,
  placement = 'top-end',
  className,
  setPopoverOpen,
  isInline = false,
  closeOnPopoverMouseLeave = false,
}) => {
  const classes = useStyles()
  const content = findChildByType(children, Content)
  const target = findChildByType(children, Target)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)

  const handleMouseEnter = (event: MouseEvent): void => {
    if (openOnlyOnHover) {
      if (setPopoverOpen) setPopoverOpen(true)
      setAnchorEl(event.currentTarget)
      event.stopPropagation()
    }
  }

  const handleMouseLeave = (event: MouseEvent): void => {
    if (openOnlyOnHover) {
      if (setPopoverOpen) setPopoverOpen(false)
      setAnchorEl(null)
      event.stopPropagation()
    }
  }

  const handleClick = (event: MouseEvent): void => {
    if (!openOnlyOnHover) {
      if (setPopoverOpen) setPopoverOpen(!anchorEl)
      setAnchorEl(anchorEl ? null : event.currentTarget)
      event.stopPropagation()
    }
  }

  const handleClickInsidePopover = (event: MouseEvent): void => {
    if (!openOnlyOnHover) {
      event.stopPropagation()
    }
  }

  const handleClickAway = (event: MouseEvent<Document>): void => {
    if (!openOnlyOnHover) {
      if (setPopoverOpen) setPopoverOpen(false)
      setAnchorEl(null)
      event.stopPropagation()
    }
  }

  const handlePopoverMouseLeave = (event: MouseEvent): void => {
    if (!openOnlyOnHover && closeOnPopoverMouseLeave) {
      // adding timeout because it looks a bit weird when it closes suddenly when we move the mouse
      setTimeout(() => {
        if (setPopoverOpen) setPopoverOpen(false)
        setAnchorEl(null)
        event.stopPropagation()
      }, POPOVER_CLOSE_DELAY)
    }
  }

  const detailsOpen = Boolean(anchorEl)
  const popper_id = `popper-${id ?? uniqueId()}`

  return (
    <>
      <ClickAwayListener
        onClickAway={handleClickAway}
        // ensure that the clickaway event is not triggered when the popover is open
        mouseEvent={anchorEl ? 'onClick' : false}
        disableReactTree
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          aria-describedby={popper_id}
          role={openOnlyOnHover ? 'tooltip' : 'button'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          style={isInline ? { display: 'inline-block' } : {}}
        >
          {target}
        </div>
      </ClickAwayListener>
      <Popper
        id={popper_id}
        open={detailsOpen}
        anchorEl={anchorEl}
        popperOptions={popperOptions}
        className={classNames(className, classes.popper)}
        placement={placement}
        onMouseLeave={handlePopoverMouseLeave}
        onClick={handleClickInsidePopover}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
          offset: {
            enabled: true,
            offset: '0, 8',
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        {content}
      </Popper>
    </>
  )
}

Popover.displayName = 'Popover'
Popover.Content = Content
Popover.Target = Target
