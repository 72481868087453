import {
  createPlateEditor,
  serializeHtml,
  type PlatePlugin,
} from '@udecode/plate'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { Node } from 'slate'
import { useDebouncedCallback } from 'use-debounce'
import {
  createNonExistentDynamicVariableNode,
  type DynamicVariableNode,
  type DynamicVariable,
} from './DynamicVariablePlugin'

interface UseDebounceChangesHook {
  onChangeNodes: (nodes: Array<Node>) => void
}

interface UseDebounceChangesProps {
  editorId: string
  debounced: boolean
  onChange: (raw: string, html: string, plaintext?: string) => void
  plugins: Array<PlatePlugin>
  variables: Array<DynamicVariable>
}

const getNodesWithValidVariables = (
  nodes: Array<Node>,
  variables: Array<DynamicVariable> = [],
): Array<Node | DynamicVariableNode> => {
  const getFormattedVariableNodes = (child: Node | DynamicVariableNode) =>
    !isNil(
      variables?.find(v => v.id === (child as DynamicVariableNode)?.variableId),
    )
      ? child
      : createNonExistentDynamicVariableNode()

  const getFormattedChildrenContent = (child: Node | DynamicVariableNode) =>
    (child as DynamicVariableNode).type !== 'dynamic-variable'
      ? child
      : getFormattedVariableNodes(child)

  const getChildren = (children: Array<Node | DynamicVariableNode>) =>
    children.map(getFormattedChildrenContent)

  // @ts-expect-error - FIXME: fix these types
  return nodes.map(({ children, ...e }) => ({
    ...e,
    children: getChildren(children),
  }))
}

/**
 * Debounces the changes in a slate document and generates the three formats
 * related to slate documents:
 * - raw        = stringified slate nodes
 * - html       = slate document serialized to HTML
 * - plaintext  = text only version, with line break between each top level slate node
 */
export const useDebounceChanges = ({
  debounced,
  onChange,
  plugins,
  variables,
}: UseDebounceChangesProps): UseDebounceChangesHook => {
  // WTF - for some reason plate ref is created with delay -  that results with serialise HTML errors due to lack of plugins
  const editor = createPlateEditor({ plugins })
  const debouncedChange = useDebouncedCallback(
    (nodes: Array<Node>) => {
      const parsed = getNodesWithValidVariables(nodes, variables)

      const raw = JSON.stringify(parsed)
      const plaintext = parsed.map(node => Node.string(node)).join('\n')
      const html = unescape(
        serializeHtml(editor, {
          nodes: JSON.parse(raw.replaceAll(/(\\?\\r)?\\?\\n/g, '<br/>')),
          stripWhitespace: false,
        }),
      )
      onChange(raw, html, plaintext)
    },
    debounced ? 100 : 0,
  )

  // Flush changes when the component unmounts to make sure that the latest changes
  // are properly saved.
  useEffect(() => {
    return () => {
      debouncedChange.flush()
    }
  }, [])

  return {
    onChangeNodes: debouncedChange,
  }
}
