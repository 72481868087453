import { debounce, isEmpty, noop, isNil } from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PAGE_SIZE } from '../utils'
import {
  convertToAwellInput,
  convertToFormHook,
  getDefaultValue,
  updateVisibility,
} from '../helpers'

import {
  type FormContextProps,
  type AnswerValue,
  type Question,
  type FormContextStateInterface,
  type FormContextUpdaterInterface,
} from './types'
import { type QuestionWithVisibility, UserQuestionType } from '../types'

const initialContext = {
  onFormChange: noop,
  resetQuestion: noop,
  resetForm: noop,
  submitForm: noop,
  formMethods: null,
}

const FormContextState = createContext<FormContextStateInterface>({
  questions: [],
  errors: [],
  readyForSubmit: true,
})

const FormContextUpdater = createContext<FormContextUpdaterInterface>(
  // @ts-expect-error initial context needs to have proper object for form methods
  initialContext,
)

// getter hook
const useFormContextState = (): FormContextStateInterface =>
  useContext(FormContextState)

// setter hook
const useFormContextUpdater = (): FormContextUpdaterInterface =>
  useContext(FormContextUpdater)

const FormContextProvider = ({
  children,
  questions,
  answers,
  evaluateDisplayConditions,
  onSubmit,
  trademark,
}: FormContextProps): JSX.Element => {
  // the value that will be given to the context
  const formMethods = useForm({
    shouldUnregister: false,
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })
  const [visibleQuestions, setVisibleQuestions] = useState<
    Array<QuestionWithVisibility>
  >([])
  const [readyForSubmit, setReadyForSubmit] = useState(true)
  const [errors, setErrors] = useState([])
  const { t } = useTranslation()

  const updateQuestionVisibility = useCallback(async () => {
    // TODO check if the condition is ever truthy
    /**
     * When form is used in read only mode, answers prop is populated
     * but the form state is empty.
     * There is probably a better solution than this but for now it will
     * have to do.
     * Logic is to get the values from the form state and fallback to answers
     * prop if the form state is empty.
     */
    const formValuesInput = convertToAwellInput(formMethods.getValues())
    const answersInput = (answers ?? []).map(({ question_id, value }) => ({
      question_id,
      value,
    }))
    const evaluateInput = isEmpty(formValuesInput)
      ? answersInput
      : formValuesInput
    const evaluationResults = await evaluateDisplayConditions(evaluateInput)
    const newVisibleQuestions = updateVisibility(
      questions,
      evaluationResults,
    ).filter(e => e.visible)
    setVisibleQuestions(newVisibleQuestions)
  }, [questions])

  const debouncedUpdateQuestionsVisibility = useMemo(
    () =>
      debounce(async () => {
        await updateQuestionVisibility()
        setReadyForSubmit(true)
      }, 1000),
    [questions],
  )

  const handleFormChange = (): void => {
    setReadyForSubmit(false)
    void debouncedUpdateQuestionsVisibility()
  }

  const resetQuestion = (question: Question): void => {
    const defaultValue = getDefaultValue(question)
    formMethods.setValue(question.id, defaultValue, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const resetForm = (): void => {
    formMethods.reset()
  }

  const getErrorsForPages = (): void => {
    visibleQuestions.forEach((q, index) => {
      const a = formMethods.getValues(q.id)
      // Since description questions have no input, no need to check for errors
      if (q.userQuestionType === UserQuestionType.Description) {
        return
      }

      if (q.userQuestionType === UserQuestionType.Email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        // Test the email string against the regex
        if (!emailRegex.test(a as string)) {
          formMethods.setError(q.id, {
            type: 'invalid',
            message: 'invalid',
          })
        }
      }

      if (q?.questionConfig?.mandatory === true && isNil(a)) {
        formMethods.setError(q.id, {
          type: 'required',
          message: 'required',
        })
        const page = Math.ceil((index + 1) / PAGE_SIZE)
        setErrors(err => [
          ...err,
          t('form_mandatory_error_with_pagination_info', {
            page,
            questionName: q.title,
          }),
        ])
      }
    })
  }

  const submitForm = (): void => {
    if (onSubmit) {
      setErrors([])
      getErrorsForPages()
      void formMethods.handleSubmit(
        async (formResponse: Record<string, AnswerValue>) => {
          await onSubmit(convertToAwellInput(formResponse))
        },
      )()
    }
  }

  useEffect(() => {
    resetForm()
  }, [])

  /**
   * If you're wondering why this hook exists and you want to remove / refactor it, DON'T :)
   * This is required for the form context to work properly when loading a form response.
   * Lesson learned today: a form and a form response ARE NOT THE SAME THING. The fact that
   * they look similar isn't a good enough reason to use the same component / context to handle
   * these two use cases.
   * Combining them both requires us to find just the right set of hooks in one place, and more
   * often than not what works for one use case breaks the other.
   */
  useEffect(() => {
    if (answers) {
      formMethods.reset(convertToFormHook(answers))
    }
  }, [answers])

  useEffect(() => {
    void updateQuestionVisibility()

    return () => {
      debouncedUpdateQuestionsVisibility.cancel()
    }
  }, [JSON.stringify(questions), JSON.stringify(answers)])

  return (
    <FormContextState.Provider
      value={{ questions: visibleQuestions, readyForSubmit, errors, trademark }}
    >
      <FormContextUpdater.Provider
        value={{
          formMethods,
          resetQuestion,
          onFormChange: handleFormChange,
          resetForm,
          submitForm,
        }}
      >
        {children}
      </FormContextUpdater.Provider>
    </FormContextState.Provider>
  )
}

export { FormContextProvider, useFormContextUpdater, useFormContextState }
