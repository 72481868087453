import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { spacing } from '../../../utils/style-guide'

type Ref = HTMLLIElement

interface ListItemProps extends React.HTMLProps<HTMLLIElement> {
  seperated?: boolean
}

const useStyles = makeStyles({
  seperated: {
    '&:not(:first-of-type)': {
      marginTop: spacing.xs,
    },
    '&:not(:last-of-type)': {
      marginBottom: spacing.xs,
    },
  },
})

export const ListItem = React.forwardRef<Ref, ListItemProps>(function ListItem(
  { children, seperated, ...rest },
  ref,
) {
  const classes = useStyles()

  return (
    <li
      className={classnames({
        [classes.seperated]: seperated,
      })}
      ref={ref}
      {...rest}
    >
      {children}
    </li>
  )
})
