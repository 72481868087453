import type { FetchResult } from '@apollo/client'
import { type RetryActivityMutation, useRetryActivityMutation } from './types'

interface UseRetryActivityHook {
  retryActivity: () => Promise<FetchResult<RetryActivityMutation>>
}

export const useRetryActivity = (activityId: string): UseRetryActivityHook => {
  const [retryActivity] = useRetryActivityMutation({
    variables: {
      input: {
        activity_id: activityId,
      },
    },
  })
  return { retryActivity }
}
