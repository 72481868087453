/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from '@awell/ui-kit/components/Button'
import { ChevronLeftDouble } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { PathwayViewsHeader } from '@awell/ui-kit/components/PathwayViewsHeader'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeletePathwayModal } from '../../components/DeletePathwayModal'
import { usePathwayContextProvider } from '../../components/PathwayContextProvider'
import { StopPathwayModal } from '../../components/StopPathwayModal'
import { useAdHocTracks } from '../../hooks/useAdHocTracks'
import { usePathway } from '../../hooks/usePathway'
import { usePathwayContext } from '../../hooks/usePathwayContext'
import { ManagePathwayButton } from './ManagePathwayButton'
import { StartTrackModal } from './StartTrackModal'
import { useGetHostedPagesLink } from '../../hooks/useGetHostedPagesLink'
import { HostedPagesQRButton } from './HostedPagesQRButton'
import { useStytchIsAuthorized } from '@stytch/react/b2b'
import { useRetoolConfig } from '../../hooks/useRetoolConfig'

interface PathwayHeaderProps {
  loading: boolean
}

export const PathwayHeader = ({ loading }: PathwayHeaderProps): JSX.Element => {
  const { t } = useTranslation()
  const retoolConfig = useRetoolConfig()
  const { isAuthorized: canAccessPatientDashboard } = useStytchIsAuthorized(
    'worklist',
    'patient_dashboard',
  )

  const { pathwayId } = usePathwayContext()
  const { pathway } = usePathway(pathwayId)

  // check dashboard also exists in the retool metadata
  const app = retoolConfig.worklists.find(
    app => app.careflowId === pathway?.pathway_definition_id,
  )
  const dashboardExists = !isNil(app)

  const [startTrackModalOpen, setStartTrackModalOpen] =
    React.useState<boolean>(false)
  const {
    pendingActivities,
    sidePanelOpen,
    onSelectActivity,
    onSelectElement,
    setSidePanelOpen,
    activities,
    pathwayIsActive,
    patientId,
  } = usePathwayContextProvider()

  const { url: hostedPagesUrlForPatient } = useGetHostedPagesLink({
    pathway_id: pathwayId!,
    stakeholder_id: patientId,
  })

  const { handleStartTrack, adHocTracks, adHocTracksLoading } = useAdHocTracks({
    pathway_id: pathwayId!,
  })
  const [openModalToStopPathway, setOpenModalToStopPathway] =
    useState<boolean>(false)
  const [openModalToDeletePathway, setOpenModalToDeletePathway] =
    useState<boolean>(false)

  const onShowPendingActivities = (): void => {
    onSelectActivity(undefined)
    onSelectElement(undefined)
    setSidePanelOpen(true)
  }

  const getPendingActivitiesTitle = (): string => {
    if (loading) {
      return t('loading')
    }
    if (pendingActivities.length === 0) {
      return t('no_pending_activities')
    }
    return t('pending_activity', { count: pendingActivities.length })
  }

  const handleCloseStartTrackModal = (): void => {
    setStartTrackModalOpen(false)
  }

  const handleModalClose = (): void => {
    setOpenModalToDeletePathway(false)
    setOpenModalToStopPathway(false)
  }

  const pendingActivitiesButtonDisabled =
    sidePanelOpen || pendingActivities.length === 0 || loading

  const addTrackButtonDisabled =
    adHocTracksLoading ||
    adHocTracks?.length === 0 ||
    !pathwayIsActive ||
    activities.length === 0

  // only an active pathway can be stopped
  const stopPathwayButtonDisabled = !pathwayIsActive

  // pathway can be deleted at any time
  const deletePathwayButtonDisabled = false

  const openDeleteConfirmationModal = (): void => {
    setOpenModalToDeletePathway(true)
  }

  const openStopConfirmationModal = (): void => {
    setOpenModalToStopPathway(true)
  }

  return (
    <PathwayViewsHeader
      showDashboard={canAccessPatientDashboard && dashboardExists}
    >
      <PathwayViewsHeader.Action>
        {pathway && (
          <HostedPagesQRButton
            hostedPagesUrlForPatient={hostedPagesUrlForPatient}
            pathwayStatus={pathway?.status}
          />
        )}
        <ManagePathwayButton
          onTrackAdd={() => {
            setStartTrackModalOpen(true)
          }}
          onPathwayStop={() => {
            openStopConfirmationModal()
          }}
          onPathwayDelete={() => {
            openDeleteConfirmationModal()
          }}
          disableTrackAdd={addTrackButtonDisabled}
          disablePathwayStop={stopPathwayButtonDisabled}
          disablePathwayDelete={deletePathwayButtonDisabled}
        />
        {startTrackModalOpen && (
          <StartTrackModal
            onClose={handleCloseStartTrackModal}
            tracks={adHocTracks}
            onStartTrack={track_definition_id => {
              void handleStartTrack(track_definition_id)
            }}
          />
        )}
        <Button
          onClick={onShowPendingActivities}
          disabled={pendingActivitiesButtonDisabled}
          startIcon={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <IconWrapper size='xxs' color={colors.neutralLight0}>
              <ChevronLeftDouble />
            </IconWrapper>
          }
        >
          {getPendingActivitiesTitle()}
        </Button>
        <DeletePathwayModal
          open={openModalToDeletePathway}
          onClose={handleModalClose}
          title={t('delete_patient_pathway_heading', {
            patientName: pathway?.patient.profile?.name,
            pathwayName: pathway?.title ?? '',
          })}
          pathway_id={pathwayId}
          redirectToUri={
            !isNil(pathway?.patient.id)
              ? `/patients/${pathway.patient.id}/pathways`
              : `/patients`
          }
          patient_id={pathway?.patient.id}
        />

        <StopPathwayModal
          open={openModalToStopPathway}
          onClose={handleModalClose}
          title={t('stop_patient_pathway_heading', {
            patientName: pathway?.patient.profile?.name,
            pathwayName: pathway?.title ?? '',
          })}
          pathway_id={pathwayId}
        />
      </PathwayViewsHeader.Action>
    </PathwayViewsHeader>
  )
}

PathwayHeader.displayName = 'PathwayHeader'
