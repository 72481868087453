import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import {
  useDeletePathwayMutation,
  type DeletePathwayInput,
  type GetPatientPathwaysQueryVariables,
  type GetPatientPathwaysQuery,
  type DeletePathwayMutation,
  GetPatientPathwaysDocument,
} from './types'
import { type PatientPathwayResult } from '../usePatientPathways/types'
import { createApolloCacheUpdate, updateQuery } from '@awell/libs-web/graphql'
import { isString } from 'lodash'

interface UseDeletePathwayHook {
  deletePathway: (input: DeletePathwayInput) => Promise<boolean>
  patientId?: string
}

export const useDeletePathway = (patientId?: string): UseDeletePathwayHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const [deletePathwayMutation] = useDeletePathwayMutation()

  const updateCache = (pathwayId: string) =>
    createApolloCacheUpdate<
      DeletePathwayMutation,
      GetPatientPathwaysQuery,
      GetPatientPathwaysQueryVariables
    >({
      query: GetPatientPathwaysDocument,
      variables: {
        patient_id: patientId!,
      },
      updateFunction: ({ query }) => {
        const patientPathways = query.patientPathways.patientPathways.filter(
          pathway => pathway.id !== pathwayId,
        )
        return updateQuery<GetPatientPathwaysQuery, PatientPathwayResult>(
          query,
          ['patientPathways', 'patientPathways'],
          patientPathways,
        )
      },
    })

  const deletePathway = async (input: DeletePathwayInput) => {
    try {
      // Define the update function only if patientId is present
      const updateFunction = isString(patientId)
        ? updateCache(input.pathway_id)
        : undefined

      await deletePathwayMutation({
        variables: {
          input,
        },
        update: updateFunction,
      })
      return true
    } catch (error) {
      notifyError({
        message: t('delete_pathway_error'),
        error,
      })
    }
    return false
  }

  return {
    deletePathway,
  }
}
