interface PathwayContext {
  pathwayId?: string
}

export const usePathwayContext = (): PathwayContext => {
  const { pathname } = window.location
  const matchPath = pathname.match(new RegExp('/pathway/([^/]*)'))

  if (matchPath) {
    const [, pathwayId] = matchPath
    return {
      pathwayId,
    }
  }
  return {}
}
