import * as React from 'react'

function SvgDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.778 4.208A.722.722 0 0110.286 4h3.428c.192 0 .375.076.508.208s.207.31.207.492V5H9.57v-.3c0-.183.074-.36.207-.492zM7.57 5v-.3c0-.719.288-1.406.798-1.912S9.57 2 10.286 2h3.428c.717 0 1.407.282 1.917.788s.798 1.193.798 1.912V5H20a1 1 0 110 2h-1v11.3c0 .719-.288 1.406-.798 1.912s-1.2.788-1.916.788H7.714a2.721 2.721 0 01-1.916-.788A2.693 2.693 0 015 18.3V7H4a1 1 0 010-2h3.571zM7 18.3V7.4h10v10.9c0 .183-.073.36-.206.492a.722.722 0 01-.508.208H7.714a.722.722 0 01-.508-.208A.693.693 0 017 18.3zm3-8.3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm5 1a1 1 0 10-2 0v5a1 1 0 102 0v-5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgDelete
