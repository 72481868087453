import { colors, spacing, type } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  activePathwaysContainer: {
    padding: spacing.s,
    border: `1px solid ${colors.neutralLight30}`,
    borderRadius: spacing.xxxs,
    marginBottom: spacing.xs,
  },
  cta: {
    width: '100%',
  },
  activePathwaysList: {
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,
  },
  pathwayRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing.xs,
    '&:not(:last-child)': {
      paddingBottom: spacing.xs,
      borderBottom: `1px solid ${colors.neutralLight40}`,
    },
  },
  pathwayNameAndActivitiesNumberWrapper: {
    marginRight: spacing.xxxs,
  },
  activityCount: {
    color: colors.neutralLight100,
  },
  viewPathwayButton: {
    padding: 0,
  },
})
