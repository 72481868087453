import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core'
import AccordionMui from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'

import { colors, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  title: {
    marginBottom: spacing.xs,
  },
})

export const MuiAccordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    // height: '48px !important',
    // minHeight: '48px !important',
  },
})(AccordionMui)

export const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: colors.neutralLight20,
    borderRadius: '4px',
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0 !important',
    },
  },
  expanded: {
    height: '48px !important',
    minHeight: '48px !important',
  },
})(AccordionSummary)
