// @ts-nocheck TODO: remove this comment and fix errors
import { useNotifications } from '../../../hooks/useNotifications'
import type { AnswerInput, QuestionRuleResult } from './types'
import { useEvaluateFormRulesMutation } from './types'

export const useEvaluateFormRules = (
  form_id: string,
): [(answers: Array<AnswerInput>) => Promise<Array<QuestionRuleResult>>] => {
  const { notifyError } = useNotifications()
  const [evaluateFormRules] = useEvaluateFormRulesMutation()

  return [
    async (answers: Array<AnswerInput>): Promise<Array<QuestionRuleResult>> => {
      try {
        const { data } = await evaluateFormRules({
          variables: {
            input: {
              form_id,
              answers,
            },
          },
        })
        return data.evaluateFormRules.results
      } catch (error) {
        notifyError({
          message: 'Something went wrong with evaluating display conditions!',
          error,
        })
        return []
      }
    },
  ]
}
