import * as React from 'react'

function SvgStickyNote(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm11.5 15a.5.5 0 00-.5.5v6.293a.5.5 0 00.854.354l6.292-6.293a.5.5 0 00-.353-.854H15.5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgStickyNote
