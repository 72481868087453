import { makeStyles } from '@material-ui/core'
import { spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  toolbar: {
    marginTop: spacing.m,
    marginBottom: spacing.m,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    marginBottom: spacing.m,
  },
})
