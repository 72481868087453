// @ts-nocheck TODO: remove this comment and fix errors
import { max } from 'lodash'
import React, {
  useState,
  useEffect,
  MouseEventHandler,
  TouchEventHandler,
  SetStateAction,
} from 'react'

export interface ResizableProps {
  layoutWidthMultiplier: number
  onResize: (widthMultiplier: number) => void
  minWidthMultiplier?: number
  maxWidthMultiplier?: number
  panelSide: 'left' | 'right'
  minWidthInPx?: number
}

export interface Resizable {
  onMouseDown: MouseEventHandler
  onMouseUp: MouseEventHandler
  onTouchStart: TouchEventHandler
  onTouchEnd: TouchEventHandler
  panelWidth: number
  dragging: boolean
  setPanelWidth: React.Dispatch<SetStateAction<number>>
}

export const useResizable = ({
  layoutWidthMultiplier,
  onResize,
  minWidthMultiplier = 0.35,
  maxWidthMultiplier = 0.55,
  panelSide = 'right',
  minWidthInPx = 0,
}: ResizableProps): Resizable => {
  const [width, setWidth] = useState(window.innerWidth)
  const [separatorXPosition, setSeparatorXPosition] = useState<number>(null)
  const [dragging, setDragging] = useState(false)
  const [panelWidth, setPanelWidth] = useState<number>(
    max([window.innerWidth * layoutWidthMultiplier, minWidthInPx]),
  )

  const multiplierMinWidthSmallerThanMinWidthProvided =
    window.innerWidth * minWidthMultiplier < minWidthInPx
  const minWidth = max([window.innerWidth * minWidthMultiplier, minWidthInPx])
  const maxWidth = window.innerWidth * maxWidthMultiplier

  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    let timeout
    if (!dragging && onResize) {
      timeout = setTimeout(() => onResize(panelWidth / width), 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [dragging])

  const onMouseDown = (e: React.MouseEvent) => {
    setSeparatorXPosition(e.clientX)
    setDragging(true)
  }

  const onTouchStart = (e: React.TouchEvent) => {
    setSeparatorXPosition(e.touches[0].clientX)
    setDragging(true)
  }

  const onMove = (clientX: number) => {
    if (multiplierMinWidthSmallerThanMinWidthProvided) {
      // We default to the explicit min width if the multiplier width would be smaller than it
      return
    }
    if (dragging && panelWidth && separatorXPosition) {
      const newWidth =
        panelSide === 'right'
          ? panelWidth - clientX + separatorXPosition
          : panelWidth + clientX - separatorXPosition
      setSeparatorXPosition(clientX)
      if (newWidth < minWidth) {
        setPanelWidth(minWidth)
        return
      }

      if (newWidth > maxWidth) {
        setPanelWidth(maxWidth)
        return
      }

      setPanelWidth(newWidth)
    }
  }

  const onMouseMove = (e: MouseEvent) => {
    if (dragging) e.preventDefault()
    onMove(e.clientX)
  }

  const onTouchMove = (e: TouchEvent) => {
    onMove(e.touches[0].clientX)
  }

  const onMouseUp = () => {
    setDragging(false)
  }

  const onTouchEnd = () => {
    setDragging(false)
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchmove', onTouchMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  })

  return {
    onMouseDown,
    onTouchStart,
    onTouchEnd,
    onMouseUp,
    panelWidth,
    setPanelWidth,
    dragging,
  }
}
