import React, { type FC, useEffect, useState } from 'react'
import {
  AlertDialog,
  Icon,
  Input,
  Card,
  Button,
} from '@awell-health/design-system'
import type { Organization } from '@stytch/vanilla-js'
import { isEmpty, isNil } from 'lodash'

interface SelectOrganizationModalProps {
  switchOrganization: (organizationId: string) => Promise<void>
  organizations: Array<Organization>
  onClose: () => void
}

export const SelectOrganizationModal: FC<SelectOrganizationModalProps> = ({
  switchOrganization,
  organizations,
  onClose,
}) => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null)
  const [filterString, setFilterString] = useState('')
  const [filteredOrganizations, setFilteredOrganizations] =
    useState(organizations)

  useEffect(() => {
    if (isEmpty(filterString)) {
      setFilteredOrganizations(organizations)
    } else {
      setFilteredOrganizations(
        organizations.filter(org => {
          return (
            org.organization_name
              .toLowerCase()
              .includes(filterString.toLowerCase()) ||
            org.organization_slug
              .toLowerCase()
              .includes(filterString.toLowerCase()) ||
            org.organization_id
              .toLowerCase()
              .includes(filterString.toLowerCase())
          )
        }),
      )
    }
  }, [filterString])

  return (
    <AlertDialog onClose={onClose} title='Select Tenant'>
      <>
        <Input
          label='Organization'
          autoFocus
          onChange={e => {
            setFilterString(e.target.value)
          }}
        />
        {!isNil(selectedOrganization) && (
          <div>
            <span>Selected {selectedOrganization.organization_name}</span>
            <div
              onClick={() => {
                setSelectedOrganization(null)
              }}
            >
              <Icon icon='RiDeleteBinLine' />
            </div>
          </div>
        )}
        <ul style={{ margin: '0.5rem' }}>
          {filteredOrganizations.map(org => (
            <Card className='w-full' key={org.organization_id}>
              <h3>{org.organization_name}</h3>
              <div className='flex justify-end'>
                <Button
                  onClick={() => {
                    void switchOrganization(org.organization_id)
                    onClose()
                  }}
                >
                  Switch
                </Button>
              </div>
            </Card>
          ))}
        </ul>
      </>
    </AlertDialog>
  )
}
