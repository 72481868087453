import * as React from 'react'

function SvgPathway(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' {...props}>
      <path fillRule='evenodd' d='M5 1v10H1V1h4zm6 0v6H7V1h4z' />
    </svg>
  )
}

export default SvgPathway
