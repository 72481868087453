/* eslint-disable react/prop-types */
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'
import { Tab, Tabs } from '../Tabs'
import { type Activity, ActivityObjectType } from './types'

interface ActivityTabsProps {
  activeTab: number
  activityEventCount: number
  activity: Activity
  setActiveTab: (newTab: number) => void
  hasFourTabs: boolean
}

export const ActivityDetailsTabs: React.FC<ActivityTabsProps> = memo(
  ({ activeTab, activity, setActiveTab, activityEventCount, hasFourTabs }) => {
    const { t } = useTranslation()

    switch (activity.object.type) {
      case ActivityObjectType.Calculation: {
        if (!hasFourTabs) {
          return (
            <Tabs
              value={activeTab}
              onChange={(_, value) => {
                setActiveTab(value)
              }}
            >
              <Tab index={0} label={t('tab_title_results')} />
              <Tab index={1} label={t('tab_title_details')} />
              <Tab
                index={2}
                label={t('tab_title_updates', { count: activityEventCount })}
              />
            </Tabs>
          )
        }
        return (
          <Tabs
            value={activeTab}
            onChange={(_, value) => {
              setActiveTab(value)
            }}
          >
            <Tab index={0} label={t('tab_title_results')} />
            <Tab index={1} label={t('tab_title_input')} />
            <Tab index={2} label={t('tab_title_details')} />
            <Tab
              index={3}
              label={t('tab_title_updates', { count: activityEventCount })}
            />
          </Tabs>
        )
      }
      case ActivityObjectType.EmrReport: {
        return (
          <Tabs
            value={activeTab}
            onChange={(_, value) => {
              setActiveTab(value)
            }}
          >
            <Tab index={0} label={startCase(t('message'))} />
            <Tab index={1} label={t('tab_title_meta_data')} />
            <Tab index={2} label={t('tab_title_details')} />
            <Tab
              index={3}
              label={t('tab_title_updates', { count: activityEventCount })}
            />
          </Tabs>
        )
      }
      case ActivityObjectType.ClinicalNote: {
        return (
          <Tabs
            value={activeTab}
            onChange={(_, value) => {
              setActiveTab(value)
            }}
          >
            <Tab index={0} label={startCase(t('narratives'))} />
            <Tab index={1} label={t('tab_title_context')} />
            <Tab
              index={2}
              label={t('tab_title_updates', { count: activityEventCount })}
            />
          </Tabs>
        )
      }
      case ActivityObjectType.PluginAction: {
        return (
          <Tabs
            value={activeTab}
            onChange={(_, value) => {
              setActiveTab(value)
            }}
          >
            <Tab index={0} label={startCase(t('fields'))} />
            <Tab index={1} label={startCase(t('data_point_plural'))} />
            <Tab index={2} label={t('tab_title_context')} />
            <Tab
              index={3}
              label={t('tab_title_updates', { count: activityEventCount })}
            />
          </Tabs>
        )
      }
      default:
        return (
          <Tabs
            value={activeTab}
            onChange={(_, value) => {
              setActiveTab(value)
            }}
          >
            <Tab
              index={0}
              label={startCase(t(`${activity.object.type.toLowerCase()}`))}
            />
            <Tab index={1} label={t('tab_title_details')} />
            <Tab
              index={2}
              label={t('tab_title_updates', { count: activityEventCount })}
            />
          </Tabs>
        )
    }
  },
  (prev, next) =>
    prev.activity.id === next.activity.id &&
    prev.activeTab === next.activeTab &&
    prev.activityEventCount === next.activityEventCount,
)

ActivityDetailsTabs.displayName = 'ActivityDetailsTabs'
