import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'

interface Props {
  value: number
  index: number
  fill?: boolean
  fullHeight?: boolean
}

const useStyles = makeStyles({
  fill: {
    flex: 1,
  },
  fullHeight: {
    height: 'calc(100% - 48px)', // 48 is height of tab list nav
  },
})

export const TabPanel: React.FC<Props> = ({
  children,
  value,
  index,
  fill,
  fullHeight,
  ...other
}) => {
  const classes = useStyles()

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classnames({
        [classes.fill]: fill,
        [classes.fullHeight]: fullHeight,
      })}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

TabPanel.displayName = 'TabPanel'
