// @ts-nocheck TODO: remove this comment and fix errors
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { usePathwayContext } from '../usePathwayContext'
import {
  useGetPathwayWebhookCallQuery,
  WebhookCall,
  GetPathwayWebhookCallQueryVariables,
  useOnWebhookCallUpdatedSubscription,
} from './types'

interface useGetWebhookCallHook {
  loading: boolean
  webhookCall?: WebhookCall
}

export const useGetPathwayWebhookCall = (
  webhookCallId: string,
): useGetWebhookCallHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()
  const { pathwayId } = usePathwayContext()

  const variables: GetPathwayWebhookCallQueryVariables = {
    webhook_call_id: webhookCallId,
  }

  const { data, loading, error } = useGetPathwayWebhookCallQuery({
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  useOnWebhookCallUpdatedSubscription({ variables: { pathway_id: pathwayId } })

  if (error) {
    notifyError({
      message: t('error_load_webhook'),
      error,
    })
    return { loading: false }
  }

  if (loading) {
    return { loading: true }
  }

  const webhookCall = data?.webhookCall.webhook_call

  return {
    loading: false,
    webhookCall,
  }
}
