import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@awell/ui-kit/components/Button'
import { Skeleton } from '@awell/ui-kit/components/Skeleton'
import { useStyles } from '../useStyles'
import { PatientProfileAttributeRow } from '../ProfileCardAttributeRow'

export const PatientProfileCardSkeleton: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.profileCardContainer}>
      <PatientProfileAttributeRow heading={t('birth_date')}>
        <Skeleton variant='text' width='150px' />
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow heading={t('language')}>
        <Skeleton variant='text' width='150px' />
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow heading={t('mobile_phone')}>
        <Skeleton variant='text' width='150px' />
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow heading={t('phone')}>
        <Skeleton variant='text' width='150px' />
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow heading={t('email')}>
        <Skeleton variant='text' width='150px' />
      </PatientProfileAttributeRow>
      <Button disabled className={classes.cta}>
        {t('start_pathway')}
      </Button>
    </div>
  )
}

PatientProfileCardSkeleton.displayName = 'PatientProfileCardSkeleton'
