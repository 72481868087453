import { makeStyles } from '@material-ui/core'
import { colors, shadows, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  backdrop: {
    // neutralDark500 with opacity 0.6
    backgroundColor: 'rgba(66,82,110,0.6)',
  },
  modal: {
    ...shadows.s,
  },
  warning: {
    backgroundColor: colors.neutralLight0,
    color: colors.signalWarning100,
  },
  error: {
    backgroundColor: colors.neutralLight0,
    color: colors.signalError100,
  },
  informative: {
    backgroundColor: colors.neutralLight0,
    color: colors.brand100,
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-start',
    padding: `${spacing.s} ${spacing.s} ${spacing.xxs} ${spacing.s}`,
  },
  icon: {
    marginRight: spacing.xxs,
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 0,
  },
  rootFooter: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    padding: `${spacing.xxs} ${spacing.s} ${spacing.s} ${spacing.s}`,
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hasAdditionalAction: {
    justifyContent: 'space-between',
  },
  mainActionButton: {
    marginLeft: spacing.xxs,
  },
})
