import { type RouteComponentProps } from '@reach/router'
import React, { type FC } from 'react'
import { CareFlowDashboard } from '../../../components/CareFlowDashboard'

interface Props {
  careflowId: string
  careflowDefinitionId: string
}

export const DashboardRoute: FC<RouteComponentProps & Props> = ({
  careflowId,
  careflowDefinitionId,
}) => {
  return (
    <CareFlowDashboard
      careflowId={careflowId}
      careflowDefinitionId={careflowDefinitionId}
    />
  )
}

DashboardRoute.displayName = 'DashboardRoute'
