import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  stakeholderWrapper: {
    marginBottom: spacing.xxs,
  },
  field: {
    marginBottom: spacing.xxs,
  },
})
