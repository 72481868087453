import {
  type DeserializeHtml,
  type SerializeHtml,
  createPluginFactory,
} from '@udecode/plate'
import React from 'react'
import { Node } from 'slate'

import { HostedPagesLinkElement } from './HostedPagesLinkElement'
import { ELEMENT_HOSTED_PAGES_LINK } from './types'

const serializeHtml: SerializeHtml = ({ element }) => {
  return <span>{Node.string(element)}</span>
}

const deserializeHtml: DeserializeHtml = {
  isElement: true,
  attributeNames: [ELEMENT_HOSTED_PAGES_LINK],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getNode: () => {},
}

export const createHostedPagesLinkPlugin = createPluginFactory({
  key: ELEMENT_HOSTED_PAGES_LINK,
  component: HostedPagesLinkElement,
  isElement: true,
  isInline: true,
  isVoid: true,
  serializeHtml,
  deserializeHtml,
})
