import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  cardContainer: {
    ...rounded.l,
    backgroundColor: 'rgb(254, 252, 232)',
    width: '100%',
    display: 'flex',
    padding: spacing.xs,
    alignItems: 'flex-start',
    marginBottom: spacing.s,
  },
  icon: {
    width: '20px',
    height: '20px',
    color: 'rgb(250, 204, 21)',
  },
  cardNote: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(161, 98, 7)',
  },
  cardText: {
    color: 'rgb(161, 98, 7)',
  },
  noteIconWrapper: {
    borderRadius: '50%',
    background: colors.signalWarning60,
    width: spacing.s,
  },
})
