// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import {
  ResizableDivider,
  ResizablePanel,
  useResizable,
} from '../../layouts/ResizablePanel'
import { findChildByType } from '../../utils/findChildByType'
import { useStyles } from './useStyles'

interface Component {
  Main?: FC
  Side?: FC
}

const Main = ({ children }) => children

const Side = ({ children }) => {
  return <>{children}</>
}

export const SidePaneLayout: Component & FC = ({ children }) => {
  const classes = useStyles()
  const mainSection = findChildByType(children, Main)
  const sideSection = findChildByType(children, Side)
  const {
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchEnd,
    dragging,
    panelWidth,
    setPanelWidth,
  } = useResizable({
    layoutWidthMultiplier: 0.3,
    onResize: () => null,
    minWidthMultiplier: 0.25,
    maxWidthMultiplier: 0.4,
    panelSide: 'right',
  })

  return (
    <div className={classes.main}>
      <div className={classes.startPanel}>{mainSection}</div>
      {sideSection && (
        <div className={classes.panelWithDivider}>
          <ResizableDivider
            transparent
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onMouseUp={onMouseUp}
            dragging={dragging}
          />
          <ResizablePanel
            panelWidth={panelWidth}
            setPanelWidth={setPanelWidth}
            className={classes.endPanel}
          >
            {sideSection}
          </ResizablePanel>
        </div>
      )}
    </div>
  )
}

SidePaneLayout.displayName = 'SidePaneLayout'
SidePaneLayout.Main = Main
SidePaneLayout.Side = Side
