import * as React from 'react'

function SvgClockSquare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width={32} height={32} rx={4} fill='#F4F5F7' />
      <path stroke='#42526E' strokeLinecap='round' d='M16.5 12.75V13' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.75 16a7.75 7.75 0 11-15.5 0 7.75 7.75 0 0115.5 0zM16 24.75a8.75 8.75 0 100-17.5 8.75 8.75 0 000 17.5zm0-15a.625.625 0 00-.625.625v6.25a.625.625 0 101.25 0v-6.25A.625.625 0 0016 9.75z'
        fill='#42526E'
      />
      <rect
        x={20.443}
        y={16}
        width={1.25}
        height={5.021}
        rx={0.625}
        transform='rotate(90 20.443 16)'
        fill='#42526E'
      />
    </svg>
  )
}

export default SvgClockSquare
