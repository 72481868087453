import {
  ListItemText,
  OutlinedInput,
  Select,
  MenuItem,
} from '@material-ui/core'
import React, { type FC, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { capitalize, intersection, isEmpty, uniqueId } from 'lodash'
import { Badge } from '../Badge'
import { Button } from '../Button'
import { Tooltip } from '../Tooltip'
import { ChevronDown } from '../Icons'
import { CheckBoxIcon } from '../SelectionControls'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

export interface OptionProps {
  value: string | number
  label: string
}

export interface MultiSelectWithCountProps {
  label?: string
  options: Array<OptionProps>
  error?: boolean
  compact?: boolean
  id?: string
  onChange: (items: Array<OptionProps>) => void
  hideClearButton?: boolean
  disabled?: boolean
  disabledTooltip?: string
  defaultSelectedOptions?: Array<string>
}

export const MultiSelectWithCount: FC<MultiSelectWithCountProps> = ({
  label = '',
  onChange,
  defaultSelectedOptions = [],
  options,
  error = false,
  compact = false,
  hideClearButton = false,
  disabled = false,
  disabledTooltip = '',
  id,
}) => {
  const [items, setItems] = React.useState<Array<string>>([])
  const classes = useStyles({ error })
  const { t } = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)

  useEffect(() => {
    if (!isEmpty(defaultSelectedOptions)) {
      // NB: default checked values are matched by option label, not option value
      const selectedOptions = intersection(
        options.map(o => capitalize(`${o.label}`)),
        defaultSelectedOptions.map(o => capitalize(o)),
      )
      setItems(selectedOptions)
    }
  }, [])

  const handleChange = (event: any): void => {
    const {
      target: { value },
    } = event
    const selectedValues: Array<string> =
      typeof value === 'string' ? value.split(',') : value
    // NB: options are checked against event values by option label, not option value
    const selection = selectedValues.map(val =>
      options.find(o => o.label === val),
    )
    setItems(selectedValues)
    onChange(selection as Array<OptionProps>)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleOpen = (): void => {
    setOpen(true)
  }
  const isSelected = (option: OptionProps): boolean => {
    // match the label or the value of the option
    return items.some(item => item.toLowerCase() === option.label.toLowerCase() || item.toLowerCase() === `${option.value}`.toLowerCase())

  }
  const renderLabel = (selected: Array<string>): JSX.Element => {
    return (
      <div className={classes.labelContainer}>
        <Type variant={TypeVariants.regular}>{label}</Type>
        <div className={classes.badgeContainer}>
          {selected.length !== 0 && (
            <Badge kind='brand'>{selected.length}</Badge>
          )}
        </div>
      </div>
    )
  }

  const handleClear = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setItems([])
    onChange([])
    event.stopPropagation()
  }

  return (
    <Tooltip info={disabled ? `${disabledTooltip}` : ''} arrow placement='left'>
      <Select
        labelId={uniqueId('multiple-checkbox-label-')}
        id={id ?? uniqueId('multiple-checkbox-')}
        multiple
        displayEmpty
        disabled={disabled}
        value={items}
        key={id ?? uniqueId('multiple-checkbox-')}
        onChange={handleChange}
        variant='outlined'
        input={<OutlinedInput />}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        // @ts-expect-error FIXME fix type of renderLabel
        renderValue={renderLabel}
        inputProps={{
          classes: {
            icon: classes.icon,
            outlined: classes.inputRoot,
            root: classnames({
              [classes.inputCompact]: compact,
            }),
          },
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          classes: {
            list: classes.dropdownList,
          },
        }}
        IconComponent={ChevronDown}
      >
        {options.map(option => (
          <MenuItem
            classes={{ selected: classes.itemSelected }}
            value={option.label}
            key={option.value}
          >
            <CheckBoxIcon checked={isSelected(option)} />
            <ListItemText
              className={classes.optionText}
              primary={option.label}
            />
          </MenuItem>
        ))}
        {!hideClearButton && (
          <div className={classes.buttonsContainer}>
            <Button size='small' onClick={handleClear} variant='text'>
              {t('clear')}
            </Button>
          </div>
        )}
      </Select>
    </Tooltip>
  )
}
