import React from 'react'
import { ExtensionActionActivityPreview } from '@awell/ui-kit/components/ExtensionActivityPreview'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { isNil } from 'lodash'
import { ErrorPage } from '@awell/ui-kit/compositions/ErrorPage'
import { useTranslation } from 'react-i18next'
import { useExtensionActivityRecord } from '../../../hooks/useExtensionActivity'
import type { Activity, ExtensionActivityRecord } from '../types'

interface ExtensionActivityDetailsProps {
  activity: Activity
}

export const ExtensionActivityDetails = ({
  activity,
}: ExtensionActivityDetailsProps): JSX.Element => {
  // TODO: CLEANUP
  const { loading, extensionActivityRecord } = useExtensionActivityRecord(
    activity.object.id,
  )
  const { t } = useTranslation()

  if (loading) {
    return <Spinner />
  }

  // TODO: CLEANUP
  if (isNil(extensionActivityRecord)) {
    return (
      <ErrorPage
        showButton={false}
        labels={{
          errorTitle: '',
          errorContent: t('error_action_content_not_available'),
        }}
      />
    )
  }

  return (
    <ExtensionActionActivityPreview<Activity, ExtensionActivityRecord>
      activity={activity}
      // TODO: CLEANUP
      extensionActivityRecord={extensionActivityRecord}
    />
  )
}
