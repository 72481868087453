import React, { useEffect, useState, type FC } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import {
  type CountryIso2,
  FlagEmoji,
  parseCountry,
} from 'react-international-phone'
import { InlineText } from '../Typography'
import { colors } from '../../utils/style-guide'
import { type CountryData } from 'react-international-phone/build/types'

interface Props {
  defaultCountries: Array<CountryData>
  country: CountryIso2 | undefined
  setCountry: (country: CountryIso2) => void
  id: string
}

export const FlagSelector: FC<Props> = ({
  defaultCountries,
  country,
  setCountry,
  id,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const selectId = `country-select-${id}` // Ensures unique ID

  useEffect(() => {
    const el = document.getElementById(selectId)
    if (el) {
      setAnchorEl(el)
    }
  }, [])

  return (
    <Select
      id={selectId}
      MenuProps={{
        anchorEl,
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        style: {
          height: '300px',
          width: '360px',
        },
      }}
      SelectDisplayProps={{
        // disable background color change on focus
        style: {
          backgroundColor: 'transparent',
        },
      }}
      disableUnderline
      value={country}
      onChange={e => {
        setCountry(e.target.value as CountryIso2)
      }}
      renderValue={value => (
        <FlagEmoji
          iso2={value as CountryIso2 | undefined}
          style={{ display: 'flex' }}
        />
      )}
    >
      {defaultCountries.map(c => {
        const country = parseCountry(c)
        return (
          <MenuItem key={country.iso2} value={country.iso2}>
            <FlagEmoji iso2={country.iso2} style={{ marginRight: '8px' }} />
            <span style={{ marginRight: 8 }}>
              <InlineText variant='textRegular'>{country.name}</InlineText>
            </span>
            <InlineText color={colors.neutralDark500}>
              +{country.dialCode}
            </InlineText>
          </MenuItem>
        )
      })}
    </Select>
  )
}
