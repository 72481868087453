import { useStytchMember } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import { useEffect } from 'react'

// Heap SDK is loaded in global scope through CDN (see index.html)
// If we start using this more extensively it would be best to load it as
// a dependency so that we can get types for it as well, but for now this will do
// @ts-expect-error see above
const { heap } = window

/**
 * Identify the user in Heap on login.
 */
export const useHeap = (): void => {
  const { member } = useStytchMember()
  useEffect(() => {
    if (!isNil(member)) {
      if (heap !== undefined) {
        try {
          heap.identify(member.email_address)
          heap.addUserProperties({
            name: member.name,
            email: member.email_address,
            company: {
              id: member.organization_id,
            },
          })
        } catch (err) {
          // Nothing to do here, if this calls fails there is no need to panic or block the app usage
        }
      }
    }
  }, [member])
}
