/**
 * Use ts never to show error when not all cases values of enum or union are handled by case statements
 *
 * If you have a enum or a union like
 * ```
 * mode: 'rich-text' | 'plaintext'
 * ```
 * and switch statement like so
 * ```
 * switch (mode) {
 *  case 'rich-text':
 *    return defaultPlugins
 *  default:
 *    return switchCaseMatchGuard(mode) <-- ts will show error here on mode
 * ```
 * TS error is shown because this function must not receive any argument
 */
export const switchCaseMatchGuard = (_: never): never => {
  throw new Error('Case should be unreachable')
}
