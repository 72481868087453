import { isEmpty, isNil } from 'lodash'
import { type WorkingListFilters } from '../WorkingListTable'
import { defaultActivityTypes } from './activityTypesLists'

export interface QueryFilters {
  activity_status?: { in: Array<string> }
  activity_type?: { in: Array<string> }
  patient_id?: { eq: string }
  pathway_definition_id?: { in: Array<string> }
  stakeholders?: { in: Array<string> }
  pathway_status?: { in: Array<string> }
}

export const getQueryFilters = (
  filters: WorkingListFilters | undefined,
): QueryFilters => {
  if (isNil(filters)) {
    return {}
  }

  const queryFilters: QueryFilters = {}

  if (!isEmpty(filters.activityStatuses) && !isNil(filters.activityStatuses)) {
    queryFilters.activity_status = { in: filters.activityStatuses }
  }

  if (!isEmpty(filters.activityTypes) && !isNil(filters.activityTypes)) {
    queryFilters.activity_type = { in: filters.activityTypes }
  }

  if (!isEmpty(filters.patient_id) && !isNil(filters.patient_id)) {
    queryFilters.patient_id = { eq: filters.patient_id }
  }

  if (
    !isEmpty(filters.pathwayDefinitionIds) &&
    !isNil(filters.pathwayDefinitionIds)
  ) {
    queryFilters.pathway_definition_id = { in: filters.pathwayDefinitionIds }
  }

  if (!isEmpty(filters.stakeholders) && !isNil(filters.stakeholders)) {
    queryFilters.stakeholders = { in: filters.stakeholders }
  }

  // If no activity types are selected, we default to non-navigation activities
  if (isEmpty(filters.activityTypes)) {
    queryFilters.activity_type = { in: defaultActivityTypes }
  }

  if (!isEmpty(filters.pathwayStatuses) && !isNil(filters.pathwayStatuses)) {
    queryFilters.pathway_status = { in: filters.pathwayStatuses }
  }

  return queryFilters
}
