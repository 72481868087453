import { FullHeightRouter } from '@awell/libs-web/routing'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { type RouteComponentProps } from '@reach/router'
import React, { type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CareAppPageLayout } from '../components/CareAppPageLayout'
import { CreatePatient, PatientPage, PatientTable } from '../pages/Patients'
import { RequestPatientDemographics } from '../pages/Patients/RequestPatientDemographics/RequestPatientDemographics'
import { withClinicalAppRedirect } from './withClinicalAppRedirect'

const PatientsRoute: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()
  const { t } = useTranslation()

  const breadcrumb = {
    label: t('patient_plural'),
    url: '/patients',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  // Primary Routers will manage focus on location transitions so we set
  // it to false here, otherwise it scrolls to the inner content on load
  return (
    <FullHeightRouter primary={false}>
      <CareAppPageLayout path='/'>
        <PatientTable default path='/' />
        <CreatePatient path='/create' />
        <RequestPatientDemographics path='/import' />
        <PatientPage path='/:patientId/*' />
      </CareAppPageLayout>
    </FullHeightRouter>
  )
}

PatientsRoute.displayName = 'PatientsRoute'

export default withClinicalAppRedirect(PatientsRoute)
