import React from 'react'
import {
  ActivityMain,
  ActivityFooter,
} from '../../components/ActivityFeedPanel'
import { type Activity, ActivityObjectType } from './types'
import { useActivityContent } from './useActivityContent'

interface ActivityPanelHeaderProps {
  activity: Activity
}

export const ActivityPanelHeader = ({
  activity,
}: ActivityPanelHeaderProps): JSX.Element => {
  const {
    content,
    content: { location, stakeholder, activityStatus, activityStatusColor },
  } = useActivityContent(activity)

  return (
    <div>
      <ActivityMain content={content} />
      <ActivityFooter
        location={location}
        stakeholder={stakeholder}
        activityStatus={activityStatus}
        activityStatusColor={activityStatusColor}
        isExtension={activity.object.type === ActivityObjectType.PluginAction}
      />
    </div>
  )
}

ActivityPanelHeader.displayName = 'ActivityPanelHeader'
