import { isEmpty } from 'lodash'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { sanitizeHtml } from '../../utils'
import { Attachments, Viewer } from '../RichText'
import { type MessageAttachment, MessageFormat } from './types'
import { useStyles } from './useStyles'

export interface MessageViewerProps {
  body: string
  format: MessageFormat
  attachments: Array<MessageAttachment>
}

export const MessageViewer: FC<MessageViewerProps> = ({
  body,
  format,
  attachments,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const cleanMessage = sanitizeHtml(unescape(body))

  return (
    <>
      <div id='message-preview' className={classes.messageViewerContainer}>
        {isEmpty(body) && t('message_content_missing')}
        {format === MessageFormat.Html && (
          <>
            <div dangerouslySetInnerHTML={{ __html: cleanMessage }} />
          </>
        )}
        {format === MessageFormat.Slate && <Viewer content={body} />}
      </div>
      <Attachments attachments={attachments} />
    </>
  )
}

MessageViewer.displayName = 'MessageViewer'
