import * as React from 'react'

function SvgTrackEnd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
      <path fill='#1F1F1F' fillRule='evenodd' d='M14 2v12h-2V9H2V7h10V2h2z' />
    </svg>
  )
}

export default SvgTrackEnd
