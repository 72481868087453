import React, { createContext, type FC, useContext, useReducer } from 'react'
import { type Breadcrumb } from '../../components/Breadcrumbs'

interface Action {
  type: 'ADD_BREADCRUMBS' | 'REMOVE_BREADCRUMBS' | 'UPDATE_BREADCRUMBS'
  payload: Array<Breadcrumb> | Breadcrumb
}
type State = Array<Breadcrumb>

interface BreadcrumbsContextInterface {
  addBreadcrumbs: (breadcrumbsToAdd: Breadcrumb | Array<Breadcrumb>) => () => void
  updateBreadcrumb: (breadcrumbToUpdate: Breadcrumb) => void
  breadcrumbs: State
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <BreadcrumbsProvider>.')
}

const initialContext = {
  addBreadcrumbs: stub,
  updateBreadcrumb: stub,
  breadcrumbs: [],
}

const BreadcrumbsContext = createContext<BreadcrumbsContextInterface>(
  initialContext,
)

function breadcrumbsReducer(breadcrumbs: State, action: Action): Array<Breadcrumb> {
  switch (action.type) {
    case 'ADD_BREADCRUMBS': {
      if (Array.isArray(action.payload)) {
        return [...breadcrumbs, ...action.payload]
      }
      return [...breadcrumbs, action.payload]
    }

    case 'UPDATE_BREADCRUMBS': {
      const breadcrumbsCopy = [...breadcrumbs]

      if (Array.isArray(action.payload)) {
        throw new Error('Please send only one breadcrumb in the payload')
      }

      const newBreadcrumb = action.payload 
      const breadcrumbToUpdateIndex = breadcrumbsCopy.findIndex(
        breadcrumb => breadcrumb.url === newBreadcrumb.url,
      )

      if (breadcrumbToUpdateIndex === -1) {
        return breadcrumbsCopy
      }

      breadcrumbsCopy.splice(breadcrumbToUpdateIndex, 1, newBreadcrumb)
      return breadcrumbsCopy
    }

    case 'REMOVE_BREADCRUMBS': {
      if (Array.isArray(action.payload)) {
        const breadcrumbsToRemove = action.payload 
        // Remove breadcrumbs if they are already present in the state
        const newBreadcrumbs = breadcrumbs.filter(
          breadcrumb =>
            breadcrumbsToRemove.find(b => b.url === breadcrumb.url) ===
            undefined,
        )

        return newBreadcrumbs
      }

      const breadcrumbToRemove = action.payload 
      const newBreadcrumbs = breadcrumbs.filter(
        breadcrumb => breadcrumbToRemove.url !== breadcrumb.url,
      )

      return newBreadcrumbs
    }
    default:
      return breadcrumbs
  }
}

const BreadcrumbsProvider: FC = ({ children }) => {
  const [breadcrumbs, dispatch] = useReducer(breadcrumbsReducer, [])

  /**
   *
   * @param breadcrumbsToAdd List of breadcrumbs to add when page is mounted and remove when the page is unmounted
   * @param dispatch method that updates the breadcrumbs state
   *
   * Its a helper method that first adds the breadcrumbs to the state and then further returns as clean up method
   * which removes the breadcrumbs.
   *
   * Usage
   * -----
   *
   * const { dispatch } = useBreadcrumbs()
   *
   * const breadcrumb = {
   *   url: "/pathways",
   *   label: "Pathways"
   * }
   * useEffect(() => {
   *   return addBreadcrumbs([breadcrumb], dispatch)
   * })
   *
   * Why does it work?
   * -----------------
   *
   * You can write useEffect as below.
   *
   * useEffect(() => {
   *
   *   // This executes `dispatch({ type: 'ADD_BREADCRUMBS', payload: breadcrumbs })` and
   *   // returns a function () => dispatch({ type: 'REMOVE_BREADCRUMBS', payload: breadcrumbs })
   *   // This function is assigned to cleanup variable
   *   const cleanup = addBreadcrumbs([breadcrumb], dispatch)
   *
   *   // Finally we return the cleanup variable i.e. a function that will execute on unmount.
   *   return cleanup
   *
   * })
   *
   * The above code can be rewritten in single line as
   *
   * `return addBreadcrumbs([breadcrumb], dispatch)`
   *
   *
   */
  const addBreadcrumbs = (
    breadcrumbsToAdd: Array<Breadcrumb> | Breadcrumb,
  ): () => void => {
    dispatch({ type: 'ADD_BREADCRUMBS', payload: breadcrumbsToAdd })
    return () =>
      { dispatch({ type: 'REMOVE_BREADCRUMBS', payload: breadcrumbsToAdd }); }
  }

  const updateBreadcrumb = (breadcrumbToUpdate: Breadcrumb): void => {
    dispatch({ type: 'UPDATE_BREADCRUMBS', payload: breadcrumbToUpdate })
  }

  const breadcrumbsContext = {
    breadcrumbs,
    addBreadcrumbs,
    updateBreadcrumb,
  }

  return (
    <BreadcrumbsContext.Provider value={breadcrumbsContext}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

const useBreadcrumbs = (): BreadcrumbsContextInterface => {
  const context = useContext(BreadcrumbsContext)
  if (context === undefined) {
    throw new Error(
      'You forgot to wrap your component in <BreadcrumbsProvider>',
    )
  }
  return context
}

export { BreadcrumbsProvider, useBreadcrumbs }
