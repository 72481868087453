/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { DragIndicator } from '../../Icons'
import { IconWrapper } from '../../IconWrapper'
import { InteractiveListContext } from '../InteractiveListContext'
import { createPortalIfDragging } from './createPortalIfDragging'
import { useStyles } from './useStyles'

interface DraggableListItemProps {
  itemId: string
  index: number
}

export const DraggableListItem: React.FC<DraggableListItemProps> = ({
  children,
  itemId,
  index,
}) => {
  const classes = useStyles()
  const { reorderable, reorderEnabled } = useContext(InteractiveListContext)

  return (
    <Draggable
      draggableId={itemId}
      index={index}
      isDragDisabled={!reorderEnabled}
    >
      {({ draggableProps, innerRef, dragHandleProps }, { isDragging }) =>
        createPortalIfDragging(
          isDragging,
          <li
            className={classes.container}
            {...draggableProps}
            ref={innerRef}
            {...dragHandleProps}
          >
            {reorderable && (
              <div className={classes.draggableIconContainer}>
                {reorderEnabled && (
                  <div className={classes.draggableIcon}>
                    <IconWrapper size='s' color='#cbd5e1'>
                      <DragIndicator />
                    </IconWrapper>
                  </div>
                )}
              </div>
            )}
            {children}
          </li>,
        )
      }
    </Draggable>
  )
}
DraggableListItem.displayName = 'InteractiveList.DraggableListItem'
