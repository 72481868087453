import { makeStyles } from '@material-ui/core'
import { shadows, spacing } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  backdrop: {
    // neutralDark500 with opacity 0.6
    backgroundColor: 'rgba(66,82,110,0.6)',
  },
  modal: {
    ...shadows.s,
    width: '814px',
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-start',
    padding: `${spacing.s} ${spacing.s} ${spacing.xxs}`,
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 0,
  },
  rootFooter: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-end',
    padding: `${spacing.xxs} ${spacing.s} ${spacing.s}`,
  },
})
