import * as React from 'react'

function SvgChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.293 6.293a1 1 0 00-1.32-.083l-.094.083-4.293 4.293a2 2 0 00-.117 2.701l.117.127 4.293 4.293a1 1 0 001.497-1.32l-.083-.094L11 12l4.293-4.293a1 1 0 000-1.414z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgChevronLeft
