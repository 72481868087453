// @ts-nocheck TODO: remove this comment and fix errors
import { MessageViewer } from '@awell/ui-kit/components/MessageViewer'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import React, { useEffect } from 'react'
import type { Activity } from '../../hooks/useMessageActivity'
import { useMessageActivity } from '../../hooks/useMessageActivity'

interface MessageActivityDetailsProps {
  activity: Activity
}

export const MessageActivityDetails = ({
  activity,
}: MessageActivityDetailsProps): JSX.Element => {
  const { loading, message, onRead } = useMessageActivity({
    activity,
  })

  useEffect(() => {
    if (loading === false) {
      onRead()
    }
  }, [loading])

  if (loading) {
    return <Spinner />
  }

  return (
    <MessageViewer
      body={message.body}
      format={message.format}
      attachments={message.attachments}
    />
  )
}

MessageActivityDetails.displayName = 'MessageActivityDetails'
