// @ts-nocheck TODO: remove this comment and fix errors
import { useEffect, useState } from 'react'
import {
  useGroupByTime,
  GroupsOfActivities,
} from '@awell/ui-kit/hooks/useGroupByTime'
import type { Activity } from '../usePathway'

interface UseActivityFeedHook {
  searchTerm: string
  onChangeSearchTerm: (searchTerm: string) => void
  groupedActivities: GroupsOfActivities
}

export const useActivityFeed = ({
  activities = [],
}: {
  activities?: Array<Activity>
}): UseActivityFeedHook => {
  const [searchTerm, onChangeSearchTerm] = useState('')
  const [groupedActivities, setGroupedActivities] = useState<
    GroupsOfActivities
  >(undefined)

  const filterActivities = (
    _activities: Array<Activity>,
    _searchTerm: string,
  ) => {
    const searching = _searchTerm !== '' && _searchTerm.length > 1
    if (searching) {
      return _activities.filter(activity => {
        return (
          activity.track.title
            ?.toLocaleLowerCase()
            .includes(_searchTerm.toLocaleLowerCase()) ||
          activity.container_name
            ?.toLocaleLowerCase()
            .includes(_searchTerm.toLocaleLowerCase()) ||
          activity.subject.name
            .toLocaleLowerCase()
            .includes(_searchTerm.toLocaleLowerCase()) ||
          activity.object.name
            .toLocaleLowerCase()
            .includes(_searchTerm.toLocaleLowerCase()) ||
          activity.object.type
            .toLocaleLowerCase()
            .includes(_searchTerm.toLocaleLowerCase()) ||
          activity.indirect_object?.name
            .toLocaleLowerCase()
            .includes(_searchTerm.toLocaleLowerCase())
        )
      })
    }
    return activities
  }

  useEffect(() => {
    const filteredActivities = filterActivities(activities, searchTerm)
    setGroupedActivities(useGroupByTime(filteredActivities))
  }, [activities, searchTerm])

  return {
    searchTerm,
    onChangeSearchTerm,
    groupedActivities,
  }
}
