// @ts-nocheck TODO: remove this comment and fix errors
/* eslint-disable react/destructuring-assignment */
import React, { FC, ReactElement } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Virtuoso } from 'react-virtuoso'
import { useStyles } from './useStyles'
import { EmptyListDropArea, HeightPreservingItem } from './Builder'
import { useWindowSize } from '../../hooks/useWindowSize'

interface BuildChildrenProps {
  id: string
}

export interface BuildProps {
  children: Array<ReactElement<BuildChildrenProps>>
}

export const Build: FC<BuildProps> = ({ children }) => {
  const classes = useStyles()
  const items = children as Array<ReactElement<BuildChildrenProps>>
  const { windowWidth } = useWindowSize()

  const DraggableQuestionListItem = React.useMemo(() => {
    // eslint-disable-next-line react/display-name
    return ({ provided, item }) => {
      // We are rendering an extra item for the placeholder
      return (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{ ...provided.draggableProps.style, paddingBottom: '8px' }}
        >
          <div className={classes.draggableItemWrapper}>{item}</div>
        </div>
      )
    }
  }, [])

  const listWidth = windowWidth > 1280 ? 300 : 250
  return (
    <>
      <Droppable
        droppableId='build'
        mode='virtual'
        renderClone={(provided, snapshot, rubric) => (
          <DraggableQuestionListItem
            provided={provided}
            item={items[rubric.source.index]}
          />
        )}
      >
        {droppableProvided => {
          return (
            <Virtuoso
              components={{
                Item: HeightPreservingItem,
                Footer: EmptyListDropArea,
                EmptyPlaceholder: EmptyListDropArea,
              }}
              data={items}
              scrollerRef={droppableProvided.innerRef}
              style={{ width: listWidth, height: '100%' }}
              itemContent={(index, item) => (
                <Draggable
                  draggableId={item.props.id}
                  index={index}
                  key={item.props.id}
                >
                  {provided => (
                    <DraggableQuestionListItem
                      provided={provided}
                      item={item}
                    />
                  )}
                </Draggable>
              )}
            />
          )
        }}
      </Droppable>
    </>
  )
}

Build.displayName = 'FormBuilder.Build'
