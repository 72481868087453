import React, { type FC } from 'react'
import { navigate } from '@reach/router'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Flex } from '../../components/Flex'
import { Button } from '../../components/Button'
import { useStyles } from './useStyles'
import { ErrorGraphic } from '../../atoms/ErrorGraphic'

// for errorContent strings please use '\n' to indicate new lines
// whiteSpace: 'pre-wrap' in the useStyles will handle line breaks
export interface ErrorLabels {
  buttonText?: string
  errorTitle?: string
  errorContent?: string
}

export interface ErrorPageProps {
  labels?: ErrorLabels
  panel?: boolean
  showButton?: boolean
  sendHome?: boolean
  default?: boolean
}

/**
 * ErrorPage is used to provide a visually appealing complement to ErrorBoundaries, and a fallback alternative to endlessly showing a Spinner. It can be used full screen or within panels (use it within one panel in a set). Note that the errorContent string should use \n for line breaks.
 *
 * @param labels labels for the error page title, content and button - see stories for example messaging. Ensure you pass in translation strings for this prop
 * @param panel boolean to indicate that this error page will be used within a thin panel (< 400px), sets styles to ensure good visibility within very narrow panels
 * @param sendHome boolean to indicate that button should not  reload page (set by default) but instead navigate the user to the app root ('/')
 * @param default comes from @reach/router (https://reach.tech/router/tutorial/08-default-routes), to indicate that a component is a fallback route
 */
export const ErrorPage: FC<ErrorPageProps> = ({
  labels,
  panel,
  sendHome = true,
  showButton = true,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const buttonAction = sendHome
    ? async () => { await navigate('/', { replace: true }); }
    : () => { document.location.reload(); }

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      className={classnames(
        classes.fullScreenContainer,
        panel ? classes.panelContainer : null,
      )}
    >
      <div className={classes.title}>
        {labels?.errorTitle ?? t('404_page_title')}
      </div>
      <div className={classes.content}>
        {labels?.errorContent ?? t('404_page_content')}
      </div>

      {showButton && (
        <Button onClick={buttonAction} type='button' className={classes.button}>
          {labels?.buttonText ?? t('404_page_button')}
        </Button>
      )}
      <ErrorGraphic />
    </Flex>
  )
}
