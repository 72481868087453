import * as React from 'react'

function SvgYesNoQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm-7 8c0-3.87 3.13-7 7-7v14c-3.87 0-7-3.13-7-7z' />
    </svg>
  )
}

export default SvgYesNoQuestion
