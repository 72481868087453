import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { useStopPathwayMutation, type StopPathwayInput } from './types'

interface UseStopPathwayHook {
  stopPathway: (input: StopPathwayInput) => Promise<boolean>
}

export const useStopPathway = (): UseStopPathwayHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const [stopPathwayMutation] = useStopPathwayMutation()

  const stopPathway = async (input: StopPathwayInput): Promise<boolean> => {
    try {
      await stopPathwayMutation({
        variables: {
          input,
        },
        refetchQueries: ['GetPatientPathways', 'GetPathway'],
      })
      return true
    } catch (error) {
      notifyError({
        message: t('stop_pathway_error'),
        error,
      })
    }
    return false
  }

  return {
    stopPathway,
  }
}
