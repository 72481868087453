/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react'
import { environment } from '@awell/libs-web/environment'
import { type ErrorContext, type ErrorReporter } from './types'

export class SentryErrorReporter implements ErrorReporter {
  constructor() {
    Sentry.init({
      environment: environment.environment,
      dsn: environment.logging.crash_reporter.care,
      release: environment.releases.care,
      ignoreErrors: [],
    })
  }

  public setContext(context: ErrorContext): void {
    Sentry.configureScope(scope => {
      scope.setUser(context.user)
    })
  }

  public report(error: Error, data?: Record<string, any>): void {
    // Skip crash reports in local to:
    // - reduce noise in sentry
    // - avoid impacting sentry quotas
    if (!environment.environment.includes('local')) {
      Sentry.captureException(error, data)
    } else {
      console.error(error, data)
    }
  }
}
