// @ts-nocheck TODO: remove this comment and fix errors
import { createPlugins, Plate } from '@udecode/plate'
import classnames from 'classnames'
import React, { FC } from 'react'
import { EditableProps } from 'slate-react/dist/components/editable'
import { type } from '../../utils/style-guide'
import { components } from './components'
import { viewerPlugins } from './plugins'
import { useStyles } from './useStyles'
import { parseStringSlateContent } from './utils'

interface ViewerProps {
  id?: string
  content?: string
}

export const Viewer: FC<ViewerProps> = ({ id, content }) => {
  const classes = useStyles()
  const plugins = createPlugins(viewerPlugins, {
    components,
  })

  const editableProps: EditableProps = {
    readOnly: true,
    className: classnames(classes.common, classes.viewer),
    style: {
      ...type.s,
    },
  }
  return (
    <Plate
      id={id}
      plugins={plugins}
      value={parseStringSlateContent(content)}
      editableProps={editableProps}
    />
  )
}

Viewer.displayName = 'Viewer'
