import React, { type FC } from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { colors } from '../../utils/style-guide'

interface DividerStyleProps {
  height?: number
}

interface DividerProps extends DividerStyleProps {
  customClasses?: string
}

const useStyles = makeStyles({
  divider: {
    height: ({ height: dividerHeight }: DividerStyleProps) =>
      `${dividerHeight}px`,
    background: colors.neutralLight40,
    width: '100%',
  },
})

export const Divider: FC<DividerProps> = ({ height = 1, customClasses }) => {
  const classes = useStyles({ height })
  return <div className={classnames(classes.divider, customClasses)} />
}
