import * as React from 'react'

function SvgFullscreen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M2 14h2v5h4v2H3a1 1 0 01-1-1v-6zm20 0v6a1 1 0 01-1 1h-5v-2h4v-5h2zM21 3a1 1 0 011 1v5h-2V5h-4V3h5zM8 5H4v4H2V4a1 1 0 011-1h5v2z'
      />
    </svg>
  )
}

export default SvgFullscreen
