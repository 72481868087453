export default function setVHCustomProperty() {
  // Recompute vh to avoid status bar issue
  // see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

  if (document.documentElement.style.getPropertyValue('--vh') === '') {
    const compute_vh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    ;(() => {
      compute_vh()
      window.addEventListener('resize', () => compute_vh())
    })()
  }
}
