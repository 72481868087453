import { isEmpty, isNil } from 'lodash'
import { type QuestionConfig } from '../../types'
import { type Maybe } from '../../../../utils'

export const validateMultiSelectTypeQuestion = (
  questionConfig: Maybe<QuestionConfig> | undefined,
  value: Array<string>,
): boolean | string => {
  const inputRequired = questionConfig?.mandatory

  // skip validation if input is not required and empty
  if (inputRequired === false && isEmpty(value)) {
    return true
  }

  if (
    isNil(questionConfig?.multiple_select?.range) ||
    questionConfig?.multiple_select?.range?.enabled === false
  ) {
    return true
  }

  const { min, max } = questionConfig?.multiple_select?.range

  // have to check if the response is an array hence the Array.isArray
  if (!isNil(min) && Array.isArray(value) && value.length < min) {
    return `question must have at least ${min} options selected. You selected ${value.length} options, which`
  }

  if (!isNil(max) && Array.isArray(value) && value.length > max) {
    return `question must have at max ${max} options selected. You selected ${value.length} options, which`
  }

  return true
}
