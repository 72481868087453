import React from 'react'
import {
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
  makeStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import { colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  danger: ({ variant }: { variant: string }) =>
    variant === 'text'
      ? {
          color: colors.signalError100,
          '&:hover, &:focus': {
            color: colors.red500,
            backgroundColor: colors.red50,
          },
        }
      : {
          color: colors.neutralLight0,
          backgroundColor: colors.signalError100,
          '&:hover, &:focus': {
            backgroundColor: colors.red500,
          },
        },
  warning: {
    color: colors.neutralLight0,
    backgroundColor: colors.signalWarning100,
    '&:hover, &:focus': {
      backgroundColor: colors.orange,
    },
  },
  root: {
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    '& svg': {
      transform: 'scale(2)',
    },
  },
})

interface CustomButtonProps extends MuiButtonProps {
  appearance?: 'danger' | 'warning'
  target?: string
  rel?: string
  overrideRootClass?: string
}

export const Button = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  function Button(
    {
      variant = 'contained',
      appearance,
      overrideRootClass,
      children,
      ...props
    },
    ref,
  ) {
    const classes = useStyles({ variant })
    const appearanceClass = appearance as keyof typeof classes

    return (
      <MuiButton
        ref={ref}
        variant={variant}
        {...props}
        classes={{
          root: classnames(
            classes.root,
            classes[appearanceClass],
            overrideRootClass,
          ),
          contained: classes[appearanceClass],
          label: classes.root,
        }}
        disableElevation
      >
        {children}
      </MuiButton>
    )
  },
)

Button.displayName = 'Button'
