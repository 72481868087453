import {
  type DeserializeHtml,
  type SerializeHtml,
  createPluginFactory,
} from '@udecode/plate'
import React from 'react'
import { Node } from 'slate'

import { DynamicVariableElement } from './DynamicVariableElement'
import {
  ELEMENT_DYNAMIC_VARIABLE,
  NON_EXISTENT_ELEMENT_DYNAMIC_VARIABLE,
} from './types'

const serializeHtml: SerializeHtml = ({ element }) => {
  return <span>{Node.string(element)}</span>
}

const deserializeHtml: DeserializeHtml = {
  isElement: true,
  attributeNames: [ELEMENT_DYNAMIC_VARIABLE],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getNode: () => {},
}

export const createDynamicVariablePlugin = createPluginFactory({
  key: ELEMENT_DYNAMIC_VARIABLE,
  component: DynamicVariableElement,
  isElement: true,
  isInline: true,
  isVoid: true,
  serializeHtml,
  deserializeHtml,
})

export const createNonExistentDynamicVariablePlugin = createPluginFactory({
  key: NON_EXISTENT_ELEMENT_DYNAMIC_VARIABLE,
  component: DynamicVariableElement,
  isElement: true,
  isInline: true,
  isVoid: true,
  serializeHtml,
  deserializeHtml,
})
