import React, { FC } from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '../../../components/Skeleton'
import {
  ActivityCardSkeleton,
  SystemActivityCardSkeleton,
} from '../../../components/ActivityFeedCard'
import { AwellTimeline } from '../../../components/AwellTimeline'

export const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateContainerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 750,
    [theme.breakpoints.down('lg')]: {
      width: 600,
    },
    [theme.breakpoints.down('sm')]: {
      width: 400,
    },
  },
}))

interface ActivityFeedSkeletonProps {
  classname: string
}

export const ActivityFeedSkeleton: FC<ActivityFeedSkeletonProps> = ({
  classname,
}): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.content, classname)}>
      <div className={classes.dateContainerWrapper}>
        <Skeleton
          variant='rect'
          style={{ borderRadius: '30px' }}
          width={70}
          height={30}
        />
      </div>
      <AwellTimeline>
        <ActivityCardSkeleton />
      </AwellTimeline>
      <AwellTimeline longConnector>
        <ActivityCardSkeleton />
      </AwellTimeline>
      <AwellTimeline>
        <SystemActivityCardSkeleton />
      </AwellTimeline>
    </div>
  )
}

ActivityFeedSkeleton.displayName = 'ActivityFeedSkeleton'
