import { isNil } from 'lodash'
import React, { type FC, type MouseEvent, useState } from 'react'
import { ChevronDown } from '../Icons'
import { Menu, MenuItem } from '../Menu'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

interface Props {
  date: string
  dateOptions?: Array<string>
  onSelectDate?: (date: string) => void
}

/**
 * Renders a date, with a dropdown to select other dates passed as
 * dateOptions.
 * All dates should be provided as ISO strings (see date-fns/formatISO)
 */
export const DateGroup: FC<Props> = ({
  date,
  dateOptions = [],
  onSelectDate,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleSelectDate = (event: MouseEvent, option: string): void => {
    if (!isNil(onSelectDate)) {
      onSelectDate(option)
    }
  }

  return (
    <div className={classes.dateGroupContainer}>
      <div className={classes.pill}>
        <Type variant={TypeVariants.h6}>{date}</Type>
        {dateOptions.length > 0 && (
          <Menu
            ToggleButtonIcon={ChevronDown}
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
          >
            {dateOptions.map(option => (
              <MenuItem
                key={option}
                onClick={event => {
                  handleSelectDate(event, option)
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    </div>
  )
}

DateGroup.displayName = 'DateGroup'
