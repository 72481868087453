import { makeStyles } from '@material-ui/core'
import React, { FC, ReactElement } from 'react'
import { colors, spacing } from '../../../utils/style-guide'
import SvgChevronRight from '../../Icons/ChevronRight'
import { IconWrapper } from '../../IconWrapper'
import { PopoverSelectorItemType } from '../../PopoverSelector'
import { Skeleton } from '../../Skeleton'

export interface NavigationWithDropdownProps {
  navigationLink: string
  parentPageName: string
  onSelect: (item: PopoverSelectorItemType) => void
  dropdownItems: Array<PopoverSelectorItemType>
  activeItem: PopoverSelectorItemType
  icon: FC
  loading?: boolean
}
const useStyles = makeStyles({
  breadCrumbContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing.xs,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})
export const TopNavigationWithBreadcrumbsSkeleton: FC = (): ReactElement => {
  const classes = useStyles()

  return (
    <>
      <Skeleton variant='text' height='50px' width='35px' />
      <div className={classes.breadCrumbContainer}>
        <div className={classes.textContainer}>
          <Skeleton variant='text' height='25px' width='80px' />
          <IconWrapper color={colors.neutralDark500}>
            <SvgChevronRight />
          </IconWrapper>
        </div>
        <Skeleton variant='text' height='25px' width='80px' />
      </div>
    </>
  )
}

TopNavigationWithBreadcrumbsSkeleton.displayName =
  'TopNavigationWithBreadcrumbsSkeleton'
