// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { colors, spacing } from '../../utils/style-guide'
import { Type, TypeVariants } from '../Typography'
import { AttachmentIcon } from './AttachmentIcon'
import { MessageAttachmentType } from './types'
import { IconWrapper } from '../IconWrapper'
import { ChevronRight } from '../Icons'
import { Tooltip } from '../Tooltip'

interface AttachmentItemProps {
  id: string
  name: string
  url: string
  type: MessageAttachmentType
  isBuilding?: boolean
  onSelect?: (attachment: string) => void
  placeholder?: string
}

const useStyles = makeStyles({
  container: ({ isBuilding }: { isBuilding: boolean }) => ({
    backgroundColor: colors.neutralLight0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    transition: 'all .3s ease-in-out',
    borderRadius: spacing.xxxs,
    '&:hover': {
      backgroundColor: isBuilding ? colors.neutralLight0 : colors.blue50,
      transition: 'all .3s ease-in-out',
    },
  }),
  containerContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: spacing.xxs,
      minWidth: spacing.m,
    },
  },
  label: {
    textAlign: 'start',
    paddingRight: spacing.xs,
  },
})

export const AttachmentItem: FC<AttachmentItemProps> = ({
  id,
  name,
  url,
  type,
  isBuilding = false,
  onSelect,
  placeholder,
}) => {
  const classes = useStyles({ isBuilding })
  return isBuilding ? (
    <Button className={classes.container} onClick={() => onSelect(id)}>
      <div className={classes.containerContent}>
        <AttachmentIcon icon={type} />
        <Tooltip info={name} placement='top' arrow>
          {!isEmpty(name) ? (
            <span className={classes.label}>
              <Type variant={TypeVariants.medium} clamp={2}>
                {name}
              </Type>
            </span>
          ) : (
            <Type variant={TypeVariants.medium} color={colors.neutralMid100}>
              {placeholder}
            </Type>
          )}
        </Tooltip>
      </div>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        <IconWrapper>
          <ChevronRight />
        </IconWrapper>
      </a>
    </Button>
  ) : (
    <a
      className={classes.container}
      href={url}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={classes.containerContent}>
        <AttachmentIcon icon={type} />
        <Tooltip info={name} placement='top' arrow>
          <span className={classes.label}>
            <Type variant={TypeVariants.medium} clamp={2}>
              {name}
            </Type>
          </span>
        </Tooltip>
      </div>
      <IconWrapper>
        <ChevronRight />
      </IconWrapper>
    </a>
  )
}

AttachmentItem.displayName = 'AttachmentItem'
