import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../utils/style-guide'

interface StyleProps {
  // eslint-disable-next-line react/require-default-props
  color?: string
}
interface LinkButtonProps extends StyleProps {
  children: React.ReactNode
  onClick: () => void
}

export const useStyles = makeStyles({
  linkButton: {
    background: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    color: ({ color }: StyleProps) => color ?? colors.brand100,
    fontSize: '1rem',
    fontFamily: 'inherit',
    fontWeight: 500,
    cursor: 'pointer',
    padding: 0,
    '&:hover, &focus': {
      textDecoration: 'underline',
    },
  },
})

export const LinkButton = ({
  children,
  color,
  ...props
}: LinkButtonProps): JSX.Element => {
  const classes = useStyles({ color })
  return (
    <button {...props} className={classes.linkButton} type='button'>
      {children}
    </button>
  )
}

LinkButton.displayName = 'LinkButton'
