import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core'
import { toNumber, without } from 'lodash'
import React, { type FC } from 'react'
import { colors } from '../../../utils/style-guide'
import { Type, TypeVariants } from '../../Typography'
import { useStyles } from './useStyles'

type OptionValue = string | number

export interface CheckboxOption {
  id: string
  label: string
  value: OptionValue
}

interface CheckboxListProps {
  onChange: (...event: Array<unknown>) => void
  onBlur?: () => void
  value: Array<OptionValue>
  options: Array<CheckboxOption>
  error?: string
  name?: string
  disabled?: boolean
  min?: number
  max?: number
}

export const CheckboxList: FC<CheckboxListProps> = ({
  value,
  options,
  onChange,
  onBlur,
  error,
  name,
  disabled,
  min,
  max,
}) => {
  const classes = useStyles()

  const handleChange = (val: OptionValue) => (checked: boolean) => {
    const newValue = checked ? value.concat(val) : without(value, val)
    onChange(newValue)
  }

  const isChecked = (val: OptionValue): boolean => {
    if (value.includes(val)) {
      return true
    }
    const asNumber = toNumber(val)
    return !isNaN(asNumber) && value.includes(asNumber)
  }

  return (
    <FormControl component='fieldset' className={classes.formControl}>
      <FormGroup>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            label={option.label}
            labelPlacement='end'
            classes={{
              root: classes.formControlLabel,
            }}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                color='primary'
                name={name}
                classes={{ root: classes.radioCheckboxInputs }}
                disabled={disabled}
                onChange={event => {
                  handleChange(option.value)(event.target.checked)
                }}
                onBlur={onBlur}
                checked={isChecked(option.value)}
              />
            }
          />
        ))}
      </FormGroup>
      <FormHelperText className={classes.error} component='div'>
        <Type variant={TypeVariants.small} color={colors.signalError100}>
          {error}
        </Type>
      </FormHelperText>
    </FormControl>
  )
}
