import React, { type FC } from 'react'
import {
  ActivityMain,
  ActivityFooter,
} from '../../components/ActivityFeedPanel'
import { SidePanel } from '../../components/SidePanel'
import { type Activity, ActivityObjectType } from './types'
import { useActivityContent } from './useActivityContent'

interface Props {
  activity: Activity
}

export const ActivityPanel: FC<Props> = ({ activity, children }) => {
  const {
    content,
    content: { location, stakeholder, activityStatus, activityStatusColor },
  } = useActivityContent(activity)

  return (
    <SidePanel>
      <SidePanel.Header>
        <ActivityMain content={content} />
        <ActivityFooter
          location={location}
          stakeholder={stakeholder}
          activityStatus={activityStatus}
          activityStatusColor={activityStatusColor}
          isExtension={activity.object.type === ActivityObjectType.PluginAction}
        />
      </SidePanel.Header>
      <SidePanel.Content>{children}</SidePanel.Content>
    </SidePanel>
  )
}

ActivityPanel.displayName = 'ActivityPanel'
