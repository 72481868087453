import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button'
import { Type, TypeVariants } from '../Typography'
import { useStyles } from './useStyles'

export interface ChecklistItem {
  id: string
  label: string
}

interface ChecklistUpdate {
  itemId: string
  checked: boolean
}

interface ChecklistProps {
  title: string
  items: Array<ChecklistItem>
  onItemUpdated: (new_value: ChecklistUpdate) => void
  onSubmit: () => void
  readOnly?: boolean
  disabled?: boolean
}

export const ChecklistPreview: FC<ChecklistProps> = ({
  title,
  items,
  onItemUpdated,
  onSubmit,
  readOnly = false,
  disabled = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [checkedItems, setCheckedItems] = useState(readOnly ? items : [])

  const handleChange = (item: ChecklistItem) => (checked: boolean) => {
    if (checked) {
      setCheckedItems([...checkedItems, item])
    } else {
      setCheckedItems(checkedItems.filter(checkedItem => checkedItem !== item))
    }
    onItemUpdated({ itemId: item.id, checked })
  }

  const handleSubmitChecklist = () => {
    onSubmit()
  }

  return (
    <div>
      <div className={classes.title}>
        <Type variant={TypeVariants.medium}>{title}</Type>
      </div>
      <div className={classes.checklist}>
        {items.map(item => (
          <FormControlLabel
            key={item.id}
            label={item.label}
            labelPlacement='end'
            classes={{ root: classes.checkboxListFormControl }}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                color='primary'
                disableRipple
                icon={<span className={classes.icon} />}
                onChange={event => handleChange(item)(event.target.checked)}
                checked={checkedItems.includes(item)}
                disabled={readOnly}
              />
            }
          />
        ))}
      </div>
      {/* in readOnly mode, the form will have been submitted already so we can either remove the button or disable it with some indicative text - we've gone for the latter option here */}
      <Button
        onClick={handleSubmitChecklist}
        disabled={checkedItems.length !== items.length || readOnly || disabled}
      >
        {readOnly ? t('submitted') : t('submit')}
      </Button>
    </div>
  )
}

ChecklistPreview.displayName = 'ChecklistPreview'
