import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  webhook_logs_top: {
    paddingBottom: spacing.m,
    borderBottom: `1px solid ${colors.neutralLight40}`,
    marginBottom: spacing.m,
  },
  info_banner_container: {
    marginTop: spacing.m,
    background: colors.blue50,
    padding: spacing.xsm,
    borderRadius: spacing.xxxs,
  },
  webhook_logs_selector: {
    marginTop: spacing.m,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: spacing.xxs,
    },
  },
  webhook_logs_pathway_select: {
    width: spacing.input.l,
    marginRight: spacing.xs,
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing.s,
  },
  webhook_logs_table: {
    marginTop: spacing.xs,
  },
})
