import {
  RadioGroup,
  type RadioGroupOption,
} from '@awell/ui-kit/components/FormBuilder/QuestionInput/RadioGroup'
import { isNil } from 'lodash'
import React, { type ChangeEvent, type FC } from 'react'
import { type Control, Controller, type FieldErrors } from 'react-hook-form'

interface PatientProfileRadioGroupProps {
  name: string
  control: Control
  errors: FieldErrors
  label?: string
  required?: boolean
  errorText?: string
  options: Array<RadioGroupOption>
}

export const PatientProfileRadioGroup: FC<PatientProfileRadioGroupProps> = ({
  name,
  control,
  errors,
  label = '',
  required = false,
  errorText = '',
  options,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={{ required }}
      render={({ onChange, onBlur, value }) => {
        return (
          <RadioGroup
            options={options}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.value)
            }}
            onBlur={onBlur}
            value={value}
            name={name}
            label={label}
            error={isNil(errors[name]) ? errorText : undefined}
            alignHorizontally
            required={required}
          />
        )
      }}
    />
  )
}
