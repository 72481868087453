import {
  ElementStatus,
  ElementType,
  ActionType,
  PathwayContext,
  ElementStakeholder,
} from '../../generated/types-design'

export {
  ActionType,
  ElementStatus,
  ElementType,
} from '../../generated/types-design'
export type { PathwayContext } from '../../generated/types-design'

export interface GanttUsage {
  id: string
  value: string
}

export enum GanttType {
  Task = 'task',
  Milestone = 'milestone',
  Project = 'project',
  Null = '',
}

export interface GanttData {
  id: string | number
  text: string
  element_type: ElementType
  activity_type?: ActionType
  start_date: Date | string
  duration?: number
  end_date?: Date | string
  progress?: number
  parent?: string | number
  open?: boolean
  readonly?: boolean
  editable?: boolean
  row_height?: number
  bar_height?: number | string
  rollup?: boolean
  hide_bar?: boolean
  usage?: GanttUsage[]
  stakeholder?: string
  stakeholders?: Array<ElementStakeholder>
  status: ElementStatus
  context: PathwayContext
}

export interface GanttLink {
  id: string | number
  source: number | string
  target: number | string
  type: '0' | '1' | '2' | '3'
  lag?: number
  readonly?: boolean
  editable?: boolean
}

export interface GanttRootObject {
  data: GanttData[]
  links: GanttLink[]
}

export interface GanttFilters {
  query: string
  type: Array<string>
  status: Array<string>
  activity_type: Array<string>
  stakeholder: Array<string>
}
