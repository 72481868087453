import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsContainer: {
    borderTop: `1px solid ${colors.neutralLight40}`,
    paddingTop: spacing.xxs,
    marginTop: spacing.xxs,
    display: 'flex',
    justifyContent: 'right',
    paddingRight: spacing.xxs,
    paddingLeft: spacing.xxs,
  },
  optionText: {
    paddingLeft: spacing.xsm,
  },
  icon: {
    width: spacing.s,
  },
  itemSelected: {
    // overriding the background color with important
    // because the MUI class takes precedence
    backgroundColor: `${colors.neutralLight20} !important`,
  },
  dropdownList: {
    minWidth: '220px',
  },
  inputRoot: {
    ...rounded.m,
    border: ({ error }: { error: boolean }) =>
      `2px solid ${error ? colors.signalError100 : colors.neutralLight40}`,
    backgroundColor: colors.neutralLight10,
    '&:focus, &:active, &[aria-expanded="true"] ': {
      ...rounded.m,
      borderColor: colors.brand100,
      backgroundColor: colors.neutralLight0,
    },
    '& ~ fieldset': {
      display: 'none',
    },
    '&.Mui-disabled': {
      borderColor: colors.neutralLight30,
      opacity: 0.7,
      pointerEvents: 'none' as const,
    },
  },
  inputCompact: {
    padding: spacing.xxs,
  },
  badgeContainer: {
    width: spacing.m,
    textAlign: 'right',
  },
})
