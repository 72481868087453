/* eslint-disable react/prop-types */
// @ts-nocheck TODO: remove this comment and fix errors
import classnames from 'classnames'
import React, { Children, useEffect, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  type DropResult,
  type ResponderProvided,
} from 'react-beautiful-dnd'
import { colors } from '../../utils/style-guide'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Plus } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { defaultLabels, InteractiveListContext } from './InteractiveListContext'
import { ListItem } from './ListItem'
import { useStyles } from './useStyles'
import { isNil } from 'lodash'
import { Type, TypeVariants } from '../Typography/Typography'

interface AddItemButtonProps {
  paddingX?: boolean
}

interface Component {
  ListItem: typeof ListItem
  ListItems: React.FC
  AddItemButton: React.FC<AddItemButtonProps>
}

// Slots
const ListItems: React.FC = ({ children }) => {
  return <>{children}</>
}
ListItems.displayName = 'InteractiveList.ListItems'

const AddItemButton: React.FC<AddItemButtonProps> = ({
  children,
  paddingX = true,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(classes.addItemButtonContainer, {
        [classes.paddedX]: paddingX,
      })}
      data-cy='pathway-add-transition-btn'
    >
      {children}
    </div>
  )
}
AddItemButton.displayName = 'InteractiveList.AddItemButton'

interface InteractiveListProps {
  onAddItem?: () => void
  editable?: boolean
  onDelete?: (itemId: string) => void
  reorderable?: boolean
  onReorder?: ({
    itemId,
    source,
    destination,
  }: {
    itemId: string
    source: number
    destination: number
  }) => void
  customLabels?: {
    delete?: string
    edit?: string
    done?: string
    add?: string
  }
  actionable?: boolean
  addItemTop?: boolean
  dataCy?: string
  className?: string
  title?: string
}

export const InteractiveList: Component & React.FC<InteractiveListProps> = ({
  children,
  onAddItem,
  editable = false,
  onDelete,
  reorderable = false,
  onReorder,
  actionable = false,
  customLabels,
  addItemTop = false,
  dataCy = null,
  className,
  title,
}) => {
  const classes = useStyles()

  const [editing, setEditing] = useState(false)
  useEffect(() => {
    setEditing(false)
  }, [editable])
  const toggleEditing = (): void => {
    setEditing(!editing)
  }

  const handleDragEnd = (
    { draggableId, source, destination }: DropResult,
    __: ResponderProvided,
  ): void => {
    if (destination && source.index !== destination.index) {
      onReorder({
        itemId: draggableId,
        source: source.index,
        destination: destination.index,
      })
    }
  }

  const labels = Object.assign(defaultLabels, customLabels)

  const contextValue = {
    editing,
    onDelete,
    reorderable,
    reorderEnabled: !editing,
    actionable,
    labels,
  }

  const childrenArray = Children.toArray(children)
  const listItems = childrenArray.find(child => child?.type === ListItems)

  const startIcon = (
    <IconWrapper color={colors.brand100} size='xxs'>
      <Plus />
    </IconWrapper>
  )

  const defaultAddItemButton = (
    <AddItemButton>
      <Button
        fullWidth
        variant='outlined'
        startIcon={startIcon}
        onClick={onAddItem}
      >
        {labels.add}
      </Button>
    </AddItemButton>
  )

  function renderAddItemButton(): JSX.Element {
    const addItemButton = childrenArray.find(
      child => child?.type === AddItemButton,
    )
    return (
      <Flex className={classes.editButtonContainer} justify='space-between'>
        <div className={classes.flexGrow}>
          {addItemButton ?? defaultAddItemButton}
        </div>
      </Flex>
    )
  }

  function EditButton(): JSX.Element {
    return (
      <Button variant='text' size='small' onClick={toggleEditing}>
        {editing ? labels.done : labels.edit}
      </Button>
    )
  }

  const Body = (): JSX.Element => {
    return (
      <div
        className={classnames(classes.listContainer, className)}
        data-cy={dataCy}
      >
        {reorderable ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='InteractiveList'>
              {({ placeholder, innerRef, droppableProps }) => (
                <ul className={classes.list} ref={innerRef} {...droppableProps}>
                  {listItems}
                  {placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <ul className={classes.list}>{listItems}</ul>
        )}
      </div>
    )
  }

  return (
    <InteractiveListContext.Provider value={contextValue}>
      {addItemTop && renderAddItemButton()}
      {editable && !isNil(title) && (
        <div className={classes.inlineItems}>
          <Type variant={TypeVariants.medium}>{title}</Type>
          <div className={classes.editButtonContainer}>
            <EditButton />
          </div>
        </div>
      )}
      {editable && isNil(title) && (
        <Flex className={classes.editButtonContainer} justify='end'>
          <EditButton />
        </Flex>
      )}
      <Body />
      {!addItemTop && renderAddItemButton()}
    </InteractiveListContext.Provider>
  )
}
InteractiveList.displayName = 'InteractiveList'
InteractiveList.ListItem = ListItem
InteractiveList.ListItems = ListItems
InteractiveList.AddItemButton = AddItemButton
