import * as React from 'react'
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import isArray from 'lodash/isArray'
import head from 'lodash/head'
import { DropDown } from './DropDown'
import { SelectInput } from './SelectInput'
import {
  generateOptionsFromChildren,
  type InternalOption,
} from './generate-options-from-children'
import { type IconNames } from '../Icon'
import { type CategoryTypes } from '../CategoryIcon'
import { type StrictUnion } from '../../utils/types'
import { isNil } from 'lodash'

type OnChangeEvent = (value: string, event: React.ChangeEvent) => void
type OnChangeEventMultiple = (
  value: Array<string>,
  event: React.ChangeEvent,
) => void

interface SelectProps {
  className?: string
  compact?: boolean
  debug?: boolean
  disabled?: boolean
  disableOnEmptyValueList?: boolean
  error?: boolean
  hideLabel?: boolean
  iconStart?: IconNames | CategoryTypes
  id?: string
  label: string | JSX.Element
  open?: boolean
  placeholder?: string
  warning?: boolean
  style?: React.CSSProperties
  dataCy?: string
  children: React.ReactNode | Array<React.ReactNode>
  description?: string
}

interface SelectPropsMultiple extends SelectProps {
  multiple: true
  onChange?: OnChangeEventMultiple
  value?: Array<string> | string
  position?: 'top' | 'bottom'
}

interface SelectPropsNonMultiple extends SelectProps {
  multiple?: false
  onChange?: OnChangeEvent
  value?: string
  position?: 'top' | 'bottom'
}

interface OptionProps {
  icon?: IconNames | CategoryTypes
  label?: string
  selected?: boolean
  value?: string
  disabled?: boolean
  fontSize?: string
}

interface OptionGroupProps {
  title: string
}

function handleOnChange(handler: OnChangeEvent) {
  return function execute(event: React.ChangeEvent, options?: InternalOption) {
    if (!isNil(options?.value)) {
      handler?.(options.value, event)
    } else {
      console.log('missing value')
    }
  }
}

function filterValues(options: Array<InternalOption>): Array<string> {
  return options
    .filter(option => !isNil(option?.value))
    .map(option => option.value) as Array<string>
}

function handleOnChangeMultiple(handler: OnChangeEventMultiple) {
  return function execute(
    event: React.ChangeEvent,
    options: Array<InternalOption>,
  ) {
    const values = options.length > 0 ? filterValues(options) : []
    handler?.(values, event)
  }
}

/**
 * Parses a string value as JSON if it's a string, otherwise returns the value as-is.
 * This is useful for avoiding unintended substring matches when comparing strings.
 * For example, "[\"10\"]" includes "1", "0", and "10" as substrings, but parsed as JSON,
 * it includes "10" only.
 *
 * @param val The value to parse.
 * @returns The parsed value if it was a string and could be parsed as JSON, otherwise the original value.
 */
const parseMultipleValue = (val: any): Array<string> | string => {
  if (typeof val === 'string') {
    try {
      return JSON.parse(val)
    } catch (e) {
      // return the original value as is
    }
  }
  return val
}

const groupBy = (option: InternalOption): string | undefined => option.group

const getOptionSelected = (
  optionObj: InternalOption,
  valueObj: InternalOption,
): boolean => {
  const option = isArray(optionObj) ? head(optionObj) : optionObj
  const value = isArray(valueObj) ? head(valueObj) : valueObj
  return option === value
}

const getOptionLabel = (optionObj: InternalOption): string => {
  const option = isArray(optionObj) ? head(optionObj) : optionObj

  if (isNil(option)) return ''

  if (!isNil(option.label)) return option.label

  if (typeof option.content === 'string') return option.content

  return option.value
}

export const Option: React.FC<OptionProps> = () => null
Option.displayName = 'Option'
export const OptionGroup: React.FC<OptionGroupProps> = () => null
OptionGroup.displayName = 'OptionGroup'

export const Select: React.FC<
  StrictUnion<SelectPropsNonMultiple | SelectPropsMultiple>
> = ({
  children,
  className,
  compact,
  debug,
  disabled,
  disableOnEmptyValueList,
  error,
  hideLabel,
  iconStart,
  id,
  label,
  multiple,
  onChange,
  open,
  placeholder,
  value,
  warning,
  description,
  style = {},
  dataCy = null,
  position = 'bottom',
}) => {
  const [options, setOptions] = React.useState<Array<InternalOption>>([])
  const [isNested, setIsNested] = React.useState(false)
  const [chosenValue, setChosenValue] = React.useState<Array<InternalOption>>(
    [],
  )

  React.useEffect(() => {
    const { options, isNested } = generateOptionsFromChildren({
      children,
      optionType: Option,
      optionGroupType: OptionGroup,
    })

    const getSelected = (option: InternalOption): boolean => {
      if (isNil(option.value)) return false

      if (multiple === true) {
        return parseMultipleValue(value).includes(option.value)
      }
      return value === option.value
    }

    // When providing a value, instead of selected attrs, make sure the selected
    // property is present in the object
    const optionsWithSelectionData = isNil(value)
      ? options
      : options.map(option => ({
          ...option,
          selected: getSelected(option),
        }))

    let chosenValue = optionsWithSelectionData.filter(
      (option: InternalOption) => option.selected === true,
    )

    chosenValue =
      isArray(chosenValue) && chosenValue.length === 0 && multiple === false
        ? []
        : chosenValue
    setOptions(optionsWithSelectionData)
    setIsNested(isNested)
    setChosenValue(chosenValue)
  }, [children, value])

  const {
    focused,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getRootProps,
    getTagProps,
    groupedOptions,
    popupOpen,
    value: internalValue,
  } = useAutocomplete({
    ...(isNested ? { groupBy } : {}),
    debug,
    disableCloseOnSelect: multiple,
    getOptionLabel,
    getOptionSelected,
    id,
    multiple,
    open,
    options,
    selectOnFocus: true,
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    value: chosenValue ?? null,
    // @ts-expect-error FIXME
    onChange:
      multiple ?? false
        ? handleOnChangeMultiple(onChange as OnChangeEventMultiple)
        : handleOnChange(onChange!),
  })

  return (
    <div
      className={className}
      style={{ position: 'relative', ...style }}
      data-cy={dataCy}
    >
      <SelectInput
        disabled={
          (disabled ?? false) ||
          ((disableOnEmptyValueList ?? false) && options.length === 0)
        }
        dropdownOpen={popupOpen}
        error={error}
        focused={focused}
        getTagProps={getTagProps}
        hideLabel={hideLabel}
        iconStart={iconStart!}
        inputLabelProps={getInputLabelProps()}
        inputProps={getInputProps() as { value: string }}
        internalValue={internalValue}
        label={label}
        multiple={multiple}
        placeholder={placeholder}
        rootProps={getRootProps()}
        totalOptions={options.length}
        warning={warning}
        description={description}
      />
      <DropDown
        compact={compact}
        open={popupOpen}
        // @ts-expect-error FIXME
        getOptionProps={getOptionProps}
        // @ts-expect-error FIXME
        items={groupedOptions}
        listBoxProps={getListboxProps}
        multiple={multiple}
        nested={isNested}
        inputProps={getInputProps() as { value: string }}
        position={position}
      />
    </div>
  )
}

Select.displayName = 'Select'
