import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../../utils/style-guide'

const useStyles = makeStyles({
  listDivider: {
    position: 'relative',
    display: 'block',
    height: '1px',
    width: '100%',
    ...spacing.paddingY.xs,
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '1px',
      backgroundColor: colors.neutralLight40,
      position: 'absolute',
      top: spacing.xs,
    },
  },
})

export const ListDivider: React.FC = ({ children }) => {
  const classes = useStyles()
  return <li className={classes.listDivider}>&nbsp;</li>
}

ListDivider.displayName = 'ListDivider'
