import { makeStyles } from '@material-ui/core'
import { colors, rounded, shadows, spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles(theme => ({
  question: {
    width: '100%',
    maxWidth: '700px', // it doesnt look good when question is taking all the width on larger screens
    padding: `0 ${spacing.m}`,
    backgroundColor: colors.neutralLight10,
    '&:focus': {
      outline: 'none',
    },
  },
  questionListItem: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    padding: `${spacing.xxs} ${spacing.s} ${spacing.xxs} ${spacing.xs}`,
    backgroundColor: colors.neutralLight10,
    alignItems: 'center',
    height: '55px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  hoverable: {
    '&:hover': {
      backgroundColor: colors.neutralLight30,

      '& $dragIcon': {
        color: colors.neutralDark500,
        display: 'block',
      },
      '& $toolbarWrapper': {
        display: 'flex',
      },
    },
  },
  questionActive: {
    backgroundColor: colors.neutralLight0,
    overflow: 'auto',
    '& $dragIcon': {
      color: colors.neutralDark500,
      display: 'block',
    },
    '& $multipleChoiceContainer': {
      paddingBottom: 0,
    },
    '& $toolbarWrapper': {
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
      padding: `0 ${spacing.xxs}`,
    },
  },
  questionTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: spacing.xxs,
  },
  multipleChoiceContainer: {
    paddingTop: 2, // Make sure that multiple choice title is aligned with icon
    paddingBottom: 33, // Add extra padding to prevent the size of the multiple choice editor to change when it becomes active. Extra size compensate for the 'Enter new choice' placeholder
    width: '100%',
    margin: spacing.xxs,
  },
  questionDragging: {
    padding: spacing.xxs,
    ...rounded.m,
    boxShadow: shadows.l.boxShadow,
    backgroundColor: colors.neutralLight20,
    '& $questionTitle': {
      marginTop: spacing.s,
    },
  },
  dragIndicator: {
    minHeight: spacing.s,
  },
  dragIcon: {
    display: 'none',
    margin: '0 auto',
  },
  yesNo: {
    paddingLeft: spacing.xl,
  },
  yesNoOption: {
    '&:not(:last-child)': {
      paddingBottom: spacing.xxs,
    },
  },
  toolbar: {
    opacity: 0,
    position: 'relative',
    minHeight: spacing.l,
    marginRight: -spacing.xs,
    '& button': {
      float: 'right',
      margin: spacing.xxxs,
      bottom: spacing.xxs,
    },
  },
  toolbarVisible: {
    opacity: 1,
  },
  iconWithNumber: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: colors.neutralLight30,
    marginRight: spacing.xxs,
  },
  activeIconWithNumber: {
    color: colors.neutralLight0,
    backgroundColor: colors.brand100,
  },
  questionNumber: {
    marginLeft: spacing.xxxs,
    paddingRight: spacing.xxxs,
    marginTop: '2px', // to center with icon, because icons have transparent part so align center is not enough
  },
  titleWrapper: {
    overflow: 'hidden',
  },
  required: {
    color: colors.red,
    paddingLeft: spacing.xxs,
  },
  toolbarWrapper: {
    display: 'none',
    flexDirection: 'column',
    position: 'absolute',
    right: spacing.xxxs,
    top: '13px',
    transform: 'rotate(90deg)',
  },
  deleteButtonText: {
    color: colors.signalError100,
  },
  navigationButton: {
    opacity: 0,
  },
  navigationButtonContainer: {
    padding: spacing.xxs,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      '& $navigationButton': {
        transition: 'opacity .2s ease-in',
        opacity: 1,
      },
    },
  },
}))
