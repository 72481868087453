import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  profileCardContainer: {
    padding: spacing.s,
    border: `1px solid ${colors.neutralLight30}`,
    borderRadius: spacing.xxxs,
    marginBottom: spacing.xs,
  },
  profileAttributeRow: {
    marginBottom: spacing.xs,
  },
  profileAttributeHeading: {
    color: colors.neutralLight100,
  },
  cta: {
    width: '100%',
  },
  email: {
    color: colors.brand100,
  },
})
