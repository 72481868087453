import React, { FC } from 'react'

import { useStyles } from './useStyles'
import errorSvg from '../assets/images/error.svg'

export const ErrorGraphic: FC = () => {
  const classes = useStyles()
  return (
    <img
      src={`${errorSvg}#errorGraphic`}
      alt='error graphic'
      className={classes.graphic}
    />
  )
}

ErrorGraphic.displayName = 'ErrorGraphic'
