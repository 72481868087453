// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@awell/ui-kit/components/Button'
import { compose, isEmpty, get } from 'lodash/fp'
import { PatientProfileAttributeRow } from './ProfileCardAttributeRow'
import { useStyles } from './useStyles'
import { Patient, ProfileCardFields } from './types'
import { defaultProfilePropTo, formatDate } from '../../utils'

interface PatientProfileCardProps {
  patient: Patient
  onStartPathway: () => void
}

export const PatientProfileCard: FC<PatientProfileCardProps> = ({
  patient,
  onStartPathway,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const language = defaultProfilePropTo({ prop: 'preferred_language' })(patient)
  const mobilePhone = defaultProfilePropTo({ prop: 'mobile_phone' })(patient)
  const phone = defaultProfilePropTo({ prop: 'phone' })(patient)
  const email = defaultProfilePropTo({ prop: 'email' })(patient)
  const birthDate = defaultProfilePropTo({
    prop: 'birth_date',
    formatFn: formatDate('dd/MM/yyyy'),
  })(patient)

  const isEmptyProp = (prop: keyof ProfileCardFields) =>
    compose(isEmpty, get(`profile.${prop}`))(patient)

  const isEmailProvided = !isEmptyProp('email')
  return (
    <div className={classes.profileCardContainer}>
      <PatientProfileAttributeRow
        heading={t('birth_date')}
        noData={isEmptyProp('birth_date')}
      >
        {birthDate}
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow
        heading={t('language')}
        noData={isEmptyProp('preferred_language')}
      >
        {language}
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow
        heading={t('mobile_phone')}
        noData={isEmptyProp('mobile_phone')}
      >
        {mobilePhone}
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow
        heading={t('phone')}
        noData={isEmptyProp('phone')}
      >
        {phone}
      </PatientProfileAttributeRow>
      <PatientProfileAttributeRow
        heading={t('email')}
        noData={!isEmailProvided}
      >
        {isEmailProvided ? (
          <a className={classes.email} href={`mailto:${email}`}>
            {email}
          </a>
        ) : (
          email
        )}
      </PatientProfileAttributeRow>
      <Button onClick={onStartPathway} className={classes.cta}>
        {t('start_pathway')}
      </Button>
    </div>
  )
}

PatientProfileCard.displayName = 'PatientProfileCard'
