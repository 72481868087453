// @ts-nocheck TODO: remove this comment and fix errors
import {
  FilterVariants,
  SearchFilterBar,
} from '@awell/ui-kit/components/SearchFilterBar'
import { Table } from '@awell/ui-kit/components/Table'
import { HeadingSecondary } from '@awell/ui-kit/components/Typography'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { isEmpty, uniqBy } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWebhookCallsForTenant } from '../../../hooks/useWebhookCallsForTenant'
import { WebhookCallListSkeleton } from '../../Pathway/skeletons'
import { WebhookCall, WebhookCallResponse } from './types'
import { useStyles } from './useStyles'
import { useWebhookLogsColumns } from './useWebhookLogsColumns'

interface WebhookFilters {
  statusCodes: Array<string>
  query: string
}

export const WebhookLogsForOrganization: FC = () => {
  const { t } = useTranslation()
  const { columns } = useWebhookLogsColumns({ includePathwayDefinition: true })
  const classes = useStyles()
  const [appliedFilters, setFilters] = useState<WebhookFilters>({
    query: '',
    statusCodes: [],
  })
  const { loading, webhookCalls } = useWebhookCallsForTenant()

  const [rows, setRows] = useState([])

  useEffect(() => {
    if (!loading) {
      setRows(webhookCalls)
    }
  }, [loading, webhookCalls])

  if (loading) return <WebhookCallListSkeleton />

  const getStatusCodeOptions = (_data: Array<WebhookCall>) => {
    return uniqBy(
      _data
        .map(d => d.responses)
        .flat()
        .map(({ status }) => ({
          label: `${status}`,
          value: `${status}`,
        })),
      'value',
    )
  }

  const filters: Array<FilterVariants> = [
    {
      label: t('status'),
      name: 'statusCodes',
      type: 'select',
      options: getStatusCodeOptions(webhookCalls),
    },
  ]

  const handleSearch = ({
    webhook_name,
    pathway_id,
    patient_id,
    query,
  }: {
    webhook_name: string
    query: string
    patient_id: string
    pathway_id: string
  }): boolean => {
    if (query === '') {
      return true
    }

    return (
      webhook_name.toLowerCase().includes(query.toLowerCase()) ||
      pathway_id.toLowerCase().includes(query.toLowerCase()) ||
      patient_id.toLowerCase().includes(query.toLowerCase())
    )
  }

  const handleStatusCodeFilter = (
    responses: Array<WebhookCallResponse>,
    codes: Array<string>,
  ): boolean => {
    if (isEmpty(codes)) {
      return true
    }
    if (isEmpty(responses)) {
      return false
    }
    return codes.includes(`${responses[responses.length - 1].status}`)
  }

  const handleSearchAndFilters = ({ statusCodes, query }: WebhookFilters) => {
    setFilters({
      statusCodes,
      query,
    })
    const newRows = webhookCalls.filter(
      ({
        responses,
        webhook_name,
        pathway: { patient_id, id: pathway_id },
      }) => {
        return (
          handleStatusCodeFilter(responses, statusCodes) &&
          handleSearch({ webhook_name, query, pathway_id, patient_id })
        )
      },
    )

    setRows(newRows)
  }

  return (
    <div>
      <HeadingSecondary variant='subHeadline'>{t('all_logs')}</HeadingSecondary>

      <div className={classes.info_banner_container}>
        <div>{t('webhook_organization_logs_info')}</div>
      </div>
      <div className={classes.webhook_logs_table}>
        <div className={classes.filtersContainer}>
          <SearchFilterBar<WebhookFilters>
            onSearchFilter={handleSearchAndFilters}
            filters={filters}
            appliedFilters={appliedFilters}
            placeholder={t('webhook_logs_search_placeholder')}
            customSpacing={`${spacing.xs} 0`}
            hideBorder
          />
        </div>
        <div className={classes.webhook_logs_table}>
          <Table
            columns={columns}
            rows={rows}
            fullWidth
            paging
            nonTablePageContentHeight={500}
          />
        </div>
      </div>
    </div>
  )
}
