import { round } from 'lodash'

// round a number or numeric string to a given number of decimal places
export const roundToDecimalPlaces = (
  value: number | string,
  numberOfDecimalPlaces: number,
): number | string =>
  Number.isNaN(Number(value))
    ? value
    : round(Number(value), numberOfDecimalPlaces)
