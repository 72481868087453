import { useStytchMember } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useAnalytics } from 'use-analytics'

/**
 * Identify the user in Custify on login.
 */
export const useCustify = (): void => {
  const { member } = useStytchMember()
  const { identify } = useAnalytics()
  useEffect(() => {
    if (!isNil(member)) {
      try {
        void identify(member.member_id ?? '', {
          user_id: member.member_id,
          email: member.email_address,
          name: member.name,
          company: {
            id: member.organization_id,
          },
        })
      } catch (err) {
        // Nothing to do here, if this calls fails there is no need to panic or block the app usage
      }
    }
  }, [member])
}
