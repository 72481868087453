import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    bottom: 1,
    right: 1,
    zIndex: 4,
  },
})
