import React from 'react'
import classnames from 'classnames'
import { useStyles } from '../useStyles'
import { useSkeletonStyles } from './useSkeletonStyles'
import { Skeleton } from '../../Skeleton'

export const ActivityCardSkeleton = () => {
  const classes = useStyles()
  const skeletonClasses = useSkeletonStyles()

  return (
    <div
      className={classnames(
        classes.cardContainer,
        skeletonClasses.feedItemContainer,
      )}
    >
      <div className={skeletonClasses.contentContainer}>
        <div className={skeletonClasses.cardContent}>
          <Skeleton
            width={32}
            height={32}
            variant='rect'
            style={{ marginRight: '16px' }}
          />
          <div>
            <Skeleton variant='text' height={30} width={70} />
            <Skeleton variant='text' width={130} />
          </div>
        </div>
        <Skeleton variant='rect' width={140} height={32} />
      </div>
      <div className={skeletonClasses.bottomInformationWrapper}>
        <div className={skeletonClasses.bottomInformationElement}>
          <Skeleton variant='text' width={50} />
        </div>
        <div className={skeletonClasses.bottomInformationElement}>
          <Skeleton variant='text' width={50} />
        </div>
      </div>
    </div>
  )
}
