import { GanttComponent, GanttFilters } from '@awell/ui-kit/components/Gantt'
import { GanttData } from '@awell/ui-kit/components/Gantt/types'
import {
  FilterVariants,
  SearchFilterBar,
} from '@awell/ui-kit/components/SearchFilterBar'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { capitalize, get, isNil, uniqBy } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Element, usePathwayElements } from '../../hooks/usePathwayElements'
import { usePathwayContextProvider } from '../PathwayContextProvider'
import { TimelineSkeleton } from './skeletons/TimelineSkeleton'
import { ElementStatus, ElementType } from './types'
import { usePreviewGrid } from './useStyles'

const convertElementDataToGantt = (data: Element[]): GanttData[] => {
  return data.map((e: Element) => ({
    id: e.id,
    parent: e.parent_id ?? undefined,
    text: e.name,
    element_type: e.type,
    activity_type: e.activity_type ?? undefined,
    start_date: new Date(e.start_date),
    end_date: isNil(e.end_date) ? new Date() : new Date(e.end_date),
    status: e.status,
    stakeholder: e.stakeholders?.map(({ name }) => name)?.join(' ') || '-',
    stakeholders: e.stakeholders,
    context: e.context,
    open: true,
  }))
}

interface TimelineProps {
  pathwayId: string
}

export const Timeline: FC<TimelineProps> = () => {
  const { elements, loading: elementsLoading } = usePathwayElements()
  const [data, setGanttData] = useState<Array<GanttData>>([])
  const grid = usePreviewGrid()
  const { t } = useTranslation()
  const [filterOptions, setFilterOptions] = useState<Array<FilterVariants>>([])
  const [appliedFilters, setFilters] = useState<GanttFilters>({
    query: '',
    type: [ElementType.Step, ElementType.Pathway, ElementType.Track],
    status: [],
    stakeholder: [],
    activity_type: [],
  })

  const {
    setPanelFromElement,
    loading: activitiesLoading,
  } = usePathwayContextProvider()

  const handleElementClick = (element: GanttData) => {
    if (!isNil(elements)) {
      const selectedElement = elements.find(e => e.id === element.id)
      if (!isNil(selectedElement)) {
        setPanelFromElement(selectedElement)
      }
    }
  }

  const statusDictionary = {
    [ElementStatus.Active]: t('activityfeed:active'),
    [ElementStatus.Done]: t('activityfeed:done'),
    [ElementStatus.Stopped]: t('activityfeed:stopped'),
    [ElementStatus.Scheduled]: t('activityfeed:scheduled'),
  }

  const getStatusOptions = (_data: GanttData[]) => {
    return uniqBy(
      _data.map(element => {
        return {
          // @ts-expect-error: We don't need to worry about the discarded status
          label: capitalize(statusDictionary[element.status]),
          value: element.status,
        }
      }),
      'label',
    )
  }

  const getStakeholderOptions = (_data: GanttData[]) => {
    return uniqBy(
      _data
        .map(d => d.stakeholders)
        .flat()
        .filter(stakeholder => !isNil(stakeholder))
        .map(stakeholder => ({
          label: capitalize(stakeholder!.name ?? ''),
          value: stakeholder!.name ?? '',
        })),
      'value',
    )
  }
  const getActivityTypeOptions = (_data: GanttData[]) => {
    return uniqBy(_data, ({ activity_type }) => activity_type)
      .filter(({ activity_type }) => !isNil(activity_type))
      .map(({ activity_type }) => {
        return {
          label: capitalize(activity_type ?? ''),
          value: activity_type ?? '',
        }
      })
  }
  const getTypeOptions = (_data: GanttData[]) => {
    return uniqBy(_data, ({ element_type }) => element_type).map(
      ({ element_type }) => {
        return {
          label: capitalize(element_type),
          value: element_type,
        }
      },
    )
  }

  useEffect(() => {
    if (elements) {
      const convertedData = convertElementDataToGantt(
        elements.filter(
          ({ status }) =>
            ![ElementStatus.Discarded, ElementStatus.Postponed].includes(
              status,
            ),
        ),
      )
      setGanttData(convertedData)
    }
  }, [elements])

  useEffect(() => {
    if (data) {
      setFilterOptions([
        {
          label: t('element'),
          name: 'type',
          type: 'select',
          options: getTypeOptions(data),
          defaultChecked: appliedFilters.type,
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          options: getStatusOptions(data),
        },
        {
          label: t('stakeholder_plural'),
          name: 'stakeholder',
          type: 'select',
          options: getStakeholderOptions(data),
          disabled: true,
          disabledTooltip: t('enable_activity_to_see_element'),
        },
        {
          label: t('activity_type'),
          name: 'activityType',
          type: 'select',
          options: getActivityTypeOptions(data),
          disabled: true,
          disabledTooltip: t('enable_activity_to_see_element'),
        },
        {
          label: t('track_trigger_plural'),
          name: 'showTracksTriggers',
          type: 'checkbox',
          defaultChecked: false,
          disabled: true,
        },
      ])
    }
  }, [data])

  if (elementsLoading || activitiesLoading || !data || !filterOptions) {
    return <TimelineSkeleton />
  }

  const disableActionRelatedFiltersWhenNonApplicable = (
    typeFilter: Array<string>,
  ) => {
    const filtersCopy = [...filterOptions]
    const isActivityTypeSelected = typeFilter.includes(ElementType.Action)
    // When action is deselected in elements filter we don't want to allow user to select action specific filters

    const getUpdatedFilters = (disabled: boolean) =>
      filtersCopy.map(filter =>
        filter.name === 'stakeholder' || filter.name === 'activity_type'
          ? { ...filter, disabled }
          : filter,
      )
    if (isActivityTypeSelected) {
      setFilterOptions(getUpdatedFilters(false))
    } else {
      setFilterOptions(getUpdatedFilters(true))
    }
  }

  const handleSearchFilter = ({
    query,
    type,
    status,
    stakeholder,
    activity_type,
  }: GanttFilters) => {
    setFilters({
      query,
      stakeholder,
      status,
      type,
      activity_type,
    })
    disableActionRelatedFiltersWhenNonApplicable(type)
  }

  return (
    <div className={grid.container}>
      <div className={grid.actionBar}>
        <SearchFilterBar<GanttFilters>
          filters={filterOptions}
          appliedFilters={appliedFilters}
          onSearchFilter={handleSearchFilter}
          placeholder={t('timeline_search_placeholder')}
          background={colors.neutralLight0}
        />
      </div>
      <div className={grid.previewContent}>
        <GanttComponent
          data={data}
          links={[]}
          onElementClick={handleElementClick}
          filters={appliedFilters}
        />
      </div>
    </div>
  )
}

Timeline.displayName = 'Timeline'
