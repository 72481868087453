/* eslint-disable react/prop-types */
import React, { type FC } from 'react'
import { SidePanel } from '@awell/ui-kit/src/components/SidePanel'
import { findChildByType } from '@awell/ui-kit/src/utils/findChildByType'
import {
  ResizableDivider,
  ResizablePanel,
  useResizable,
} from '@awell/ui-kit/layouts/ResizablePanel'
import classNames from 'classnames'
import { IconButton } from '@awell/ui-kit/components/Button'
import { colors } from '@awell/ui-kit/utils/style-guide'
import { Cross } from '@awell/ui-kit/components/Icons'
import { Flex } from '@awell/ui-kit/components/Flex'
import { usePathwayContextProvider } from '../PathwayContextProvider'
import { useStyles } from './useStyles'
import { CopyToClipboardButton } from '../CopyToClipboardButton'

const Header: FC = ({ children }) => {
  return <>{children}</>
}

const Content: FC = ({ children }) => {
  return <>{children}</>
}

interface Slots {
  Header: FC
  Content: FC
}

interface PathwaySidePanelLayoutProps {
  showTabs?: boolean
  actionButtons?: JSX.Element | Array<JSX.Element>
  showCopyButton?: boolean
  copyUrl?: string
}

export const PathwaySidePanelLayout: FC<PathwaySidePanelLayoutProps> &
  Slots = ({
  showTabs = false,
  actionButtons,
  children,
  showCopyButton = false,
  copyUrl = '',
}) => {
  const header = findChildByType(children, Header)
  const content = findChildByType(children, Content)
  const { sidePanelOpen, onClosePanel } = usePathwayContextProvider()

  const {
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchEnd,
    dragging,
    panelWidth,
    setPanelWidth,
  } = useResizable({
    layoutWidthMultiplier: 0.25,
    onResize: () => null,
    minWidthMultiplier: 0.25,
    maxWidthMultiplier: 0.4,
    panelSide: 'right',
  })
  const classes = useStyles({ panelWidth })

  return (
    <div
      className={classNames(classes.panelWithDivider, {
        [classes.open]: sidePanelOpen,
        [classes.close]: !sidePanelOpen,
      })}
    >
      <ResizableDivider
        transparent
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseUp={onMouseUp}
        dragging={dragging}
        className={classes.panelDivider}
      />
      <ResizablePanel
        panelWidth={panelWidth}
        setPanelWidth={setPanelWidth}
        className={classes.endPanel}
      >
        <SidePanel className={classes.sidePanelContainer} hasTabs={showTabs}>
          <SidePanel.Header>
            {header}
            <div>
              <Flex direction='row'>
                {actionButtons}
                {showCopyButton && <CopyToClipboardButton url={copyUrl} />}
                <IconButton
                  onClick={() => {
                    onClosePanel()
                  }}
                  style={{ padding: 0 }}
                  iconColor={colors.neutralLight100}
                  iconSize='m'
                >
                  <Cross />
                </IconButton>
              </Flex>
            </div>
          </SidePanel.Header>
          <SidePanel.Content>{content}</SidePanel.Content>
        </SidePanel>
      </ResizablePanel>
    </div>
  )
}

PathwaySidePanelLayout.Header = Header
PathwaySidePanelLayout.Content = Content
