import React, { type FC } from 'react'
import { usePreviewStyles } from './useStyles'
import { CenteredLayout } from '../../layouts/CenteredLayout'
import { ScrollContainer } from '../ScrollContainer'
import { Text } from '../Typography'
import { Form } from './Form'
import { PreviewHeader } from './PreviewHeader'
import { useFormContextState } from './contexts'
import { usePreviewContext } from './usePreviewContext'

export interface PreviewProps {
  previewTitle: string
}

export const Preview: FC<PreviewProps> = ({ previewTitle }) => {
  const { questions } = useFormContextState()
  const classes = usePreviewStyles()
  const {
    labels: { no_questions_tip },
  } = usePreviewContext()

  return (
    <section className={classes.previewWrapper}>
      <ScrollContainer dataCy='pathway-form-builder-preview'>
        <CenteredLayout>
          <PreviewHeader previewTitle={previewTitle} />
          {questions.length > 0 && <Form />}
          {questions.length === 0 && (
            <Text variant='tinyHeadline'>{no_questions_tip}</Text>
          )}
        </CenteredLayout>
      </ScrollContainer>
    </section>
  )
}

Preview.displayName = 'FormBuilder.Preview'
