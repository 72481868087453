import * as React from 'react'

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 4a1 1 0 01.993.883L13 5v6h6a1 1 0 01.117 1.993L19 13h-6v6a1 1 0 01-1.993.117L11 19v-6H5a1 1 0 01-.117-1.993L5 11h6V5a1 1 0 011-1z'
      />
    </svg>
  )
}

export default SvgPlus
