import React, { FC } from 'react'
import { ItemProps as VirtuosoItemProps } from 'react-virtuoso'

// this component comes from an example of how to use virtuoso with drag and drop -> https://virtuoso.dev/react-beautiful-dnd-window-scroller/
export const HeightPreservingItem: FC<VirtuosoItemProps> = ({
  children: heightPreservingItemChildren,
  ...props
}) => {
  return (
    // the height is necessary to prevent the item container from collapsing, which confuses Virtuoso measurements
    <div
      {...props}
      style={{
        // eslint-disable-next-line react/destructuring-assignment
        height: props['data-known-size'] || '58px',
        backgroundColor: 'white',
      }}
    >
      {heightPreservingItemChildren}
    </div>
  )
}
