import { ErrorContext, ErrorReporter } from './types'

/**
 * Reason for existence is:
 * - hiding the actual reporter implementation
 * - having 1 single instance of the crash reporter
 */

export class CrashReporter {
  private static reporter: ErrorReporter

  private static getReporter() {
    if (!CrashReporter.reporter) {
      throw new Error(
        'Error Reporter not specified, make sure initializeCrashReporter is called on Application start',
      )
    }
    return CrashReporter.reporter
  }

  static setReporter(reporter: ErrorReporter): void {
    CrashReporter.reporter = reporter
  }

  static setContext(context: ErrorContext): void {
    CrashReporter.getReporter().setContext(context)
  }

  static report(error: Error, data?: Record<string, any>): void {
    CrashReporter.getReporter().report(error, data)
  }
}
