import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@awell/ui-kit/components/Table'
import {
  FilterVariants,
  SearchFilterBar,
} from '@awell/ui-kit/components/SearchFilterBar'
import { RouteComponentProps } from '@reach/router'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { isEmpty, uniqBy } from 'lodash'
import {
  DropdownButton,
  DropdownOption,
} from '@awell/ui-kit/components/DropdownButton'
import { useApiCallsListColumns } from './useApiCallsListColumns'

import { usePathwayContext } from '../../../hooks/usePathwayContext'
import { ApiCallListSkeleton } from './skeleton'
import {
  ApiCallResponse,
  useGetApiCalls,
} from '../../../hooks/useGetPathwayApiCalls'
import { useStyles } from './useStyles'
import { ApiCall } from '../../../hooks/useApiCall'
import { useRetryAllApiCalls } from '../../../hooks/useRetryAllApiCalls'
import { useRetryAllFailedApiCalls } from '../../../hooks/useRetryAllFailedApiCalls'

interface ApiCallFilters {
  statusCodes: Array<string>
  query: string
}

export const ApiCallList: FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const { columns } = useApiCallsListColumns()
  const { pathwayId } = usePathwayContext()
  const classes = useStyles()

  const { loading, apiCalls } = useGetApiCalls(pathwayId)
  const { retryAllFailedApiCalls } = useRetryAllFailedApiCalls()
  const { retryAllApiCalls } = useRetryAllApiCalls()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [appliedFilters, setFilters] = useState<ApiCallFilters>({
    query: '',
    statusCodes: [],
  })
  const [rows, setRows] = useState<Array<ApiCall>>([])
  useEffect(() => {
    if (!loading && !!Array.isArray(apiCalls)) {
      setRows(apiCalls)
    }
  }, [loading, apiCalls?.length])

  if (loading) return <ApiCallListSkeleton />

  const getStatusCodeOptions = (_data: Array<ApiCall>) => {
    return uniqBy(
      _data
        .map(d => d.responses)
        .flat()
        .map(({ status }) => ({
          label: `${status}`,
          value: `${status}`,
        })),
      'value',
    )
  }

  const filters: Array<FilterVariants> = [
    {
      label: t('status'),
      name: 'statusCodes',
      type: 'select',
      options: getStatusCodeOptions(apiCalls || []),
    },
  ]

  const handleSearchByTitle = (title: string, query: string): boolean => {
    if (query === '') {
      return true
    }

    return title.toLowerCase().includes(query.toLowerCase())
  }

  const handleStatusCodeFilter = (
    responses: Array<ApiCallResponse>,
    codes: Array<string>,
  ): boolean => {
    if (isEmpty(codes)) {
      return true
    }
    if (isEmpty(responses)) {
      return false
    }
    return codes.includes(`${responses[responses.length - 1].status}`)
  }

  const handleSearchAndFilters = ({ statusCodes, query }: ApiCallFilters) => {
    setFilters({
      statusCodes,
      query,
    })
    const newRows = (apiCalls || []).filter(({ responses, title }) => {
      return (
        handleStatusCodeFilter(responses, statusCodes) &&
        handleSearchByTitle(title, query)
      )
    })

    setRows(newRows)
  }
  const handleRetryAll = async () => {
    setDropdownOpen(false)
    await retryAllApiCalls(pathwayId || '')
  }

  const handleRetryFailed = async () => {
    setDropdownOpen(false)
    await retryAllFailedApiCalls(pathwayId || '')
  }
  return (
    <div>
      <div className={classes.filtersContainer}>
        <SearchFilterBar<ApiCallFilters>
          onSearchFilter={handleSearchAndFilters}
          filters={filters}
          appliedFilters={appliedFilters}
          placeholder={t('api_call_search_placeholder')}
          customSpacing={`${spacing.xs} 0`}
          hideBorder
        />
        <DropdownButton
          label={t('manage')}
          isOpen={dropdownOpen}
          onClick={setDropdownOpen}
        >
          <DropdownOption
            title={t('api_call_retry_all')}
            onClick={handleRetryAll}
            data-heap='api-calls-retry-all-btn'
            data-product-tour-target='api-calls-retry-all-btn'
          />

          <DropdownOption
            title={t('api_call_retry_all_failed')}
            onClick={handleRetryFailed}
            data-heap='api-calls-retry-failed-btn'
            data-product-tour-target='api-calls-retry-failed-btn'
          />
        </DropdownButton>
      </div>
      <div>
        <Table
          columns={columns}
          rows={rows}
          fullWidth
          paging
          nonTablePageContentHeight={500}
        />
      </div>
    </div>
  )
}

ApiCallList.displayName = 'ApiCallList'
