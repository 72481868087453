import * as React from 'react'

function SvgClinicalNote(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.08 3.418h-1.924v-1.06c0-.204-.128-.341-.32-.341h-1.059C10.617.889 9.72 0 8.597 0 7.472 0 6.574.889 6.414 2.017H5.356c-.192 0-.32.137-.32.342v1.094H2.95C1.315 3.453 0 4.854 0 6.598v11.827c0 1.743 1.315 3.145 2.95 3.145H14.05c1.636 0 2.95-1.402 2.95-3.145V6.598c.033-1.744-1.282-3.18-2.918-3.18zM8.597.684c.77 0 1.379.58 1.54 1.333h-3.08c.16-.752.77-1.333 1.54-1.333zM5.676 2.7h5.806v2.257H5.677V2.7zm10.682 15.725c0 1.367-.995 2.461-2.278 2.461H2.95c-1.283 0-2.309-1.094-2.309-2.46V6.597c0-1.368 1.059-2.462 2.31-2.462h2.084v1.162c0 .206.129.342.321.342h6.447c.192 0 .32-.136.32-.341V4.101h1.925c1.283 0 2.31 1.094 2.31 2.461v11.862z'
        fill='currentColor'
      />
      <path
        d='M11.636 11.893h-1.394v-1.338c0-.669-.446-1.226-1.06-1.338-.39-.084-.78.028-1.059.279-.306.25-.474.613-.474 1.003v1.394H6.311c-.669 0-1.226.446-1.338 1.06-.083.39.028.78.279 1.059.25.306.613.473 1.003.473H7.65v1.394c0 .39.168.753.474 1.004.223.195.53.307.837.307.083 0 .167 0 .223-.028.613-.112 1.059-.67 1.059-1.338v-1.338h1.338c.669 0 1.227-.447 1.338-1.06.084-.39-.028-.78-.278-1.059a1.283 1.283 0 00-1.004-.474zm.725 1.45c-.056.334-.39.585-.781.585H9.99a.31.31 0 00-.306.307v1.589c0 .39-.25.725-.585.78a.798.798 0 01-.614-.167.727.727 0 01-.278-.558v-1.644a.31.31 0 00-.307-.307H6.255a.651.651 0 01-.557-.279.799.799 0 01-.168-.613c.056-.335.39-.585.78-.585H7.9a.31.31 0 00.307-.307v-1.645c0-.223.083-.418.278-.557a.799.799 0 01.614-.168c.334.056.585.39.585.78v1.59c0 .167.14.306.307.306h1.645c.223 0 .418.084.557.28.168.167.223.39.168.613z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgClinicalNote
