import { makeStyles } from '@material-ui/core'
import { type, spacing, colors } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  headerButton: {
    display: 'flex',
    marginLeft: spacing.xs,
  },
  logo: {
    height: spacing.m,
  },
  pageTitle: {
    ...spacing.paddingX.s,
  },
  iconSpacing: {
    marginRight: spacing.xs,
  },
  status: {
    fontSize: type.xs.fontSize,
    color: colors.neutralDark500,
    marginTop: 3, // keeps text baseline aligned with page title
  },
  breadcrumbs: {
    marginLeft: spacing.xs,
  },
  tabButtons: {
    display: 'flex',
    paddingLeft: spacing.xs,
    paddingRight: spacing.xxs,
    height: '100%',
  },
})
