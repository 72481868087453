import { type RouteComponentProps } from '@reach/router'
import React, { useEffect, type FC } from 'react'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { isNil } from 'lodash'
import { usePublishedPathwayDefinition } from '../../../hooks/usePublishedPathwayDefinition'
import { WorkingListTable } from '../../../components/WorkingListTable'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { useStakeholdersByPathwayDefinitionIds } from '../../../hooks/useStakeholders'

interface WorkingListParams {
  pathwayDefinitionId: string
}

export const WorkingListForPathwayDefinition: FC<
  RouteComponentProps<WorkingListParams>
> = ({ pathwayDefinitionId = '' }) => {
  const { addBreadcrumbs, updateBreadcrumb } = useBreadcrumbs()
  const { publishedPathwayDefinition, loading: loadingPathwayDefinition } =
    usePublishedPathwayDefinition(pathwayDefinitionId)

  const { stakeholders } = useStakeholdersByPathwayDefinitionIds({
    pathwayDefinitionIds: [pathwayDefinitionId],
  })

  const breadcrumb = {
    label: !isNil(publishedPathwayDefinition)
      ? publishedPathwayDefinition.title
      : pathwayDefinitionId,
    url: `/activities/${pathwayDefinitionId}`,
    level: 1,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  useEffect(() => {
    updateBreadcrumb(breadcrumb)
  }, [publishedPathwayDefinition])

  if (loadingPathwayDefinition) {
    return <Spinner />
  }

  if (isNil(publishedPathwayDefinition)) {
    return (
      <div>Could not load pathway definition with id {pathwayDefinitionId}</div>
    )
  }

  return (
    <WorkingListTable
      pathwayDefinitionId={pathwayDefinitionId}
      stakeholders={stakeholders}
    />
  )
}
