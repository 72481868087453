import { isNil } from 'lodash'
import { type Maybe } from '../../../../utils'
import { type NumberConfig } from '../../types'

export const getMaxValueForNumberInput = (
  numberConfig: Maybe<NumberConfig> | undefined,
): number | undefined => {
  if (isNil(numberConfig) || isNil(numberConfig.range)) {
    return undefined
  }

  if (numberConfig.range.enabled === true && !isNil(numberConfig.range.max)) {
    return numberConfig.range.max
  }

  return undefined
}
