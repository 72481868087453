import * as React from 'react'

function SvgWarningRound(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={32} cy={32} r={32} fill='#E6EDF9' />
      <circle cx={32} cy={32} r={23} fill='#C5D8F9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.867 21.499a1 1 0 00-1.737 0L19.893 41.172a1 1 0 00.868 1.495h22.475a1 1 0 00.868-1.495L32.867 21.499zm-2.2 6.168a1 1 0 011-1h.667a1 1 0 011 1v6a1 1 0 01-1 1h-.667a1 1 0 01-1-1v-6zm0 10.666a1 1 0 011-1h.628a1 1 0 011 1v.588a1 1 0 01-1 1h-.628a1 1 0 01-1-1v-.588z'
        fill='#3476E0'
      />
    </svg>
  )
}

export default SvgWarningRound
