import { makeStyles } from '@material-ui/core'
import { colors, rounded, spacing } from '../../utils/style-guide'
import { isNil } from 'lodash'
interface InfoCardProps {
  backgroundColor?: string
  color?: string
  padding?: string
}

export const useStyles = makeStyles({
  noteContainer: {
    ...rounded.l,
    backgroundColor: (props: InfoCardProps) =>
      !isNil(props.backgroundColor)
        ? `${props.backgroundColor}`
        : colors.brand10,
    width: '100%',
    display: 'flex',
    padding: (props: InfoCardProps) =>
      !isNil(props.padding) ? `${props.padding}` : spacing.xs,
    alignItems: 'center',
  },
  reminderNote: {
    display: 'flex',
    alignItems: 'center',
  },
  noteIconWrapper: {
    color: colors.neutralLight0,
    borderRadius: '50%',
    background: (props: InfoCardProps) =>
      !isNil(props.color) ? `${props.color}` : colors.brand300,
    width: spacing.s,
  },
})
