import { endOfWeek, format, getMinutes, startOfWeek } from 'date-fns'
import { floor, capitalize, isNil } from 'lodash'
import React from 'react'
import { renderToString } from 'react-dom/server'
import { TFunction } from 'react-i18next'
import { ElementStatus } from '../../generated/types-design'
import { colors, spacing } from '../../utils/style-guide'
import {
  FeedCalculation,
  FeedChecklist,
  FeedForm,
  FeedMessage,
  Emr,
  Graphql,
  ClinicalNote,
} from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { GanttConfigOptions, GanttStatic } from './dhtmlxGantt/dhtmlxgantt'
import { ActionType, GanttData, ElementType } from './types'

// This shows date in format '1-Jan-2022 1:30 PM'
const TIME_FORMAT_FOR_GANTT = 'd-LLL-yyyy p'

export const config: (
  t: TFunction<string>,
) => Partial<GanttConfigOptions> = translationFn => {
  return {
    drag_progress: false,
    drag_links: false,
    drag_resize: false,
    drag_move: false,
    drag_mode: 'move',
    // Date format: https://docs.dhtmlx.com/gantt/desktop__date_format.html
    // This matches the date format above (TIME_FORMAT_FOR_GANTT): 15-Mar-2022 11:30 PM
    date_grid: '%j-%M-%Y %g:%i %A',
    readonly: true,
    types: {
      task: {
        template: 'task_template',
        label: translationFn('activity'),
      },
      project: {
        template: 'project_template',
        label: translationFn('track'),
      },
      milestone: {
        template: 'milestone_template',
        label: translationFn('pathway'),
      },
    },
    fit_tasks: false,
    duration_unit: 'day',
    duration_step: 2,
    open_tree_initially: true,
    bar_height: 24,
    preserve_scroll: false,
    row_height: 40,
    smart_scales: true,
    multiselect: false,
    columns: [
      {
        name: 'text',
        label: translationFn('element'),
        tree: true,
        width: 200,
        resize: false,
      },
      {
        name: 'status',
        label: translationFn('status'),
        width: 100,
        resize: false,
      },
      {
        name: 'start_date',
        label: translationFn('activation_date'),
        align: 'center',
        width: 150,
        resize: false,
      },
      {
        name: 'stakeholder',
        label: translationFn('stakeholder_plural'),
        align: 'center',
        width: 100,
        resize: false,
      },
    ],
    date_format: '%l, %j-%M-%Y %g:%i %A',
    start_on_monday: true,
  }
}

const getGanttGridFileIcon = (activity_type: ActionType): string => {
  switch (activity_type) {
    case ActionType.Message:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        >
          <FeedMessage />
        </IconWrapper>,
      )
    case ActionType.Form:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        >
          <FeedForm />
        </IconWrapper>,
      )
    case ActionType.Calculation:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{
            marginRight: spacing.xxs,
            marginTop: spacing.xxxs,
          }}
        >
          <FeedCalculation />
        </IconWrapper>,
      )
    case ActionType.Checklist:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        >
          <FeedChecklist />
        </IconWrapper>,
      )
    case ActionType.ApiCall:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        >
          <Emr />
        </IconWrapper>,
      )
    case ActionType.ApiCallGraphql:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        >
          <Graphql />
        </IconWrapper>,
      )
    case ActionType.ClinicalNote:
      return renderToString(
        <IconWrapper
          bare
          size='xsm'
          color={colors.neutralLight20}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        >
          <ClinicalNote />
        </IconWrapper>,
      )
    default:
      return ''
  }
}

export const getTooltipText = (
  start: Date,
  end: Date,
  task: GanttData,
  t: TFunction<string>,
): string => {
  switch (task.element_type) {
    case ElementType.Pathway:
      return `<b>${t('pathway')}:</b> ${task.text}<br/>
      <b>${t('start_date')}</b>: ${format(start, TIME_FORMAT_FOR_GANTT)}<br/>
      <b>${t('end_date')}</b>: ${format(end, TIME_FORMAT_FOR_GANTT)}`

    case ElementType.Track:
      return `<b>${t('track')}:</b> ${task.text}<br/>
      <b>${t('start_date')}</b>: ${format(start, TIME_FORMAT_FOR_GANTT)}<br/>
      <b>${t('end_date')}</b>: ${format(end, TIME_FORMAT_FOR_GANTT)}`
    case ElementType.Step:
      return `<b>${t('step')}:</b> ${task.text}<br/>
      <b>${t('start_date')}</b>: ${format(start, TIME_FORMAT_FOR_GANTT)}<br/>
      <b>${t('end_date')}</b>: ${format(end, TIME_FORMAT_FOR_GANTT)}`
    case ElementType.Action:
      return `<b>${t('activity')}:</b> ${task.text}<br/>
      <b>${t('type')}</b>: ${capitalize(
        task.activity_type?.split('_').join(' '),
      )}<br/>
      <b>${t('start_date')}</b>: ${format(start, TIME_FORMAT_FOR_GANTT)}<br/>
      <b>${t('end_date')}</b>: ${format(end, TIME_FORMAT_FOR_GANTT)}`
    default:
      return ''
  }
}

export const getTimelineCellClass = (gantt: GanttStatic) => (
  _item: any,
  date: Date,
): string => {
  const isDayZoom = gantt.ext.zoom.getCurrentLevel() === 2
  if (isDayZoom && (date.getDay() === 0 || date.getDay() === 6)) {
    return 'gantt-timeline-cell__weekend'
  }
  return ''
}

export const getGridFolder = (_task: GanttData): string => ''

export const getGridFile = (task: GanttData): string => {
  if (task.element_type === ElementType.Action && !isNil(task.activity_type)) {
    return getGanttGridFileIcon(task.activity_type)
  }
  return ''
}

export const getGridRowClass = (
  start: Date,
  end: Date,
  task: GanttData,
): string => {
  if (task.element_type === ElementType.Pathway) {
    return 'gantt_row--pathway'
  }
  if (task.element_type === ElementType.Action) {
    return 'gantt_row--action'
  }
  if (task.element_type === ElementType.Step) {
    return 'gantt_row--step'
  }
  if (task.element_type === ElementType.Track) {
    return 'gantt_row--track'
  }
  return ''
}

export const getTaskRowClass = (
  start: Date,
  end: Date,
  task: GanttData,
): string => {
  if (task.element_type === ElementType.Pathway) {
    return 'task_row--pathway'
  }
  return ''
}

export const getTaskClass = (
  start: Date,
  end: Date,
  task: GanttData,
): string => {
  const isCompleted = task.status === ElementStatus.Done
  const isStopped = task.status === ElementStatus.Stopped

  switch (task.element_type) {
    case ElementType.Pathway:
      return `bar__pathway ${isCompleted ? 'completed' : ''}${
        isStopped ? 'stopped' : ''
      }`
    case ElementType.Track:
      return `bar__track ${isCompleted ? 'completed' : ''}${
        isStopped ? 'stopped' : ''
      }`

    case ElementType.Step:
      return `bar__step ${isCompleted ? 'completed' : ''}${
        isStopped ? 'stopped' : ''
      }`

    case ElementType.Action:
      return `bar__action ${isCompleted ? 'completed' : ''}${
        isStopped ? 'stopped' : ''
      }`

    default:
      return ''
  }
}

type ZoomLevels =
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year'

/**
 * Each scale in a zoom level generates a header row with information about
 * the task date.
 *
 * Example:
 *
 * Scale #1: shows the day formatted as `February 12`
 *
 * Scale #2: shows the hour of day formatted as `1PM`
 *
 * => Generates a header with
 * - First row: `February 12` | `February 13` | `February 14` ...
 * - Second row: `1pm` | `2pm` | `3pm` | `4pm` ...
 */
interface ZoomLevelScale {
  unit: ZoomLevels
  step: number
  format: string | ((date: Date) => string)
  css?: () => string
}

export interface ZoomLevel {
  name: ZoomLevels
  scale_height: number
  min_column_width: number
  scales: Array<ZoomLevelScale>
}

export interface ZoomConfig {
  levels: Array<ZoomLevel>
}

export const minuteScale: ZoomLevelScale = {
  unit: 'minute',
  step: 15,
  format(date: Date) {
    // Round minutes to the nearest 15' step
    return `${floor(getMinutes(date) / 15) * 15}`
  },
}

export const hourScale: ZoomLevelScale = {
  unit: 'hour',
  step: 1,
  format(date: Date) {
    return format(date, 'h aa')
  },
}

export const dayScale: ZoomLevelScale = {
  unit: 'day',
  step: 1,
  format(date: Date) {
    return format(date, 'd-LLL-yyyy')
  },
}

export const shortDayScale: ZoomLevelScale = {
  unit: 'day',
  step: 1,
  format(date: Date) {
    return format(date, 'd')
  },
}

export const weekNumberScale: ZoomLevelScale = {
  unit: 'week',
  step: 1,
  format(date: Date) {
    return format(date, "'Week' I")
  },
}

export const weekStartEndDaysScale: ZoomLevelScale = {
  unit: 'week',
  step: 1,
  format(date: Date) {
    const dateFormat = 'MMMM d'
    const weekStart = startOfWeek(date, { weekStartsOn: 1 }) // week starts on monday
    const weekEnd = endOfWeek(date, { weekStartsOn: 1 }) // week starts on monday
    return `${format(weekStart, dateFormat)} - ${format(weekEnd, dateFormat)}`
  },
}

export const monthScale: ZoomLevelScale = {
  unit: 'month',
  step: 1,
  format(date: Date) {
    return format(date, 'MMMM')
  },
  css: (): string => 'gantt-scale-cell--bold',
}

export const quarterScale: ZoomLevelScale = {
  unit: 'quarter',
  step: 1,
  format: (date: Date): string => {
    return format(date, "'Quarter' Q")
  },
}

export const yearScale: ZoomLevelScale = {
  unit: 'year',
  step: 1,
  format(date: Date) {
    return format(date, 'yyyy')
  },
}

export const zoomConfig: ZoomConfig = {
  levels: [
    // Disable minute zoom level as the scales are not properly aligned
    // {
    //   name: 'minute',
    //   scale_height: 75,
    //   min_column_width: 25,
    //   scales: [
    //     { ...dayScale, css: (): string => 'gantt-scale-cell--bold' },
    //     { ...hourScale, css: (): string => 'gantt-scale-cell--bold' },
    //     minuteScale,
    //   ],
    // },
    {
      name: 'hour',
      scale_height: 64,
      min_column_width: 50,
      scales: [
        {
          ...dayScale,
          css: (): string => 'gantt-scale-cell--bold',
        },
        hourScale,
      ],
    },
    {
      name: 'day',
      scale_height: 64,
      min_column_width: 150,
      scales: [
        {
          ...monthScale,
          css: (): string => 'gantt-scale-cell--bold',
        },
        {
          ...shortDayScale,
          css: (): string => 'gantt-scale__day',
        },
      ],
    },
    {
      name: 'week',
      scale_height: 64,
      min_column_width: 200,
      scales: [
        {
          ...weekNumberScale,
          css: (): string => 'gantt-scale-cell--bold',
        },
        weekStartEndDaysScale,
      ],
    },
    {
      name: 'month',
      scale_height: 64,
      min_column_width: 120,
      scales: [
        {
          ...yearScale,
          css: (): string => 'gantt-scale-cell--bold',
        },
        monthScale,
      ],
    },
    {
      name: 'quarter',
      scale_height: 75,
      min_column_width: 150,
      scales: [
        {
          ...yearScale,
          css: (): string => 'gantt-scale-cell--bold',
        },
        quarterScale,
      ],
    },
    {
      name: 'year',
      scale_height: 50,
      min_column_width: 200,
      scales: [
        {
          ...yearScale,
          css: (): string => 'gantt-scale-cell--bold',
        },
      ],
    },
  ],
}
