// @ts-nocheck TODO: remove this comment and fix errors
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { useEffect } from 'react'
import { isNil } from 'lodash'
import { updateQuery } from '@awell/libs-web/graphql'
import { useNotifications } from '../useNotifications'
import {
  useGetPathwayWebhookCallsQuery,
  GetPathwayWebhookCallsQueryVariables,
  useOnWebhookCallCreatedSubscription,
  useOnWebhookCallUpdatedSubscription,
  GetPathwayWebhookCallsQuery,
  GetPathwayWebhookCallsDocument,
} from './types'
import type { WebhookCall } from './types'

interface useGetWebhooksHook {
  loading: boolean
  webhookCalls?: Array<WebhookCall>
}

export const useGetPathwayWebhookCalls = (
  pathwayId: string,
): useGetWebhooksHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const variables: GetPathwayWebhookCallsQueryVariables = {
    pathway_id: pathwayId,
  }

  const client = useApolloClient()

  const { data, loading, error } = useGetPathwayWebhookCallsQuery({
    variables,
  })

  const onWebhookCallCreated = useOnWebhookCallCreatedSubscription({
    variables,
  })

  useOnWebhookCallUpdatedSubscription({ variables })

  const webhookCalls = data?.webhookCalls.webhook_calls ?? []

  const updateWebhookCallsQuery = ({
    updatedWebhookCalls,
  }: {
    updatedWebhookCalls: Array<WebhookCall>
  }) => {
    const updatedQuery = updateQuery<
      GetPathwayWebhookCallsQuery,
      Array<WebhookCall>
    >(data, ['webhookCalls', 'webhook_calls'], updatedWebhookCalls)
    client.writeQuery({
      query: GetPathwayWebhookCallsDocument,
      variables,
      data: updatedQuery,
    })
  }

  if (error) {
    notifyError({
      message: t('error_load_webhook_plural'),
      error,
    })
    return { loading: false }
  }

  useEffect(() => {
    if (!isNil(onWebhookCallCreated.data)) {
      const {
        data: { webhookCallCreated },
      } = onWebhookCallCreated
      updateWebhookCallsQuery({
        updatedWebhookCalls: [...webhookCalls, webhookCallCreated],
      })
    }
  }, [onWebhookCallCreated.data])

  if (loading) {
    return { loading: true }
  }

  return {
    loading: false,
    webhookCalls,
  }
}
