import React, { createContext, useContext } from 'react'
import { FormMode } from '../../../enums'
import {
  type FormSettingsContextProps,
  type FormSettingsContextInterface,
} from './types'

// todo change name to static settings context
const initialSettingsContext = {
  mode: FormMode.FormPreview,
  buttonStyle: undefined,
  labels: {
    mandatory_error_text: 'This field is required',
    pagination_currently_shown_items_info: () => '',
    submit_button: 'Submit',
    invalid_error_text: 'This field is invalid',
    icd_10_description:
      'Preview mode shows an example list of medical conditions; not all conditions are included.',
  },
  NL: false,
  preview: false,
}
const FormSettingsContext = createContext<FormSettingsContextInterface>(
  initialSettingsContext,
)
const FormSettingsContextProvider = ({
  children,
  buttonStyle = 'contained',
  mode,
  NL,
  labels,
  preview,
}: FormSettingsContextProps): JSX.Element => {
  return (
    <FormSettingsContext.Provider
      // @ts-expect-error FIXME: fix FormSettingsContextProps using Partial<FormSettingsContextInterface> in types.ts
      value={{ mode, NL, labels, buttonStyle, preview }}
    >
      {children}
    </FormSettingsContext.Provider>
  )
}

const useFormSettingsContext = (): FormSettingsContextInterface =>
  useContext(FormSettingsContext)

export { FormSettingsContextProvider, useFormSettingsContext }
