import * as React from 'react'

function SvgTransition(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path fill='#fff' d='M0 0h24v24H0z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm5 7H5v10h4V7zm12 5l-4-5v3h-6v4h6v3l4-5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTransition
