import Image from '@material-ui/icons/Image'
import Movie from '@material-ui/icons/Movie'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '../../Tooltip'
import { type UploadWidgetConfig } from '../../UploadWidget'
import { DialogType } from '../types'
import { ToolbarButtonMedia } from './ToolbarButtonMedia'
import { type DialogLabels, useSlateDialog } from './useSlateDialog'

export enum MediaToolsMode {
  ImageOnly = 'image-only',
  VideoOnly = 'video-only',
  All = 'all',
  None = 'none',
}

interface MediaToolsProps {
  id: string
  config?: UploadWidgetConfig
  tools?: MediaToolsMode
}

export const MediaTools: FC<MediaToolsProps> = ({
  id,
  tools = MediaToolsMode.All,
  config = {
    uploadPreset: '',
    cloudName: '',
  },
}) => {
  const { t } = useTranslation()
  const dialogLabels: DialogLabels = {
    insertVideo: t('insert_video'),
    insertImage: t('insert_image'),
    insertLink: t('insert_link'),
    updateLink: t('update_link'),
    placeholderUrl: t('placeholder_paste_url'),
    insert: t('insert'),
    remove: t('remove'),
    cancel: t('cancel'),
  }

  const { getUrlWithDialog, position } = useSlateDialog({
    dialogLabels,
    id,
    config,
  })

  return (
    <>
      {(tools === MediaToolsMode.All || tools === MediaToolsMode.ImageOnly) && (
        <Tooltip info='Image' arrow>
          {/* span required for tooltip to work */}
          <span>
            <ToolbarButtonMedia
              type={DialogType.image}
              icon={<Image />}
              getUrl={getUrlWithDialog}
              position={position}
              id={id}
            />
          </span>
        </Tooltip>
      )}
      {(tools === MediaToolsMode.All || tools === MediaToolsMode.VideoOnly) && (
        <Tooltip info='Media' arrow>
          <span>
            <ToolbarButtonMedia
              type={DialogType.video}
              icon={<Movie />}
              getUrl={getUrlWithDialog}
              position={position}
              id={id}
            />
          </span>
        </Tooltip>
      )}
    </>
  )
}

MediaTools.displayName = 'MediaTools'
